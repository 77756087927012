import uniqeBy from '@/utils/uniqueBy';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useRef } from 'react';
import styled from 'styled-components';

/**
 * The style for the items in the selected item area.
 */
const ListItemContainer = styled.div`
  margin: 0.5em;
`;

/**
 * This interface describes the props that can be passed to
 * the ItemAssigner Component.
 * TItem indicates the type of the treated items in the assigner.
 */
interface ItemAssignerProps<TItem> {
  /**
   * The items to display in the selection dropdown.
   */
  dropdownItems?: TItem[];

  /**
   * The currently selected item.
   * To be passed from the parent, but updated in the Component.
   */
  selectedItem?: TItem | null;

  /**
   * Callback to update the selected item.
   */
  setSelectedItem?: (selectedItem: TItem | undefined) => void;

  /**
   * Logic to determine whether disabling the dropdown.
   */
  dropdownDisabled?: boolean;

  /**
   * Logic to determine whether rendering the default assignment.
   */
  hasDefaultAssignment?: boolean;

  /**
   * The default assignment information to be displayed, if any.
   */
  defaultAssignment?: React.ReactNode;

  /**
   * The assigned items to be passed down from the parent.
   */
  assignedItems: TItem[];

  /**
   * Callback to update the assigned items.
   */
  setAssignedItems: (assignedItems: TItem[]) => void;

  /**
   * Logic to determine whether to render the remove button
   * in the assigned items list.
   */
  hasRemoveButton?: boolean;

  /**
   * Logic to determine whether to render the add all button.
   */
  hasAddAllButton?: boolean;

  /**
   * Whether to render the upload button.
   */
  hasUploadButton?: boolean;

  /**
   * Logic to determine whether to disable but keep visible the remove button.
   */
  disableRemoveButton?: boolean;

  /**
   * The "remove" button label as a React.ReactNode, so that
   * an entire JSX element can be passed.
   * e.g.: useful to pass down localized labels through a t("...")
   */
  removeButtonLabel: React.ReactNode;

  /**
   * The "add all" button label as a React.ReactNode, so that
   * an entire JSX element can be passed.
   * e.g.: useful to pass down localized labels through a t("...")
   */
  addAllButtonLabel?: React.ReactNode;

  /**
   * The "upload" button label as a React.ReactNode, so that
   * an entire JSX element can be passed.
   * e.g.: useful to pass down localized labels through a t("...")
   */
  uploadButtonLabel?: React.ReactNode;

  /**
   * Whether to disable the "add" button.
   */
  disableAddButton?: boolean;

  /**
   * Whether to disable the "add all" button.
   */
  disableAddAllButton?: boolean;

  /**
   * Whether to disable the "upload" button.
   */
  disableUploadButton?: boolean;

  /**
   * Ref to the file input in order to interact with it if necessary.
   */
  uploadRef?: React.RefObject<HTMLInputElement> | null;

  /**
   * Callback for the "upload" button click.
   */
  onUploadButtonChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * Custom dropdown render logic.
   */
  renderDropdown?: () => React.ReactNode;
}

/**
 * Necessary item shape to treat them inside the assigner.
 */
interface ItemShape {
  id: number;
  name: string;
  description?: string;
}

/**
 *
 * This Component handles the assignment of items to a specific target model.
 *
 * @returns the Component to be used in JSXs.
 */
export default function ItemAssigner<TItem extends ItemShape>({
  dropdownItems,
  selectedItem,
  setSelectedItem,
  dropdownDisabled,
  hasDefaultAssignment,
  defaultAssignment,
  assignedItems,
  setAssignedItems,
  disableRemoveButton,
  disableAddButton,
  disableUploadButton,
  removeButtonLabel,
  addAllButtonLabel,
  uploadButtonLabel,

  hasRemoveButton = true,
  hasAddAllButton = false,
  hasUploadButton = false,
  disableAddAllButton = false,

  uploadRef = null,

  onUploadButtonChange,
  renderDropdown,
}: ItemAssignerProps<TItem>) {
  if (!renderDropdown && !setSelectedItem) {
    console.error('cannot have setSelectedItem undefined if renderDropdown is also undefined.');

    return null;
  }

  if (hasUploadButton && !onUploadButtonChange) {
    console.warn('No callback for the upload button onChange event has been assigned.');
  }

  return (
    <div className="d-flex">
      <div className="d-flex flex-column align-items-stretch" style={{ flex: 1 }}>
        {renderDropdown ? (
          renderDropdown()
        ) : (
          <DropDownList
            className="mb-3 w-100"
            data={dropdownItems}
            value={selectedItem}
            textField="name"
            dataItemKey="id"
            onChange={(e) => setSelectedItem!(e.target.value)}
            disabled={dropdownDisabled}
          />
        )}

        <div style={{ minHeight: 100, backgroundColor: '#e4e7eb' }}>
          {hasDefaultAssignment ? (
            <ListItemContainer>{defaultAssignment}</ListItemContainer>
          ) : (
            assignedItems.map((item, index) => (
              <ListItemContainer key={index}>
                <span>{item.name}</span>
                {item.description && <span>&nbsp;{item.description}</span>}
                {hasRemoveButton && (
                  <button
                    type="button"
                    className="ms-2 btn btn-danger btn-sm"
                    onClick={() => {
                      const itemToRemove = assignedItems.findIndex((assignedItem) => assignedItem.id === item.id);
                      const newItems = [...assignedItems];
                      newItems.splice(itemToRemove, 1);

                      setAssignedItems!(newItems);
                    }}
                    disabled={disableRemoveButton}
                  >
                    {removeButtonLabel}
                  </button>
                )}
              </ListItemContainer>
            ))
          )}
        </div>
      </div>

      <button
        type="button"
        className="ms-2 btn btn-primary p-1 py-0 rounded-circle fw-bold align-self-start"
        onClick={() => {
          if (selectedItem && !assignedItems.some((shop) => shop.id === selectedItem.id)) {
            setAssignedItems([...assignedItems, selectedItem]);
          }
        }}
        disabled={disableAddButton}
      >
        <span style={{ fontSize: 36, lineHeight: 0.65, alignSelf: 'center' }}>+</span>
      </button>
      {hasAddAllButton && (
        <button
          type="button"
          className="ms-2 btn btn-primary p-1 py-0 align-self-start"
          style={{ height: 34 }}
          onClick={() => {
            const newItemsToAssign = uniqeBy([...assignedItems, ...(dropdownItems ?? [])], (item) => item.id);
            setAssignedItems(newItemsToAssign);
          }}
          disabled={disableAddAllButton}
        >
          <span style={{ padding: '0 0.5rem', whiteSpace: 'nowrap' }}>{addAllButtonLabel}</span>
        </button>
      )}
      {hasUploadButton && (
        <>
          <label
            htmlFor="uploadPostalCodes"
            className="ms-2 btn btn-secondary p-1 py-0 align-self-start d-flex align-items-center"
            style={{ cursor: 'pointer', pointerEvents: disableUploadButton ? 'none' : 'auto', height: 34 }}
          >
            <span style={{ padding: '0 0.5rem' }}>{uploadButtonLabel}</span>
          </label>
          <input
            id="uploadPostalCodes"
            disabled={disableUploadButton}
            className="form-control"
            type="file"
            accept="text/csv"
            ref={uploadRef}
            style={{ display: 'none' }}
            onChange={onUploadButtonChange}
          />
        </>
      )}
    </div>
  );
}
