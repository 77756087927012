import moment, { Moment } from 'moment';

export function convertHoursInUtc(date: Date, hours: string): string {
  return `${moment(date)
    .hour(Number(hours.split(':')[0]))
    .utc()
    .hour()
    .toString()}:00`;
}

export function convertHoursFromUtcInLocale(utcDate: Date, utcHours: string): string {
  const utcOffset = Date.UTC(
    utcDate.getFullYear(),
    utcDate.getMonth(),
    utcDate.getDate(),
    Number(utcHours.split(':')[0]),
  );

  const newUtcDate = new Date(utcOffset);

  return `${newUtcDate.getHours().toString()}:00`;
}

export function convertDateInUtc(date: Date, hours: string): Moment {
  const newDate = new Date(date);

  newDate.setHours(Number(hours.split(':')[0]));

  return moment(newDate).utc();
}

export function convertDateFromUtcInLocale(utcDate: Date, utcHours?: string): Date {
  const utcOffset = Date.UTC(
    utcDate.getFullYear(),
    utcDate.getMonth(),
    utcDate.getDate(),
    Number(utcHours?.split(':')[0]),
  );

  return new Date(utcOffset);
}
