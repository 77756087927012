import { BaseService } from './BaseService';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { ShopType } from '../models/ShopType';

/**
 * The service class to interact with the Shops.
 */
export class ShopTypesService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/shoptypes');
  }

  /**
   * Retrieves all the shops.
   * @returns A list of all the shops
   */
  getAll(): Promise<ShopType[]> {
    return this.get('/list');
  }
}
