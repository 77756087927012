import ActionButtons from '@/components/common/action-buttons/ActionButtons';
import { KendoCellRenderer } from '@/components/common/kendo-grid-renderers/KendoGridRenderer';
import Spinner from '@/components/common/spinner/Spinner';
import { Constants } from '@/constants/Constants';
import useApplicationState from '@/hooks/useApplicationState';
import useCurrentLanguage from '@/hooks/useCurrentLanguage';
import { useCurrentLocale } from '@/hooks/useCurrentLocale';
import useErrorHandling from '@/hooks/useErrorHandling';
import useIsInRole from '@/hooks/useIsInRole';
import useIsReadOnly from '@/hooks/useIsReadOnly';
import usePostalCodeService from '@/hooks/usePostalCodeService';
import useZonesService from '@/hooks/useZonesService';
import {
  Disporegion,
  ErrorViewModel,
  isErrorViewModel,
  PostalCode,
  ProductType,
  Shop,
  Zone,
  ZoneProduct,
} from '@/models';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridItemChangeEvent,
  GridSortChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { saveAs } from 'file-saver';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CopyButton from '../../../common/copy-button/CopyButton';
import DeleteButton from '../../../common/delete-button/DeleteButton';
import DownloadButton from '../../../common/download-button/DownloadButton';
import EditButton from '../../../common/edit-button/EditButton';
import LinkButton from '../../../common/link-button/LinkButton';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import CheckPriceForm from '../check-price-form/CheckPriceForm';
import InspectPostalCodes from '../inspect-postal-codes/InspectPostalCodes';
import AddZoneForm from '../new-form/AddZoneForm';
import CheckPriceButton from './check-price-button/CheckPriceButton';
import { IGridZone, IsIGridZoneKey } from './IGridZone';
import InspectPostalCodesButton from './inspect-postal-codes-button/InspectPostalCodesButton';
import uniqueBy from '@/utils/uniqueBy';
import { formatters } from '@/utils/formatters';
import { DieselProductTypeId, DieselProductTypeName } from '@/assets/constants';
import useIsDieselAdministrator from '@/hooks/useIsDieselAdministrator';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 20px;
`;

const GlobalUseCustomTransportCostPriceContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const initialSort: Array<SortDescriptor> = [{ field: 'name', dir: 'asc' }];

/**
 * A wrapper for the zones table.
 */
function ViewZones() {
  /**
   * Stores a value indicating if the user is administrator or not.
   */
  const isAdministrator = useIsInRole(Constants.Roles.Administrator);
  const isHoldingUser = useIsInRole(Constants.Roles.HoldingUser);

  /**
   * Stores a value indicating if the user is read only or not.
   */
  const isReadOnly = useIsReadOnly();
  const isDieselAdministrator = useIsDieselAdministrator();

  const appInsights = useAppInsightsContext();

  const { errors, setErrors } = useErrorHandling();

  const { shops: userShops, selectedShop, selectedProduct, role } = useApplicationState();

  const zonesService = useZonesService();

  const postalCodeService = usePostalCodeService();

  const { t } = useTranslation();

  const language = useCurrentLanguage();

  const currentLocale = useCurrentLocale();

  const [loadingZones, setLoadingZones] = useState<boolean>(true);

  const [gridData, setGridData] = useState<IGridZone[]>([]);

  const [zones, setZones] = useState<Zone[]>([]);

  const [disporegions, setDisporegions] = useState<Disporegion[]>([]);

  const [shops, setShops] = useState<Shop[]>([]);

  const [productTypes, setProductTypes] = useState<ProductType[]>([]);

  const [sort, setSort] = useState(initialSort);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>();

  const [showUpsertModal, setShowUpsertModal] = useState<boolean>(false);

  const [showCheckPriceModal, setShowCheckPriceModal] = useState<boolean>(false);

  const [selectedZone, setSelectedZone] = useState<IGridZone>();
  const [selectedZonePostalCodes, setSelectedZonePostalCodes] = useState<PostalCode[]>();

  const [inspectPostalCodes, setInspectPostalCodes] = useState<boolean>(false);

  const [selectedProductType, setSelectedProductType] = useState<ProductType>();

  const [enableGlobalCustomPrice, setEnableGlobalCustomPrice] = useState(false);

  const [isSavingTransportCosts, setIsSavingTransportCosts] = useState<{ [zoneId: string]: boolean }>({});

  const canRenderGlobalCustomPrice = useMemo(
    () => enableGlobalCustomPrice && selectedProduct,
    [enableGlobalCustomPrice, selectedProduct],
  );

  const trackGetZones = useTrackEvent(appInsights, 'Get Zones', zones);

  const findZoneProductInZoneByProduct = useCallback(
    (zones: Zone[], zoneId: number | undefined) => {
      if (!!selectedProduct) {
        const selectedProductId = selectedProduct.id;
        const zone = zones.find((z) => z.id === zoneId);

        if (zone?.zoneProduct) {
          return zone.zoneProduct.find((zp) => zp.productId === selectedProductId);
        }
      }
    },
    [selectedProduct],
  );

  /**
   * This callback filters the list of products as returned from the APIs
   * based on the current language and the global filter.
   */
  useEffect(() => {
    let filteredZones = zones;

    if (selectedShop) {
      filteredZones = filteredZones.filter((zone) => zone.shopId === selectedShop?.id);
    }

    if (selectedProduct) {
      filteredZones = filteredZones.filter((zone) => zone.products.some((product) => product.id == selectedProduct.id));
    }

    const newGridData = filteredZones.map<IGridZone>((zone: Zone) => {
      const shop = shops.find((shop) => shop.id === zone.shopId);
      const zoneProduct = findZoneProductInZoneByProduct(zones, zone.id);

      return {
        ...zone,
        shopName: !shop ? 'Shop not found' : shop.name,
        shop,
        unloadingFee: zoneProduct?.unloadingFee ?? 0,
        useTktm: zoneProduct?.useTktm ?? true,
        transportCostPrice: zoneProduct?.transportCostPrice ?? 0,
      };
    });

    if (newGridData.filter((row) => !row.useTktm).length > 0) {
      setEnableGlobalCustomPrice(true);
    }

    setGridData(newGridData);
  }, [zones, selectedShop, selectedProduct, shops, findZoneProductInZoneByProduct]);

  /**
   * This callback takes care of retrieving the zones
   * by calling the appropriate endpoint through the zones
   * service.
   */
  const retrieveViewModel = useCallback(async () => {
    setLoadingZones(true);

    try {
      const zoneViewModel = await zonesService.getByShopIds(userShops.map((shop) => shop.id));

      trackGetZones(zoneViewModel.zones);
      setZones(zoneViewModel.zones);
      setDisporegions(zoneViewModel.disporegions);
      setShops(zoneViewModel.shops);
      setProductTypes(zoneViewModel.productTypes);
    } catch (error) {
      console.error(error);
      setErrors([...errors, error as ErrorViewModel]);
    } finally {
      setLoadingZones(false);
    }
  }, [
    setLoadingZones,
    zonesService,
    userShops,
    trackGetZones,
    setZones,
    setShops,
    setDisporegions,
    setProductTypes,
    setErrors,
    errors,
  ]);

  const deleteZone = useCallback(
    async (zoneId: number) => {
      try {
        setLoadingZones(true);

        await zonesService.deleteZone(zoneId);
        PubSub.publish(Constants.Topics.UserInformation);
      } catch (error) {
        setErrors([...errors, error as ErrorViewModel]);
      } finally {
        setLoadingZones(false);
        retrieveViewModel();
      }
    },
    [zonesService, setLoadingZones, retrieveViewModel, setErrors, errors],
  );

  const downloadPostalCodesAndZones = useCallback(async () => {
    try {
      const something = await postalCodeService.downloadPostalCodesAndZonesCSV();
      const blob = new Blob([something], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'PostalCodesAndZones.csv');
    } catch (error) {
      console.error(error);

      setErrors([...errors, error as ErrorViewModel]);
    }
  }, [postalCodeService, setErrors, errors]);

  const renderGlobalCustomPriceError = useCallback(() => {
    if (!selectedProduct) {
      return (
        <Alert style={{ marginBottom: 0 }} variant={'warning'}>
          {t('ZonesView.YouMustSelectAProduct')}
        </Alert>
      );
    }
    return null;
  }, [selectedProduct, t]);

  const renderUnloadingFeeWarning = useCallback(() => {
    if (selectedProduct) {
      return (
        <Alert style={{ marginBottom: 0 }} variant={'warning'}>
          {t('ZonesView.UnloadingFeeWarning')}
        </Alert>
      );
    }
    return null;
  }, [selectedProduct, t]);

  useEffect(() => {
    if (userShops.length) {
      retrieveViewModel();
    } else {
      setLoadingZones(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userShops]);

  const getZoneModalTitle = useCallback(() => {
    if (selectedZone) {
      if (selectedZone.id === 0) {
        return (
          <Trans i18nKey="AddZoneModal.CopyZoneTitle" t={t}>
            {{ zone: selectedZone.name }}
          </Trans>
        );
      } else {
        return (
          <Trans i18nKey="AddZoneModal.EditZoneTitle" t={t}>
            {{ zone: selectedZone.name }}
          </Trans>
        );
      }
    }

    return t('AddZoneModal.ModalTitle');
  }, [selectedZone, t]);

  const enterEdit = useCallback((dataItem: IGridZone, field: string | undefined) => {
    setGridData((gd) =>
      gd.map((item: IGridZone) => ({
        ...item,
        inEdit: item.id === dataItem.id ? field : undefined,
      })),
    );

    setEditField(field);
  }, []);

  const exitEdit = useCallback(() => {
    setGridData((gd) => gd.map((item: IGridZone) => ({ ...item, inEdit: undefined })));

    setEditField(undefined);
  }, []);

  const updateZoneProduct = useCallback(
    (zoneId: number, updateFn: (item: ZoneProduct) => ZoneProduct): ZoneProduct | null => {
      let foundZoneProduct: ZoneProduct | null = null;

      if (!!selectedProduct) {
        const selectedProductId = selectedProduct.id;
        const updatedZones = zones.map((zone) => {
          let toUpdateZone = zone;

          if (zone.id === zoneId && !!zone.zoneProduct) {
            const zoneProduct = zone.zoneProduct.find((zp) => zp.productId === selectedProductId);

            if (!!zoneProduct) {
              const updatedZoneProduct = updateFn(zoneProduct);

              foundZoneProduct = updatedZoneProduct;

              toUpdateZone = {
                ...zone,
                zoneProduct: [
                  ...zone.zoneProduct.filter((zp) => zp.productId !== selectedProductId),
                  updatedZoneProduct,
                ],
              };
            }
          }

          return toUpdateZone;
        });

        setZones(updatedZones);
      }
      return foundZoneProduct;
    },
    [selectedProduct, zones],
  );

  const saveUnloadingFee = useCallback(
    (item: IGridZone) => {
      if (!!item && !!selectedProduct && !!item.id) {
        const zoneProduct = updateZoneProduct(item.id, (zp) => ({
          ...zp,
          unloadingFee: item.unloadingFee,
        }));

        if (!!zoneProduct) {
          zonesService
            .updateUnloadingFee({
              zoneId: item.id,
              productId: selectedProduct.id,
              unloadingFee: zoneProduct.unloadingFee,
            })
            .catch((error) => {
              setErrors([...errors, error as ErrorViewModel]);
            });
        }
      }
    },
    [errors, selectedProduct, setErrors, updateZoneProduct, zonesService],
  );

  const disableTransportCost = useCallback(
    async (updatedItem: IGridZone) => {
      if (selectedProduct && selectedProduct.productType && selectedProduct.id) {
        if (updatedItem && !!updatedItem.id) {
          const updatedZoneId = updatedItem.id;

          setIsSavingTransportCosts((isSaving) => ({ ...isSaving, [updatedZoneId]: true }));
          const zoneProduct = updateZoneProduct(updatedZoneId, (zp) => ({ ...zp, useTktm: true }));

          if (!!zoneProduct) {
            try {
              await zonesService.disableTransportCost(zoneProduct.id);
            } catch (error) {
              setErrors([...errors, error as ErrorViewModel]);
            }
          }

          setIsSavingTransportCosts((isSaving) => ({ ...isSaving, [updatedZoneId]: false }));
        }
      }
    },
    [errors, selectedProduct, setErrors, updateZoneProduct, zonesService],
  );

  const setTransportCost = useCallback(
    async (updatedItem: IGridZone) => {
      if (selectedProduct && selectedProduct.productType && selectedProduct.id) {
        if (updatedItem && !!updatedItem.id) {
          const updatedZoneId = updatedItem.id;

          if (updatedItem.transportCostPrice <= 0) {
            if (!confirm(t('ZonesView.SaveZeroAsCustomTransportCostPriceMessage'))) {
              return;
            }
          }

          setIsSavingTransportCosts((isSaving) => ({ ...isSaving, [updatedZoneId]: true }));
          const zoneProduct = updateZoneProduct(updatedZoneId, (zp) => ({
            ...zp,
            useTktm: false,
            transportCostPrice: updatedItem.transportCostPrice,
          }));

          if (!!zoneProduct) {
            try {
              await zonesService.setTransportCost({
                zoneProductId: zoneProduct.id,
                unitCode: selectedProduct.productType.unitCode,
                quantity: selectedProduct.productType.quantity,
                transportCost: updatedItem.transportCostPrice,
              });
            } catch (error) {
              setErrors([...errors, error as ErrorViewModel]);
            }
          }

          setIsSavingTransportCosts((isSaving) => ({ ...isSaving, [updatedZoneId]: false }));
        }
      }
    },
    [errors, selectedProduct, setErrors, t, updateZoneProduct, zonesService],
  );

  const handleUseTktmChange = useCallback(
    async (newValue: boolean, item: IGridZone) => {
      try {
        if (newValue) {
          exitEdit();
          await disableTransportCost(item);
        } else {
          if (!!item.id) {
            if (item.transportCostPrice > 0) {
              await setTransportCost(item);
            } else {
              // we only want to set value, but without saving it
              updateZoneProduct(item.id, (zp) => ({
                ...zp,
                useTktm: false,
              }));
            }
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          setErrors([
            ...errors,
            {
              statusCode: '',
              title: 'Unexpected error',
              value: {
                description: error.message,
              },
            },
          ]);
        }
      }
    },
    [disableTransportCost, errors, exitEdit, setErrors, setTransportCost, updateZoneProduct],
  );

  const checkIfItemIsBeingSaved = useCallback(
    (zone: IGridZone | null) => {
      if (!zone || !zone.id) {
        return false;
      }

      return isSavingTransportCosts[zone.id] ?? false;
    },
    [isSavingTransportCosts],
  );

  const isDieselProductSelected = useCallback(() => {
    if (!selectedProduct || !selectedProduct.productType) {
      return false;
    }

    const typeId = productTypes.find((type) => type.productName === DieselProductTypeName)?.id;

    if (!typeId) {
      return false;
    }
    return selectedProduct.productTypeId === typeId;
  }, [productTypes, selectedProduct]);

  const handleChangeEnableGlobalCustomPrice = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const useTktm = event.currentTarget.value === 'false';

      if (useTktm) {
        const zoneIds = gridData
          .map((row) => row.id)
          .filter((id) => !!id)
          .map((id) => id as number);

        let zoneProductIds = zones
          .filter((zone) => zoneIds.includes(zone.id ?? 0) && !!zone.zoneProduct)
          .flatMap((zone) => zone.zoneProduct?.map((zoneProduct) => zoneProduct.id))
          .filter((id) => !!id)
          .map((id) => id as number);

        zoneProductIds = uniqueBy(zoneProductIds, (id) => id);

        setIsSavingTransportCosts(zoneIds.reduce((acc, id) => ({ ...acc, [id]: true }), {}));

        await zonesService.disableTransportCost(...zoneProductIds);
        for (const zoneId of zoneIds) {
          updateZoneProduct(zoneId, (zp) => ({ ...zp, useTktm: true }));
        }

        setIsSavingTransportCosts(zoneIds.reduce((acc, id) => ({ ...acc, [id]: false }), {}));
      }

      setEnableGlobalCustomPrice(!useTktm);
    },
    [gridData, updateZoneProduct, zones, zonesService],
  );

  const [editField, setEditField] = useState<string | undefined>(undefined);

  const onGridItemChange = (event: GridItemChangeEvent) => {
    const field = event.field;

    if (!IsIGridZoneKey(field)) {
      return;
    }

    const dataItem = event.dataItem as IGridZone;

    const regexp = /^\d*\.?\d*$/;

    if (regexp.test(event.value)) {
      if (event.value == null || event.value === '') {
        event.dataItem[field] = 0;
      } else {
        event.dataItem[field] = event.value;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const updatedGridData = gridData.map((item: any) => {
        if (item.id === dataItem.id) {
          const value = dataItem[field];
          if (!!value) {
            item[field] = value;
          }
        }
        return item;
      });

      setGridData(updatedGridData);
    }
  };

  const handleInputBlur = useCallback(
    async (dataItem: IGridZone, cellField: string | undefined) => {
      if (cellField === 'transportCostPrice') {
        await setTransportCost(dataItem);
      } else if (cellField === 'unloadingFee') {
        saveUnloadingFee(dataItem);
      }
    },
    [setTransportCost, saveUnloadingFee],
  );

  const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, props: GridCellProps) => (
    <KendoCellRenderer
      originalProps={props}
      td={td}
      enterEdit={enterEdit}
      exitEdit={exitEdit}
      editField={editField}
      render={(item, field, node) => {
        if (item.useTktm && field === 'transportCostPrice') {
          return '-';
        }
        return node;
      }}
      onInputBlur={handleInputBlur}
    />
  );

  if (loadingZones) {
    return <LoadingPanel />;
  }

  return (
    <ViewWrapper
      title={t('ZonesView.ManageZones')}
      addButton={
        isAdministrator &&
        !isReadOnly && (
          <button
            type="button"
            className="ms-2 btn btn-primary p-1 pt-0 pb-0 rounded-circle fw-bold"
            onClick={() => setShowUpsertModal(true)}
          >
            <span style={{ fontSize: 36, lineHeight: 0.65 }}>+</span>
          </button>
        )
      }
    >
      <Container>
        {isHoldingUser && (
          <>
            {renderUnloadingFeeWarning()}
            <GlobalUseCustomTransportCostPriceContainer>
              <ButtonGroup className="align-self-start">
                <ToggleButton
                  id="radio-enable-global-custom-price"
                  type="radio"
                  variant={!enableGlobalCustomPrice ? 'primary' : 'light'}
                  value="false"
                  disabled={!selectedProduct}
                  checked={!enableGlobalCustomPrice}
                  onChange={handleChangeEnableGlobalCustomPrice}
                >
                  {t('ZonesView.GlobalUseTktm')}
                </ToggleButton>

                <ToggleButton
                  id="radio-disable-global-custom-price"
                  type="radio"
                  variant={enableGlobalCustomPrice ? 'primary' : 'light'}
                  value="true"
                  disabled={!selectedProduct}
                  checked={enableGlobalCustomPrice}
                  onChange={handleChangeEnableGlobalCustomPrice}
                >
                  {t('ZonesView.GlobalUseCustomTransportCostPrice')}
                </ToggleButton>
              </ButtonGroup>
              {renderGlobalCustomPriceError()}
            </GlobalUseCustomTransportCostPriceContainer>
          </>
        )}

        <AddZoneForm
          modalTitle={getZoneModalTitle()}
          show={showUpsertModal}
          size="xl"
          update={!!selectedZone}
          shops={shops}
          disporegions={disporegions}
          productTypes={productTypes}
          {...selectedZone}
          handleClose={() => {
            setShowUpsertModal(false);
            selectedZone && setSelectedZone(undefined);
          }}
          handleSave={() => {
            setShowUpsertModal(false);
            selectedZone && setSelectedZone(undefined);
            retrieveViewModel();
          }}
        />

        <InspectPostalCodes
          modalTitle={
            <Trans i18nKey="InspectPostalCodesModal.Title" t={t}>
              {{ zone: selectedZone?.name }}
            </Trans>
          }
          show={inspectPostalCodes}
          size="sm"
          handleClose={() => {
            setInspectPostalCodes(false);
            selectedZone && setSelectedZone(undefined);
          }}
          postalCodes={selectedZonePostalCodes ?? []}
        />

        {selectedProductType && (
          <CheckPriceForm
            modalTitle={
              <Trans i18nKey="CheckPriceModal.ModalTitle" t={t}>
                Check price for {{ shop: selectedShop?.name }},{' '}
                {{ product: language === 'de' ? selectedProduct?.nameDe : selectedProduct?.nameFr }}, in{' '}
                {{ zone: selectedZone?.name }}
              </Trans>
            }
            show={showCheckPriceModal}
            size="xl"
            handleClose={() => {
              setShowCheckPriceModal(false);
              selectedZone && setSelectedZone(undefined);
            }}
            zoneId={selectedZone?.id}
            productType={selectedProductType}
          />
        )}

        <LocalizationProvider language={language}>
          <IntlProvider locale={currentLocale}>
            <Grid
              data={filter ? filterBy(orderBy(gridData, sort), filter) : orderBy(gridData, sort)}
              sortable={true}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
              }}
              onItemChange={onGridItemChange}
              filterable={true}
              filter={filter}
              resizable={true}
              cellRender={customCellRender}
              editField="inEdit"
              onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
            >
              <GridToolbar>
                <DownloadButton
                  onClick={() => {
                    downloadPostalCodesAndZones();
                  }}
                />
              </GridToolbar>
              <Column field="name" title={t('ZonesView.ColumnName')} editable={false} />
              <Column field="shopName" title={t('ZonesView.ShopName')} editable={false} />
              {!selectedProduct || isDieselProductSelected() ? null : (
                <Column field="unloadingFee" title={t('ZonesView.UnloadingFee')} />
              )}
              {canRenderGlobalCustomPrice && (
                <Column
                  field="useTktm"
                  title={t('ZonesView.ColumnTransportPriceSource')}
                  width={language === 'de' ? 320 : 250}
                  cell={(props) => {
                    const item = props.dataItem as IGridZone;

                    return (
                      <td
                        colSpan={props.colSpan}
                        role="gridcell"
                        aria-colindex={props.ariaColumnIndex}
                        data-grid-col-index={props.columnIndex}
                        style={{ textAlign: 'center' }}
                      >
                        {checkIfItemIsBeingSaved(item) ? (
                          <Spinner />
                        ) : (
                          <ButtonGroup className="align-self-start">
                            <ToggleButton
                              title={t('ZonesView.TooltipUseTktm')}
                              id={`radio-use-tktm-${props.id}`}
                              type="radio"
                              variant={item.useTktm ? 'primary' : 'light'}
                              value="true"
                              checked={item.useTktm}
                              onChange={(e) => {
                                handleUseTktmChange(e.currentTarget.value === 'true', item);
                              }}
                            >
                              {t('ZonesView.SingleUseTktm')}
                            </ToggleButton>

                            <ToggleButton
                              title={t('ZonesView.TooltipUseCustomTransportCostPrice')}
                              id={`radio-use-custom-${props.id}`}
                              type="radio"
                              variant={!item.useTktm ? 'primary' : 'light'}
                              value="false"
                              checked={!item.useTktm}
                              onChange={(e) => {
                                handleUseTktmChange(e.currentTarget.value === 'true', item);
                              }}
                            >
                              {t('ZonesView.SingleUseCustomTransportCostPrice')}
                            </ToggleButton>
                          </ButtonGroup>
                        )}
                      </td>
                    );
                  }}
                />
              )}
              {canRenderGlobalCustomPrice && (
                <Column
                  field="transportCostPrice"
                  title={t('ZonesView.ColumnCustomTransportPrice')}
                  editor="numeric"
                  width={150}
                  format={formatters.currency.asKendoFormat({
                    t,
                    decimals: 2,
                    unitCode: selectedProduct?.productType?.unitCode,
                  })}
                />
              )}
              <Column
                title={t('Views.ColumnActions')}
                width={500}
                editable={false}
                filterable={false}
                sortable={false}
                cell={(props: GridCellProps) => {
                  return (
                    <td colSpan={1} role="gridcell" aria-colindex={3} data-grid-col-index={2}>
                      <ActionButtons>
                        {!isReadOnly && (
                          <EditButton
                            onClick={() => {
                              setSelectedZone(props.dataItem);
                              setShowUpsertModal(true);
                            }}
                          />
                        )}
                        {!isReadOnly &&
                          selectedProduct?.productType?.id !== DieselProductTypeId &&
                          (role === 'HoldingUser' || role === 'ShopUser') && (
                            <LinkButton
                              href={`\\margins?zoneId=${props.dataItem.id}`}
                              textResource="ZonesView.ZoneMarginButton"
                            />
                          )}
                        <CheckPriceButton
                          onClick={() => {
                            if (selectedShop && selectedProduct) {
                              const productType = productTypes.find(
                                (productType) => productType.id === selectedProduct?.productTypeId,
                              );

                              if (!productType) {
                                setErrors([
                                  ...errors,
                                  {
                                    title:
                                      "Couldn't find the the product type of the selected product from the global filter.",
                                    statusCode: '',
                                    value: {
                                      description:
                                        "Couldn't find the the product type of the selected product from the global filter.",
                                    },
                                  },
                                ]);

                                return;
                              }

                              setSelectedZone(props.dataItem);
                              setSelectedProductType(productType);
                              setShowCheckPriceModal(true);
                            }
                          }}
                          disabled={!(selectedShop && selectedProduct)}
                          disabledOnClick={() => alert(t('ZonesView.SelectGlobalFilter'))}
                        />
                        {isAdministrator && !isReadOnly && (
                          <CopyButton
                            onClick={() => {
                              setSelectedZone({ ...props.dataItem, id: 0 });
                              setShowUpsertModal(true);
                            }}
                          />
                        )}
                        {isAdministrator && !isReadOnly && (
                          <DeleteButton
                            onClick={() => {
                              if (confirm(t('ZonesView.DeleteMessage') + ' ' + props.dataItem.name)) {
                                deleteZone(props.dataItem.id);
                              }
                            }}
                          />
                        )}
                        <InspectPostalCodesButton
                          onClick={async () => {
                            const zone = props.dataItem as IGridZone;

                            try {
                              if (!!zone && !!zone.id) {
                                const zonePostalCodes = await postalCodeService.getPostalCodesByZoneId(zone.id);

                                setSelectedZonePostalCodes(zonePostalCodes);
                                setSelectedZone(zone);
                                setInspectPostalCodes(true);
                              }
                            } catch (error) {
                              if (isErrorViewModel(error)) {
                                setErrors([...errors, error]);
                              }
                            }
                          }}
                        />
                      </ActionButtons>
                    </td>
                  );
                }}
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </Container>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewZones);
