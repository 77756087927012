import { PostalCode, Zone } from '../models';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { BaseService } from './BaseService';

export interface GetPostalCodesByCriteriaResult {
  searchResult: PostalCode[];
  zones: Zone[];
}

/**
 * The service class to interact with the Postal Codes.
 */
export class PostalCodeService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/postalcode');
  }

  getPostalCodesByCriteria(query: string): Promise<GetPostalCodesByCriteriaResult> {
    return this.get(`/criteria/${encodeURIComponent(encodeURIComponent(query))}`);
  }

  getPostalCodesByZoneId(zoneId: number): Promise<PostalCode[]> {
    return this.get(`/zone/${zoneId}`);
  }

  updatePostalCode(postalCode: PostalCode): Promise<void> {
    return this.post('/zone', postalCode);
  }

  downloadPostalCodesAndZonesCSV(): Promise<string> {
    return this.get('/download');
  }
}
