import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Tab from 'react-bootstrap/Tab';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Nav from 'react-bootstrap/Nav';
import withModal from '../../../hoc/with-modal/withModal';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import {
  BasePromotionRequest,
  CreatePromotionRequest,
  isErrorViewModel,
  Product,
  ProductTemplate,
  ProductType,
  Promotion,
  PromotionType,
  UpdatePromotionRequest,
  ValidityDelimitation,
} from '../../../../models';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import useModal from '../../../../hooks/useModal';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import getPromotionFormValidationSchema from './ValidationSchema';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import useApplicationState from '../../../../hooks/useApplicationState';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import { Formik, FormikErrors } from 'formik';
import mapEnum from '../../../../utils/mapEnum';
import { DieselProductTypeName, WoodProductTypeName } from '../../../../assets/constants/DataConstants';
import usePromotionsService from '../../../../hooks/usePromotionsService';
import ItemAssigner from '../../../common/item-assigner/ItemAssigner';
import ReactTooltip from 'react-tooltip';
import { convertHoursFromUtcInLocale, convertDateInUtc, convertHoursInUtc } from '../../../../utils/date-handling';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import ProductTemplatePreview from '../../../common/product-template-preview/ProductTemplatePreview';
import Spinner from '../../../common/spinner/Spinner';
import usePostalCodeService from '@/hooks/usePostalCodeService';
import uniqueBy from '@/utils/uniqueBy';
import { Grid, GridColumn as Column, GridCellProps, GridItemChangeEvent } from '@progress/kendo-react-grid';

import TimePicker from './time-picker/TimePicker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

// Include the locale utils designed for moment
import MomentLocaleUtils from 'react-day-picker/moment';
const { formatDate, parseDate } = MomentLocaleUtils;
import moment from 'moment';
import { formatters } from '@/utils/formatters';

// Make sure moment.js has the required locale data
import 'moment/locale/de';
import 'moment/locale/fr';

import './AddPromotionForm.scss';
import { KendoCommandCell } from '../../../common/grid-cell/KendoCommandCell';

/**
 * Wrapper around the DayPickerInput Component from react-day-picker
 * for easier replication in the modal.
 */
const DayPicker = styled(DayPickerInput).attrs<DayPickerProps>(({ locale }) => ({
  inputProps: { className: 'form-control' },
  dayPickerProps: { locale: locale, localeUtils: MomentLocaleUtils },
}))<DayPickerProps>``;

/**
 * This styled component is for adding the pointer
 * when I hover on the tab nav item.
 */
const NavItemHoverPointer = styled(Nav.Item)`
  &:hover {
    cursor: pointer;
  }
`;

/**
 * These are the styles for the tab content area.
 */
const BorderedTabContent = styled(Tab.Content).attrs(() => ({
  className: 'container',
}))<{}>`
  @media (max-width: 576px) {
    border: 1px solid #dee2e6;
  }
`;

/**
 * These are the styles for the tab pills.
 * I decided to make them custom in order to have a more
 * appropriate mobile version of them.
 */
const NavPill = styled(Nav.Link)`
  @media (max-width: 576px) {
    border-radius: 0.5rem 0.5rem 0 0 !important;
    padding: 0.25rem 0.75rem;
  }

  @media (min-width: 576px) {
    border-radius: 0.5rem 0 0 0.5rem !important;
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;
  }
`;

/**
 * Type describing the available keys for the tabs.
 */
enum TabKey {
  German,
  French,
}

/**
 * Enum for the validity zone choice in the modal.
 */
export enum ValidityZone {
  AllZones,
  SelectedZones,
  SelectedPostalCodes,
}

/**
 * ValidityDelimitation variant with only the options to display in the dropdown.
 */
enum ValidityDelimitationDropdown {
  NoDelimitations = ValidityDelimitation.NoDelimitations,
  WorkDaysFrom18To8 = ValidityDelimitation.WorkDaysFrom18To8,
  WorkDaysWeekendFrom18To8 = ValidityDelimitation.WorkDaysWeekendFrom18To8,
}

/**
 * Interface for DayPicker styled component wrapper.
 */
interface DayPickerProps {
  locale: string;
}

export interface ProductTemplateItem {
  baseTemplateProduct?: Product;
  product?: Product;
  productTemplates: ProductTemplate[];
}

/**
 * Shape of assigned zone to the promotion.
 */
interface AssignedZone {
  id: number;
  name: string;
}

/**
 * Shape of assigned postal code to the promotion.
 */
interface AssignedPostalCode {
  id: number;
  name: string;
  description: string;
}

interface AssignedGift {
  id?: number;
  descriptionDE: string;
  descriptionFR: string;
  inEdit?: boolean | string;
}

/**
 * The promotion modal form values.
 */
export interface PromotionForm {
  productType: ProductType;
  promotionForSingleProduct: boolean;
  product: Product | null;
  internalName: string;
  promotionType: PromotionType;
  promotionCode?: string;
  minimumQuantity: string;
  maximumQuantity: string;
  amountToDiscount: string;
  validityZone: ValidityZone;
  validFrom: Date | undefined;
  validTo: Date | undefined;
  hourValidFrom: string | null;
  hourValidTo: string | null;
  validityDelimitation: ValidityDelimitation;
  descriptionDE: string;
  descriptionFR: string;
  emailDescriptionDE: string;
  emailDescriptionFR: string;
  hasTemplate: boolean;
  promotionTemplateImage?: string;
  templates: ProductTemplateItem[];
  assignedZones?: AssignedZone[];
  assignedPostalCodes?: AssignedPostalCode[];
  gameficationLinkUrl: string | null;
  gameficationLinkTitle: string | null;
  gameficationIFrameLinkUrl: string | null;
  gameficationLinkUrlFR: string | null;
  gameficationLinkTitleFR: string | null;
  gameficationIFrameLinkUrlFR: string | null;
  assignedGifts?: AssignedGift[];
}

/**
 * The AddProductForm props.
 */
interface AddProductFormProps {
  /**
   * The promotion to update.
   * Nullable because for creation it's not necessary.
   */
  promotionToUpdate?: Promotion;
}

/**
 *
 * The interectable content of the modal for adding a product.
 *
 * @returns the component to be displayed.
 */
const AddPromotionForm = ({ promotionToUpdate }: AddProductFormProps): JSX.Element | null => {
  const { t } = useTranslation();
  const language = useCurrentLanguage();

  const { handleSave } = useModal();
  const { errors: appErrors, setErrors } = useErrorHandling();

  const promotionsService = usePromotionsService();
  const postalCodeService = usePostalCodeService();

  const UpsertPromotionValidationSchema = useMemo(() => getPromotionFormValidationSchema(t), [t]);

  const { holding, shops, products } = useApplicationState();

  const uploadInputRef = useRef<HTMLInputElement>(null);

  const discountPhrasePlaceholders = useMemo(
    () => ['CHFAmountTotal', 'CHFAmount', 'EmailContribution', 'MinimalQuantity', 'PromotionName'],
    [],
  );

  const availableProductTypes = useMemo(
    () =>
      products
        ?.map((product) => product.productType!)
        .filter((value, index, self) => self.map((productType) => productType.id).indexOf(value.id) === index),
    [products],
  );

  const [activeTabKey, setActiveTabKey] = useState<keyof typeof TabKey>(TabKey[0] as keyof typeof TabKey);
  const [activeTemplateTabKey, setActiveTemplateTabKey] = useState<keyof typeof TabKey>(
    TabKey[0] as keyof typeof TabKey,
  );
  const [selectedZone, setSelectedZone] = useState<AssignedZone>();
  const [selectedPostalCode, setSelectedPostalCode] = useState<AssignedPostalCode | null>(null);

  const [assignablePostalCodes, setAssignablePostalCodes] = useState<AssignedPostalCode[]>([]);
  const [loadingPostalCodes, setLoadingPostalCodes] = useState<boolean>(false);

  const [isUploadingPostalCodes, setIsUploadingPostalCodes] = useState<boolean>(false);

  const [assignedGifts, setAssignedGifts] = useState<AssignedGift[]>([]);

  const defaultSelectedTemplateProduct: Product = {
    id: undefined,
    nameDe: t('PromotionsView.All'),
    nameFr: t('PromotionsView.All'),
  };

  const removeFileFromInput = useCallback(() => {
    if (uploadInputRef.current) {
      uploadInputRef.current.value = '';
    }
  }, [uploadInputRef]);

  const isWood = useCallback(
    (selectedProductType: ProductType) => selectedProductType.productName === WoodProductTypeName,
    [WoodProductTypeName],
  );

  const isDiesel = useCallback(
    (selectedProductType: ProductType) => selectedProductType.productName === DieselProductTypeName,
    [DieselProductTypeName],
  );

  const assignableZones = useMemo<AssignedZone[]>(
    () =>
      shops
        .flatMap((shop) => shop.zones)
        ?.map((zone) => ({
          id: zone!.id!,
          name: zone!.name,
        })),
    [shops],
  );

  const isCopying = useMemo(() => {
    if (promotionToUpdate && promotionToUpdate.id === 0) {
      return true;
    }

    return false;
  }, [promotionToUpdate]);

  const readValidityZone = useCallback((promotion: Promotion | undefined) => {
    if (promotion) {
      if (promotion.isAllZones) {
        return ValidityZone.AllZones;
      } else if (promotion.postalCodes && promotion.postalCodes.length > 0) {
        return ValidityZone.SelectedPostalCodes;
      } else {
        return ValidityZone.SelectedZones;
      }
    }

    return ValidityZone.AllZones;
  }, []);

  const shouldShowDiscountField = useCallback(
    (promotionType: PromotionType) =>
      promotionType !== PromotionType.Coupon &&
      promotionType !== PromotionType.CouponWithCode &&
      promotionType !== PromotionType.Gift &&
      promotionType !== PromotionType.GiftWithCode,
    [],
  );

  const updateAssignedGifts = useCallback((promotionType: PromotionType) => {
    if (promotionType !== PromotionType.Gift && promotionType != PromotionType.GiftWithCode) {
      setAssignedGifts([]);
    }
  }, []);

  const shouldShowCodeField = useCallback(
    (promotionType: PromotionType) =>
      promotionType === PromotionType.FixedAmountWithCode ||
      promotionType === PromotionType.AmountByQuantityWithCode ||
      promotionType === PromotionType.CouponWithCode ||
      promotionType === PromotionType.PercentualWithCode ||
      promotionType === PromotionType.FinalFixedPriceWithCode ||
      promotionType === PromotionType.GiftWithCode,
    [],
  );

  const showCorrectInputLabel = useCallback(
    (promotionType: PromotionType) => {
      if (promotionType == PromotionType.FixedAmount || promotionType == PromotionType.FixedAmountWithCode) {
        return <span className="d-inline-block me-3">{t('AddPromotionModal.AmountToDiscount')}</span>;
      }

      if (promotionType == PromotionType.AmountByQuantity || promotionType == PromotionType.AmountByQuantityWithCode) {
        return <span className="d-inline-block me-3">{t('AddPromotionModal.AmountToDiscountQuantityBased')}</span>;
      }

      if (promotionType == PromotionType.FinalFixedPrice || promotionType == PromotionType.FinalFixedPriceWithCode) {
        return <span className="d-inline-block me-3">{t('AddPromotionModal.FinalFixedPrice')}</span>;
      }

      if (promotionType == PromotionType.Percentual || promotionType == PromotionType.PercentualWithCode) {
        return <span className="d-inline-block me-3">{t('AddPromotionModal.Percentual')}</span>;
      }

      return <span>{t('AddPromotionModal.CannotReadPromotionType')}</span>;
    },
    [t],
  );

  const showCorrectInputUnitCode = useCallback(
    (promotionType: PromotionType, productType: ProductType) => {
      if (promotionType == PromotionType.FixedAmount || promotionType == PromotionType.FixedAmountWithCode) {
        return <span className="d-inline-block">CHF</span>;
      }

      if (
        promotionType == PromotionType.AmountByQuantity ||
        promotionType == PromotionType.AmountByQuantityWithCode ||
        promotionType == PromotionType.FinalFixedPrice ||
        promotionType == PromotionType.FinalFixedPriceWithCode
      ) {
        return (
          <span className="d-inline-block">
            CHF{' '}
            {isWood(productType)
              ? t('AddPromotionModal.MeasureUnitTons')
              : isDiesel(productType)
              ? t('AddPromotionModal.MeasureUnitTons')
              : t('AddPromotionModal.MeasureUnitLiters')}
          </span>
        );
      }

      if (promotionType == PromotionType.Percentual || promotionType == PromotionType.PercentualWithCode) {
        return <span className="d-inline-block">%</span>;
      }

      return <span>{t('AddPromotionModal.CannotReadPromotionType')}</span>;
    },
    [isWood, isDiesel, t],
  );

  const readHolding = useCallback(() => {
    if (!holding) {
      throw new Error("Couldn't read the holding of the user.");
    }

    return holding;
  }, [holding]);

  const initialValues = useMemo<PromotionForm>(() => {
    const validityZone = readValidityZone(promotionToUpdate);

    const initialValues: PromotionForm = {
      productType: promotionToUpdate?.productType ?? availableProductTypes[0],
      promotionForSingleProduct: promotionToUpdate?.productId ? true : false,
      product: promotionToUpdate?.product ?? null,
      internalName: promotionToUpdate?.internalName ?? '',
      promotionType: promotionToUpdate?.promotionType ?? PromotionType.FixedAmount,
      minimumQuantity: promotionToUpdate ? formatters.promotion.minimalQuantity(promotionToUpdate).toString() : '',
      maximumQuantity: promotionToUpdate ? formatters.promotion.maximumQuantity(promotionToUpdate).toString() : '',
      amountToDiscount: promotionToUpdate ? formatters.promotion.amountToDiscount(promotionToUpdate).toString() : '',
      validityZone: validityZone,
      validFrom: promotionToUpdate ? moment(promotionToUpdate.validityPeriodStart).hour(12).toDate() : undefined,
      validTo: promotionToUpdate ? moment(promotionToUpdate.validityPeriodEnd).hour(12).toDate() : undefined,
      hourValidFrom: promotionToUpdate
        ? formatters.datetime.getHour(
            convertHoursFromUtcInLocale(promotionToUpdate.validityPeriodStart, promotionToUpdate.validityTimeStart),
          )
        : null,
      hourValidTo: promotionToUpdate
        ? formatters.datetime.getHour(
            convertHoursFromUtcInLocale(promotionToUpdate.validityPeriodEnd, promotionToUpdate.validityTimeEnd),
          )
        : null,
      validityDelimitation: promotionToUpdate?.validityDelimitation ?? ValidityDelimitation.NoDelimitations,
      descriptionDE: promotionToUpdate?.descriptionDE ?? '',
      descriptionFR: promotionToUpdate?.descriptionFR ?? '',
      emailDescriptionDE: promotionToUpdate?.emailContributionDE ?? '',
      emailDescriptionFR: promotionToUpdate?.emailContributionFR ?? '',
      promotionCode: promotionToUpdate?.discountCode ?? undefined,
      hasTemplate:
        promotionToUpdate?.promotionTemplates && promotionToUpdate.promotionTemplates.length > 0 ? true : false,
      promotionTemplateImage: promotionToUpdate?.promotionTemplateImageUrl,
      assignedZones:
        validityZone == ValidityZone.AllZones
          ? assignableZones
          : promotionToUpdate?.zones.map((zone) => ({
              id: zone.id!,
              name: zone.name ?? '',
            })),
      assignedPostalCodes: promotionToUpdate?.postalCodes?.map((pc) => ({
        id: pc.id,
        name: pc.npa.toString(),
        description: pc.name,
      })),
      gameficationLinkTitle: promotionToUpdate?.gameficationLinkTitle ?? '',
      gameficationLinkUrl: promotionToUpdate?.gameficationLinkUrl ?? '',
      gameficationIFrameLinkUrl: promotionToUpdate?.gameficationIFrameLinkUrl ?? '',
      gameficationLinkTitleFR: promotionToUpdate?.gameficationLinkTitleFR ?? '',
      gameficationLinkUrlFR: promotionToUpdate?.gameficationLinkUrlFR ?? '',
      gameficationIFrameLinkUrlFR: promotionToUpdate?.gameficationIFrameLinkUrlFR ?? '',
      assignedGifts: promotionToUpdate?.promotionGifts ?? [],
      templates: [],
    };

    // Map templates
    const productTemplateItems: ProductTemplateItem[] = [];
    if (promotionToUpdate?.promotionTemplates) {
      promotionToUpdate.promotionTemplates.forEach((element) => {
        const existingItemIndex = productTemplateItems.findIndex(
          (o) =>
            o.product?.id === element.productId || element.productId === defaultSelectedTemplateProduct.product?.id,
        );
        if (existingItemIndex >= 0) {
          productTemplateItems[existingItemIndex].productTemplates.push(element);
        } else {
          productTemplateItems.push({
            baseTemplateProduct: undefined,
            product: element?.productId
              ? products.find((o) => o.id == element.productId)
              : defaultSelectedTemplateProduct,
            productTemplates: Array(element),
          });
        }
      });
    }
    initialValues.templates = new Array(...productTemplateItems);

    // test
    if (isCopying) {
      initialValues.internalName = '';
      initialValues.promotionCode = undefined;
    }

    setAssignedGifts(initialValues.assignedGifts ?? []);
    return initialValues;
  }, [isCopying]);

  const setTemplate = useCallback(
    (
      language: 'DE' | 'FR',
      previousValue: ProductTemplate[],
      template: ProductTemplate,
      field: string,
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    ) => {
      const newTemplates = [...previousValue];
      const indexToReplace = newTemplates.findIndex((t) => t.language === language);

      if (indexToReplace >= 0) {
        newTemplates.splice(indexToReplace, 1, template);
      } else {
        newTemplates.push(template);
      }

      setFieldValue(field, newTemplates);
    },
    [],
  );

  const setTemplateImage = useCallback(
    (
      imageUrl: string | undefined,
      activeTabKey: string,
      productTemplates: ProductTemplate[],
      field: string,
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    ) => {
      const newProductTemplates = [...productTemplates];
      newProductTemplates
        .filter((o) => o.language === (activeTabKey === 'French' ? 'FR' : 'DE'))
        .map((o) => {
          o.templateImageUrl = imageUrl ?? '';
        });

      setFieldValue(field, newProductTemplates);
    },
    [],
  );

  const getTemplateError = useCallback((errors: FormikErrors<PromotionForm>, index: number, tabIndex: number) => {
    return errors && errors.templates && (errors.templates as unknown as ProductTemplateItem[])[index]?.productTemplates
      ? (errors.templates as unknown as ProductTemplateItem[])[index]?.productTemplates[tabIndex]?.template ?? ''
      : '';
  }, []);

  const submitForm = useCallback(
    async (values: PromotionForm) => {
      try {
        const isAllZones = values.validityZone == ValidityZone.AllZones;

        const baseRequest: BasePromotionRequest = {
          descriptionDE: values.descriptionDE,
          descriptionFR: values.descriptionFR,
          emailContributionDE: values.emailDescriptionDE,
          emailContributionFR: values.emailDescriptionFR,
          holdingId: readHolding().id,
          internalName: values.internalName,
          minimalQuantity:
            Number(values.minimumQuantity) /
            (isWood(values.productType) ? 1000 : isDiesel(values.productType) ? 1 : 100),
          maximumQuantity:
            Number(values.maximumQuantity) /
            (isWood(values.productType) ? 1000 : isDiesel(values.productType) ? 1 : 100),
          productId: values.promotionForSingleProduct ? values.product!.id! : null,
          productTypeId: values.productType.id,
          promotionType: values.promotionType,
          unitCode: values.productType.unitCode,
          validityDelimitation: values.validityDelimitation,
          validityPeriodStart: convertDateInUtc(values.validFrom!, formatters.datetime.toHour(values.hourValidFrom!)),
          validityPeriodEnd: convertDateInUtc(values.validTo!, formatters.datetime.toHour(values.hourValidTo!)),
          validityTimeStart: convertHoursInUtc(values.validFrom!, formatters.datetime.toHour(values.hourValidFrom!)),
          validityTimeEnd: convertHoursInUtc(values.validTo!, formatters.datetime.toHour(values.hourValidTo!)),
          discountCode: values.promotionCode,
          promotionTemplateImageUrl: values.hasTemplate ? values.promotionTemplateImage : undefined,
          promotionTemplates: values.hasTemplate ? values.templates?.flatMap((o) => o.productTemplates) : [],
          isAllZones: isAllZones,
          zoneIds: isAllZones ? undefined : values.assignedZones?.map((assignedZone) => assignedZone.id),
          postalCodeIds: values.assignedPostalCodes?.map((pc) => pc.id),
          gameficationLinkUrl: values.gameficationLinkUrl,
          gameficationLinkTitle: values.gameficationLinkTitle,
          gameficationIFrameLinkUrl: values.gameficationIFrameLinkUrl,
          gameficationLinkUrlFR: values.gameficationLinkUrlFR,
          gameficationLinkTitleFR: values.gameficationLinkTitleFR,
          gameficationIFrameLinkUrlFR: values.gameficationIFrameLinkUrlFR,
          promotionGifts: assignedGifts.map((item) => {
            return {
              id: 0,
              descriptionDE: item.descriptionDE,
              descriptionFR: item.descriptionFR,
            };
          }),
        };

        if (
          values.promotionType == PromotionType.Percentual ||
          values.promotionType == PromotionType.PercentualWithCode
        ) {
          baseRequest.percentual = Number(values.amountToDiscount || 0);
        } else {
          baseRequest.chfAmount = Number(values.amountToDiscount || 0);
        }

        if (promotionToUpdate && promotionToUpdate.id !== 0) {
          const promotion: UpdatePromotionRequest = {
            ...baseRequest,
            id: promotionToUpdate.id,
            discountCode:
              values.promotionType == PromotionType.AmountByQuantityWithCode ||
              values.promotionType == PromotionType.CouponWithCode ||
              values.promotionType == PromotionType.FixedAmountWithCode ||
              values.promotionType == PromotionType.FinalFixedPriceWithCode ||
              values.promotionType == PromotionType.PercentualWithCode ||
              values.promotionType == PromotionType.GiftWithCode
                ? values.promotionCode
                : undefined,
          };

          await promotionsService.updatePromotion(promotion);
        } else {
          const promotion: CreatePromotionRequest = {
            ...baseRequest,
          };

          await promotionsService.createPromotion(promotion);
        }

        handleSave!();
      } catch (error) {
        console.error(error);

        if (isErrorViewModel(error)) {
          setErrors([...appErrors, error]);
        } else {
          const castedError = error as Error;

          setErrors([
            ...appErrors,
            {
              statusCode: '',
              title: castedError.message,
              value: {
                description: castedError.message,
              },
            },
          ]);
        }
      }
    },
    [
      readHolding,
      isWood,
      isDiesel,
      promotionsService,
      handleSave,
      isErrorViewModel,
      setErrors,
      appErrors,
      assignedGifts,
    ],
  );

  useEffect(() => {
    async function getZonePostalCodes(zoneId: number) {
      try {
        setLoadingPostalCodes(true);
        setAssignablePostalCodes([]);
        setSelectedPostalCode(null);

        const zonePostalCodes = await postalCodeService.getPostalCodesByZoneId(zoneId);

        setAssignablePostalCodes(
          zonePostalCodes.map((pc) => ({
            id: pc.id,
            name: pc.npa.toString(),
            description: pc.name,
          })),
        );
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...appErrors, error]);
        } else if (error instanceof Error) {
          setErrors([
            ...appErrors,
            {
              statusCode: '',
              title: 'An unexpected error occurred.',
              value: {
                description: error.message,
              },
            },
          ]);
        }
      } finally {
        setLoadingPostalCodes(false);
      }
    }

    if (selectedZone) {
      getZonePostalCodes(selectedZone.id);
    }
  }, [selectedZone]);

  if (availableProductTypes.length === 0) {
    return <span className="d-block text-danger fw-bold">{t('AddPromotionModal.NoProductTypesAvailable')}</span>;
  }

  const giftItemRemove = (dataItem: AssignedGift) => {
    const index = assignedGifts.findIndex((record) => record.id === dataItem.id);
    assignedGifts.splice(index, 1);
    setAssignedGifts(assignedGifts);
  };

  const giftItemAdd = (dataItem: AssignedGift) => {
    dataItem.inEdit = false;
    dataItem.id = assignedGifts.length;
    initialValues.assignedGifts?.unshift(dataItem);
    setAssignedGifts(initialValues.assignedGifts ?? []);
  };

  const giftItemUpdate = (dataItem: AssignedGift) => {
    dataItem.inEdit = false;
    const index = assignedGifts.findIndex((record) => record.id === dataItem.id);
    assignedGifts[index] = dataItem;
    setAssignedGifts(assignedGifts);
  };

  const giftItemDiscard = () => {
    const newData = [...assignedGifts];
    newData.splice(0, 1);
    setAssignedGifts(newData);
  };

  const giftItemCancel = (dataItem: AssignedGift) => {
    const originalItem = initialValues.assignedGifts?.find((p) => p.id === dataItem.id);
    const newData = assignedGifts.map((item) => (originalItem && item.id === originalItem.id ? originalItem : item));

    setAssignedGifts(newData ?? []);
  };

  const giftItemEnterEdit = (dataItem: AssignedGift) => {
    setAssignedGifts(assignedGifts.map((item) => (item.id === dataItem.id ? { ...item, inEdit: true } : item)));
  };

  const giftItemOnChange = (event: GridItemChangeEvent) => {
    const newData = assignedGifts.map((item) =>
      item.id === event.dataItem.id ? { ...item, [event.field || '']: event.value } : item,
    );

    setAssignedGifts(newData);
  };

  const giftItemAddNew = () => {
    const newDataItem = { inEdit: true, descriptionDE: '', descriptionFR: '' };
    setAssignedGifts([newDataItem, ...assignedGifts]);
  };

  const editField = 'inEdit';

  const commandCell = (props: GridCellProps) => (
    <KendoCommandCell
      {...props}
      edit={giftItemEnterEdit}
      remove={giftItemRemove}
      add={giftItemAdd}
      discard={giftItemDiscard}
      update={giftItemUpdate}
      cancel={giftItemCancel}
      editField={editField}
    />
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={UpsertPromotionValidationSchema}
        onSubmit={submitForm}
        validateOnMount={isCopying}
        initialTouched={
          isCopying
            ? {
                internalName: true,
                promotionCode: true,
              }
            : undefined
        }
      >
        {({ handleSubmit, handleChange, setFieldValue, setFieldTouched, isSubmitting, values, errors, touched }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              handleSubmit(e);
            }}
            className="container px-2 pt-2"
          >
            <div className="mb-3">
              {touched.productType && errors.productType && (
                <span className="d-block text-danger">{errors.productType}</span>
              )}
              <label htmlFor="productTypeDropdown" className="form-label me-3">
                {t('AddPromotionModal.ProductType')}
              </label>
              <DropDownList
                id="productTypeDropdown"
                data={availableProductTypes}
                value={values.productType}
                textField={`name${capitalizeFirstLetter(language)}`}
                name="productType"
                dataItemKey="id"
                onChange={(e) => {
                  handleChange(e);

                  setFieldValue('product', null);
                }}
              />
            </div>
            {touched.promotionForSingleProduct && errors.promotionForSingleProduct && (
              <span className="d-block text-danger">{errors.promotionForSingleProduct}</span>
            )}
            {touched.product && errors.product && <span className="d-block text-danger">{errors.product}</span>}
            <div className="d-flex align-items-center mb-3">
              <span className="d-inline-block me-3">{t('AddPromotionModal.PromotionFor')}</span>
              <ButtonGroup className="align-self-start">
                <ToggleButton
                  id="radio-all-product-types"
                  type="radio"
                  variant={values.promotionForSingleProduct ? 'light' : 'primary'}
                  value="false"
                  checked={!values.promotionForSingleProduct}
                  onChange={(e) =>
                    setFieldValue('promotionForSingleProduct', e.currentTarget.value.toString() === 'true')
                  }
                >
                  <Trans i18nKey="AddPromotionModal.AllProductsOfType" t={t}>
                    {{ productType: values.productType[`name${capitalizeFirstLetter(language)}`] }}
                  </Trans>
                </ToggleButton>
                <ToggleButton
                  id="radio-single-product"
                  type="radio"
                  variant={values.promotionForSingleProduct ? 'primary' : 'light'}
                  value="true"
                  checked={values.promotionForSingleProduct}
                  onChange={(e) =>
                    setFieldValue('promotionForSingleProduct', e.currentTarget.value.toString() === 'true')
                  }
                >
                  {t('AddPromotionModal.SelectedProductOnly')}
                </ToggleButton>
              </ButtonGroup>

              {values.promotionForSingleProduct && (
                <div className="ms-3">
                  <label htmlFor="productsDropdown" className="form-label me-3">
                    {t('AddPromotionModal.Product')}
                  </label>
                  <DropDownList
                    id="productsDropdown"
                    data={products?.filter((product) => product.productTypeId === values.productType.id)}
                    value={values.product}
                    textField={`name${capitalizeFirstLetter(language)}`}
                    name="product"
                    dataItemKey="id"
                    onChange={handleChange}
                    style={{
                      width: 'auto',
                      minWidth: '200px',
                      border: touched.product && errors.product ? '1px solid red' : 'none',
                      borderCollapse: 'collapse',
                      borderRadius: '0.25rem',
                    }}
                  />
                </div>
              )}
            </div>
            <div className="mb-3">
              {touched.internalName && errors.internalName && (
                <span className="d-block text-danger">{errors.internalName}</span>
              )}
              <label htmlFor="intenalPromotionNameId" className="form-label">
                {t('AddPromotionModal.InternalPromotionName')}
              </label>
              <input
                id="intenalPromotionNameId"
                name="internalName"
                className="form-control"
                value={values.internalName}
                onChange={handleChange}
              />
            </div>
            {touched.promotionType && errors.promotionType && (
              <span className="d-block text-danger">{errors.promotionType}</span>
            )}
            <div className="d-flex align-items-center mb-3">
              <label htmlFor="promotionTypeId" className="form-label mb-0 me-3">
                {t('AddPromotionModal.PromotionType')}
              </label>
              <select
                id="promotionTypeId"
                name="promotionType"
                className="form-select"
                style={{ width: 'initial', flex: 1 }}
                value={values.promotionType}
                onChange={(e) => {
                  handleChange(e);
                  updateAssignedGifts(Number(e.target.value));
                }}
              >
                {mapEnum(PromotionType, (v, index) => (
                  <option key={index} value={v}>
                    {formatters.promotion.getTypeName(t, v)}
                  </option>
                ))}
              </select>
            </div>
            {shouldShowCodeField(Number(values.promotionType)) && (
              <>
                {touched.promotionCode && errors.promotionCode && (
                  <span className="d-block text-danger">{errors.promotionCode}</span>
                )}
                <div className="d-flex align-items-center mb-3">
                  <span className="d-inline-block me-3">{t('AddPromotionModal.PromotionCode')}</span>
                  <input
                    id="promotionCodeId"
                    name="promotionCode"
                    className="form-control me-3"
                    style={{ width: '50%', minWidth: 'min-content' }}
                    value={values.promotionCode}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    style={{ whiteSpace: 'nowrap' }}
                    onClick={() => alert('Not implemented yet.')}
                  >
                    {t('AddPromotionModal.GenerateUniqueCode')}
                  </button>
                </div>
              </>
            )}
            <div className="mb-3">
              {touched.minimumQuantity && errors.minimumQuantity && (
                <span className="d-block text-danger">{errors.minimumQuantity}</span>
              )}
              <span className="d-inline-block me-3">{t('AddPromotionModal.MinimumQuantity')}</span>
              <input
                id="minimumQuantityId"
                name="minimumQuantity"
                className="form-control d-inline-block me-3"
                style={{ width: 'min-content' }}
                value={values.minimumQuantity}
                onChange={handleChange}
              />
              <span className="d-inline-block">
                {isWood(values.productType)
                  ? t('AddPromotionModal.Kilos')
                  : isDiesel(values.productType)
                  ? t('AddPromotionModal.Liters')
                  : t('AddPromotionModal.Liters')}
              </span>
            </div>
            <div className="mb-3">
              {touched.maximumQuantity && errors.maximumQuantity && (
                <span className="d-block text-danger">{errors.maximumQuantity}</span>
              )}
              <span className="d-inline-block me-3">{t('AddPromotionModal.MaximumQuantity')}</span>
              <input
                id="maximumQuantityId"
                name="maximumQuantity"
                className="form-control d-inline-block me-3"
                style={{ width: 'min-content' }}
                value={values.maximumQuantity}
                onChange={handleChange}
              />
              <span className="d-inline-block">
                {isWood(values.productType)
                  ? t('AddPromotionModal.Kilos')
                  : isDiesel(values.productType)
                  ? t('AddPromotionModal.Liters')
                  : t('AddPromotionModal.Liters')}
              </span>
            </div>
            <div className="mb-3">
              {touched.amountToDiscount && errors.amountToDiscount && (
                <span className="d-block text-danger">{errors.amountToDiscount}</span>
              )}
              {shouldShowDiscountField(Number(values.promotionType)) && (
                <>
                  {showCorrectInputLabel(values.promotionType)}
                  <input
                    id="amountToDiscountId"
                    name="amountToDiscount"
                    className="form-control d-inline-block me-3"
                    style={{ width: 'min-content' }}
                    value={values.amountToDiscount}
                    onChange={handleChange}
                  />
                  {showCorrectInputUnitCode(values.promotionType, values.productType)}
                </>
              )}
            </div>
            <div className="mb-3">
              <span>Gamefication</span>
            </div>
            {/* Gamefication */}
            <div className="mb-3">
              <Tab.Container
                activeKey={activeTabKey}
                onSelect={(key) => setActiveTabKey(key ? (key as keyof typeof TabKey) : 'German')}
              >
                <div className="d-flex flex-column flex-sm-row">
                  <Nav variant="pills" className="mt-0 mt-sm-4 flex-row flex-sm-column">
                    {mapEnum(TabKey, (val, index) => (
                      <NavItemHoverPointer key={index}>
                        <NavPill eventKey={TabKey[val]}>{t(`CommonLabels.${TabKey[val]}`)}</NavPill>
                      </NavItemHoverPointer>
                    ))}
                  </Nav>
                  <BorderedTabContent className="border border-bottom-0">
                    {mapEnum(TabKey, (val, index) => (
                      <Tab.Pane key={index} eventKey={TabKey[val]}>
                        {/* GameficationLinkURL */}
                        <div className="mb-3">
                          <label htmlFor={`${TabKey[val]}GameficationLinkUrlId`} className="form-label">
                            {t('AddPromotionModal.GameficationLinkUrl')}
                          </label>
                          {val === TabKey.German && errors.gameficationLinkUrl && (
                            <span className="d-block text-danger">{errors.gameficationLinkUrl}</span>
                          )}
                          {val === TabKey.French && errors.gameficationLinkUrlFR && (
                            <span className="d-block text-danger">{errors.gameficationLinkUrlFR}</span>
                          )}
                          <input
                            id={`${TabKey[val]}GameficationLinkUrlId`}
                            name={val === TabKey.German ? 'gameficationLinkUrl' : 'gameficationLinkUrlFR'}
                            className={`form-control ${
                              val === TabKey.German
                                ? errors.gameficationLinkUrl
                                  ? 'is-invalid'
                                  : ''
                                : errors.gameficationLinkUrlFR
                                ? 'is-invalid'
                                : ''
                            }`}
                            value={
                              val === TabKey.German
                                ? values.gameficationLinkUrl ?? ''
                                : values.gameficationLinkUrlFR ?? ''
                            }
                            onChange={handleChange}
                          />
                        </div>
                        {/* GameficationLinkTitle */}
                        <div className="mb-3">
                          <label htmlFor={`${TabKey[val]}gameficationLinkTitleId`} className="form-label">
                            {t('AddPromotionModal.GameficationLinkTitle')}
                          </label>
                          {val === TabKey.German && errors.gameficationLinkTitle && (
                            <span className="d-block text-danger">{errors.gameficationLinkTitle}</span>
                          )}
                          {val === TabKey.French && errors.gameficationLinkTitleFR && (
                            <span className="d-block text-danger">{errors.gameficationLinkTitleFR}</span>
                          )}
                          <input
                            id={`${TabKey[val]}gameficationLinkTitleId`}
                            name={val === TabKey.German ? 'gameficationLinkTitle' : 'gameficationLinkTitleFR'}
                            className={`form-control ${
                              val === TabKey.German
                                ? errors.gameficationLinkTitle
                                  ? 'is-invalid'
                                  : ''
                                : errors.gameficationLinkTitleFR
                                ? 'is-invalid'
                                : ''
                            }`}
                            value={
                              val === TabKey.German
                                ? values.gameficationLinkTitle ?? ''
                                : values.gameficationLinkTitleFR ?? ''
                            }
                            onChange={handleChange}
                          />
                        </div>
                        {/* GameficationIFrameLinkUrlFR */}
                        <div className="mb-3">
                          <label htmlFor={`${TabKey[val]}gameficationIFrameLinkUrlId`} className="form-label">
                            {t('AddPromotionModal.GameficationIFrameLinkUrl')}
                          </label>
                          {val === TabKey.German && errors.gameficationIFrameLinkUrl && (
                            <span className="d-block text-danger">{errors.gameficationIFrameLinkUrl}</span>
                          )}
                          {val === TabKey.French && errors.gameficationIFrameLinkUrlFR && (
                            <span className="d-block text-danger">{errors.gameficationIFrameLinkUrlFR}</span>
                          )}
                          <input
                            id={`${TabKey[val]}gameficationIFrameLinkUrlId`}
                            name={val === TabKey.German ? 'gameficationIFrameLinkUrl' : 'gameficationIFrameLinkUrlFR'}
                            className={`form-control ${
                              val === TabKey.German
                                ? errors.gameficationIFrameLinkUrl
                                  ? 'is-invalid'
                                  : ''
                                : errors.gameficationIFrameLinkUrlFR
                                ? 'is-invalid'
                                : ''
                            }`}
                            value={
                              val === TabKey.German
                                ? values.gameficationIFrameLinkUrl ?? ''
                                : values.gameficationIFrameLinkUrlFR ?? ''
                            }
                            onChange={handleChange}
                          />
                        </div>
                      </Tab.Pane>
                    ))}
                  </BorderedTabContent>
                </div>
              </Tab.Container>
            </div>
            {touched.validityZone && errors.validityZone && (
              <span className="d-block text-danger">{errors.validityZone}</span>
            )}
            <div className="d-flex align-items-center mb-3">
              <span className="d-inline-block me-3">{t('AddPromotionModal.ValidityZones')}</span>
              <ButtonGroup className="align-self-start">
                {mapEnum(ValidityZone, (val, index) => (
                  <ToggleButton
                    key={index}
                    id={`radio-validity-zone-${val}`}
                    type="radio"
                    variant={values.validityZone === val ? 'primary' : 'light'}
                    value={val}
                    checked={values.validityZone === val}
                    onChange={(e) => {
                      if (Number(e.currentTarget.value) === ValidityZone.AllZones) {
                        setFieldValue('assignedZones', assignableZones);
                      } else {
                        setFieldValue('assignedZones', undefined);
                      }

                      setFieldValue('assignedPostalCodes', undefined);
                      setFieldValue('validityZone', Number(e.currentTarget.value));
                    }}
                  >
                    <Trans i18nKey={`AddPromotionModal.${ValidityZone[val]}`} t={t}>
                      {{ holding: holding?.name }}
                    </Trans>
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
            {values.validityZone === ValidityZone.SelectedZones && (
              <div className="mb-3">
                {touched.assignedZones && errors.assignedZones && (
                  <span className="d-block text-danger">{errors.assignedZones}</span>
                )}
                <span className="d-block mb-2">{t('AddPromotionModal.AddZonesToPromotion')}</span>
                <ItemAssigner
                  dropdownItems={assignableZones.sort((first, second) => first.name.localeCompare(second.name))}
                  assignedItems={values.assignedZones ?? []}
                  setAssignedItems={(assignedItems) => {
                    setFieldTouched('assignedZones');
                    setFieldValue('assignedZones', assignedItems);
                  }}
                  selectedItem={selectedZone}
                  setSelectedItem={(selectedZone) => setSelectedZone(selectedZone)}
                  removeButtonLabel={t('Promotion.Remove')}
                  hasAddAllButton
                  addAllButtonLabel={t('Promotion.AddAllZones')}
                />
              </div>
            )}
            {values.validityZone === ValidityZone.SelectedPostalCodes && (
              <div className="mb-3">
                {touched.assignedPostalCodes && errors.assignedPostalCodes && (
                  <span className="d-block text-danger">{errors.assignedPostalCodes}</span>
                )}
                <span className="d-block mb-2">{t('AddPromotionModal.AddZonesToPromotion')}</span>
                <ItemAssigner
                  dropdownItems={assignablePostalCodes}
                  assignedItems={values.assignedPostalCodes ?? []}
                  setAssignedItems={(assignedItems) => {
                    setFieldTouched('assignedPostalCodes');
                    setFieldValue('assignedPostalCodes', assignedItems);
                  }}
                  selectedItem={selectedPostalCode}
                  removeButtonLabel={t('Promotion.Remove')}
                  hasAddAllButton
                  hasUploadButton
                  addAllButtonLabel={t('AddPromotionModal.AddAllPostalCodes')}
                  uploadButtonLabel={isUploadingPostalCodes ? <Spinner /> : t('AddPromotionModal.Upload')}
                  disableAddAllButton={loadingPostalCodes || !selectedZone}
                  disableUploadButton={isUploadingPostalCodes}
                  uploadRef={uploadInputRef}
                  onUploadButtonChange={(e) => {
                    setIsUploadingPostalCodes(true);

                    const postalCodesToUpload = e.currentTarget.files?.item(0);

                    if (postalCodesToUpload) {
                      promotionsService
                        .uploadPostalCodes(
                          assignableZones.map((zone) => zone.id),
                          postalCodesToUpload,
                        )
                        .then((res) => {
                          const castSuccessfulPostalCode = res.successful.map<AssignedPostalCode>((pc) => ({
                            id: pc.id,
                            name: pc.npa.toString(),
                            description: pc.name,
                          }));

                          const newAssignedPostalCodes = uniqueBy(
                            [...(values.assignedPostalCodes ?? []), ...castSuccessfulPostalCode],
                            (pc) => pc.id,
                          );

                          setFieldValue('assignedPostalCodes', newAssignedPostalCodes);
                          setIsUploadingPostalCodes(false);
                        })
                        .catch((error) => {
                          console.error(error);

                          if (isErrorViewModel(error)) {
                            setErrors([...appErrors, error]);
                          }

                          setIsUploadingPostalCodes(false);
                        })
                        .finally(() => {
                          removeFileFromInput();
                        });
                    } else {
                      setErrors([
                        ...appErrors,
                        { title: 'File error', value: { description: "Couldn't read the file" }, statusCode: '' },
                      ]);

                      removeFileFromInput();

                      setIsUploadingPostalCodes(false);
                    }
                  }}
                  renderDropdown={() => (
                    <div className="d-flex mb-3 align-items-center">
                      <DropDownList
                        className="me-3"
                        style={{ flex: 1 }}
                        data={assignableZones.sort((first, second) => first.name.localeCompare(second.name))}
                        value={selectedZone}
                        textField="name"
                        dataItemKey="id"
                        onChange={(e) => {
                          setFieldTouched('assignedPostalCodes');
                          setSelectedZone(e.target.value);
                        }}
                      />

                      <DropDownList
                        style={{ flex: 1 }}
                        data={assignablePostalCodes
                          .map((pc) => ({
                            ...pc,
                            fullName: `${pc.name} ${pc.description}`,
                          }))
                          .sort((first, second) => first.name.localeCompare(second.name))}
                        value={selectedPostalCode}
                        textField="fullName"
                        dataItemKey="id"
                        onChange={(e) => {
                          setFieldTouched('assignedPostalCodes');
                          setSelectedPostalCode(e.target.value);
                        }}
                        disabled={loadingPostalCodes}
                      />

                      {loadingPostalCodes && (
                        <div className="ms-1 spinner-border" style={{ color: '#007d32' }} role="status">
                          <span className="visually-hidden">{t('CommonLabels.Loading')}...</span>
                        </div>
                      )}
                    </div>
                  )}
                />
              </div>
            )}
            <div className="d-flex flex-column mb-3">
              <div className="d-flex align-items-center">
                <span className="d-block text-danger" style={{ flex: 1 }}>
                  {touched.validFrom || touched.hourValidFrom ? errors.validFrom || errors.hourValidFrom : ''}
                </span>
                <span className="d-block text-danger" style={{ flex: 1 }}>
                  {touched.validTo || touched.hourValidTo ? errors.validTo || errors.hourValidTo : ''}
                </span>
              </div>

              <div className="d-flex">
                <div className="d-flex align-items-center" style={{ flex: 1, flexWrap: 'nowrap' }}>
                  <span className="d-inline-block me-3" style={{ whiteSpace: 'nowrap' }}>
                    {t('AddPromotionModal.ValidFrom')}
                  </span>
                  <DayPicker
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="L"
                    placeholder={formatDate(new Date(), 'L', language)}
                    locale={language}
                    onDayChange={(date: Date) => setFieldValue('validFrom', date)}
                    value={values.validFrom}
                    style={{ marginRight: '1rem' }}
                  />
                  <TimePicker
                    onChange={(e) => setFieldValue('hourValidFrom', e)}
                    value={values.hourValidFrom ?? ''}
                    isInputValid={touched.hourValidFrom ? !errors.hourValidFrom : true}
                  />
                </div>

                <div className="d-flex align-items-center" style={{ flex: 1, flexWrap: 'nowrap' }}>
                  <span className="d-inline-block me-3" style={{ whiteSpace: 'nowrap' }}>
                    {t('AddPromotionModal.ValidUntil')}
                  </span>
                  <DayPicker
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="L"
                    placeholder={formatDate(new Date(), 'L', language)}
                    locale={language}
                    onDayChange={(date: Date) => setFieldValue('validTo', date)}
                    value={values.validTo}
                    style={{ marginRight: '1rem' }}
                  />
                  <TimePicker
                    onChange={(e) => setFieldValue('hourValidTo', e)}
                    value={values.hourValidTo ?? ''}
                    isInputValid={touched.hourValidFrom ? !errors.hourValidTo : true}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              {touched.validityDelimitation && errors.validityDelimitation && (
                <span className="d-block text-danger">{errors.validityDelimitation}</span>
              )}
              <label htmlFor="validityDelimitationId" className="form-label">
                {t('AddPromotionModal.ValidityDelimitationOptions')}
              </label>
              <select
                id="validityDelimitationId"
                name="validityDelimitation"
                className="form-select"
                value={values.validityDelimitation}
                onChange={handleChange}
              >
                {mapEnum(ValidityDelimitationDropdown, (v, index) => (
                  <option key={index} value={v}>
                    {t(`AddPromotionModal.ValidityDelimitations.${ValidityDelimitationDropdown[v]}`)}
                  </option>
                ))}
              </select>
            </div>
            {/* Descrption and email Description */}
            {(errors.descriptionDE || errors.descriptionFR) && (
              <div className="mb-1">
                {touched.descriptionDE && <span className="d-block text-danger">{errors.descriptionDE}</span>}
                {touched.descriptionFR && <span className="d-block text-danger">{errors.descriptionFR}</span>}
              </div>
            )}
            <Tab.Container
              activeKey={activeTabKey}
              onSelect={(key) => setActiveTabKey(key ? (key as keyof typeof TabKey) : 'German')}
            >
              <div className="d-flex flex-column flex-sm-row">
                <Nav variant="pills" className="mt-0 mt-sm-4 flex-row flex-sm-column">
                  {mapEnum(TabKey, (val, index) => (
                    <NavItemHoverPointer key={index}>
                      <NavPill eventKey={TabKey[val]}>{t(`CommonLabels.${TabKey[val]}`)}</NavPill>
                    </NavItemHoverPointer>
                  ))}
                </Nav>
                <BorderedTabContent className="border border-bottom-0">
                  {mapEnum(TabKey, (val, index) => (
                    <Tab.Pane key={index} eventKey={TabKey[val]}>
                      <div className="mb-3">
                        <label htmlFor={`${TabKey[val]}DescriptionId`} className="form-label">
                          {val === TabKey.German
                            ? t('AddPromotionModal.DescriptionDE')
                            : t('AddPromotionModal.DescriptionFR')}
                        </label>
                        <input
                          id={`${TabKey[val]}DescriptionId`}
                          data-tip={t('AddPromotionModal.DescriptionToolTip', {
                            placeholders: discountPhrasePlaceholders.join(', '),
                          })}
                          name={val === TabKey.German ? 'descriptionDE' : 'descriptionFR'}
                          className="form-control"
                          value={val === TabKey.German ? values.descriptionDE : values.descriptionFR}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor={`${TabKey[val]}EmailDescriptionId`} className="form-label">
                          {val === TabKey.German
                            ? t('AddPromotionModal.EmailDescriptionDE')
                            : t('AddPromotionModal.EmailDescriptionFR')}
                        </label>
                        <textarea
                          id={`${TabKey[val]}EmailDescriptionId`}
                          name={val === TabKey.German ? 'emailDescriptionDE' : 'emailDescriptionFR'}
                          className="form-control"
                          value={val === TabKey.German ? values.emailDescriptionDE : values.emailDescriptionFR}
                          onChange={handleChange}
                          rows={3}
                        />
                      </div>
                    </Tab.Pane>
                  ))}
                </BorderedTabContent>
              </div>
            </Tab.Container>
            {/* Gift Selection */}
            {(values.promotionType == PromotionType.GiftWithCode || values.promotionType == PromotionType.Gift) && (
              <div>
                <div className="mb-3">
                  <label className="form-label">{t('AddPromotionModal.GiftSelection')}</label>
                  <button
                    type="button"
                    className="ms-2 btn btn-primary p-1 pt-0 pb-0 rounded-circle fw-bold"
                    onClick={giftItemAddNew}
                  >
                    <span style={{ fontSize: 36, lineHeight: 0.65 }}>+</span>
                  </button>
                </div>
                <div className="mb-3">
                  <div className="d-flex flex-column flex-sm-row">
                    <Grid data={assignedGifts} onItemChange={giftItemOnChange} editField={editField}>
                      <Column field="descriptionDE" title={t('AddPromotionModal.DescriptionDE')} editor="text" />
                      <Column field="descriptionFR" title={t('AddPromotionModal.DescriptionFR')} editor="text" />
                      <Column cell={commandCell} />
                    </Grid>
                  </div>
                </div>
              </div>
            )}
            {/* Product templates */}
            {values.templates &&
              values.templates.map((item, index) => (
                <div key={'productTemplateRow' + index}>
                  <div className="mb-1">
                    <span className="d-inline-block mt-3 me-2">{t('AddPromotionModal.AddPromotionDisplay')}</span>
                    <button
                      type="button"
                      className={`btn ${
                        !values.templates ? 'btn-primary px-1 py-0 rounded-circle fw-bold' : 'px-2 py-1 btn-danger'
                      }`}
                      onClick={() => {
                        values.templates?.splice(index, 1);
                        setFieldValue('templates', values.templates);
                      }}
                    >
                      {!values.templates && (
                        <span style={{ fontSize: 36, lineHeight: 0.65, alignSelf: 'center' }}>+</span>
                      )}
                      {values.templates && <span>{t('Promotion.Remove')}</span>}
                    </button>
                  </div>
                  <div className="mt-3">
                    {/* Base template selection */}
                    <label htmlFor={'baseTemplate' + index} className="mb-1 d-block">
                      {t('AddPromotionModal.ChooseProduct')}
                    </label>
                    <DropDownList
                      name={'baseTemplate' + index}
                      className="mb-4 w-100"
                      data={products?.filter((product) => product.productTypeId === values.productType.id)}
                      value={values && values.templates ? values.templates[index].baseTemplateProduct : ''}
                      textField={`name${capitalizeFirstLetter(language)}`}
                      dataItemKey="id"
                      onChange={(e) => {
                        const selection = e.target.value as Product;
                        if (
                          confirm(t('AddPromotionModal.ResetTemplate')) &&
                          selection.productTemplate &&
                          values.templates
                        ) {
                          values.templates[index].productTemplates.map((o) => {
                            o.id = 0;
                            o.template =
                              (o.language === 'FR'
                                ? selection.productTemplate?.find((o) => o.language === 'FR')?.template
                                : selection.productTemplate?.find((o) => o.language === 'DE')?.template) ?? '';
                          });
                          setFieldTouched(`templates[${index}].productTemplates`);
                        }
                      }}
                    />

                    {/* Product template selection */}
                    <label htmlFor={'applyTemplateToProductId' + index} className="mb-1 d-block">
                      {t('AddPromotionModal.ChooseProductToApplyTo')}
                    </label>
                    {(errors.templates?.[index] as FormikErrors<ProductTemplateItem>)?.product && (
                      <span className="d-block text-danger">
                        {(errors.templates?.[index] as FormikErrors<ProductTemplateItem>)?.product}
                      </span>
                    )}
                    <DropDownList
                      id={'applyTemplateToProductId' + index}
                      name={`values.templates[${index}].product`}
                      className="mb-4 w-100"
                      data={Array(defaultSelectedTemplateProduct).concat(
                        products?.filter((product) => product.productTypeId === values.productType.id),
                      )}
                      value={values.templates ? values.templates[index]?.product : undefined}
                      textField={`name${capitalizeFirstLetter(language)}`}
                      dataItemKey="id"
                      onChange={(e) => {
                        const selection = e.target.value as Product;
                        if (values.templates) {
                          values.templates[index].productTemplates.map((o) => {
                            o.productId = selection.id;
                          });
                        }
                        setFieldValue(`templates[${index}].product`, selection);
                        setFieldTouched(`templates[${index}].productTemplates`);
                        setFieldTouched(`templates[${index}].product`);
                      }}
                    />
                    <Tab.Container
                      id={'tabContainerProductTemplate' + index}
                      activeKey={activeTemplateTabKey}
                      onSelect={(key) => setActiveTemplateTabKey(key ? (key as keyof typeof TabKey) : 'German')}
                    >
                      <div className="d-flex flex-column flex-sm-row">
                        <Nav variant="pills" className="mt-0 mt-sm-4 flex-row flex-sm-column">
                          {mapEnum(TabKey, (val, tabIndex) => (
                            <NavItemHoverPointer key={tabIndex}>
                              <NavPill eventKey={TabKey[val]}>{t(`CommonLabels.${TabKey[val]}`)}</NavPill>
                            </NavItemHoverPointer>
                          ))}
                        </Nav>
                        <BorderedTabContent className="border border-bottom-0" style={{ minHeight: '60vh' }}>
                          {mapEnum(TabKey, (val, tabIndex) => (
                            <Tab.Pane
                              id={'tabPaneLanguage' + index}
                              key={tabIndex}
                              eventKey={TabKey[val]}
                              className="h-100"
                            >
                              <ProductTemplatePreview
                                id={`productTemplateOverview-${index}-${tabIndex}`}
                                productImage={
                                  values.templates
                                    ? values.templates[index]?.productTemplates[tabIndex]?.templateImageUrl
                                    : values.promotionTemplateImage
                                }
                                setProductImage={(imageUrl) => {
                                  setTemplateImage(
                                    imageUrl,
                                    activeTemplateTabKey,
                                    values.templates[index].productTemplates,
                                    `templates[${index}].productTemplates`,
                                    setFieldValue,
                                  );
                                }}
                                productTemplate={
                                  (values.templates &&
                                    values.templates[index].productTemplates.find(
                                      (o) => o.language === (val === TabKey.German ? 'DE' : 'FR'),
                                    )) || {
                                    id: 0,
                                    language: val === TabKey.German ? 'DE' : 'FR',
                                    template: '',
                                    templateImageUrl: '',
                                  }
                                }
                                setTemplate={(template) => {
                                  setTemplate(
                                    val === TabKey.German ? 'DE' : 'FR',
                                    values.templates && values.templates[index].productTemplates
                                      ? [...values.templates[index].productTemplates]
                                      : [],
                                    template,
                                    `templates[${index}].productTemplates`,
                                    setFieldValue,
                                  );
                                }}
                                templateError={getTemplateError(errors, index, tabIndex)}
                              />
                            </Tab.Pane>
                          ))}
                        </BorderedTabContent>
                      </div>
                    </Tab.Container>
                  </div>
                </div>
              ))}
            {values.templates && (
              <div className="mb-1">
                <span className="d-inline-block mt-3 me-2">{t('AddPromotionModal.AddPromotionDisplay')}</span>
                <button
                  type="button"
                  className={'btn btn-primary px-1 py-0 rounded-circle fw-bold'}
                  onClick={() => {
                    values.templates?.push({
                      baseTemplateProduct: undefined,
                      product: undefined,
                      productTemplates: [
                        {
                          id: 0,
                          language: 'DE',
                          template: '',
                          templateImageUrl: '',
                        },
                        {
                          id: 0,
                          language: 'FR',
                          template: '',
                          templateImageUrl: '',
                        },
                      ],
                    });
                    setFieldValue('hasTemplate', true);
                  }}
                >
                  <span style={{ fontSize: 36, lineHeight: 0.65, alignSelf: 'center' }}>+</span>
                </button>
              </div>
            )}
            <div className="modal-footer pb-0 pe-0">
              <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <Spinner /> : t('Promotion.SavePromotion')}
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <ReactTooltip multiline place="top" effect="solid" textColor="white" />
    </>
  );
};

export default withModal(withErrorHandling(AddPromotionForm));
