import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { LogsService } from '../services/LogsService';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * LogsService to be used in components to make API calls.
 *
 * @returns a LogsService instance
 */
export default function useLogsService(): LogsService {
  const config = useContext(AgrConfigurationContext);

  const logsService = useMemo(() => {
    return new LogsService(config);
  }, [config]);

  return logsService;
}
