import { Constants } from '@/constants/Constants';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Routes } from '../../../assets/constants/Links';
import useApplicationState from '../../../hooks/useApplicationState';
import useIsInRole from '../../../hooks/useIsInRole';
import { LoadMessage } from '../../common/load-message/LoadMessage';
import ProtectedRoute from '../../common/protected-route/ProtectedRoute';
import ViewAlerts from '../../pages/alerts/view-form/ViewAlerts';
import ViewPricelogs from '../../pages/pricelogs/view-form/ViewPricelogs';
import ViewLogs from '../../pages/audit-logs/view-form/ViewLogs';
import ViewBasePrice from '../../pages/base-price/view-form/ViewBasePrice';
import ViewDashboard from '../../pages/dashboard/view-form/ViewDashboard';
import ViewDisporegions from '../../pages/disporegions/view-form/ViewDisporegions';
import ViewMailTemplates from '../../pages/mail-templates/view-form/ViewMailTemplates';
import ViewMargins from '../../pages/margins/view-form/ViewMargins';
import ViewOrders from '../../pages/orders/view-form/ViewOrders';
import ViewPortalIntegration from '../../pages/portal-integration/view-form/ViewPortalIntegration';
import ViewPostalCodesAssignment from '../../pages/postal-codes/view-form/ViewPostalCodesAssignment';
import ViewProducts from '../../pages/products/view-form/ViewProducts';
import ViewPromotions from '../../pages/promotions/view-form/ViewPromotions';
import EditSettings from '../../pages/settings/view-form/EditSettings';
import ViewShops from '../../pages/shops/view-form/ViewShops';
import ViewUserProfiles from '../../pages/user-profile/view-form/ViewUserProfiles';
import ViewZones from '../../pages/zones/view-form/ViewZones';
import { ErrorMessageAuthorization } from '../error-message-authorization/ErrorMessageAuthorization';
import './RoutedContent.scss';
import ViewTextResources from '@/components/pages/textresources/view-form/ViewTextResources';
import ViewDieselSettings from '@/components/pages/diesel/view-form/ViewDieselSettings';

/**
 * The routed content of the web site.
 */
function RoutedContent(): JSX.Element | null {
  const isAdministrator = useIsInRole(Constants.Roles.Administrator);

  const { loadingRoles } = useApplicationState();

  return (
    <div className="agr-content form-1-box full">
      <div>
        {loadingRoles ? (
          <LoadMessage keyMessage="Authorizing" showSpinner={false} />
        ) : (
          <Switch>
            <Route exact path="/">
              <Redirect to={isAdministrator ? Routes.Users : Routes.Dashboard} />
            </Route>
            <ProtectedRoute
              role={['Administrator', 'HoldingUser', 'ShopUser']}
              path={Routes.Dashboard}
              component={ViewDashboard}
            />
            <ProtectedRoute role={['Administrator']} component={ViewUserProfiles} path={Routes.Users} />
            <ProtectedRoute role={['Administrator']} path={Routes.Products} component={ViewProducts} />
            <ProtectedRoute role={['Administrator']} component={ViewMailTemplates} path={Routes.MailTemplates} />
            <ProtectedRoute
              role={['Administrator', 'ShopUser', 'HoldingUser']}
              path={Routes.Zones}
              component={ViewZones}
            />
            <ProtectedRoute
              role={['Administrator', 'ShopUser', 'HoldingUser']}
              path={Routes.Diesel}
              component={ViewDieselSettings}
            />
            <ProtectedRoute role={['Administrator']} component={ViewShops} path={Routes.Shops} />
            <ProtectedRoute role={['Administrator']} component={ViewLogs} path={Routes.SystemLogs} />
            <ProtectedRoute
              role={['Administrator', 'ShopUser', 'HoldingUser']}
              component={ViewOrders}
              path={Routes.Orders}
            />
            <ProtectedRoute role={['Administrator']} component={ViewDisporegions} path={Routes.Disporegions} />
            <ProtectedRoute role={['Administrator']} component={ViewPostalCodesAssignment} path={Routes.PostalCodes} />
            <ProtectedRoute role={['HoldingUser', 'ShopUser']} component={ViewMargins} path={Routes.Margins} />
            <ProtectedRoute
              role={['Administrator', 'ShopUser', 'HoldingUser']}
              component={ViewAlerts}
              path={Routes.Alerts}
            />
            <ProtectedRoute
              role={['Administrator']}
              component={ViewPortalIntegration}
              path={Routes.PortalIntegration}
            />
            <ProtectedRoute
              role={['Administrator', 'ShopUser', 'HoldingUser']}
              component={ViewPromotions}
              path={Routes.Promotions}
            />
            <ProtectedRoute role={['Administrator']} component={EditSettings} path={Routes.Settings} />
            <ProtectedRoute role={['Administrator', 'HoldingUser']} component={ViewBasePrice} path={Routes.BasePrice} />
            <ProtectedRoute role={['Administrator']} component={ViewPricelogs} path={Routes.PriceLogs} />
            <ProtectedRoute role={['Administrator']} component={ViewTextResources} path={Routes.TextResources} />
            <Route path="/not-authorized">
              <ErrorMessageAuthorization />
            </Route>
          </Switch>
        )}
      </div>
    </div>
  );
}

export default RoutedContent;
