export function GetLanguageName(t: (arg: string) => '', language: Language): string {
  switch (language) {
    case Language.DE:
      return t('Language.German');
    case Language.FR:
      return t('Language.French');
    default:
      throw 'Invalid language: ' + language;
  }
}

export enum Language {
  DE,
  FR,
}
