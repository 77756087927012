import { Alert } from './Alert';
import { Order } from './Order';

/**
 * Represents price request log.
 */
export interface Pricelog {
  id: number;

  priceLogType: PriceLogType | string;

  /**
   * The translated representation of the pricelogType.
   */
  pricelogTypeName?: string | null;

  invocationId: string;

  executionTime: Date | null;

  request: string;

  response: string;

  productNumber: number;

  basisPrice: number;

  quantity: number;

  order?: Order;

  alert?: Alert;

  email: string;
}

/**
 * @param pricelogType Type guard for ErrorViewModel
 */
export function isPricelogType(pricelogType: PriceLogType | any): pricelogType is PriceLogType {
  return (
    (pricelogType as PriceLogType) === PriceLogType.PriceDesired ||
    (pricelogType as PriceLogType) === PriceLogType.PriceAlarm ||
    (pricelogType as PriceLogType) === PriceLogType.PriceSubscription ||
    (pricelogType as PriceLogType) === PriceLogType.FuelOrder ||
    (pricelogType as PriceLogType) === PriceLogType.WoodOrder ||
    (pricelogType as PriceLogType) === PriceLogType.DieselOrder ||
    (pricelogType as PriceLogType) === PriceLogType.Unknown
  );
}

/**
 * Computes a translated string representing the pricelog type.
 * @param t the i8n translation object.
 * @param PricelogType The value of the enumeration of the type.
 * @returns A translated string representing the pricelog type.
 */
export function GetPricelogTypeName(
  t: (arg: string) => '',
  pricelogType: PriceLogType | string | null | undefined,
): string {
  if (isPricelogType(pricelogType)) {
    switch (pricelogType) {
      case PriceLogType.PriceAlarm:
        return t('PricelogType.PriceAlarm');
      case PriceLogType.PriceDesired:
        return t('PricelogType.PriceDesired');
      case PriceLogType.PriceSubscription:
        return t('PricelogType.PriceSubscription');
      case PriceLogType.FuelOrder:
        return t('PricelogType.FuelOrder');
      case PriceLogType.WoodOrder:
        return t('PricelogType.WoodOrder');
      case PriceLogType.DieselOrder:
        return t('PricelogType.DieselOrder');
      case PriceLogType.Unknown:
        return t('PricelogType.Unknown');
      default:
        throw 'Invalid status: ' + pricelogType;
    }
  }
  return '';
}

export enum PriceLogType {
  Unknown = 0,
  WoodOrder = 1,
  FuelOrder = 2,
  PriceAlarm = 4,
  PriceAbo = 8,
  PriceDesired = 16,
  PriceSubscription = 32,
  DieselOrder = 64,
}
