import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { PriceService } from '../services/PriceService';

/**
 * Wraps the PriceService in a useMemo call.
 *
 * @returns the memoized PriceService
 */
export default function usePriceService(): PriceService {
  const config = useContext(AgrConfigurationContext);

  const priceService = useMemo(() => {
    return new PriceService(config);
  }, [config]);

  return priceService;
}
