import { object, string } from 'yup';
import { Constants } from '../../../../constants/Constants';

/**
 * This file exports the function in order to get the validation schema
 * with the appropriate translation at runtime.
 *
 * @param t - The translation function as provided by the useTranslation
 *            i18next hook.
 *
 * @returns the validation schema with the appropriate translations.
 */
export default function getOrderStatusChangeFormValidationSchema(t: any) {
  const schema = object().shape({
    sender: string()
      .max(Constants.Validation.TextMaxLength, t('OrderNotification.ValidationErrors.SenderEmailNotValid'))
      .required(t('OrderNotification.ValidationErrors.SenderRequired'))
      .email(t('OrderNotification.ValidationErrors.SenderEmailNotValid')),
    recipient: string()
      .max(Constants.Validation.TextMaxLength, t('OrderNotification.ValidationErrors.RecipientEmailNotValid'))
      .required(t('OrderNotification.ValidationErrors.RecipientRequired'))
      .email(t('OrderNotification.ValidationErrors.RecipientEmailNotValid')),
  });

  return schema;
}
