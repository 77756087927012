import { object, number } from 'yup';

/**
 *
 * This file exports the function in order to get the validation schema
 * with the appropriate translation at runtime.
 *
 * @param t - The translation function as provided by the useTranslation
 *            i18next hook.
 *
 * @returns the validation schema with the appropriate translations.
 */
export default function getEditMarginValidationSchema(t: any) {
  const requiredErrorSuffix = t('ValidationErrors.Required');
  const numberErrorSuffix = t('ValidationErrors.Number');
  const positiveErrorSuffix = t('ValidationErrors.PositiveNumber');

  const schema = object().shape({
    deliveryQuantity: number()
      .typeError(`${t('MarginsView.ValidationErrors.TheDeliveryQuantity')} ${numberErrorSuffix}.`)
      .positive(`${t('MarginsView.ValidationErrors.TheDeliveryQuantity')} ${positiveErrorSuffix}.`)
      .required(`${t('MarginsView.ValidationErrors.TheDeliveryQuantity')} ${requiredErrorSuffix}.`),
    marginValue: number()
      .typeError(`${t('MarginsView.ValidationErrors.TheMarginValue')} ${numberErrorSuffix}.`)
      .positive(`${t('MarginsView.ValidationErrors.TheMarginValue')} ${positiveErrorSuffix}.`)
      .required(`${t('MarginsView.ValidationErrors.TheMarginValue')} ${requiredErrorSuffix}.`),
  });

  return schema;
}
