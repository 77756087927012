import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useEffect, useState } from 'react';

const Clear = 'clear';

export const DropdownFilterCell = (props: any) => {
  let hasValue: any = (value: any) => Boolean(value && value !== props.defaultItem);

  const [selectedValue, setSelectedValue] = useState<string | any>();

  useEffect(() => {
    setSelectedValue(props.value);
  }, [setSelectedValue, props.value]);

  const onChange = (event: any) => {
    hasValue = hasValue(event.target.value);
    setSelectedValue(event.target.value);
    props.onChange({
      value: hasValue ? event.target.value.id : Clear,
      operator: 'eq',
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    setSelectedValue('');
    props.onChange({
      value: '',
      operator: '',
      syntheticEvent: event,
    });
  };

  return (
    <div className="k-filtercell">
      <DropDownList
        data={props.data}
        onChange={onChange}
        value={selectedValue || props.defaultItem}
        defaultItem={props.defaultItem}
        textField={props.textField}
        dataItemKey={props.dataItemKey}
      />
    </div>
  );
};

/**
 * The table column is based on the 'statusName' field, which contains the translated value of the 'status' enumeration.
 * However, the server filter requires the 'status' field; this code will remove replace the filter on the column 'statusName'
 * with a filter on the 'status' column.
 * @param e the new data state.
 * @param tCol the name of the column that has the translated value.
 * @param sCol the actual name of the column that should be sent to the server.
 */
export function filterDropDownValues(e: any, tCol: string, sCol: string) {
  if (e.dataState.filter && e.dataState.filter.filters && e.dataState.filter.filters.length > 0) {
    // Looks for a filter with name 'statusName'.
    const statusNameFilter = e.dataState.filter.filters.find((f: { field: string }) => f.field == tCol);
    // If there is a filter with name 'statusName'...
    if (statusNameFilter != null) {
      // ...first remove the filter that might have been set on a previous filter selection.
      e.dataState.filter.filters = e.dataState.filter.filters.filter((f: { field: string }) => f.field != sCol);

      if (statusNameFilter.value != Clear) {
        // Then replace the name of the new 'statusName' filter with 'status'
        statusNameFilter.field = sCol;
      } else {
        e.dataState.filter.filters = e.dataState.filter.filters.filter((f: { field: string }) => f.field != tCol);
      }
    }
  }

  return e;
}

/**
 * The table column is based on the 'statusName' field, which contains the translated value of the 'status' enumeration.
 * However, the server filter requires the 'status' field; this code will remove replace the filter on the column 'statusName'
 * with a filter on the 'status' column.
 * @param e the new data state.
 * @param tCol the name of the column that has the translated value.
 * @param sCol the actual name of the column that should be sent to the server.
 */
export function filterDropDownUndefinedValues(e: any) {
  if (e.dataState.filter && e.dataState.filter.filters && e.dataState.filter.filters.length > 0) {
    e.dataState.filter.filters = e.dataState.filter.filters.filter(
      (f: { field: string; value: any | undefined }) => f.value != undefined,
    );
  }

  return e;
}

export function getFilterValue (e: any, tCol: string, sCol: string) : any {
  const edatastate = filterDropDownValues(e, tCol, sCol);
  let result = '';
  if (edatastate.dataState.filter && edatastate.dataState.filter.filters && edatastate.dataState.filter.filters.length){
    result = edatastate.dataState.filter.filters[0].value;
  }
  return result;
}
