import { object, string, number } from 'yup';
import { Constants } from '../../../../constants/Constants';
import useApplicationState from '../../../../hooks/useApplicationState';

/**
 * This file exports the function in order to get the validation schema
 * with the appropriate translation at runtime.
 *
 * @param t - The translation function as provided by the useTranslation
 *            i18next hook.
 *
 * @returns the validation schema with the appropriate translations.
 */
export default function getDisporegionFormValidationSchema(t: any, maxShowWeeksNumber: number) {

  const requiredErrorSuffix = t('ValidationErrors.Required');
  const maxLengthTextBoxErrorSuffix = t('ValidationErrors.MaxLengthTextbox');
  const maxLengthTextAreaErrorSuffix = t('ValidationErrors.MaxLengthTextarea');

  const schema = object().shape({
    name: string()
      .max(
        Constants.Validation.TextMaxLength,
        `${t('AddDisporegionModal.ValidationErrors.Name')} ${maxLengthTextBoxErrorSuffix}`,
      )
      .required(`${t('AddDisporegionModal.ValidationErrors.Name')} ${requiredErrorSuffix}.`),
    showWeeks: number().min(1).max(maxShowWeeksNumber),
    deliveryWaitTime: number().min(0).max(6),
    textDe: string().max(
      Constants.Validation.TextareaMaxLength,
      `${t('AddDisporegionModal.ValidationErrors.TextDe')} ${maxLengthTextAreaErrorSuffix}`,
    ),
    textFr: string().max(
      Constants.Validation.TextareaMaxLength,
      `${t('AddDisporegionModal.ValidationErrors.TextFr')} ${maxLengthTextAreaErrorSuffix}`,
    ),
  });

  return schema;
}
