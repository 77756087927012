/**
 *
 * This function capitalizes the first letter of a string.
 * It is used for filtering the grid by language.
 *
 * @param str - the string whose first letter has to be capitalized.
 *
 * @returns a new string with the capitalized first letter.
 */
export default function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
