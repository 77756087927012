import { object, number, string, array } from 'yup';

/**
 *
 * This file exports the function in order to get the validation schema
 * with the appropriate translation at runtime.
 *
 * @param t - The translation function as provided by the useTranslation
 *            i18next hook.
 *
 * @returns the validation schema with the appropriate translations.
 */
export default function getProductFormValidationSchema(t: any) {
  const requiredErrorSuffix = t('ValidationErrors.Required');
  const numberErrorSuffix = t('ValidationErrors.Number');
  const positiveErrorSuffix = t('ValidationErrors.PositiveNumber');
  const floatingPointErrorSuffix = t('ValidationErrors.FloatingPointNumber');

  const schema = object().shape({
    productTypeId: number()
      .required(`${t('AddProductModal.ValidationErrors.TheProductType')} ${requiredErrorSuffix}.`)
      .integer(`${t('AddProductModal.ValidationErrors.TheProductType')} ${floatingPointErrorSuffix}.`),
    agrolaProductNumber: number()
      .typeError(`${t('AddProductModal.ValidationErrors.TheAgrolaProductNumber')} ${numberErrorSuffix}.`)
      .required(`${t('AddProductModal.ValidationErrors.TheAgrolaProductNumber')} ${requiredErrorSuffix}.`)
      .positive(`${t('AddProductModal.ValidationErrors.TheAgrolaProductNumber')} ${positiveErrorSuffix}.`)
      .integer(`${t('AddProductModal.ValidationErrors.TheAgrolaProductNumber')} ${floatingPointErrorSuffix}.`),
    tktmProductNumber: number()
      .typeError(`${t('AddProductModal.ValidationErrors.TheTktmProductNumber')} ${numberErrorSuffix}.`)
      .required(`${t('AddProductModal.ValidationErrors.TheTktmProductNumber')} ${requiredErrorSuffix}.`)
      .positive(`${t('AddProductModal.ValidationErrors.TheTktmProductNumber')} ${positiveErrorSuffix}.`)
      .integer(`${t('AddProductModal.ValidationErrors.TheTktmProductNumber')} ${floatingPointErrorSuffix}.`),
    nameDe: string()
      .required(`${t('AddProductModal.ValidationErrors.RequiredProductName')} ${requiredErrorSuffix}.`)
      .label('name'),
    nameFr: string()
      .required(`${t('AddProductModal.ValidationErrors.RequiredProductName')} ${requiredErrorSuffix}.`)
      .label('name'),
    productTemplate: array()
      .of(
        object().shape({
          language: string().oneOf(['DE', 'FR']).required(),
          template: string()
            .required(`${t('AddProductModal.ValidationErrors.RequiredTemplate')} ${requiredErrorSuffix}.`)
            .label('template'),
        }),
      )
      .required(),
  });

  return schema;
}
