import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { DashboardService } from '../services/DashboardService';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * DisporegionsService to be used in components to make API calls.
 *
 * @returns a DisporegionsService instance
 */
export default function useDisporegionsService(): DashboardService {
  const config = useContext(AgrConfigurationContext);

  const dashboardService = useMemo(() => {
    return new DashboardService(config);
  }, [config]);

  return dashboardService;
}
