/**
 * The type of promotion.
 */
export enum PromotionType {
  FixedAmount,
  FixedAmountWithCode,
  AmountByQuantity,
  AmountByQuantityWithCode,
  Coupon,
  CouponWithCode,
  Percentual,
  PercentualWithCode,
  FinalFixedPrice,
  FinalFixedPriceWithCode,
  Gift,
  GiftWithCode
}
