import { ValidationError } from 'yup';

export type YupError<T> = { [index in keyof T]: string };

export default function useYupErrors<Shape>(schema: Shape): (error: ValidationError) => YupError<Shape> {
  return (error: ValidationError): YupError<Shape> => {
    type ShapeKeys = Array<keyof Shape>;
    const realShapeKeys = Object.keys(schema) as ShapeKeys;

    return realShapeKeys.reduce((acc, key) => {
      const foundError = error.inner.find((err) => {
        return err.path === (key as string) || err.params?.label === (key as string);
      });

      if (foundError) {
        acc[key] = foundError.message;
      }

      return acc;
    }, {} as YupError<Shape>);
  };
}
