import { ComponentType } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ModalProvider as ModalContextProvider } from '../../../contexts/ModalContext';

/**
 *
 * The modal props
 *
 */
export interface ModalProps {
  modalTitle: string | React.ReactNode;
  size?: 'sm' | 'lg' | 'xl'; // The size of the modal. It's automatically responsive, so this sets the default.
  show: boolean; // Can be a React state update for custom show logic inside the Modal.
  handleClose: () => void;
  handleSave?: () => void;
}

/**
 *
 * This HOC takes care of wrapping another component in a Modal
 * so that it can be reused everywhere in the code.
 *
 * @param WrappedComponent - the React Component to be wrapped in the Modal.
 *
 * @returns the Component wrapped in a Modal that can be used anywhere.
 */
export default function withModal<P>(WrappedComponent: ComponentType<P>) {
  return (props: P & ModalProps) => {
    const { modalTitle, show, size, handleClose, handleSave, ...rest } = props;

    return (
      <ModalContextProvider value={{ closeModal: handleClose, handleSave: handleSave }}>
        <Modal
          dialogClassName="modal-fullscreen-xl-down"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size={size}
        >
          <Modal.Header closeButton>{modalTitle}</Modal.Header>
          <Modal.Body>
            <WrappedComponent {...(rest as unknown as P)} />
          </Modal.Body>
        </Modal>
      </ModalContextProvider>
    );
  };
}
