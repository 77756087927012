import styled from 'styled-components';

/**
 * Main Wrapper style.
 */
const Wrapper = styled.div`
  margin: 0 20px 0 20px;
  overflow-x: auto;
  display: flex;
  min-height: 500px;
`;

/**
 * The ColumnsWrapper props.
 */
interface ColumnsWrapperProps {
  children: React.ReactNode;
}

/**
 *
 * The wrapper for the assignment columns that takes care of growing accordingly
 * to the numbers of column and setting the right overflow css property in case
 * of overflow.
 *
 * @returns the Component to be used in JSXs.
 */
export default function ColumnsWrapper({ children }: ColumnsWrapperProps) {
  return <Wrapper>{children}</Wrapper>;
}
