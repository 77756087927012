export interface PriceExportResult {
  /// <summary>
  /// The id of the export.
  /// </summary>
  id: number;

  /// <summary>
  /// The product id.
  /// </summary>
  tktmProductNumber: number;

  /// <summary>
  /// The quantity of the product.
  /// </summary>
  quantity: number;

  /// <summary>
  /// The creation time of the export.
  /// </summary>
  creationTime: Date;

  /// <summary>
  /// The start time of the export.
  /// </summary>
  startTime: Date | null;

  /// <summary>
  /// The end time of the export.
  /// </summary>
  endTime: Date | null;

  /// <summary>
  /// The type of the export.
  /// </summary>
  exportType: ExportType;

  /// <summary>
  /// true if the export failed.
  /// </summary>
  failed: boolean;

  /// <summary>
  /// The end time of the export.
  /// </summary>
  data: string;
}

export enum ExportType {
  Unknown,
  Price,
}
