import { number, object, string } from 'yup';

/**
 *
 * This file exports the function in order to get the validation schema
 * with the appropriate translation at runtime.
 *
 * @param t - The translation function as provided by the useTranslation
 *            i18next hook.
 *
 * @returns the validation schema with the appropriate translations.
 */
export function getZoneFormValidationSchema(t: any) {
  const requiredErrorSuffix = t('ValidationErrors.Required');
  const numberErrorSuffix = t('ValidationErrors.Number');
  const positiveErrorSuffix = t('ValidationErrors.PositiveNumber');
  const floatingPointErrorSuffix = t('ValidationErrors.FloatingPointNumber');

  const schema = object().shape({
    zoneId: number()
      .typeError(`${t('AddZoneModal.ValidationErrors.TheZoneId')} ${numberErrorSuffix}.`)
      .required(`${t('AddZoneModal.ValidationErrors.TheZoneId')} ${requiredErrorSuffix}.`)
      .integer(`${t('AddZoneModal.ValidationErrors.TheZoneId')} ${floatingPointErrorSuffix}.`)
      .positive(`${t('AddZoneModal.ValidationErrors.TheZoneId')} ${positiveErrorSuffix}.`),
    name: string().required(`${t('AddZoneModal.ValidationErrors.TheName')} ${requiredErrorSuffix}.`),
    shopId: number().required(`${t('AddZoneModal.ValidationErrors.TheShopId')} ${requiredErrorSuffix}.`),
    woodDisporegionId: number().required(
      `${t('AddZoneModal.ValidationErrors.TheWoodDisporegionId')} ${requiredErrorSuffix}.`,
    ),
    fuelDisporegionId: number().required(
      `${t('AddZoneModal.ValidationErrors.TheFuelDisporegionId')} ${requiredErrorSuffix}.`,
    ),
    dieselDisporegionId: number().required(
      `${t('AddZoneModal.ValidationErrors.TheDieselDisporegionId')} ${requiredErrorSuffix}.`,
    ),

    // other fields to be validated in the future...
  });

  return schema;
}

/**
 *
 * This file exports the function in order to get the validation schema
 * with the appropriate translation at runtime.
 *
 * @param t - The translation function as provided by the useTranslation
 *            i18next hook.
 *
 * @returns the validation schema with the appropriate translations.
 */
export function getShopCalendarValidationSchema(t: any, maxShowWeeksNumber: number) {
  const requiredErrorSuffix = t('ValidationErrors.Required');

  const schema = object().shape({
    showWeeks: number()
      .min(1)
      .max(maxShowWeeksNumber)
      .required(`${t('AddZoneModal.ValidationErrors.TheWeeksToBeShown')} ${requiredErrorSuffix}.`),
    deliveryWaitTime: number()
      .min(0)
      .max(6)
      .required(`${t('AddZoneModal.ValidationErrors.TheDeliveryWaitTime')} ${requiredErrorSuffix}.`),
    textDe: string().nullable(),
    textFr: string().nullable(),
    // other fields to be validated in the future...
  });

  return schema;
}
