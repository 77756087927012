import { Grid, GridColumn as Column, GridFilterOperators } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useLogsService from '../../../../hooks/useLogsService';
import {
  AuditLog,
  ErrorViewModel,
  gridStateToSortOrder,
  IAuditLogFilterRequest,
  IFilter,
  IGridSort,
  IGridState,
  IPagedResultViewModel,
} from '../../../../models';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import InspectPayloadDetails from '../inspect-modal/InspectPayloadDetails';

/**
 * A wrapper for the logs table.
 */
function ViewLogs() {
  const { errors, setErrors } = useErrorHandling();

  const { t } = useTranslation();

  const language = useCurrentLanguage();

  const currentLocale = useCurrentLocale();

  const logsService = useLogsService();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  /**
   * Indicates if the first loading of the orders is going on. It is used to display the loader
   * only during the initial load, other wise it would spin each time the user types a character
   * in any column filter, thus initiating a server-side call.
   */
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const [showInspect, setShowInspect] = useState<boolean>(false);

  const [payloadToInspect, setPayloadToInspect] = useState<string>('');

  /**
   * The default sorting of the grid.
   */
  const defaultSort: IGridSort[] = [{ field: 'dateTime', dir: 'desc' }];

  /**
   * The data displayed in the grid, that is the data retrieved from the server after applying the filters.
   */
  const [gridData, setGridData] = useState<IPagedResultViewModel<AuditLog>>({
    data: [],
    total: 0,
    sort: defaultSort,
  });

  /**
   * The current paging and sorting information.
   */
  const [dataState, setDataState] = useState<{
    take: number;
    skip: number;
    filter: { logic: 'and' | 'or'; filters: IFilter[] };
    sort: IGridSort[];
  }>({
    take: 10,
    skip: 0,
    filter: { logic: 'and', filters: [] },
    sort: defaultSort,
  });

  /**
   * Unions the odata query computed by the Kendo grid with the global filters.
   * @param shop the shops selected in the global filter.
   * @param product the product selected in the global filter.
   * @param odataString the odata query computed by the Kendo grid
   * @returns an odata query string that includes both the filter computed by the Kendo grid and the global filters.
   */
  const addFilters = (dataState: IGridState): IAuditLogFilterRequest => {
    const filter: IAuditLogFilterRequest = { sortOrder: [] };

    if (dataState && dataState.filter && dataState.filter.filters && dataState.filter.filters.length > 0) {
      filter.apiName = dataState.filter.filters.find((f) => f.field == 'apiName')?.value;
      filter.userName = dataState.filter.filters.find((f) => f.field == 'userName')?.value;
      filter.dateTime = {
        value: dataState.filter.filters.find((f) => f.field == 'dateTime')?.value,
        operator: dataState.filter.filters.find((f) => f.field == 'dateTime')?.operator,
      };
    }

    if (dataState) {
      filter.skip = dataState.skip;
      filter.take = dataState.take;
    }

    filter.sortOrder = gridStateToSortOrder(dataState);

    return filter;
  };

  /**
   * This callback takes care of retrieving the logs
   * by calling the appropriate endpoint through the logs
   * service.
   */
  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const filter = addFilters(dataState);
      const allLogs = await logsService.getAllPaged(filter);
      parseData(allLogs.data);
      setGridData({ data: allLogs.data, total: allLogs.total });
    } catch (error) {
      console.error(error);
      setErrors([...errors, error as ErrorViewModel]);
    } finally {
      setIsLoading(false);
      setInitialLoading(false);
    }
  }, [dataState, setIsLoading, setInitialLoading, logsService, setGridData, setErrors, errors]);

  /**
   * Retrieves the data from the server on the initial load.
   */
  useEffect(() => {
    loadData();
  }, [dataState, setInitialLoading, setIsLoading]);

  /**
   * Applies date and time transformation and localization to the order status field.
   */
  const parseData = (data: AuditLog[]) => {
    data.forEach((d) => {
      d.dateTime = d.dateTime !== null ? new Date(d.dateTime) : null;
    });
  };

  if (isLoading && initialLoading) {
    return <LoadingPanel />;
  }

  /**
   * Updates the data state.
   * @param e the new data state.
   */
  const dataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  /**
   * We must limit the types of filter operators, because the backend is not able to handle all the cases.
   * So it is only "contains" for text fields and "equal or lesser" for date types of field.
   */
  const filterOperators: GridFilterOperators = {
    text: [{ text: 'grid.filterContainsOperator', operator: 'contains' }],
    date: [
      { text: 'grid.filterLteOperator', operator: 'lte' },
      { text: 'grid.filterGteOperator', operator: 'gte' },
      { text: 'grid.filterEqOperator', operator: 'eq' },
    ],
  };

  return (
    <ViewWrapper title={t('LogsView.ViewTitle')}>
      <InspectPayloadDetails
        payload={payloadToInspect}
        show={showInspect}
        handleClose={() => setShowInspect(false)}
        modalTitle={t('LogsView.InspectModal.Title')}
      />

      <LocalizationProvider language={language}>
        <IntlProvider locale={currentLocale}>
          <Grid
            style={{ margin: '0 20px 0 20px' }}
            data={gridData}
            total={gridData.total}
            filterable={true}
            sortable={true}
            resizable={true}
            pageable={{ buttonCount: 4, pageSizes: true }}
            {...dataState}
            onDataStateChange={dataStateChange}
            filterOperators={filterOperators}
          >
            <Column field="dateTime" title={t('LogsView.LogDate')} filter="date" format="{0:dd.MM.yyyy hh:mm:ss}" />
            <Column field="userName" title={t('LogsView.LogUserName')} />
            <Column field="apiName" title={t('LogsView.LogApiName')} />
            <Column
              field="payload"
              title={t('LogsView.LogPayload')}
              cell={(props) => (
                <td>
                  <div className="d-flex justify-content-around">
                    <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                      {props.dataItem.payload}
                    </div>

                    <button
                      type="button"
                      className="btn btn-secondary btn-sm"
                      onClick={() => {
                        setPayloadToInspect(props.dataItem.payload);
                        setShowInspect(true);
                      }}
                    >
                      {t('LogsView.Inspect')}
                    </button>
                  </div>
                </td>
              )}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewLogs);
