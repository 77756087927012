import { object, string, number, array } from 'yup';
import { ValidityZone } from './ViewDieselSettings';

/**
 *
 * This file exports the function in order to get the validation schema
 * with the appropriate translation at runtime.
 *
 * @param t - The translation function as provided by the useTranslation
 *            i18next hook.
 *
 * @returns the validation schema with the appropriate translations.
 */
export default function getDieselSettingsFormValidationSchema(t: any) {
  const schema = object().shape({
    assignedZones: array().when('validityZone', {
      is: ValidityZone.SelectedZones,
      then: array()
        .of(
          object().shape({
            id: number().required(),
            name: string(),
          }),
        )
        .min(1, t('AddPromotionModal.ValidationErrors.AssignedZonesAreRequired'))
        .required(t('AddPromotionModal.ValidationErrors.AssignedZonesAreRequired')),
      otherwise: array(),
    }),
  });

  return schema;
}
