import { useContext, useMemo } from 'react';
import { UserProfilesService } from '../services/UserProfilesService';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';

/**
 * Wraps the UsersService in a useMemo call.
 * @returns the memoized UsersService
 */
export default function useUserProfilesService(): UserProfilesService {
  const config = useContext(AgrConfigurationContext);

  const userProfilesService = useMemo(() => {
    return new UserProfilesService(config);
  }, [config]);

  return userProfilesService;
}
