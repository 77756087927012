import { PostalCode, Zone } from '../../../models';

/**
 * This const represents the type used by react-dnd for defining
 * the dragged object type.
 */
export const dndPostalCodeType = 'PostalCode';

/**
 * Shape of the postal code when dragged in the UI.
 */
export interface DnDPostalCode {
  postalCode: PostalCode;
  zone: Zone | undefined;
}

/**
 * The drag & drop result object shape.
 */
export interface DropResult {
  zoneId: number | null;
  dndPostalCode: DnDPostalCode;
}
