import i18next from '../i18n';

/**
 *
 * This hook returns the always updated current language.
 *
 * @returns the current language in string.
 */
export default function useCurrentLanguage(): string {
  return i18next.language;
}
