import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Constants } from '../../../../constants/Constants';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useSettingService from '../../../../hooks/useSettingService';
import { ErrorViewModel, isErrorViewModel, Setting } from '../../../../models';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import ShopSettings from './ShopSettings/ShopSettings';
import ShopSettingsKeyValue from './ShopSettings/ShopSettingsKeyValue';
import { DieselProductTypeName } from '@/assets/constants';
import groupBy from '@/utils/groupBy';

/**
 *
 * This View shows all the available entry points for the setting update.
 *
 * @returns the Component to be used in JSXs.
 */
function EditSettings(): JSX.Element | null {
  /**
   * Stores a value indicating if the user is read only or not.
   */
  const [settings, setSettings] = useState<{}[]>();

  /**
   * Boolean indicating whether an APi call is ongoing.
   */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation('translation', { keyPrefix: 'EditSettings' });
  const { errors, setErrors } = useErrorHandling();
  const settingService = useSettingService();

  /**
   * Retrieves the product types from the remote API. Will be required when adding/editign a disporegion within the modal form.
   */
  useEffect(() => {
    async function getInitialData() {
      try {
        setIsLoading(true);
        if (!settings) {
          getSettings();
        }
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errors, error as ErrorViewModel]);
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    }

    async function getSettings() {
      try {
        let settings = await settingService.getAll();

        if (!settings) {
          return;
        }

        settings = settings.filter((s) => s.settingCategory && s.settingCategory !== DieselProductTypeName);

        const dict: { key: string; value: Setting[] }[] = groupBy(settings, 'settingCategory');

        setSettings(dict);
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errors, error as ErrorViewModel]);
        } else {
          console.error(error);
        }
      }
    }
    getInitialData();
  }, [settingService, setSettings, settings, setErrors, errors]);

  /**
   * Handles which setting component will be displayed.
   * @param props Setting for choosing the correct setting component by Name (use Constants).
   */
  function drawSetting(setting: Setting, index: number) {
    switch (setting.settingName) {
      case Constants.SettingTypes.ShopSettings:
        return <ShopSettings key={index} shopSetting={setting} />;
      default:
        return <ShopSettingsKeyValue key={index} shopSetting={setting} />;
    }
  }

  if (isLoading || !settings) {
    return <LoadingPanel />;
  }

  return (
    <ViewWrapper title={t('Title')}>
      <div style={{ marginLeft: '2em' }}>
        {Object.entries(settings).map(([key, values]) => (
          <div key={key}>
            <div className="pb-3 h3">{t('SettingCategoryEnum.' + key)}</div>
            {(values as Setting[]).map((setting: Setting, index: number) => drawSetting(setting, index))}
          </div>
        ))}
      </div>
    </ViewWrapper>
  );
}

export default withErrorHandling(EditSettings);
