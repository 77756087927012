import React, { createContext, SetStateAction } from 'react';

export interface ModalContextValues {
  closeModal: () => void;
  handleSave?: () => void;
}

const ModalContext = createContext<ModalContextValues | undefined>(undefined);

export const ModalProvider = ModalContext.Provider;

export default ModalContext;
