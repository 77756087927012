import { Language } from './Language';

export interface MailTemplate {
  mailTemplateTypeNameDe: string;
  mailTemplateTypeNameFr: string;
  productTypeNameDe: string;
  productTypeNameFr: string;
  items: MailTemplateItem[];
}

export interface MailTemplateItem {
  id: number;
  language: Language;
}

export interface MailTemplateEditModel {
  id: number;
  mailTemplateTypeNameDe: string;
  mailTemplateTypeNameFr: string;
  productTypeNameDe: string;
  productTypeNameFr: string;
  language: Language;
  sendGridId: string;
  sendGridLink: string;
  senderMail: string;
  senderName: string;
}

export class MailTemplateUpdateModel {
  id!: number;
  sendGridId!: string;
  sendGridLink!: string;
  senderMail!: string;
  senderName!: string;
}

export class MailTemplateSendGridModel {
  id!: number;
  receiverMail!: string;
}
