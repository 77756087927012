import { Margin, ProductType } from '../models';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { BaseService } from './BaseService';

export interface GetAllMarginViewModel {
  margins: Margin[];
  productTypes: ProductType[];
}

/**
 * The service class to interact with the Zones.
 */
export class MarginsService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/margin');
  }

  getAll(holdingId: number, zoneId: number | null): Promise<GetAllMarginViewModel> {

    if (zoneId) {
      return this.get(`/view/${holdingId}/${zoneId}`);
    } else {
      return this.get(`/view/${holdingId}`);
    }
  }

  addMargin(margin: Margin): Promise<boolean> {
    return this.post('', margin);
  }

  updateMargin(margin: Margin): Promise<boolean> {
    return this.put('', margin);
  }

  delete(marginId: number): Promise<void> {
    return super.delete(`${marginId}`);
  }
}
