import { useTranslation } from 'react-i18next';

interface LinkButtonProps {
  href: string;
  textResource: string;
}

export default function LinkButton({ href, textResource }: LinkButtonProps): JSX.Element | null {
  const { t } = useTranslation();

  return (
    <a className="btn btn-primary btn-sm mx-1" style={{ color: '#fff' }} type="button" href={href}>
      {t(textResource)}
    </a>
  );
}
