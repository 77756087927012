import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { AlertService } from '../services/AlertService';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * NotificationService to be used in components to make API calls.
 *
 * @returns a NotificationService instance
 */
export default function useAlertService(): AlertService {
  const config = useContext(AgrConfigurationContext);

  const memoizedNotificationService = useMemo(() => {
    return new AlertService(config);
  }, [config]);

  return memoizedNotificationService;
}
