import * as axios from 'axios';

import { acquireAccessToken } from './AuthService';

/**
 * The base class for all the services that call the remote API. It provides the base configuration
 * for the Axios package and the default methods for all the verbs.
 */
export class BaseService {
  constructor(config, baseUrl) {
    this.axios = axios.create({
      baseURL: config.api.baseUrl + baseUrl,
    });

    this.axios.defaults.headers.get['Cache-Control'] = 'no-cache';
    this.axios.defaults.headers.get['Pragma'] = 'no-cache';
    this.axios.defaults.headers.post['Content-Type'] = 'application/json';
    this.axios.defaults.headers.put['Content-Type'] = 'application/json';
    this.axios.defaults.headers.patch['Content-Type'] = 'application/json';
    this.axios.defaults.params = {};
    this.axios.defaults.params['code'] = config.api.authCode;

    /**
     * Appends the authentication token to each request.
     */
    this.axios.interceptors.request.use(
      async (config) => {
        const accessToken = await acquireAccessToken();
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
      },
      async (error) => {
        console.error('ACCESS TOKEN REQUEST', error);
        return error;
      },
    );

    /**
     * Transforms the response from a string to a JSON object.
     */
    this.axios.defaults.transformResponse = [
      (data, headers) => {
        try {
          return data ? JSON.parse(data) : null;
        } catch (ex) {
          throw ex;
        }
      },
    ];

    /**
     * A configuration specific for dowloading files.
     */
    this.axiosFile = axios.create({
      baseURL: config.api.baseUrl + baseUrl,
    });

    this.axiosFile.defaults.headers.get['Cache-Control'] = 'no-cache';
    this.axiosFile.defaults.headers.get['Pragma'] = 'no-cache';
    this.axiosFile.interceptors.response.use((response) => {
      const fileName = 'agrola_order.pdf'; //this.getFileName(response.headers['content-disposition']);
      const blob = new Blob([response.data], { type: 'application/pdf' });
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName);
      } else if (window.navigator.msSaveBlob) {
        // for IE browser
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        a.click();
        a.remove();
      }

      return response;
    });
  }

  /**
   * Retrieves the file name from the response.
   * @param {*} contentDisposition The content of the response.
   * @returns The name of the file.
   */
  getFileName(contentDisposition) {
    let fileName = null;
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }
    return fileName;
  }

  /*
   * Get request are cached by default to allow multiple components
   * to request the same data and return the same inflight promise.
   * Therefore, a request will not be fired twice.
   *
   * @param url the url to get
   */
  get(url, params) {
    return new Promise((resolve, reject) => {
      this.axios
        .get(url, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          this.handleError(error, reject);
        });
    });
  }

  /**
   * Posts the data to the API.
   * @param {*} url The URL address of the POST request.
   * @param {*} data The POST data.
   * @returns a Promise object.
   */
  post(url, data) {
    return new Promise((resolve, reject) => {
      this.axios
        .post(url, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          this.handleError(error, reject);
        });
    });
  }

  /**
   * Puts the data to the API.
   * @param {*} url The URL address of the PUT request.
   * @param {*} data The PUT data.
   * @returns a Promise object.
   */
  put(url, data) {
    return new Promise((resolve, reject) => {
      this.axios
        .put(url, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          this.handleError(error, reject);
        });
    });
  }

  /**
   * Delete REST call to the API.
   * @param {*} url The URL address of the DELETE request.
   * @param {*} data The DELETE data.
   * @returns a Promise object.
   */
  delete(url, options = {}) {
    return new Promise((resolve, reject) => {
      this.axios
        .delete(url, options)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          this.handleError(error, reject);
        });
    });
  }

  /**
   * Does a GET request to get a file from an APi.
   * @param {*} url The URL to GET the file from.
   * @param {*} config The request configuration.
   * @returns a Promise object.
   */
  getFile(url, config) {
    return new Promise((resolve, reject) => {
      this.axiosFile
        .get(url, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          this.handleError(error, reject);
        });
    });
  }

  /**
   * Handles the request errors. Used within the catch clause of all the methods that do a request to the remote API.
   * @param {*} error The error object.
   * @param {*} reject The Promise reject function.
   */
  handleError(error, reject) {
    reject({
      title: error.message,
      description: error.respose?.data?.description,
      statusCode: error.respose?.data?.statusCode,
      value: error.response?.data,
    });
  }
}
