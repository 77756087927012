import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { OrdersService } from '../services/OrdersService';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * OrdersService to be used in components to make API calls.
 *
 * @returns a OrdersService instance
 */
export default function useOrdersService(): OrdersService {
  const config = useContext(AgrConfigurationContext);

  const memoizedOrdersService = useMemo(() => {
    return new OrdersService(config);
  }, [config]);

  return memoizedOrdersService;
}
