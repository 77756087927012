import { BaseService } from './BaseService';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { MailTemplate, MailTemplateEditModel, MailTemplateUpdateModel, MailTemplateSendGridModel } from '../models';

/**
 * The service class to interact with the MailTemplate data.
 */
export class MailTemplatesService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/mailtemplates');
  }

  getAll = (): Promise<MailTemplate[]> => {
    return this.post('', {});
  };

  load = (id: number): Promise<MailTemplateEditModel> => {
    return this.get('/' + id);
  };

  update = (model: MailTemplateUpdateModel): Promise<void> => {
    return this.put('', model);
  };

  sendMail = (model: MailTemplateSendGridModel): Promise<void> => {
    return this.put('/sendmail', model);
  };
}
