import { BaseService } from './BaseService';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { AuditLog, IAuditLogFilterRequest, IPagedResultViewModel } from '../models';

/**
 *
 * The service class to interact with the Holdings.
 *
 */
export class LogsService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/auditlog');
  }

  getAllPaged(filter: IAuditLogFilterRequest): Promise<IPagedResultViewModel<AuditLog>> {
    return this.post('/view', filter);
  }
}
