import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextArea } from '@progress/kendo-react-inputs';
import styled from 'styled-components';
import useModal from '../../../../hooks/useModal';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import withModal from '../../../hoc/with-modal/withModal';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import { YupError } from '../../../../hooks/useYupErrors';
import { ErrorViewModel, TextResource } from '../../../../models';
import Spinner from '../../../common/spinner/Spinner';
import useTextResourceService from '@/hooks/useTextResourceService';
import { Language } from '@/models/Language';

interface InputLabelProps {
  validationError: boolean;
}

/**
 * Base style for labels in this from.
 */
const InputLabel = styled.label<InputLabelProps>`
  margin-bottom: ${({ validationError }) => (validationError ? '0' : '0.5em')};
`;

const ErrorMessage = styled.span.attrs(() => ({ className: 'text-danger' }))<{}>`
  margin-bottom: 0.25em;
`;

/**
 * The interface for the error messages
 */
export interface ITextResourceValidationError {
  text: string;
}

export interface ITextResourceDetailProps {
  textResource: TextResource | null | undefined;
}

/**
 * Allows editing the status of the order, then the internal and external comments.
 * @param param0 the input of the order edit form.
 * @returns The form component.
 */
const EditTextResourceFrom = ({ textResource }: ITextResourceDetailProps) => {
  const { t } = useTranslation();

  const { handleSave } = useModal();

  const { errors, setErrors } = useErrorHandling();

  const service = useTextResourceService();

  const [loading, setLoading] = useState<boolean>(false);

  const [textResourceId, setTextResourceId] = useState<number | null | undefined>(textResource?.id);

  const [updateTextResourceText, setUpdateTextResourceText] = useState<string | undefined>(textResource?.text);
  const [updateKeyName, setUpdateKeyName] = useState<string | undefined>(textResource?.keyName);
  const [updateLanguage, setUpdateLanguage] = useState<Language | undefined>(textResource?.language);
  const [updateLanguageName, setUpdateLanguageName] = useState<string | undefined>(textResource?.languageName);

  const [validationErrors, setValidationErrors] = useState<YupError<ITextResourceValidationError> | null>();

  return (
    <div className="container px-2">
      <div className="d-flex flex-row align-items-start">
        <div className="col-md-3 p-2">
          <span>{t('EditTextResourceModal.LanguageName')}</span>
        </div>
        <div className="col-md-5 p-2">
          <span>{updateLanguageName}</span>
        </div>
      </div>
      <div className="d-flex flex-row align-items-start">
        <div className="col-md-3 p-2">
          <span>{t('EditTextResourceModal.KeyName')}</span>
        </div>
        <div className="col-md-5 p-2">
          <span>{updateKeyName}</span>
        </div>
      </div>
      <div className="d-flex flex-column align-items-start p-2">
        <InputLabel htmlFor="textResourceText" validationError={!!validationErrors?.text}>
          {t('EditTextResourceModal.Text')}
        </InputLabel>
        {validationErrors?.text && <ErrorMessage>{validationErrors.text}</ErrorMessage>}
        <TextArea
          style={{ marginBottom: '1em', width: '100%' }}
          id="textResourceText"
          value={updateTextResourceText}
          onChange={async (e) => setUpdateTextResourceText(e.value)}
          rows={3}
        ></TextArea>
      </div>

      <div className="modal-footer pb-0 pe-0">
        <button
          className="btn btn-primary"
          type="button"
          disabled={loading}
          onClick={async () => {
            try {
              setLoading(true);
              await service.update(textResourceId, updateTextResourceText, updateKeyName, updateLanguage);
              handleSave!();
            } catch (error) {
              console.error(error);
              setErrors([...errors, error as ErrorViewModel]);
            } finally {
              setLoading(false);
            }
          }}
        >
          {loading ? <Spinner /> : t('EditTextResourceModal.Save')}
        </button>
      </div>
    </div>
  );
};

export default withModal(withErrorHandling(EditTextResourceFrom));
