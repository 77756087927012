import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  FuelProductTypeName,
  WoodProductTypeName,
  DieselProductTypeName,
} from '../../../../assets/constants/DataConstants';
import useApplicationState from '../../../../hooks/useApplicationState';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import useDashboardService from '../../../../hooks/useDashboardService';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useProductsService from '../../../../hooks/useProductsService';
import { Dashboard, ErrorViewModel, OrderStatus, ProductType } from '../../../../models';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import Tile from './tile/Tile';

/**
 * Styles for the container of the main page content.
 */
const DashboardContainer = styled.div`
  margin-left: 20px;
  position: relative;
  min-width: 500px;
  width: 45%;
  height: 0;
  padding-bottom: 45%;
`;

/**
 * This component is the default redirection page for Shop Users and Holding users
 * when they navigate to the root "/" page of the website
 */
function ViewDashboard() {
  const { t } = useTranslation();
  const language = useCurrentLanguage();

  const { errors, setErrors } = useErrorHandling();

  const { shops, selectedShop, products, selectedProduct } = useApplicationState();

  const dashboardService = useDashboardService();
  const productService = useProductsService();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [dashboardData, setDashboardData] = useState<Dashboard | null>();

  const [productTypes, setProductTypes] = useState<ProductType[]>();

  const [woodProductType, setWoodProductType] = useState<ProductType>();
  const [fuelProductType, setFuelProductType] = useState<ProductType>();
  const [dieselProductType, setDieselProductType] = useState<ProductType>();

  const retrieveDashboardData = useCallback(async () => {
    try {
      const dashboardData = await dashboardService.getDashboardData({
        shopsId: selectedShop ? [selectedShop.id] : shops.map((shop) => shop.id),
        productsId: selectedProduct ? [selectedProduct.id!] : products.map((product) => product.id!),
      });

      setDashboardData(dashboardData);
    } catch (error) {
      setDashboardData(null);

      throw error;
    }
  }, [dashboardService, selectedShop, shops, selectedProduct, products, setDashboardData]);

  const retrieveAllProductTypes = useCallback(async () => {
    const productTypes = await productService.getAllProductTypes();

    setProductTypes(productTypes);
  }, [productService, setProductTypes]);

  const retrieveData = useCallback(async () => {
    setIsLoading(true);

    try {
      await retrieveDashboardData();
      await retrieveAllProductTypes();
    } catch (error) {
      console.error(error);

      setErrors([...errors, error as ErrorViewModel]);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, retrieveDashboardData, retrieveAllProductTypes, setErrors, errors]);

  const getPageTitle = useCallback(() => {
    let title = t('ViewDashboard.Message');

    if (selectedShop || selectedProduct) {
      title += ' (';

      if (selectedShop) {
        title += selectedShop.name;
      }

      if (selectedProduct) {
        title += selectedShop
          ? ', ' + selectedProduct['name' + capitalizeFirstLetter(language)]
          : selectedProduct['name' + capitalizeFirstLetter(language)];
      }

      title += ')';
    }

    return title;
  }, [t, selectedShop, selectedProduct]);

  useEffect(() => {
    if (dashboardData !== null) {
      retrieveData();
    }
  }, [shops, products, retrieveData]);

  useEffect(() => {
    if (productTypes && productTypes.length > 0) {
      const woodProductType = productTypes.find((type) => type.productName === WoodProductTypeName);
      const fuelProductType = productTypes.find((type) => type.productName === FuelProductTypeName);
      const dieselProductType = productTypes.find((type) => type.productName === DieselProductTypeName);

      setWoodProductType(woodProductType);
      setFuelProductType(fuelProductType);
      setDieselProductType(dieselProductType);
    }
  }, [productTypes]);

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (productTypes === undefined || !woodProductType || !fuelProductType || !dieselProductType) {
    return <></>;
  }

  return (
    <ViewWrapper title={getPageTitle()}>
      <DashboardContainer>
        <div className="d-flex justify-content-between">
          <Tile
            title={t('ViewDashboard.NewFuel')}
            value={dashboardData?.newFuel ?? 0}
            productType={fuelProductType}
            orderStatus={OrderStatus.Open}
          />
          <Tile
            title={t('ViewDashboard.ProgressFuel')}
            value={dashboardData?.progressFuel ?? 0}
            productType={fuelProductType}
            orderStatus={OrderStatus.Delivering}
          />
        </div>
        <div className="d-flex justify-content-between">
          <Tile
            title={t('ViewDashboard.NewWood')}
            value={dashboardData?.newWood ?? 0}
            productType={woodProductType}
            orderStatus={OrderStatus.Open}
          />
          <Tile
            title={t('ViewDashboard.ProgressWood')}
            value={dashboardData?.progressWood ?? 0}
            productType={woodProductType}
            orderStatus={OrderStatus.Delivering}
          />
        </div>
        <div className="d-flex justify-content-between">
          <Tile
            title={t('ViewDashboard.NewDiesel')}
            value={dashboardData?.newDiesel ?? 0}
            productType={dieselProductType}
            orderStatus={OrderStatus.Open}
          />
          <Tile
            title={t('ViewDashboard.ProgressDiesel')}
            value={dashboardData?.progressDiesel ?? 0}
            productType={dieselProductType}
            orderStatus={OrderStatus.Delivering}
          />
        </div>
      </DashboardContainer>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewDashboard);
