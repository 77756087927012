import { SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import AceEditor from 'react-ace';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ProductTemplateKeys } from '../../../assets/constants';
import Spinner from '../../common/spinner/Spinner';
import useErrorHandling from '../../../hooks/useErrorHandling';
import useProductsService from '../../../hooks/useProductsService';
import { ErrorViewModel, ProductTemplate } from '../../../models';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-xcode';

/**
 * These are the styles for the code editor area.
 *
 * Unfortunately, I have to set the important flag
 * because the Component otherwise sets some on its own
 * and they have to be overwritten.
 */
const MobileReadyAceEditor = styled(AceEditor)`
  width: 100% !important;
  height: 40vh !important;

  @media (min-width: 768px) {
    height: 100% !important;
  }
`;

/**
 * These are the styles for the code editor container.
 */
const EditorContainer = styled.div`
  flex: 1;
  margin-bottom: 1em;
`;

/**
 * These are the styles for the template preview area.
 */
const TemplatePreview = styled.iframe.attrs((props) => ({
  ...props,
}))<{}>`
  flex: 1;

  @media (max-width: 768px) {
    width: 100%;
    min-height: 30vh;
  }
`;

/**
 * Type representing the simulated placeholder values in the product template.
 */
type PlaceholderValues = {
  [Property in ProductTemplateKeys]?: string;
};

/**
 * Shape of the items contained in the placeholder values dropdown.
 */
interface DropdownPlaceholder {
  id: ProductTemplateKeys;
  name: string;
}

/**
 * The props for the ProductTemplatePreview Component.
 */
interface ProductTemplatePreviewProps {
  productTemplate: ProductTemplate;
  setTemplate: (template: ProductTemplate) => void;

  productImage: string | undefined;
  setProductImage: (imageUrl: string | undefined) => void;

  templateError?: string;
  id?: string;
}

/**
 *
 * This is the bottom part of the add product modal menu.
 * It contains the tab menus with the product details and
 * the coding environment for writing the template.
 *
 * @returns the product details Component.
 */
export default function ProductTemplatePreview({
  id,
  productTemplate,
  setTemplate,

  productImage,
  setProductImage,

  templateError,
}: ProductTemplatePreviewProps) {
  const { t } = useTranslation();
  const productsService = useProductsService();
  const { errors, setErrors } = useErrorHandling();

  const [isSavingImage, setIsSavingImage] = useState<boolean>(false);

  const [simulationValues, setSimulationValues] = useState<PlaceholderValues>({});

  const imageInputRef = useRef<HTMLInputElement>(null);

  const [selectedPlaceholderKey, setSelectedPlaceholderKey] = useState<DropdownPlaceholder>();
  const placeholderDropdownValues = useMemo<DropdownPlaceholder[]>(() => {
    const placeholderValuesKeys: ProductTemplateKeys[] = [
      '{{basePrice}}',
      '{{discount}}',
      '{{image}}',
      '{{outputUnitCode}}',
      '{{productQuantity}}',
      '{{promotion}}',
      '{{quantity}}',
      '{{totalDiscount}}',
      '{{totalMargin}}',
      '{{totalPriceDiscounted}}',
      '{{totalPrice}}',
      '{{totalUnloadingFee}}',
      '{{totalVat}}',
      '{{unitDiscount}}',
      '{{unitMargin}}',
      '{{unitPriceDiscounted}}',
      '{{unitPrice}}',
      '{{unitVat}}',
      '{{unloadingFee}}',
      '{{unloadingPlacesCount}}',
      '{{vatRate}}',
      '{{quantityBasedDiscount}}',
    ];

    return placeholderValuesKeys.map((key) => ({
      id: key,
      name: key.replace('{{', '').replace('}}', ''),
    }));
  }, []);

  const removeImageFromInput = useCallback(() => {
    if (imageInputRef.current) {
      imageInputRef.current.value = '';
    }
  }, [imageInputRef]);

  const injectTemplateValues = useCallback(() => {
    function replaceTemplateKey(stringToModify: string, key: ProductTemplateKeys, value: string) {
      return stringToModify.replace(key, value);
    }

    let previewTemplate = productTemplate ? productTemplate.template : '';

    previewTemplate = replaceTemplateKey(previewTemplate, '{{basePrice}}', simulationValues['{{basePrice}}'] ?? '');
    previewTemplate = replaceTemplateKey(previewTemplate, '{{discount}}', simulationValues['{{discount}}'] ?? '');
    previewTemplate = replaceTemplateKey(
      previewTemplate,
      '{{outputUnitCode}}',
      simulationValues['{{outputUnitCode}}'] ?? '',
    );
    previewTemplate = replaceTemplateKey(
      previewTemplate,
      '{{productQuantity}}',
      simulationValues['{{productQuantity}}'] ?? '',
    );
    previewTemplate = replaceTemplateKey(
      previewTemplate,
      '{{totalDiscount}}',
      simulationValues['{{totalDiscount}}'] ?? '',
    );
    previewTemplate = replaceTemplateKey(previewTemplate, '{{totalMargin}}', simulationValues['{{totalMargin}}'] ?? '');
    previewTemplate = replaceTemplateKey(
      previewTemplate,
      '{{totalPriceDiscounted}}',
      simulationValues['{{totalPriceDiscounted}}'] ?? '',
    );
    previewTemplate = replaceTemplateKey(previewTemplate, '{{totalPrice}}', simulationValues['{{totalPrice}}'] ?? '');
    previewTemplate = replaceTemplateKey(
      previewTemplate,
      '{{totalUnloadingFee}}',
      simulationValues['{{totalUnloadingFee}}'] ?? '',
    );
    previewTemplate = replaceTemplateKey(previewTemplate, '{{totalVat}}', simulationValues['{{totalVat}}'] ?? '');
    previewTemplate = replaceTemplateKey(
      previewTemplate,
      '{{unitDiscount}}',
      simulationValues['{{unitDiscount}}'] ?? '',
    );
    previewTemplate = replaceTemplateKey(previewTemplate, '{{unitMargin}}', simulationValues['{{unitMargin}}'] ?? '');
    previewTemplate = replaceTemplateKey(
      previewTemplate,
      '{{unitPriceDiscounted}}',
      simulationValues['{{unitPriceDiscounted}}'] ?? '',
    );
    previewTemplate = replaceTemplateKey(previewTemplate, '{{unitPrice}}', simulationValues['{{unitPrice}}'] ?? '');
    previewTemplate = replaceTemplateKey(previewTemplate, '{{unitVat}}', simulationValues['{{unitVat}}'] ?? '');
    previewTemplate = replaceTemplateKey(
      previewTemplate,
      '{{unloadingFee}}',
      simulationValues['{{unloadingFee}}'] ?? '',
    );
    previewTemplate = replaceTemplateKey(
      previewTemplate,
      '{{unloadingPlacesCount}}',
      simulationValues['{{unloadingPlacesCount}}'] ?? '',
    );
    previewTemplate = replaceTemplateKey(previewTemplate, '{{vatRate}}', simulationValues['{{vatRate}}'] ?? '');
    previewTemplate = replaceTemplateKey(previewTemplate, '{{quantity}}', simulationValues['{{quantity}}'] ?? '');
    previewTemplate = replaceTemplateKey(
      previewTemplate,
      '{{quantityBasedDiscount}}',
      simulationValues['{{quantityBasedDiscount}}'] ?? '',
    );

    if (productImage) {
      previewTemplate = replaceTemplateKey(previewTemplate, '{{image}}', productImage);
    }

    return previewTemplate;
  }, [productTemplate, simulationValues, productImage]);

  return (
    <div id={id} className="d-flex flex-column h-100">
      <span className="mt-3">{t('AddProductModal.Template')}</span>
      <div className="mb-2 position-relative">
        {productTemplate && templateError && <small className="text-danger">{templateError}</small>}
      </div>
      <div className="d-flex flex-column flex-md-row border" style={{ flex: 'auto' }}>
        <div className="d-sm-flex flex-sm-column" style={{ flex: 1 }}>
          <EditorContainer>
            <MobileReadyAceEditor
              mode="html"
              theme="xcode"
              value={productTemplate ? productTemplate.template : ''}
              onChange={(e) => setTemplate({ ...productTemplate, template: e })}
              name="templateCodeEditor"
            />
          </EditorContainer>

          <span className="mx-2">{t('AddProductModal.EnterPlaceholder')}</span>
          <div className="d-flex mb-1">
            <DropDownList
              id={`${id}placeholderValuesKeysDropdownId`}
              className="mx-2"
              data={placeholderDropdownValues}
              value={selectedPlaceholderKey}
              textField="name"
              dataItemKey="id"
              onChange={(e) => setSelectedPlaceholderKey(e.target.value)}
            />

            <input
              type="text"
              value={
                selectedPlaceholderKey && simulationValues[selectedPlaceholderKey.id]
                  ? simulationValues[selectedPlaceholderKey.id]
                  : ''
              }
              onChange={(e) =>
                setSimulationValues({ ...simulationValues, [selectedPlaceholderKey!.id]: e.currentTarget.value })
              }
              className="form-control align-self-end"
              style={{ flex: 1 }}
              disabled={!selectedPlaceholderKey}
            />
          </div>
        </div>
        <TemplatePreview width={0} sandbox="" srcDoc={injectTemplateValues()} />
      </div>

      <div className="mt-3 mb-2 align-self-end">
        <label
          htmlFor={`${id}uploadImage`}
          className="btn btn-secondary"
          style={{ cursor: 'pointer', pointerEvents: isSavingImage ? 'none' : 'auto' }}
        >
          {isSavingImage ? <Spinner /> : t('AddProductModal.UploadImage')}
        </label>
        <input
          id={`${id}uploadImage`}
          disabled={isSavingImage}
          className="form-control"
          type="file"
          accept="image/*"
          ref={imageInputRef}
          style={{ display: 'none' }}
          onChange={(e) => {
            setIsSavingImage(true);

            const imageToUpload = e.currentTarget.files?.item(0);

            if (imageToUpload) {
              const fileReader = new FileReader();
              fileReader.readAsDataURL(imageToUpload);
              fileReader.onload = () => {
                const byteArray = fileReader.result;

                if (!byteArray) {
                  setErrors([
                    ...errors,
                    { title: 'Image error', value: { description: "Couldn't read the image" }, statusCode: '' },
                  ]);
                  removeImageFromInput();

                  setIsSavingImage(false);

                  return;
                }

                productsService
                  .uploadImage(imageToUpload.name, byteArray.toString().replace(/data:image\/[a-zA-Z]+;base64,/, ''))
                  .then((res) => {
                    setProductImage(res.url);
                    setIsSavingImage(false);
                  })
                  .catch((error) => {
                    console.error(error);
                    removeImageFromInput();

                    setErrors([...errors, error as ErrorViewModel]);

                    setIsSavingImage(false);
                  });
              };
            } else {
              setIsSavingImage(false);
            }
          }}
        />
      </div>
    </div>
  );
}
