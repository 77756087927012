/**
 * This enum describes the validity of a promotion.
 */
export enum ValidityDelimitation {
  NoDelimitations = 0,
  From18To8 = 1,
  WorkDays = 1 << 1,
  Weekend = 1 << 2,
  WorkDaysFrom18To8 = WorkDays | From18To8,
  WorkDaysWeekendFrom18To8 = WorkDays | Weekend | From18To8,
}
