import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ValidationError } from 'yup';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import { ErrorViewModel, Disporegion, ProductType, isErrorViewModel } from '../../../../models';

import CalendarSetting from '../../../common/calendar-setting/CalendarSetting';
import withModal from '../../../hoc/with-modal/withModal';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import useModal from '../../../../hooks/useModal';
import useYupErrors, { YupError } from '../../../../hooks/useYupErrors';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useDisporegionsService from '../../../../hooks/useDisporegionsService';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import Spinner from '../../../common/spinner/Spinner';
import getDisporegionFormValidationSchema from './ValidationSchema';
import useApplicationState from '../../../../hooks/useApplicationState';

interface InputLabelProps {
  validationError: boolean;
}

/**
 * Base style for labels in this from.
 */
const InputLabel = styled.label<InputLabelProps>`
  margin-bottom: ${({ validationError }) => (validationError ? '0' : '0.5em')};
`;

const ErrorMessage = styled.span.attrs(() => ({ className: 'text-danger' }))<{}>`
  margin-bottom: 0.25em;
`;

/**
 * The interface for the error messages
 */
export interface DisporegionError {
  name: string;
  showWeeks: number;
  deliveryWaitTime: number;
  textDe: string;
  textFr: string;
  productTypeId: number;
}

/**
 * Dummy object to initialize the yup error schema
 * in order to map the errors array to a typed object.
 */
const yupDisporegionErrorSchema: DisporegionError = {
  name: '',
  showWeeks: 0,
  deliveryWaitTime: 0,
  textDe: '',
  textFr: '',
  productTypeId: 0,
};

/**
 * The form props.
 */
interface AddDisporegionFormProps {
  update?: boolean;
  productTypes: ProductType[];
  id?: number | undefined;
  name?: string | undefined;
  showWeeks?: number | undefined;
  deliveryWaitTime?: number | undefined;
  textDe?: string | undefined;
  textFr?: string | undefined;
  productTypeId?: number | undefined;
}

/**
 * The interectable content of the modal for adding a Disporegion.
 * @returns the component to be displayed.
 */
const AddDisporegionForm = ({
  update,
  productTypes,
  id: disporegionId,
  name,
  showWeeks,
  deliveryWaitTime,
  textDe,
  textFr,
  productTypeId,
}: AddDisporegionFormProps) => {

  const { showWeeksNumber } = useApplicationState();
  
  const showWeeksData = Array.from({length: showWeeksNumber}, (_, i) => i + 1);

  const deliveryWaitTimeData = [0, 1, 2, 3, 4, 5, 6];

  const { t } = useTranslation();

  const language = useCurrentLanguage();

  const { handleSave } = useModal();

  const { errors, setErrors } = useErrorHandling();

  const mapErrors = useYupErrors<DisporegionError>(yupDisporegionErrorSchema);

  const disporegionsService = useDisporegionsService();

  const upsertDisporegionValidationSchema = useMemo(() => getDisporegionFormValidationSchema(t, showWeeksNumber), [t]);

  const [loading, setLoading] = useState<boolean>(false);

  const [validationErrors, setValidationErrors] = useState<YupError<DisporegionError> | null>();

  const [updatedName, setName] = useState<string>(name ?? '');

  const [updatedProductTypeId, setUpdatedProductTypeId] = useState<number | undefined>(productTypeId);

  const [updatedShowWeek, setUpdatedShowWeek] = useState<number | undefined>(showWeeks || showWeeksData[0]);

  const [updatedDeliveryWaitTime, setUpdatedDeliveryWaitTime] = useState<number | undefined>(
    deliveryWaitTime || deliveryWaitTimeData[0],
  );

  const [updatedTextDe, setUpdatedTextDe] = useState<string | undefined>(textDe);

  const [updatedTextFr, setUpdatedTextFr] = useState<string | undefined>(textFr);

  // validate form and return true if no errors found, false vice versa.
  const validateForm = useCallback(
    (disporegion: Disporegion) => {
      try {
        setValidationErrors(null);
        upsertDisporegionValidationSchema.validateSync(disporegion, { abortEarly: false });
        return true;
      } catch (error) {
        setValidationErrors(mapErrors(error as ValidationError));

        return false;
      }
    },
    [upsertDisporegionValidationSchema, setValidationErrors, mapErrors],
  );

  return (
    <div className="container px-2">
      <div className="d-flex flex-column align-items-start px-3">
        <div className="mb-2">{t('AddDisporegionModal.ProductType')}</div>
        <ButtonGroup className="align-self-start mb-3">
          {productTypes.map((productType, i) => {
            return (
              <ToggleButton
                key={i}
                id={`radio-${i}`}
                type="radio"
                variant={updatedProductTypeId === productType.id ? 'primary' : 'light'}
                name="radio"
                value={productType.id || 0}
                checked={updatedProductTypeId === productType.id}
                onChange={(e) => setUpdatedProductTypeId(Number(e.currentTarget.value))}
              >
                {productType[`name${capitalizeFirstLetter(language)}`]}
              </ToggleButton>
            );
          })}
        </ButtonGroup>

        <InputLabel htmlFor="disporegionNameInput" validationError={!!validationErrors?.name}>
          {t('AddDisporegionModal.Name')}
        </InputLabel>
        {validationErrors?.name && <ErrorMessage>{validationErrors.name}</ErrorMessage>}
        <input
          id="disporegionNameInput"
          type="text"
          className="form-control mb-3"
          value={updatedName}
          onChange={(e) => setName(e.target.value)}
        />

        {t('AddDisporegionModal.CalendarSettings')}
        <CalendarSetting
          showWeeksDropdownValues={showWeeksData}
          showWeeks={updatedShowWeek == 0 ? showWeeksData[0] : updatedShowWeek}
          deliveryWaitTimeDropdownValues={deliveryWaitTimeData}
          deliveryWaitTime={updatedDeliveryWaitTime}
          textDe={updatedTextDe}
          textFr={updatedTextFr}
          validationErrors={
            validationErrors && {
              deliveryWaitTime: validationErrors.deliveryWaitTime,
              showWeeks: validationErrors.showWeeks,
              textDe: validationErrors.textDe,
              textFr: validationErrors.textFr,
            }
          }
          onShowWeeksChange={(e) => setUpdatedShowWeek(e.value)}
          onDeliveryWaitTimeChange={(e) => setUpdatedDeliveryWaitTime(e.value)}
          onTextDeChange={(e) => setUpdatedTextDe(e.value)}
          onTextFrChange={(e) => setUpdatedTextFr(e.value)}
        />
      </div>
      <div className="modal-footer pb-0 pe-0">
        <button
          className="btn btn-primary"
          type="button"
          disabled={loading}
          onClick={async () => {
            try {
              const disporegion: Disporegion = {
                id: disporegionId ? disporegionId : 0,
                name: updatedName,
                showWeeks: updatedShowWeek || 0,
                deliveryWaitTime: updatedDeliveryWaitTime || 0,
                productTypeId: updatedProductTypeId || 0,
                textDe: updatedTextDe || '',
                textFr: updatedTextFr || '',
              };

              if (await validateForm(disporegion)) {
                setLoading(true);

                if (update) {
                  await disporegionsService.update(disporegion);
                } else {
                  await disporegionsService.add(disporegion);
                }

                handleSave!();
              }
            } catch (error) {
              if (isErrorViewModel(error)) {
                setErrors([...errors, error as ErrorViewModel]);
              } else {
                console.error(error);
              }
            } finally {
              setLoading(false);
            }
          }}
        >
          {loading ? <Spinner /> : t('AddShopModal.SaveShop')}
        </button>
      </div>
    </div>
  );
};

export default withModal(withErrorHandling(AddDisporegionForm));
