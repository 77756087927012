import { Language, PriceCalculationResult, UnitCode } from '.';
import { OrderStatus } from './OrderStatus';
import { Delivery } from './Delivery';
import { PromotionGift } from './promotion/PromotionGift';

/**
 * Contains the additional properties of an order. They are separated
 * from the Order interface because they are so many.
 */
export interface OrderData {
  productId: number;

  status?: OrderStatus | null;

  externalOrderNumber?: string;

  /**
   * Contains the address of the shop that delivers the product.
   */
  shopContact: string;

  orderDate?: Date;

  timeStamp?: Date;

  invoiceSalutation: string;

  invoiceName: string;

  invoiceSurname: string;

  priceOffer: PriceCalculationResult;

  invoiceCompany: string;

  invoiceStreet: string;

  invoiceHouseNumber: string;

  invoicePostalCode: string;

  invoiceLocation: string;

  deliveryAddresses: Array<Delivery>;

  productDescription: string;

  priceWithoutVAT?: number;

  priceWithVAT?: number;

  priceDiscountedWithVAT?: number;

  vat?: number;

  totalPriceWithoutVAT?: number;

  totalVAT?: number;

  totalPriceIncludingVAT?: number;

  email: string;

  phone: string;

  paymentType: string;

  fillTankCompletely?: boolean;

  bonus?: string;

  portal: string;

  language: Language;

  ourComment?: string;

  clientComment: string;

  quantity: number;

  quantityUnit: UnitCode;

  unloadingPlacesCount: number;

  deliveryWeeks: Array<number>;

  storageRoomCleaning?: boolean;

  storageRoomCleaningWithVAT?: number;

  storageRoomCleaningWithoutVAT?: number;

  smallVehicle?: boolean;

  smallVehicleWithVAT?: number;

  smallVehicleWithoutVAT?: number;

  hoseLength?: boolean;

  hoseLengthWithVAT?: number;

  hoseLengthWithoutVAT?: number;

  promotionGift?: PromotionGift;

  smallVehicleText?: string;

  storageRoomCleaningText?: string;

  hoseLengthText?: string;
}

/**
 * Returns a translated string that prefixes the number of delivery weeks.
 */
export const getDeliveryWeeks = (t: any, deliveryWeeks: Array<number> | undefined): string => {
  if (deliveryWeeks == undefined || deliveryWeeks.length == 0) {
    return '-';
  }
  if (deliveryWeeks.length == 1) {
    return `${t('OrderDetail.DeliveryWeekSingle')} ${deliveryWeeks[0]}`;
  } else {
    return `${t('OrderDetail.DeliveryWeekPlural')} ${deliveryWeeks.join()}`;
  }
};
