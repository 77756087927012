import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Routes } from '../../../../../assets/constants/Links';
import { OrderStatus, ProductType } from '../../../../../models';

/**
 * Styles for the main tile Component.
 */
const Square = styled(Link)`
  position: relative;
  color: black;

  margin: 5% 5% 0 0%;
  width: 45%;
  height: 0;
  padding-bottom: 45%;
  border: 3px solid grey;
  border-radius: 8px;
  transition: 0.1s;

  &:hover {
    border-color: #006428;
    box-shadow: 2px 2px 2px grey;
    transition: 0.1s;
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

/**
 * The tile props.
 */
interface TileProps {
  /**
   * The title to display on top of the tile.
   */
  title: string;

  /**
   * The value to display on the right bottom part of the tile.
   */
  value: number;

  /**
   * The product type that the tile refers to.
   * Used to construct the query parameters to pass to the orders page.
   */
  productType: ProductType;

  /**
   * The order status that the tile refers to.
   * Used to construct the query parameters to pass to the orders page.
   */
  orderStatus: OrderStatus;
}

export default function Tile({ title, value, productType, orderStatus }: TileProps) {
  return (
    <Square
      to={
        Routes.Orders +
        '?' +
        new URLSearchParams({ productType: productType.id.toString(), orderStatus: orderStatus.toString() })
      }
    >
      <Container>
        <div className="mx-3 mt-2" style={{ fontSize: 24 }}>
          {title}
        </div>
        <div className="mb-3 me-4 fw-bold" style={{ textAlign: 'right', fontSize: '4em' }}>
          {value}
        </div>
      </Container>
    </Square>
  );
}
