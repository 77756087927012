/**
 * The source array of Products.
 * @param a the source array.
 * @param key a function that returns a value for the comparison of objects.
 * @returns a filtered array.
 */
export default function uniqueBy<Type>(a: Type[], key: (p: Type) => number) {
  const seen: number[] = [];
  return a.filter(function (item: Type) {
    const k = key(item);
    if (seen.find((n) => n === k)) {
      return false;
    } else {
      seen.push(k);
      return true;
    }
  });
}
