import { PricelogService } from '../services/PricelogsService';
import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * NotificationService to be used in components to make API calls.
 *
 * @returns a NotificationService instance
 */
export default function usePricelogService(): PricelogService {
  const config = useContext(AgrConfigurationContext);

  const memoizedNotificationService = useMemo(() => {
    return new PricelogService(config);
  }, [config]);

  return memoizedNotificationService;
}
