import { DieselPromotionSettings } from '@/models/promotion/DieselPromotionSettings';
import { DieselPromotionSettingsRequest } from '@/models/promotion/DieselPromotionSettingsRequest';
import {
  CreatePromotionRequest,
  IPagedResultViewModel,
  IPromotionFilterRequest,
  Promotion,
  UpdatePromotionRequest,
  UploadPostalCodesResult,
} from '../models';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { BaseService } from './BaseService';

/**
 * The service class to interact with the Orders.
 */
export class PromotionsService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/promotion');
  }

  getAllPaged(filter: IPromotionFilterRequest): Promise<IPagedResultViewModel<Promotion>> {
    return this.post('/view', filter);
  }

  createPromotion(promotion: CreatePromotionRequest): Promise<number> {
    return this.post('', promotion);
  }

  updatePromotion(promotion: UpdatePromotionRequest): Promise<number> {
    return this.put('', promotion);
  }

  delete(promotionId: number): Promise<void> {
    return super.delete(`/${promotionId}`);
  }

  uploadPostalCodes(zoneIds: number[], file: File): Promise<UploadPostalCodesResult> {
    const formData = new FormData();
    formData.append('zoneIds', zoneIds.join(','));
    formData.append('file', file);

    return this.put(`/postalcodes`, formData);
  }

  getDieselPromotionSettings(): Promise<DieselPromotionSettings> {
    return this.get('/diesel');
  }

  updateDieselPromotionSettings(settings: DieselPromotionSettingsRequest): Promise<UploadPostalCodesResult> {
    return this.put('/diesel', settings);
  }
}
