import { useContext } from 'react';
import ErrorHandlingContext, { ErrorHandlingContextValues } from '../contexts/ErrorHandlingContext';

/**
 *
 * This hook gets the error handling context in order to read/set
 * its values in the Components that need it.
 *
 * @returns the error handling context values.
 */
export default function useErrorHandling(): ErrorHandlingContextValues {
  const errorHandlingContext = useContext(ErrorHandlingContext);

  return errorHandlingContext!;
}
