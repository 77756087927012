import { BaseService } from './BaseService';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { Setting } from '../models';

/**
 * The service class to interact with the settings.
 */
export class SettingService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/settings');
  }

  /**
   * Retrieves all the settings.
   * @returns A list of all the settings
   */
  getAll(): Promise<Setting[]> {
    return this.get('');
  }

  /**
   * Retrieves setting by name.
   * @returns A setting
   */
  getSettingByName(settingName: string): Promise<Setting> {
    return this.get(`/getSettingByName/${settingName}`);
  }

  /**
   * Updates a Setting
   * @param setting the setting to be updated.
   * @returns the updated setting.
   */
  update(setting: Setting): Promise<Setting> {
    return this.put('', setting);
  }
}
