import { useTranslation } from 'react-i18next';

export enum MessageAlignment {
  Top,
  Middle,
  Bottom,
}

/**
 *
 */
export interface IErrorMessageProps {
  keyMessage: string | null;
  alignMessage: MessageAlignment;
}

/**
 * The component that is displayed during the authentication flow.
 */
export const ErrorMessage = ({ keyMessage, alignMessage }: IErrorMessageProps): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`d-flex flex-column min-vh-100 align-items-center ${
          alignMessage == MessageAlignment.Middle ? 'justify-content-center' : null
        }`}
      >
        <h1 className="text-primary">{keyMessage ? t(keyMessage) : t('Error')}</h1>
      </div>
    </>
  );
};
