import { BaseService } from './BaseService';
import { UserProfile, UserProfileDeleteModel, UserProfileEditModel } from '../models/UserProfile';
import { IAgrConfiguration } from '../models/ConfigurationModel';

/**
 * The service class to interact with the UserProfiles data.
 */
export class UserProfilesService extends BaseService {
  /**
   * Initializes a new instance of the User service, passing the application configuration.
   */
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/userprofiles');
  }

  /**
   * Retrieves the complete profile of the currently authenticated user. Since the user information
   * is contained in the access token which is sent to the server by the BaseService, then it is
   * not necessary to include a username parameter along with the request.
   * @returns The complete user profile of the authenticated user, including the roles by shop.
   */
  getAuthenticatedUser = (): Promise<UserProfile> | null => {
    return this.get('/current');
  };

  /**
   * Retrieves all the user profiles that are defined in the application.
   * @returns The complete list of user profiles.
   */
  getAll = (): Promise<UserProfile[]> => {
    return this.get();
  };

  load = async (id: number): Promise<UserProfileEditModel> => {
    return this.get('/' + id);
  };

  create = async (model: UserProfileEditModel): Promise<number> => {
    return this.post('/', model);
  };

  update = async (model: UserProfileEditModel): Promise<number> => {
    return this.put('/', model);
  };

  delete = async (model: UserProfileDeleteModel): Promise<void> => {
    return this.put('/delete', model);
  };

  restore = async (): Promise<void> => {
    return this.post('/restore', null);
  };

  /**
   * Normalizes list of user profiles, with an item for each role of the roles list of the item.
   * @param current a list of user profiles.
   * @returns a normalized list of user profiles, with an item for each role of the roles list of the item.
   */
  expand = (current: UserProfile[]): UserProfile[] => {
    const d = new Array<UserProfile>();
    current.forEach((u) => {
      if (u.userShops.length > 1) {
        u.userShops.forEach((r) => {
          d.push({
            id: u.id,
            isActive: u.isActive,
            isActiveString: u.isActiveString,
            readOnly: u.readOnly,
            readOnlyString: u.readOnlyString,
            dieselAdministratorString: u.dieselAdministratorString,
            externalSystemId: u.externalSystemId,
            identityId: u.identityId,
            email: u.email,
            username: u.username,
            firstName: u.firstName,
            lastName: u.lastName,
            fullName: u.fullName,
            identityProvider: u.identityProvider,
            userShops: [...u.userShops],
            holding: u.holding,
            holdingId: u.holdingId,
            roleName: u.roleName,
            shop: r.shop?.name,
            translatedRole: u.translatedRole,
            lastLogin: u.lastLogin,
            dieselAdministrator: u.dieselAdministrator,
          });
        });
      } else {
        d.push(u);
      }
    });

    return d;
  };
}
