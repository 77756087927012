import removePaginationFilters from '@/utils/removeODataPagination';
import { toODataString } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { saveAs } from 'file-saver';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Constants } from '../../../../constants/Constants';
import useAlertService from '../../../../hooks/useAlertService';
import useApplicationState from '../../../../hooks/useApplicationState';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useIsInRole from '../../../../hooks/useIsInRole';
import useIsReadOnly from '../../../../hooks/useIsReadOnly';
import {
  Alert,
  ErrorViewModel,
  GetSubscriptionTypeName,
  isErrorViewModel,
  Product,
  Shop,
  SubscriptionType,
} from '../../../../models';
import injectCustomOData from '../../../../utils/injectCustomOData';
import ActionButtons from '../../../common/action-buttons/ActionButtons';
import EditButton from '../../../common/edit-button/EditButton';
import GridButton from '../../../common/grid-button/GridButton';
import {
  DropdownFilterCell,
  filterDropDownUndefinedValues,
  filterDropDownValues,
} from '../../../common/kendo-filter-dropdown/DropDownFilterCell';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';

/**
 * Represents the properties of a Kendo Grid cell.
 */
interface IGridColumnProps {
  field: string;
  title: string;
  show: boolean;
  filter?: 'boolean' | 'date' | 'text' | 'numeric';
  format?: string;
  filterCell?: any;
}

/**
 * Displays a filterable and page-able list of notifications.
 */
function ViewAlerts(): JSX.Element | null {
  const notificationService = useAlertService();

  /**
   * Required to display the toast notification.
   */
  const { errors, setErrors } = useErrorHandling();

  /**
   * The translation service.
   */
  const { t } = useTranslation();

  /**
   * Stores a value indicating if the user is administrator or not.
   */
  const isAdministrator = useIsInRole(Constants.Roles.Administrator);

  /**
   * Stores a value indicating if the user is read only or not.
   */
  const isReadOnly = useIsReadOnly();

  /**
   * The hook to always have the updated language.
   */
  const language = useCurrentLanguage();

  /**
   * Hook to retrieve the current locale.
   */
  const currentLocale = useCurrentLocale();

  /**
   * The data of the grid.
   */
  const [gridData, setGridData] = useState<Alert[]>();

  /**
   * The visible columns of the grid.
   */
  const [gridColumns, setGridColumns] = useState<Array<IGridColumnProps>>([]);

  /**
   * The total count of grid items after applying the filtering but before pagination.
   */
  const [gridDataTotal, setGridDataTotal] = useState<number>();

  /**
   * The current paging and sorting information.
   */
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
  });

  /**
   * The application state.
   */
  const { shops, selectedProduct, selectedShop } = useApplicationState();

  /**
   * Boolean indicating whether an APi call is ongoing.
   */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * Indicates if the first loading of the orders is going on. It is used to display the loader
   * only during the initial load, other wise it would spin each time the user types a character
   * in any column filter, thus initiating a server-side call.
   */
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  /**
   * Applies date and time transformation and localization to the order status field.
   */
  const parseData = (data: Alert[] | undefined) => {
    if (data !== undefined) {
      data.forEach((o) => {
        o.priceValidUntil =
          o.priceValidUntil !== null && o.priceValidUntil !== undefined
            ? new Date(new Date(o.priceValidUntil).toDateString())
            : null;
        o.subScriptionDate =
          o.subScriptionDate !== null && o.subScriptionDate !== undefined
            ? new Date(new Date(o.subScriptionDate).toDateString())
            : null;
        o.lastNotificationDate =
          o.lastNotificationDate !== null && o.lastNotificationDate !== undefined
            ? new Date(new Date(o.lastNotificationDate).toDateString())
            : null;
        o.subscriptionTypeName = GetSubscriptionTypeName(t, o.subscriptionType);
      });
    }
  };

  /**
   * Applies the transformations to the data (the localization of Role and ReadOnly,
   * and the formatting of the data).
   */
  useEffect(() => {
    setGridColumns([
      {
        field: 'subscriptionTypeName',
        title: t('NotificationsView.ColumnSubscriptionType'),
        show: true,
        filterCell: SubscriptionTypeFilterCell,
      },
      { field: 'postalCode', title: t('NotificationsView.ColumnPostalCode'), show: true },
      { field: 'deliveryDifficultyFeeCode', title: t('NotificationsView.ColumnDeliveryDifficultyFeeCode'), show: true },
      { field: 'productName', title: t('NotificationsView.ColumnProductName'), show: true },
      { field: 'quantity', title: t('NotificationsView.ColumnQuantity'), show: true, filter: 'numeric' },
      { field: 'unloadingPlaces', title: t('NotificationsView.ColumnUnloadingPlaces'), show: true, filter: 'numeric' },
      { field: 'portal', title: t('NotificationsView.ColumnPortal'), show: true },
      { field: 'subscriberEmail', title: t('NotificationsView.ColumnSubscriberEmail'), show: true },
      { field: 'historicPrice', title: t('NotificationsView.ColumnHistoricPrice'), show: true, filter: 'numeric' },
      { field: 'desiredPrice', title: t('NotificationsView.ColumnDesiredPrice'), show: true, filter: 'numeric' },
      {
        field: 'subScriptionDate',
        title: t('NotificationsView.ColumnSubScriptionDate'),
        show: true,
        filter: 'date',
        format: '{0:d}',
      },
      {
        field: 'priceValidUntil',
        title: t('NotificationsView.ColumnPriceValidUntil'),
        show: true,
        filter: 'date',
        format: '{0:d}',
      },
      {
        field: 'lastNotificationDate',
        title: t('NotificationsView.ColumnLastNotificationDate'),
        show: true,
        filter: 'date',
        format: '{0:d}',
      },
    ]);
    parseData(gridData);
  }, [language, gridData]);

  /**
   *
   * Unions the odata query computed by the Kendo grid with the global filters.
   *
   * @param product - the product selected in the global filter.
   * @param queryParams - the query parameters from the url.
   * @param odataString - the odata query computed by the Kendo grid.
   * @returns an odata query string that includes both the filter computed by the Kendo grid and the global filters.
   */
  const addFilters = (shops: Shop[], product: Product | null, odataString: string): string => {
    let customFilter = '';

    const hasShops = shops.length > 0;
    if (hasShops) {
      customFilter += '(';

      for (const [i, shop] of shops.entries()) {
        customFilter += `${i > 0 ? ' or ' : ''}shopId eq ${shop.id}`;
      }

      customFilter += ')';
    }

    if (product) {
      customFilter += `${customFilter ? ' and ' : ''}productId eq ${product.id}`;
    }

    if (customFilter) {
      odataString = injectCustomOData(customFilter, odataString);
    }

    return odataString;
  };

  const getNotifications = useCallback(
    async (shops: Shop[], selectedProduct: Product | null) => {
      try {
        setIsLoading(true);
        let odataString: string = toODataString(dataState);
        console.log(odataString);
        odataString = addFilters(shops, selectedProduct, odataString);
        const result = await notificationService.getAllPaged(odataString);
        parseData(result.data);
        setGridData(result.data);

        odataString = removePaginationFilters(odataString);
        const total = await notificationService.getCount(odataString);
        setGridDataTotal(total);
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errors, error]);
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
        setInitialLoading(false);
      }
    },
    [
      setIsLoading,
      dataState,
      addFilters,
      selectedShop,
      shops,
      selectedProduct,
      notificationService,
      parseData,
      setGridData,
      setGridDataTotal,
      isErrorViewModel,
      setErrors,
      errors,
    ],
  );

  /**
   * Loads the data.
   */
  useEffect(() => {
    async function loadData() {
      if (shops.length) {
        await getNotifications(selectedShop ? [selectedShop] : shops, selectedProduct);
      }
    }

    loadData();
  }, [shops, selectedShop, selectedProduct, dataState]);

  /**
   * Updates the data state.
   * @param e the new data state.
   */
  const dataStateChange = (e: any) => {
    e = filterDropDownValues(e, 'subscriptionTypeName', 'subscriptionType');
    e = filterDropDownUndefinedValues(e);
    setDataState(e.dataState);
  };

  /**
   * This callback takes care of deleting a record and refreshing the view with
   * the updated results.
   */
  const deleteSubscription = useCallback(
    async (subscriptionId: number) => {
      try {
        setIsLoading(true);

        await notificationService.delete(subscriptionId);
        await getNotifications(selectedShop ? [selectedShop] : shops, selectedProduct);
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errors, error]);
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, notificationService, selectedShop, shops, selectedProduct, getNotifications, setErrors, errors],
  );

  /**
   * The filter for the subscription type.
   */
  const SubscriptionTypeFilterCell = useCallback(
    (props: any) => (
      <DropdownFilterCell
        {...props}
        data={[
          { id: SubscriptionType[SubscriptionType.PriceAlarm], text: t('SubscriptionType.PriceAlarm') },
          { id: SubscriptionType[SubscriptionType.PriceDesired], text: t('SubscriptionType.PriceDesired') },
          { id: SubscriptionType[SubscriptionType.PriceSubscription], text: t('SubscriptionType.PriceSubscription') },
          { id: SubscriptionType[SubscriptionType.Newsletter], text: t('SubscriptionType.Newsletter') },
        ]}
        textField="text"
        dataItemKey="id"
        defaultItem={{ id: 0, text: t('SubscriptionType.Any') }}
      />
    ),
    [language],
  );

  const downloadSubscriptions = useCallback(async () => {
    try {
      let odataString = "$filter=(subscriptionType eq 'Newsletter')";

      let usedShops = shops;
      if (selectedShop) {
        usedShops = shops.filter((s) => s.id === selectedShop.id);
      }

      odataString = addFilters(usedShops, selectedProduct, odataString);

      const result = await notificationService.getCSVSubscription(odataString);
      const blob = new Blob([result], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'NewsletterSubscription.csv');
    } catch (error) {
      console.error(error);

      setErrors([...errors, error as ErrorViewModel]);
    }
  }, [shops, selectedShop, notificationService, setErrors, errors]);

  if (isLoading && initialLoading) {
    return <LoadingPanel />;
  }

  return (
    <ViewWrapper title={t('NotificationsView.ManageNotifications')}>
      <LocalizationProvider language={language}>
        <IntlProvider locale={currentLocale}>
          <Grid
            style={{ margin: 20 }}
            data={gridData}
            total={gridDataTotal}
            sortable={true}
            filterable={true}
            resizable={true}
            pageable={{ buttonCount: 4, pageSizes: true }}
            {...dataState}
            onDataStateChange={dataStateChange}
          >
            <GridToolbar>
              <button
                className="btn btn-primary btn-sm"
                type="button"
                onClick={() => {
                  downloadSubscriptions();
                }}
              >
                <span>{t('NotificationsView.ExportNewsletter')}</span>
              </button>
            </GridToolbar>
            {gridColumns.map((column, index) => {
              return (
                column.show && (
                  <Column
                    className={column.field + '-class'}
                    key={index}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    format={column.format}
                    filterCell={column.filterCell}
                  />
                )
              );
            })}
            <Column
              title={t('Views.ColumnActions')}
              width={250}
              filterable={false}
              sortable={false}
              cell={(props) => {
                return (
                  <td>
                    <ActionButtons>
                      {(isAdministrator && !isReadOnly) ||
                        (!isAdministrator && <EditButton onClick={() => alert('Not implemented yet!')} />)}
                      {isAdministrator && !isReadOnly && (
                        <GridButton
                          onClick={async () => {
                            const alert = props.dataItem as Alert;

                            if (alert) {
                              if (confirm(t('NotificationsView.DeleteMessage'))) {
                                await deleteSubscription(alert.id);
                              }
                            } else {
                              setErrors([
                                ...errors,
                                {
                                  statusCode: '',
                                  title: 'Unexpected item shape error.',
                                  value: {
                                    description: 'Selected row item cannot be casted to Alert.',
                                  },
                                },
                              ]);
                            }
                          }}
                          text={t('Views.DeleteButton')}
                          className="btn-danger"
                          useLoading
                        />
                      )}
                    </ActionButtons>
                  </td>
                );
              }}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewAlerts);
