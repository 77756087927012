import removePaginationFilters from '@/utils/removeODataPagination';
import { SortDescriptor, toODataString } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePricelogService from '../../../../hooks/usePricelogService';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import { isErrorViewModel } from '../../../../models';
import {
  DropdownFilterCell,
  filterDropDownUndefinedValues,
  filterDropDownValues,
} from '../../../common/kendo-filter-dropdown/DropDownFilterCell';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import PricelogDetails from '../edit-form/pricelog-details/PricelogDetails';
import { GetPricelogTypeName, Pricelog, PriceLogType } from '@/models/Pricelog';

/**
 * Represents the properties of a Kendo Grid cell.
 */
interface IGridColumnProps {
  field: string;
  title: string;
  show: boolean;
  filter?: 'boolean' | 'date' | 'text' | 'numeric';
  format?: string;
  filterCell?: any;
  encoded?: boolean;
}

/**
 * Displays a filterable and page-able list of notifications.
 */
function ViewPricelogs(): JSX.Element | null {
  const service = usePricelogService();

  /**
   * Required to display the toast notification.
   */
  const { errors, setErrors } = useErrorHandling();

  /**
   * The translation service.
   */
  const { t } = useTranslation();

  /**
   * Sets the item that has to be edited in the modal window.
   */
  const [editingPriceLog, setEditingPriceLog] = useState<Pricelog>();

  /**
   * Toggles the display of the order status/comments update modal window.
   */
  const [showModal, setShowModal] = useState<boolean>(false);

  /**
   * The hook to always have the updated language.
   */
  const language = useCurrentLanguage();

  /**
   * Hook to retrieve the current locale.
   */
  const currentLocale = useCurrentLocale();

  /**
   * The data of the grid.
   */
  const [gridData, setGridData] = useState<Pricelog[]>();

  /**
   * The visible columns of the grid.
   */
  const [gridColumns, setGridColumns] = useState<Array<IGridColumnProps>>([]);

  /**
   * The total count of grid items after applying the filtering but before pagination.
   */
  const [gridDataTotal, setGridDataTotal] = useState<number>();

  const initialSort: Array<SortDescriptor> = [
    { field: 'id', dir: 'desc' },
    { field: 'executionTime', dir: 'desc' },
  ];

  /**
   * The current paging and sorting information.
   */
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    sort: initialSort,
  });

  /**
   * Boolean indicating whether an APi call is ongoing.
   */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * Indicates if the first loading of the orders is going on. It is used to display the loader
   * only during the initial load, other wise it would spin each time the user types a character
   * in any column filter, thus initiating a server-side call.
   */
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  /**
   * Applies date and time transformation and localization to the order status field.
   */
  const parseData = (data: Pricelog[] | undefined) => {
    if (data !== undefined) {
      data.forEach((o) => {
        o.pricelogTypeName = GetPricelogTypeName(t, o.priceLogType);

        o.executionTime = o.executionTime !== null ? new Date(o.executionTime) : null;
      });
    }
  };

  /**
   * Applies the transformations to the data (the localization of Role and ReadOnly,
   * and the formatting of the data).
   */
  useEffect(() => {
    setGridColumns([
      {
        field: 'pricelogTypeName',
        title: t('PricelogsView.ColumnPriceLogType'),
        show: true,
        filterCell: PricelogTypeFilterCell,
      },
      {
        field: 'executionTime',
        title: t('PricelogsView.ColumnExecutionTime'),
        filter: 'date',
        format: '{0:dd.MM.yyyy hh:mm:ss}',
        show: true,
      },
      { field: 'productNumber', title: t('PricelogsView.ColumnProductNumber'), show: true, filter: 'numeric' },
      { field: 'quantity', title: t('PricelogsView.ColumnQuantity'), show: true, filter: 'numeric' },
      { field: 'basisPrice', title: t('PricelogsView.ColumnBasisprice'), show: true, filter: 'numeric' },
      { field: 'email', title: t('PricelogsView.ColumnEmail'), show: true, filter: 'text' },
    ]);
    parseData(gridData);
  }, [language, gridData]);

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      let odataString: string = toODataString(dataState);
      const result = await service.getAllPaged(odataString);
      parseData(result.data);
      setGridData(result.data);

      odataString = removePaginationFilters(odataString);
      const total = await service.getCount(odataString);
      setGridDataTotal(total);
    } catch (error) {
      if (isErrorViewModel(error)) {
        setErrors([...errors, error]);
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
      setInitialLoading(false);
    }
  }, [setIsLoading, dataState, service, parseData, setGridData, setGridDataTotal, isErrorViewModel, setErrors, errors]);

  /**
   * Loads the data.
   */
  useEffect(() => {
    async function loadData() {
      getData();
    }

    loadData();
  }, [dataState]);

  /**
   * Updates the data state.
   * @param e the new data state.
   */
  const dataStateChange = (e: any) => {
    e = filterDropDownValues(e, 'pricelogTypeName', 'pricelogType');
    e = filterDropDownUndefinedValues(e);
    setDataState(e.dataState);
  };

  /**
   * The filter for the subscription type.
   */
  const PricelogTypeFilterCell = useCallback(
    (props: any) => (
      <DropdownFilterCell
        {...props}
        data={[
          { id: PriceLogType[PriceLogType.PriceAlarm], text: t('PricelogType.PriceAlarm') },
          { id: PriceLogType[PriceLogType.PriceDesired], text: t('PricelogType.PriceDesired') },
          { id: PriceLogType[PriceLogType.PriceSubscription], text: t('PricelogType.PriceSubscription') },
          { id: PriceLogType[PriceLogType.FuelOrder], text: t('PricelogType.FuelOrder') },
          { id: PriceLogType[PriceLogType.WoodOrder], text: t('PricelogType.WoodOrder') },
          { id: PriceLogType[PriceLogType.DieselOrder], text: t('PricelogType.DieselOrder') },
        ]}
        textField="text"
        dataItemKey="id"
        defaultItem={{ id: 0, text: t('PricelogType.Any') }}
      />
    ),
    [language],
  );

  if (isLoading && initialLoading) {
    return <LoadingPanel />;
  }

  return (
    <ViewWrapper title={t('PricelogsView.PricelogsTitle')}>
      <PricelogDetails
        id={editingPriceLog?.id}
        show={showModal}
        handleClose={() => setShowModal(false)}
        modalTitle={t('ViewPricelogs.DetailsModal.Title')}
      />
      <LocalizationProvider language={language}>
        <IntlProvider locale={currentLocale}>
          <Grid
            style={{ margin: 20 }}
            data={gridData}
            onRowClick={(props) => {
              setEditingPriceLog(props.dataItem);
              setShowModal(true);
            }}
            total={gridDataTotal}
            sortable={true}
            filterable={true}
            resizable={true}
            pageable={{ buttonCount: 4, pageSizes: true }}
            {...dataState}
            onDataStateChange={dataStateChange}
          >
            <GridToolbar></GridToolbar>
            {gridColumns.map((column, index) => {
              return (
                column.show && (
                  <Column
                    className={column.field + '-class'}
                    key={index}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    format={column.format}
                    filterCell={column.filterCell}
                  />
                )
              );
            })}
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewPricelogs);
