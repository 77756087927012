import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import getPromotionFormValidationSchema from './ValidationSchema';
import useApplicationState from '../../../../hooks/useApplicationState';
import { Formik } from 'formik';
import usePromotionsService from '../../../../hooks/usePromotionsService';
import ItemAssigner from '../../../common/item-assigner/ItemAssigner';
import Spinner from '../../../common/spinner/Spinner';
import { Constants } from '../../../../constants/Constants';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useSettingService from '../../../../hooks/useSettingService';
import { ErrorViewModel, isErrorViewModel, Product, Setting } from '../../../../models';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import { DieselProductTypeId, DieselProductTypeName } from '@/assets/constants';
import { DieselPromotionSettings } from '@/models/promotion/DieselPromotionSettings';
import { DieselPromotionSettingsRequest } from '@/models/promotion/DieselPromotionSettingsRequest';
import { saveAs } from 'file-saver';

import 'react-day-picker/lib/style.css';

// Make sure moment.js has the required locale data
import 'moment/locale/de';
import 'moment/locale/fr';

import './ViewDieselSettings.scss';
import groupBy from '@/utils/groupBy';
import ShopSettings from '../../settings/view-form/ShopSettings/ShopSettings';
import ShopSettingsKeyValue from '../../settings/view-form/ShopSettings/ShopSettingsKeyValue';
import withErrorHandling from '@/components/hoc/with-error-handling/withErrorHandling';
import useExportService from '@/hooks/useExportService';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import useProductsService from '@/hooks/useProductsService';
import { PriceExportResult, ExportType } from '@/models/price-calculation/PriceExportResult';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import ActionButtons from '@/components/common/action-buttons/ActionButtons';

/**
 * Enum for the validity zone choice in the modal.
 */
export enum ValidityZone {
  SelectedZones,
}

/**
 * Shape of assigned zone to the promotion.
 */
interface AssignedZone {
  id: number;
  name: string;
}

/**
 * The promotion modal form values.
 */
export interface DieselPromotionSettingsForm {
  assignedZones?: AssignedZone[];
  unAssignedZones?: AssignedZone[];
}

/**
 *
 * The interectable content of the modal for adding a product.
 *
 * @returns the component to be displayed.
 */
const ViewDieselSettings = (): JSX.Element | null => {
  const { t } = useTranslation();

  /**
   * Initial grid sorting.
   */
  const sort: Array<SortDescriptor> = [{ field: 'creationTime', dir: 'desc' }];

  const promotionsService = usePromotionsService();
  const exportService = useExportService();
  const productsService = useProductsService();

  const UpsertPromotionValidationSchema = useMemo(() => getPromotionFormValidationSchema(t), [t]);

  const { shops } = useApplicationState();

  const [selectedZone, setSelectedZone] = useState<AssignedZone>();
  const [settings, setSettings] = useState<{}[]>();
  const [quantity, setQuantity] = useState<string>('2000');
  const [product, setProduct] = useState<Product>();
  const [products, setProducts] = useState<Product[]>([]);
  const [priceExports, setPriceExports] = useState<PriceExportResult[]>([]);

  const { errors, setErrors } = useErrorHandling();
  const settingService = useSettingService();

  /**
   * Toggle for the loading spinner.
   */
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /**
   * The data displayed in the grid, that is the data retrieved from the server after applying the filters.
   */
  const [promotionSettings, setPromotionSettings] = useState<DieselPromotionSettings>({
    id: 0,
    zones: [],
  });

  /**
   * Applies date and time transformation and localization to the order status field.
   */
  const parseData = useCallback((data: PriceExportResult[]) => {
    data.forEach((o) => {
      o.creationTime = new Date(new Date(o.creationTime).toString());
      o.startTime =
        o.startTime !== null && o.startTime !== undefined ? new Date(new Date(o.startTime).toString()) : null;
      o.endTime = o.endTime !== null && o.endTime !== undefined ? new Date(new Date(o.endTime).toString()) : null;
    });
  }, []);

  const loadExports = useCallback(async () => {
    const priceExports = await exportService.getExports(ExportType.Price);
    parseData(priceExports);

    setPriceExports(priceExports);
  }, [parseData, exportService, setPriceExports]);

  const loadProducts = useCallback(async () => {
    const allProducts = await productsService.getAllProducts();
    const products = allProducts.products.filter((p) => p.productTypeId === DieselProductTypeId && p.id);
    if (products.length) {
      setProducts(products);
      setProduct(products[0]);
    }
  }, [productsService]);

  const loadSettings = useCallback(async () => {
    const dieselPromotionSettings = await promotionsService.getDieselPromotionSettings();
    setPromotionSettings(dieselPromotionSettings);
  }, [promotionsService]);

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);

      await loadProducts();
      await loadSettings();
      await loadExports();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [loadProducts, loadSettings, loadExports]);

  /**
   * Retrieves the data from the server on the initial load.
   */
  useEffect(() => {
    loadData();
  }, [loadData]);

  const assignableZones = useMemo<AssignedZone[]>(
    () =>
      shops
        .flatMap((shop) => shop.zones)
        ?.map((zone) => ({
          id: zone!.id!,
          name: zone!.name,
        })),
    [shops],
  );

  const initialValues = useMemo<DieselPromotionSettingsForm>(() => {
    const initialValues: DieselPromotionSettingsForm = {
      assignedZones: promotionSettings?.zones.map((zone) => ({
        id: zone.id!,
        name: zone.name ?? '',
      })),
      unAssignedZones: assignableZones.filter((az) => !promotionSettings?.zones.find((z) => z.id == az.id)),
    };

    return initialValues;
  }, [assignableZones, promotionSettings?.zones]);

  const submitForm = useCallback(
    async (values: DieselPromotionSettingsForm) => {
      try {
        const request: DieselPromotionSettingsRequest = {
          id: promotionSettings.id,
          zoneIds: values.assignedZones?.map((assignedZone) => assignedZone.id),
        };

        await promotionsService.updateDieselPromotionSettings(request);
      } catch (error) {
        console.error(error);
      }
    },
    [promotionsService, promotionSettings.id],
  );

  const exportPrices = useCallback(async () => {
    try {
      if (!quantity || !product || !product.id) {
        return;
      }
      setIsLoading(true);

      await exportService.triggerPriceExport(product.id, parseInt(quantity));
      await loadExports();
    } catch (error) {
      console.error(error);

      setErrors([...errors, error as ErrorViewModel]);
    } finally {
      setIsLoading(false);
    }
  }, [quantity, product, exportService, loadExports, setErrors, errors]);

  const downloadCsv = useCallback(
    (priceExportResult: PriceExportResult) => {
      try {
        setIsLoading(true);

        if (!priceExportResult) {
          return;
        }

        const blob = new Blob([priceExportResult.data], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, 'DieselPrices.csv');
      } catch (error) {
        console.error(error);

        setErrors([...errors, error as ErrorViewModel]);
      } finally {
        setIsLoading(false);
      }
    },
    [setErrors, errors],
  );

  const deleteExport = useCallback(
    async (exportResult: PriceExportResult) => {
      try {
        setIsLoading(true);

        if (!exportResult) {
          return;
        }

        await exportService.deleteExport(exportResult.id);
        await loadExports();
      } catch (error) {
        console.error(error);

        setErrors([...errors, error as ErrorViewModel]);
      } finally {
        setIsLoading(false);
      }
    },
    [exportService, loadExports, setErrors, errors],
  );

  /**
   * Retrieves the product types from the remote API. Will be required when adding/editign a disporegion within the modal form.
   */
  useEffect(() => {
    async function getInitialData() {
      try {
        setIsLoading(true);
        if (!settings) {
          loadSettings();
        }
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errors, error as ErrorViewModel]);
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    }

    async function loadSettings() {
      try {
        let settings = await settingService.getAll();

        if (!settings) {
          return;
        }

        settings = settings.filter((s) => s.settingCategory && s.settingCategory === DieselProductTypeName);

        const dict: { key: string; value: Setting[] }[] = groupBy(settings, 'settingCategory');

        setSettings(dict);
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errors, error as ErrorViewModel]);
        } else {
          console.error(error);
        }
      }
    }
    getInitialData();
  }, [errors, setErrors, setSettings, settingService, settings]);

  /**
   * Handles which setting component will be displayed.
   * @param props Setting for choosing the correct setting component by Name (use Constants).
   */
  function drawSetting(setting: Setting, index: number) {
    switch (setting.settingName) {
      case Constants.SettingTypes.ShopSettings:
        return <ShopSettings key={index} shopSetting={setting} />;
      default:
        return <ShopSettingsKeyValue key={index} shopSetting={setting} />;
    }
  }

  const rowRender = (trElement: any, props: { dataItem: PriceExportResult }) => {
    const green = {
      backgroundColor: 'rgb(0, 255, 0, 0.32)',
    };
    const yellow = {
      backgroundColor: 'rgb(255, 255, 0, 0.32)',
    };
    const red = {
      backgroundColor: 'rgb(243, 23, 0, 0.32)',
    };

    const trProps = {
      style: props.dataItem.failed
        ? red
        : props.dataItem.endTime === null || props.dataItem.endTime === undefined
        ? yellow
        : green,
    };
    return React.cloneElement(
      trElement,
      {
        ...trProps,
      },
      trElement.props.children,
    );
  };

  if (isLoading || !settings) {
    return <LoadingPanel />;
  }

  return (
    <>
      <div style={{ marginLeft: '2em', paddingBottom: '2em' }}>
        {
          <>
            <div className="d-flex flex-column bg-light mb-5 form-group">
              <div className="p-2 text-success h5">{t('DieselPromotion.ExportName')}</div>
              <form onSubmit={exportPrices} className="ps-2 row">
                <div className="col-4">
                  <label htmlFor="quantity">{t('Promotion.AmountL')}</label>
                  <div className="input-group mb-4">
                    <input
                      id="quantity"
                      type="number"
                      className="form-control"
                      value={quantity}
                      onChange={async (e) => {
                        setQuantity(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="productId">{t('Promotion.TktmProductNumber')}</label>
                  <div className="input-group mb-4">
                    <DropDownList
                      className="mb-3 w-100"
                      data={products}
                      value={product}
                      textField="nameDe"
                      dataItemKey="id"
                      onChange={(e) => setProduct(e.target.value)}
                    />
                  </div>
                </div>

                <span className="input-group-btn">
                  <button className="btn btn-primary" type="submit">
                    {t('Promotion.Export')}
                  </button>
                </span>
              </form>
            </div>
            <div>
              {priceExports && (
                <Grid
                  style={{ margin: 20 }}
                  data={orderBy(priceExports, sort)}
                  pageable={false}
                  resizable={false}
                  rowRender={rowRender}
                >
                  <Column field="creationTime" title={t('Promotion.CreatedTime')} format="{0:dd.MM.yyyy H:mm:ss}" />
                  <Column field="quantity" title={t('Promotion.AmountL')} />
                  <Column field="tktmProductNumber" title={t('Promotion.TktmProductNumber')} />
                  <Column
                    width={250}
                    filterable={false}
                    cell={(props) => {
                      return (
                        <td>
                          <ActionButtons>
                            <>
                              {props.dataItem.endTime && props.dataItem.data && (
                                <button
                                  className="btn btn-primary btn-sm mx-1"
                                  type="button"
                                  onClick={() => downloadCsv(props.dataItem)}
                                >
                                  {t('Promotion.Download')}
                                </button>
                              )}
                            </>

                            <>
                              {(props.dataItem.endTime || props.dataItem.failed) && props.dataItem.id && (
                                <button
                                  className="btn btn-primary btn-sm btn-danger mx-1"
                                  type="button"
                                  onClick={() => deleteExport(props.dataItem)}
                                >
                                  {t('Promotion.Delete')}
                                </button>
                              )}
                            </>
                          </ActionButtons>
                        </td>
                      );
                    }}
                  />
                </Grid>
              )}
            </div>
            <hr />
          </>
        }
        {Object.entries(settings).map(([key, values]) => (
          <div key={key}>
            {(values as Setting[]).map((setting: Setting, index: number) => drawSetting(setting, index))}
          </div>
        ))}
        <Formik
          initialValues={initialValues}
          validationSchema={UpsertPromotionValidationSchema}
          onSubmit={submitForm}
          initialTouched={{
            internalName: true,
          }}
          enableReinitialize={true}
        >
          {({ handleSubmit, setFieldValue, setFieldTouched, isSubmitting, values, errors, touched }) => (
            <Form
              noValidate
              onSubmit={(e) => {
                handleSubmit(e);
              }}
              className="py-2 mb-4"
            >
              <div className="h5 text-success">{t('DieselPromotion.ZonesHeader')}</div>
              {
                <div>
                  {touched.assignedZones && errors.assignedZones && (
                    <span className="d-block text-danger">{errors.assignedZones}</span>
                  )}
                  <span className="d-block mb-2">{t('DieselPromotion.ZonesDescription')}</span>
                  <ItemAssigner
                    dropdownItems={assignableZones.sort((first, second) => first.name.localeCompare(second.name))}
                    assignedItems={values.assignedZones ?? []}
                    setAssignedItems={(assignedItems) => {
                      setFieldTouched('unAssignedZones');
                      setFieldTouched('assignedZones');
                      setFieldValue('assignedZones', assignedItems);
                      setFieldValue(
                        'unAssignedZones',
                        assignableZones.filter((az) => !assignedItems.find((z) => z.id == az.id)),
                      );
                    }}
                    selectedItem={selectedZone}
                    setSelectedItem={(selectedZone) => setSelectedZone(selectedZone)}
                    removeButtonLabel={t('Promotion.Remove')}
                    hasAddAllButton
                    addAllButtonLabel={t('Promotion.AddAllZones')}
                  />
                </div>
              }
              <div className="modal-footer">
                <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <Spinner /> : t('Promotion.SavePromotion')}
                </button>
              </div>
              <div>
                <div className="h6">{t('Promotion.UnassignedZones')}</div>
                <div className="row">
                  {values.unAssignedZones &&
                    values.unAssignedZones.map((uz, i) => (
                      <div key={i} className="col-12 col-md-6 col-lg-4 text-list-item">
                        {uz.name}
                      </div>
                    ))}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default withErrorHandling(ViewDieselSettings);
