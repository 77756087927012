import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Constants } from '../../../../constants/Constants';
import useApplicationState from '../../../../hooks/useApplicationState';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';
import useDisporegionsService from '../../../../hooks/useDisporegionsService';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useIsInRole from '../../../../hooks/useIsInRole';
import useIsReadOnly from '../../../../hooks/useIsReadOnly';
import useProductsService from '../../../../hooks/useProductsService';
import { Disporegion, ErrorViewModel, isErrorViewModel, ProductType } from '../../../../models';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import ActionButtons from '../../../common/action-buttons/ActionButtons';
import DeleteButton from '../../../common/delete-button/DeleteButton';
import EditButton from '../../../common/edit-button/EditButton';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import AddDisporegionForm from '../new-form/AddDisporegionForm';

const initialSort: Array<SortDescriptor> = [{ field: 'name', dir: 'asc' }];

/**
 *
 * The model that describes the disporegions grid data.
 *
 */
interface IGridDisporegion {
  id: number;
  name: string;
  productTypeId: number;
  productType?: ProductType;
  showWeeks: number;
  deliveryWaitTime: number;
  textDe: string;
  textFr: string;
}

/**
 * A wrapper for the disporegions table.
 */
function ViewDisporegions() {
  /**
   * Stores a value indicating if the user is administrator or not.
   */
  const isAdministrator = useIsInRole(Constants.Roles.Administrator);

  /**
   * Stores a value indicating if the user is read only or not.
   */
  const isReadOnly = useIsReadOnly();

  /**
   * Sets the product that has to be edited in the modal window.
   */
  const [editingDisporegion, setEditingDisporegion] = useState<Disporegion>();

  /**
   * Toggles the display of the shop add/update modal window.
   */
  const [showModal, setShowModal] = useState<boolean>(false);

  const appInsights = useAppInsightsContext();

  const { errors, setErrors } = useErrorHandling();

  const disporegionsService = useDisporegionsService();

  const productsService = useProductsService();

  const { selectedProduct } = useApplicationState();

  const { t } = useTranslation();

  const language = useCurrentLanguage();

  const currentLocale = useCurrentLocale();

  const [loadingDisporegions, setLoadingDisporegions] = useState<boolean>(true);

  const [allProductTypes, setAllProductTypes] = useState<ProductType[]>();

  const [allDisporegions, setAllDisporegions] = useState<Disporegion[]>();

  /**
   *
   * This callback maps the Disporegion model as served from the APIs to the
   * grid visualization version.
   *
   */
  const getGridDisporegions = useCallback(
    (disporegions: Disporegion[]) => {
      if (selectedProduct) {
        return disporegions
          .filter((disporegion) => disporegion.productType?.productName === selectedProduct.productType?.productName)
          .map<IGridDisporegion>((disporegion) => ({
            ...disporegion,
          }));
      }

      return disporegions.map<IGridDisporegion>((disporegion) => ({
        ...disporegion,
      }));
    },
    [selectedProduct],
  );

  /**
   *
   * The memoized grid disporegions.
   * When allDisporegions is set, it calls the callback
   * to retrieve the correct disporegion schema for the grid.
   *
   */
  const gridDisporegions = useMemo(() => {
    if (!allDisporegions) {
      return [];
    }

    return getGridDisporegions(allDisporegions);
  }, [allDisporegions, getGridDisporegions]);

  /**
   * Retrieves the product types from the remote API. Will be required when adding/editign a disporegion within the modal form.
   */
  useEffect(() => {
    async function getProductTypes() {
      try {
        const productTypes = await productsService.getAllProductTypes();
        setAllProductTypes(productTypes);
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errors, error as ErrorViewModel]);
        } else {
          console.error(error);
        }
      }
    }
    getProductTypes();
  }, [errors, productsService, setAllProductTypes, setErrors]);

  const [sort, setSort] = useState(initialSort);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>();

  const trackGetDisporegions = useTrackEvent(appInsights, 'Get Disporegions', allDisporegions);

  /**
   *
   * This callback takes care of retrieving the zones
   * by calling the appropriate endpoint through the zones
   * service.
   *
   */
  const retrieveAllDisporegions = useCallback(async () => {
    setLoadingDisporegions(true);

    try {
      const allDisporegions = await disporegionsService.getAll();
      trackGetDisporegions(allDisporegions);
      setAllDisporegions(allDisporegions);
    } catch (error) {
      console.error(error);
      setErrors([...errors, error as ErrorViewModel]);
    }

    setLoadingDisporegions(false);
  }, [setLoadingDisporegions, disporegionsService, trackGetDisporegions, setAllDisporegions, setErrors, errors]);

  const deleteDispoRegion = useCallback(
    async (disporegionId: number) => {
      try {
        setLoadingDisporegions(true);
        await disporegionsService.delete(disporegionId);
        PubSub.publish(Constants.Topics.UserInformation);
      } catch (error) {
        console.error(error);

        setErrors([...errors, error as ErrorViewModel]);
      } finally {
        setLoadingDisporegions(false);
        retrieveAllDisporegions();
      }
    },
    [disporegionsService, setErrors, errors, retrieveAllDisporegions],
  );

  useEffect(() => {
    if (!allDisporegions) {
      retrieveAllDisporegions();
    }
  }, [allDisporegions]);

  if (!allDisporegions && loadingDisporegions) {
    return <LoadingPanel />;
  }

  return (
    <ViewWrapper
      title={
        t('DisporegionsView.ManageDisporegions') +
        (selectedProduct?.productType
          ? ' ' + selectedProduct.productType['name' + capitalizeFirstLetter(language)]
          : '')
      }
      addButton={
        isAdministrator && !isReadOnly ? (
          <button
            type="button"
            className="ms-2 btn btn-primary p-1 pt-0 pb-0 rounded-circle fw-bold"
            onClick={() => {
              setEditingDisporegion(undefined);
              setShowModal(true);
            }}
          >
            <span style={{ fontSize: 36, lineHeight: 0.65 }}>+</span>
          </button>
        ) : null
      }
    >
      <LocalizationProvider language={language}>
        <IntlProvider locale={currentLocale}>
          <AddDisporegionForm
            show={showModal}
            update={!!editingDisporegion}
            productTypes={allProductTypes ?? []}
            {...editingDisporegion}
            size="xl"
            modalTitle={
              !!editingDisporegion ? (
                <Trans i18nKey="AddDisporegionModal.EditDisporegionTitle" t={t}>
                  {{ disporegion: editingDisporegion.name }}
                </Trans>
              ) : (
                t('AddDisporegionModal.ModalTitle')
              )
            }
            handleClose={() => {
              setShowModal(false);
              editingDisporegion && setEditingDisporegion(undefined);
            }}
            handleSave={() => {
              setShowModal(false);
              editingDisporegion && setEditingDisporegion(undefined);
              retrieveAllDisporegions();
            }}
          />
          <Grid
            data={filter ? filterBy(orderBy(gridDisporegions, sort), filter) : orderBy(gridDisporegions, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
            }}
            filterable={true}
            filter={filter}
            resizable={true}
            onFilterChange={(e) => setFilter(e.filter)}
            style={{ margin: '0 20px 0 20px' }}
          >
            <Column field="name" title={t('DisporegionsView.ColumnName')} />
            <Column
              field={'productType.name' + capitalizeFirstLetter(language)}
              title={t('DisporegionsView.ColumnProductType')}
            />
            <Column
              title={t('Views.ColumnActions')}
              width={200}
              filterable={false}
              sortable={false}
              cell={(props) => {
                return (
                  <td colSpan={1} role="gridcell" aria-colindex={3} data-grid-col-index={2}>
                    {isAdministrator && !isReadOnly ? (
                      <ActionButtons>
                        <EditButton
                          onClick={() => {
                            setEditingDisporegion(props.dataItem);
                            setShowModal(true);
                          }}
                        />
                        <DeleteButton
                          onClick={() => {
                            if (confirm(t('DisporegionsView.DeleteMessage') + ' ' + props.dataItem.name)) {
                              deleteDispoRegion(props.dataItem.id);
                            }
                          }}
                        />
                      </ActionButtons>
                    ) : null}
                  </td>
                );
              }}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewDisporegions);
