import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useOrderService from '../../../../hooks/useOrderService';
import useModal from '../../../../hooks/useModal';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import withModal from '../../../hoc/with-modal/withModal';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import useYupErrors, { YupError } from '../../../../hooks/useYupErrors';

import { ErrorViewModel, Language, Order } from '../../../../models';

import getOrderStatusChangeFormValidationSchema from './ValidationSchema';
import { ValidationError } from 'yup';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import EditOrderContext from '../OrderContext';
import Spinner from '../../../common/spinner/Spinner';

interface InputLabelProps {
  validationError: boolean;
}

/**
 * Base style for labels in this from.
 */
const InputLabel = styled.label<InputLabelProps>`
  margin-bottom: ${({ validationError }) => (validationError ? '0' : '0.5em')};
`;

const ErrorMessage = styled.span.attrs(() => ({ className: 'text-danger' }))<{}>`
  margin-bottom: 0.25em;
`;

/**
 * The interface for the error messages
 */
export interface IOrderNotificationError {
  sender: string | undefined;
  recipient: string | undefined;
}

/**
 * Dummy object to initialize the yup error schema
 * in order to map the errors array to a typed object.
 */
const yupShopErrorSchema: IOrderNotificationError = {
  sender: '',
  recipient: '',
};

export interface IOrderDetailsProps {
  order: Order | null | undefined;
}

/**
 * Allows sending a notification email when the status of an order changes.
 * @param param0 the input of the order edit form.
 * @returns The form component.
 */
const StatusChangeNotificationForm = ({ order }: IOrderDetailsProps) => {
  const { t } = useTranslation();

  const language = useCurrentLanguage();

  const { handleSave, closeModal } = useModal();

  const { errors, setErrors } = useErrorHandling();

  const orderService = useOrderService();

  const [loading, setLoading] = useState<boolean>(false);

  const [orderId, setOrderId] = useState<number | undefined>(order?.id);

  /**
   * Stores the status of a selected order when the edit button is clicked. It is then sent to the
   * API that sends the notification email so that the email can be sent only of the old status is new
   * and the updated status is executing.
   */
  const { previousStatus } = useContext(EditOrderContext)!;

  const [sender, setSender] = useState<string | undefined>(order?.shop?.emailAddress);

  const [recipient, setRecipient] = useState<string | undefined>(order?.orderData.email);

  const [validationErrors, setValidationErrors] = useState<YupError<IOrderNotificationError> | null>();

  const mapErrors = useYupErrors<IOrderNotificationError>(yupShopErrorSchema);

  const upsertOrderStatusChangeValidationSchema = useMemo(() => getOrderStatusChangeFormValidationSchema(t), [t]);

  // validate form and return true if no errors found, false vice versa.
  const validateForm = useCallback(
    ({ sender, recipient }: IOrderNotificationError) => {
      try {
        setValidationErrors(null);
        upsertOrderStatusChangeValidationSchema.validateSync({ sender, recipient }, { abortEarly: false });
        return true;
      } catch (error) {
        setValidationErrors(mapErrors(error as ValidationError));
        return false;
      }
    },
    [upsertOrderStatusChangeValidationSchema, setValidationErrors, mapErrors],
  );

  return (
    <div className="container px-2">
      <div className="d-flex flex-column align-items-start px-3">
        <InputLabel htmlFor="shopNameInput" validationError={!!validationErrors?.sender}>
          {t('OrderNotification.Sender')}
        </InputLabel>
        {validationErrors?.sender && <ErrorMessage>{validationErrors.sender}</ErrorMessage>}
        <input
          id="shopNameInput"
          type="text"
          className="form-control"
          value={sender}
          style={{ marginBottom: '1em' }}
          onChange={async (e) => {
            setSender(e.target.value);
          }}
        />
      </div>

      <div className="d-flex flex-column align-items-start px-3">
        <InputLabel htmlFor="shopNameInput" validationError={!!validationErrors?.recipient}>
          {t('OrderNotification.Recipient')}
        </InputLabel>
        {validationErrors?.recipient && <ErrorMessage>{validationErrors.recipient}</ErrorMessage>}
        <input
          id="shopNameInput"
          type="text"
          className="form-control"
          value={recipient}
          style={{ marginBottom: '1em' }}
          onChange={async (e) => {
            setRecipient(e.target.value);
          }}
        />
      </div>

      <div className="modal-footer pb-0 pe-0 d-flex flex-column align-items-start px-3">
        <div>{t('OrderNotification.Confirmation')}</div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <div>
            <button
              className="btn btn-danger"
              style={{ width: 80, marginRight: 5 }}
              type="button"
              onClick={async () => {
                closeModal();
              }}
            >
              {t('CommonLabels.No')}
            </button>
          </div>
          <div>
            <button
              className="btn btn-primary"
              style={{ width: 80 }}
              type="button"
              disabled={loading}
              onClick={async () => {
                try {
                  setLoading(true);

                  if (validateForm({ sender, recipient })) {
                    await orderService.sendStatusChangeNotification(
                      orderId,
                      previousStatus,
                      sender,
                      recipient,
                      language.toLowerCase() == 'de' ? Language.DE : Language.FR,
                    );
                    handleSave!();
                  }
                } catch (error) {
                  console.error(error);
                  setErrors([...errors, error as ErrorViewModel]);
                } finally {
                  setLoading(false);
                }
              }}
            >
              {loading ? <Spinner /> : t('CommonLabels.Yes')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withModal(withErrorHandling(StatusChangeNotificationForm));
