/**
 *
 * This function rounds the price to 2 decimals.
 *
 * @param price - The price to round.
 *
 * @returns the rounded price.
 */
export default function roundPrice(price: number) {
  return Math.round((price + Number.EPSILON) * 100) / 100;
}
