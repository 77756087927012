import { GetUnitCodeName, UnitCode } from '@/models';

interface CurrencyAsKendoFormatOptions {
  decimals: number;

  unitCode?: UnitCode;
  t?: (k: string) => string;
}

export const formatCurrencyAsKendoFormat = ({ t, unitCode, decimals = 2 }: CurrencyAsKendoFormatOptions) => {
  let formattedUnitCode = '';

  if (unitCode) {
    if (!t) {
      throw new Error('if you want to use unitCode you must pass the translation function is required');
    }

    formattedUnitCode = `/${GetUnitCodeName(t, unitCode)}`;
  }

  return `{0:n${decimals}} CHF${formattedUnitCode}`;
};
