import { createContext, Dispatch, SetStateAction } from 'react';
import { UserRole } from '../constants/Constants';
import { Holding, Product, Shop } from '../models';

export interface IAgrApplicationContextValues {
  selectedShop: Shop | null;
  setSelectedShop: Dispatch<SetStateAction<Shop | null>>;
  shops: Shop[];
  setShops: Dispatch<SetStateAction<Shop[]>>;
  selectedProduct: Product | null;
  setSelectedProduct: Dispatch<SetStateAction<Product | null>>;
  products: Product[];
  setProducts: Dispatch<SetStateAction<Product[]>>;
  role: UserRole;
  setRole: Dispatch<SetStateAction<UserRole>>;
  holding?: Holding;
  setHolding: Dispatch<SetStateAction<Holding | undefined>>;
  loadingRoles: boolean;
  setLoadingRoles: Dispatch<SetStateAction<boolean>>;
  isReadOnly: boolean;
  setIsReadOnly: Dispatch<SetStateAction<boolean>>;
  isAuthorized: boolean | undefined;
  setIsAuthorized: Dispatch<SetStateAction<boolean | undefined>>;
  showWeeksNumber: number;
  setShowWeeksNumber: Dispatch<SetStateAction<number>>;
  isDieselAdministrator: boolean;
  setIsDieselAdministrator: Dispatch<SetStateAction<boolean>>;
}

const AgrApplicationStateContext = createContext<IAgrApplicationContextValues | undefined>(undefined);

export const AgrApplicationStateProvider = AgrApplicationStateContext.Provider;

export default AgrApplicationStateContext;
