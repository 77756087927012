import { useState } from 'react';
import getMsalConfig, { loginRequest } from './authConfig';
import { PublicClientApplication, InteractionType, LogLevel } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useMsal, useIsAuthenticated, MsalAuthenticationTemplate } from '@azure/msal-react';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import createAppInsightConfig from './AppInsights';
import { BrowserRouter as Router } from 'react-router-dom';
import { load, loadMessages } from '@progress/kendo-react-intl';

import weekData from 'cldr-core/supplemental/weekData.json';
import caGregorianDe from 'cldr-dates-full/main/de-CH/ca-gregorian.json';
import dateFieldsDe from 'cldr-dates-full/main/de-CH/dateFields.json';
import caGregorianFr from 'cldr-dates-full/main/fr-CH/ca-gregorian.json';
import dateFieldsFr from 'cldr-dates-full/main/fr-CH/dateFields.json';
import numbersDe from 'cldr-numbers-full/main/de-CH/numbers.json';
import numbersFr from 'cldr-numbers-full/main/fr-CH/numbers.json';
import deMessages from './assets/i18n/resources/de.json';
import frMessages from './assets/i18n/resources/fr.json';

import { LoadMessageLogin } from './components/layout/load-message-login/LoadMessageLogin';
import { ErrorMessageAuthenticaton } from './components/layout/error-message-authenticaton/ErrorMessageAuthentication';
import Header from './components/layout/header/Header';
import Navigation from './components/layout/navigation/Navigation';
import RoutedContent from './components/layout/routed-content/RoutedContent';
import GlobalFilter from './components/layout/global-filter/GlobalFilter';
import { IAgrConfiguration } from './models/ConfigurationModel';
import { AgrConfigurationProvider } from './contexts/AgrConfigurationContext';
import { AgrApplicationStateProvider } from './contexts/AgrApplicationStateContext';
import { Shop, Product, Holding } from './models/';

import './App.scss';
import { UserRole } from './constants/Constants';

load(caGregorianDe, dateFieldsDe, caGregorianFr, dateFieldsFr, weekData, numbersDe, numbersFr);
loadMessages(deMessages, 'de');
loadMessages(frMessages, 'fr');

/**
 * Container for all the components. Also configures the authentication.
 */
function App(config: IAgrConfiguration): JSX.Element | null {
  /**
   * Returns an object with the interface that PublicClientApplication is expecting.
   */
  const pcaConfig = getMsalConfig(config, LogLevel.Error);
  /**
   * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
   * Initialized this outside of the root component to ensure it is not re-initialized on re-renders
   */
  const msalInstance = new PublicClientApplication(pcaConfig);

  /**
   * The configuration for Application Insight.
   */
  const { reactPlugin, appInsights } = createAppInsightConfig(config);

  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    /**
     * The currently logged-in user accounts.
     */
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { accounts } = useMsal();

    // sets the username as the context for Application Insight, for a better correlation of the errors.
    appInsights.setAuthenticatedUserContext(accounts[0].username);
  }

  /**
   * The application-wide state.
   */
  const [shops, setShops] = useState<Shop[]>([]);
  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [role, setRole] = useState<UserRole>('ShopUser');
  const [holding, setHolding] = useState<Holding>();
  const [loadingRoles, setLoadingRoles] = useState<boolean>(true);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const [isDieselAdministrator, setIsDieselAdministrator] = useState<boolean>(true);
  const [isAuthorized, setIsAuthorized] = useState<boolean | undefined>(undefined);
  const [showWeeksNumber, setShowWeeksNumber] = useState<number>(25);

  return (
    <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong</h1>} appInsights={reactPlugin}>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
          errorComponent={ErrorMessageAuthenticaton}
          loadingComponent={LoadMessageLogin}
        >
          <div className="agr-app container-fluid">
            <AppInsightsContext.Provider value={reactPlugin}>
              <AgrConfigurationProvider value={config}>
                <AgrApplicationStateProvider
                  value={{
                    selectedShop,
                    setSelectedShop,
                    shops,
                    setShops,
                    selectedProduct,
                    setSelectedProduct,
                    products,
                    setProducts,
                    role,
                    setRole,
                    holding,
                    setHolding,
                    loadingRoles,
                    setLoadingRoles,
                    isReadOnly,
                    setIsReadOnly,
                    isAuthorized,
                    setIsAuthorized,
                    showWeeksNumber,
                    setShowWeeksNumber,
                    isDieselAdministrator,
                    setIsDieselAdministrator,
                  }}
                >
                  <Router>
                    <Header></Header>
                    <div className="row">
                      <div className="col-sm-2 margin-top">
                        <div className="row">
                          <div className="col">
                            <GlobalFilter></GlobalFilter>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <Navigation></Navigation>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-10">
                        <RoutedContent></RoutedContent>
                      </div>
                    </div>
                  </Router>
                </AgrApplicationStateProvider>
              </AgrConfigurationProvider>
            </AppInsightsContext.Provider>
          </div>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </AppInsightsErrorBoundary>
  );
}

export default App;
