import { PriceCalculationRequest, PriceCalculationResult } from '../models';
import {} from '../models';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { BaseService } from './BaseService';

/**
 * The service class to interact with the Zones.
 */
export class PriceService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/price');
  }

  checkPrice(priceCalculationRequest: PriceCalculationRequest): Promise<PriceCalculationResult> {
    return this.post('', priceCalculationRequest);
  }
}
