import { Constants } from '@/constants/Constants';
import { toODataString } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useIsInRole from '../../../../hooks/useIsInRole';
import useIsReadOnly from '../../../../hooks/useIsReadOnly';
import {
  IGridSort,
  IPagedResultViewModel,
  Language,
  TextResource,
  isErrorViewModel,
  GetLanguageName,
} from '../../../../models';
import removePaginationFilters from '../../../../utils/removeODataPagination';
import ActionButtons from '../../../common/action-buttons/ActionButtons';
import EditButton from '../../../common/edit-button/EditButton';
import {
  DropdownFilterCell,
  filterDropDownUndefinedValues,
  filterDropDownValues,
} from '../../../common/kendo-filter-dropdown/DropDownFilterCell';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import useTextResourceService from '@/hooks/useTextResourceService';
import EditTextResourceForm from '../edit-form/EditTextResourceForm';

/**
 * Represents the properties of a Kendo Grid cell.
 */
interface IGridColumnProps {
  field: string;
  title: string;
  show: boolean;
  filter?: 'boolean' | 'date' | 'text' | 'numeric';
  format?: string;
  filterCell?: any;
  encoded?: boolean;
}

function ViewTextResources(): JSX.Element | null {
  /**
   * Stores a value indicating if the user is administrator or not.
   */
  const isAdministrator = useIsInRole(Constants.Roles.Administrator);

  /**
   * Stores a value indicating if the user is read only or not.
   */
  const isReadOnly = useIsReadOnly();

  /**
   * Toggles the display of the order status/comments update modal window.
   */
  const [showModal, setShowModal] = useState<boolean>(false);

  /**
   * Sets the product that has to be edited in the modal window.
   */
  const [editingTextResource, setEditingTextResource] = useState<TextResource | undefined>();

  /**
   * Required to display the toast notification.
   */
  const { errors, setErrors } = useErrorHandling();

  /**
   * The hook to always have the updated language.
   */
  const language = useCurrentLanguage();

  /**
   * Hook to retrieve the locale.
   */
  const currentLocale = useCurrentLocale();

  /**
   * The translation service.
   */
  const { t } = useTranslation();

  /**
   * An instance of the textresource service.
   */
  const service = useTextResourceService();

  /**
   * The switch used to display the spinning loader.
   */
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /**
   * Indicates if the first loading of the orders is going on. It is used to display the loader
   * only during the initial load, other wise it would spin each time the user types a character
   * in any column filter, thus initiating a server-side call.
   */
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  /**
   * The visible columns of the grid.
   */
  const [gridColumns, setGridColumns] = useState<Array<IGridColumnProps>>([]);

  /**
   * The default sorting of the grid.
   */
  const defaultSort: IGridSort[] = useMemo(
    () => [
      { field: 'keyname', dir: 'asc' },
      { field: 'language', dir: 'asc' },
    ],
    [],
  );

  /**
   * The data displayed in the grid, that is the data retrieved from the server after applying the filters.
   */
  const [gridData, setGridData] = useState<IPagedResultViewModel<TextResource>>({
    data: [],
    total: 0,
    sort: defaultSort,
  });

  /**
   * The total count of grid items after applying the filtering but before pagination.
   */
  const [gridDataTotal, setGridDataTotal] = useState<number>();

  /**
   * The current paging and sorting information.
   */
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    sort: defaultSort,
  });

  const parseData = useCallback(
    (data: TextResource[]) => {
      if (data !== undefined) {
        data.forEach((o) => {
          o.languageName = GetLanguageName(t, o.language);
        });
      }
    },
    [t],
  );

  /**
   * The filter for the language type.
   */
  const languageType = useCallback(
    (props: any) => (
      <DropdownFilterCell
        {...props}
        data={[
          { id: Language[Language.DE], text: t('CommonLabels.German') },
          { id: Language[Language.FR], text: t('CommonLabels.French') },
        ]}
        textField="text"
        dataItemKey="id"
        defaultItem={{ id: undefined, text: t('CommonLabels.Any') }}
      />
    ),
    [language],
  );

  /**
   * Updates the data state.
   * @param e the new data state.
   */
  const dataStateChange = (e: any) => {
    e = filterDropDownValues(e, 'languageName', 'language');
    e = filterDropDownUndefinedValues(e);
    setDataState(e.dataState);
  };

  /**
   * Applies the transformations to the data (the localization of Role and ReadOnly,
   * and the formatting of the data).
   */
  useEffect(() => {
    setGridColumns([
      {
        field: 'languageName',
        title: t('TextResourcesView.ColumnLanguage'),
        show: true,
        filterCell: languageType,
      },
      {
        field: 'keyName',
        title: t('TextResourcesView.ColumnKeyName'),
        show: true,
        filter: 'text',
      },
      {
        field: 'text',
        title: t('TextResourcesView.ColumnText'),
        show: true,
        filter: 'text',
      },
    ]);
    parseData(gridData.data);
  }, [language, gridData]);

  const getTextResources = useCallback(async () => {
    try {
      setIsLoading(true);
      let odataString: string = toODataString(dataState);
      const result = await service.getAllPaged(odataString);
      parseData(result.data);
      setGridData(result);

      odataString = removePaginationFilters(odataString);
      const total = await service.getCount(odataString);
      setGridDataTotal(total);
    } catch (error) {
      if (isErrorViewModel(error)) {
        setErrors([...errors, error]);
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
      setInitialLoading(false);
    }
  }, [setIsLoading, dataState, service, parseData, setGridData, setGridDataTotal, isErrorViewModel, setErrors, errors]);

  /**
   * Applies the transformations to the data (the localization of Role and ReadOnly,
   * and the formatting of the data).
   */
  useMemo(() => {
    return parseData(gridData.data);
  }, [parseData, gridData.data]);

  /**
   * Loads the data.
   */
  useEffect(() => {
    async function loadData() {
      await getTextResources();
    }

    loadData();
  }, [dataState]);

  if (isLoading && initialLoading) {
    return <LoadingPanel />;
  }

  return (
    <ViewWrapper title={t('TextResourcesView.TextResourceTitle')}>
      <EditTextResourceForm
        textResource={editingTextResource}
        modalTitle={t('EditTextResourceModal.EditTextResourceTitle')}
        show={editingTextResource !== undefined}
        size="xl"
        handleClose={() => setEditingTextResource(undefined)}
        handleSave={() => {
          setShowModal(false);
          editingTextResource && setEditingTextResource(undefined);
          getTextResources();
        }}
      />

      <LocalizationProvider language={language}>
        <IntlProvider locale={currentLocale}>
          <Grid
            style={{ margin: 20 }}
            data={gridData.data}
            total={gridDataTotal}
            sortable={true}
            filterable={true}
            resizable={true}
            pageable={{ buttonCount: 4, pageSizes: true }}
            {...dataState}
            onDataStateChange={dataStateChange}
          >
            {gridColumns.map((column, index) => {
              return (
                column.show && (
                  <Column
                    className={column.field + '-class'}
                    key={index}
                    field={column.field}
                    title={column.title}
                    filter={column.filter}
                    format={column.format}
                    filterCell={column.filterCell}
                  />
                )
              );
            })}
            <Column
              title={t('Views.ColumnActions')}
              width={100}
              filterable={false}
              sortable={false}
              cell={(props) => {
                return (
                  <td>
                    {isAdministrator && !isReadOnly ? (
                      <ActionButtons>
                        <EditButton
                          onClick={() => {
                            setEditingTextResource(props.dataItem);
                            setShowModal(true);
                          }}
                        />
                      </ActionButtons>
                    ) : null}
                  </td>
                );
              }}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewTextResources);
