import { useTranslation } from 'react-i18next';

interface DownloadButtonProps {
  onClick: () => void;
}

export default function DownloadButton({ onClick }: DownloadButtonProps): JSX.Element | null {
  const { t } = useTranslation();

  return (
    <button className="btn btn-primary btn-sm mx-1" type="button" onClick={onClick}>
      <span>{t('Views.DownloadButton')}</span>
    </button>
  );
}
