import { IPagedResultViewModel, Language } from '../models';
import { TextResource } from '../models/TextResource';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { BaseService } from './BaseService';

/**
 * The service class to interact with the TextResource.
 */
export class TextResourceService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/textresources');
  }

  getAllPaged(filter: string): Promise<IPagedResultViewModel<TextResource>> {
    return this.get('?' + filter);
  }

  getCount(filter: any): Promise<number> {
    return this.get('/count?' + filter);
  }

  getById(filter: number): Promise<TextResource> {
    return this.get('/' + filter);
  }

  update(
    id: number | null | undefined,
    text: string | null | undefined,
    keyName: string | null | undefined,
    language: Language | null | undefined,
  ): Promise<string> {
    return this.put(``, {
      id,
      text,
      keyName,
      language,
    });
  }
}
