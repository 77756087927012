import { useTranslation } from 'react-i18next';
import useApplicationState from '../../../hooks/useApplicationState';
import useCurrentLanguage from '../../../hooks/useCurrentLanguage';

import AgrDropDown, { IAgrDropDownItem } from '../../common/agr-dropdown/AgrDropDown';
import './GlobalFilter.scss';

/**
 * Dispalys the global filters on top of the navigation. Allows setting the global filter in the context.
 */
function GlobalFilter(): JSX.Element | null {
  /**
   * The translation service.
   */
  const { t } = useTranslation();

  /**
   * The hook to always have the updated language.
   */
  const language = useCurrentLanguage();

  /**
   * The application state.
   */
  const { shops, selectedShop, setSelectedShop, products, selectedProduct, setSelectedProduct } = useApplicationState();

  /**
   * When a shop is selected, it steres it in the context.
   */
  function onSelectedShop(selectedItem: any): any {
    if (selectedItem?.id === undefined) {
      setSelectedShop(null);
    } else {
      setSelectedShop(shops.find((p) => p.id === selectedItem.id)!);
    }
  }

  /**
   * When a product is selected, it steres it in the context.
   */
  function onSelectedProduct(selectedItem: any): any {
    if (selectedItem?.id === undefined) {
      setSelectedProduct(null);
    } else {
      setSelectedProduct(products.find((p) => p.id === selectedItem.id) || null);
    }
  }

  return (
    <div className="agr-global-filter form-1-box mb-4">
      <fieldset className="border">
        <legend className="float-none ml-4">{t('Navigation.GlobalFilter')}</legend>
        <AgrDropDown
          defaultItem={{ id: undefined, title: shops?.length > 0 ? t('Navigation.AllShops') : t('Navigation.NoShops') }}
          selectedItem={selectedShop ? { id: selectedShop?.id, title: selectedShop?.name } : null}
          data={shops?.map((s) => {
            return { id: s.id, title: s.name, active: s.id === selectedShop?.id };
          })}
          onChange={onSelectedShop}
        />
        <AgrDropDown
          defaultItem={{
            id: undefined,
            title: products?.length > 0 ? t('Navigation.AllProducts') : t('Navigation.NoProducts'),
          }}
          selectedItem={
            selectedProduct
              ? {
                  id: selectedProduct?.id,
                  title: language === 'de' ? selectedProduct?.nameDe : selectedProduct?.nameFr,
                }
              : null
          }
          data={products?.map((s) => {
            return { id: s.id, title: language === 'de' ? s.nameDe : s.nameFr, active: s.id === selectedShop?.id };
          })}
          onChange={onSelectedProduct}
        />
      </fieldset>
    </div>
  );
}

export default GlobalFilter;
