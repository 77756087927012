import { createContext, Dispatch, SetStateAction } from 'react';
import { ErrorViewModel } from '../models';

export interface ErrorHandlingContextValues {
  setErrors: Dispatch<SetStateAction<ErrorViewModel[]>>;
  errors: ErrorViewModel[];
}

const ErrorHandlingContext = createContext<ErrorHandlingContextValues | undefined>(undefined);

export const ErrorHandlingProvider = ErrorHandlingContext.Provider;

export default ErrorHandlingContext;
