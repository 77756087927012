import useApplicationState from './useApplicationState';

/**
 * determines if te current user is a readOnyy user.
 */
export default function useIsDieselAdministrator(): boolean {
  const { isDieselAdministrator } = useApplicationState();

  return isDieselAdministrator;
}
