import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * A generic efficient toggle component. Based on:
 * https://dev.to/alexkhismatulin/update-boolean-state-right-with-react-hooks-3k2i
 */
export default function useToggle(initialState: any) {
  const [isToggled, setIsToggled] = useState(initialState);
  const isToggledRef = useRef(isToggled);

  const toggle = useCallback(() => setIsToggled(!isToggledRef.current), [isToggledRef, setIsToggled]);

  // keep the value in isToggledRef actual
  // when isToggled changes, isToggledRef is updated accordingly
  useEffect(() => {
    isToggledRef.current = isToggled;
  }, [isToggled]);

  return [isToggled, toggle];
}
