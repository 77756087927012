import { useTranslation } from 'react-i18next';

/**
 *
 * The check price button props.
 * Disabled in this case doesn't actually disable
 * the button as a normal html disable, it's just
 * a way to describe that disabledOnClick should be
 * called and display the information message.
 *
 */
interface CheckPriceButtonProps {
  onClick: () => void;
  disabled?: boolean;
  disabledOnClick: () => void;
}

/**
 *
 * The CheckPriceButton Component to be used in JSX.
 *
 * @returns
 */
export default function CheckPriceButton({ onClick, disabled, disabledOnClick }: CheckPriceButtonProps) {
  const { t } = useTranslation();

  return (
    <button className="btn btn-secondary btn-sm mx-1" type="button" onClick={disabled ? disabledOnClick : onClick}>
      <span>{t('ZonesView.CheckPriceButton')}</span>
    </button>
  );
}
