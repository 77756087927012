import { UserRole } from '@/constants/Constants';
import useApplicationState from './useApplicationState';

/**
 * determines if the current user has the requested roles.
 */
export default function useIsInRole(...requiredRoles: UserRole[]): boolean {
  const { role } = useApplicationState();

  return requiredRoles.some((requiredRole) => requiredRole === role);
}

