import { useTranslation } from 'react-i18next';

interface EditButtonProps {
  onClick: () => void;
}

export default function EditButton({ onClick }: EditButtonProps): JSX.Element | null {
  const { t } = useTranslation();

  return (
    <button className="btn btn-primary btn-sm mx-1" type="button" onClick={onClick}>
      <span>{t('Views.EditButton')}</span>
    </button>
  );
}
