import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter';
import { IGridState } from './IGridState';

interface SortOrder {
  propertyName: string;
  sortAscending: boolean;
}
/**
 * The base type for all the requests that the client sends to the API when an user filters a grid.
 * */
export interface IFilterRequest {
  skip?: number;
  take?: number;
  sortOrder?: SortOrder[];
}

export const gridStateToSortOrder = (gridState: IGridState | null) => {
  const result: SortOrder[] = [];

  if (gridState && gridState.sort && gridState.sort.length > 0) {
    gridState.sort.forEach((s) => {
      result.push({ propertyName: capitalizeFirstLetter(s.field), sortAscending: s.dir === 'asc' });
    });
  }

  return result;
};
