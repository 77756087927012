import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { ProductsService } from '../services/ProductsService';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * ProductsService to be used in components to make API calls.
 *
 * @returns a ProductsService instance
 */
export default function useProductsService(): ProductsService {
  const config = useContext(AgrConfigurationContext);

  const productService = useMemo(() => {
    return new ProductsService(config);
  }, [config]);

  return productService;
}
