import CopyButton from '@/components/common/copy-button/CopyButton';
import { Constants } from '@/constants/Constants';
import { PromotionState } from '@/models/promotion/PromotionState';
import { formatters } from '@/utils/formatters';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { IntlProvider, IntlService, LocalizationProvider } from '@progress/kendo-react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { FuelProductTypeName, DieselProductTypeName } from '../../../../assets/constants/DataConstants';
import useApplicationState from '../../../../hooks/useApplicationState';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useIsInRole from '../../../../hooks/useIsInRole';
import useIsReadOnly from '../../../../hooks/useIsReadOnly';
import usePromotionsService from '../../../../hooks/usePromotionsService';
import {
  ErrorViewModel,
  gridStateToSortOrder,
  Holding,
  IFilter,
  IGridSort,
  IGridState,
  IPagedResultViewModel,
  IPromotionFilterRequest,
  isErrorViewModel,
  Product,
  ProductType,
  Promotion,
  PromotionType,
  Shop,
} from '../../../../models';
import { convertDateFromUtcInLocale } from '../../../../utils/date-handling';
import uniqueBy from '../../../../utils/uniqueBy';
import ActionButtons from '../../../common/action-buttons/ActionButtons';
import DeleteButton from '../../../common/delete-button/DeleteButton';
import EditButton from '../../../common/edit-button/EditButton';
import { FormattableGridCell } from '../../../common/grid-cell/FormattableGridCell';
import {
  DropdownFilterCell,
  filterDropDownUndefinedValues,
} from '../../../common/kendo-filter-dropdown/DropDownFilterCell';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import AddPromotionForm from '../new-form/AddPromotionForm';

/**
 * A wrapper for the promotions table.
 */
function ViewPromotions() {
  /**
   * The translation service.
   */
  const { t } = useTranslation();

  /**
   * The hook to always have the updated language.
   */
  const language = useCurrentLanguage();

  /**
   * Hook to retrieve the locale.
   */
  const currentLocale = useCurrentLocale();

  /**
   * used to retrieve the promotions from the server.
   */
  const promotionsService = usePromotionsService();

  /**
   * Indicates if the current user is a holding user.
   */
  const isHoldingUser = useIsInRole(Constants.Roles.HoldingUser);

  /**
   * Indicates if the current user is a read-only user.
   */
  const isReadOnly = useIsReadOnly();

  /**
   * The state necessary to display the errors in the bottom right tost notifications.
   */
  const { errors, setErrors } = useErrorHandling();

  /**
   * The application state. Specific for the products.
   */
  const { selectedProduct, selectedShop, holding, products } = useApplicationState();

  /**
   * Toggle for the loading spinner.
   */
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /**
   * Indicates if the first loading of the orders is going on. It is used to display the loader
   * only during the initial load, other wise it would spin each time the user types a character
   * in any column filter, thus initiating a server-side call.
   */
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  /**
   * All the product types of the application. It is required for the add/edit modal window.
   */
  const [allProductTypes, setAllProductTypes] = useState<(ProductType | undefined)[]>();

  /**
   * Toggle to display the add/edit window.
   */
  const [showUpsertModal, setShowUpsertModal] = useState<boolean>(false);

  /**
   * The default sorting of the grid.
   */
  const defaultSort: IGridSort[] = useMemo(() => [{ field: 'id', dir: 'desc' }], []);

  /**
   * The current paging and sorting information.
   */
  const [dataState, setDataState] = useState<{
    take: number;
    skip: number;
    filter: { logic: 'and' | 'or'; filters: IFilter[] };
    sort: IGridSort[];
  }>({
    take: 10,
    skip: 0,
    filter: { logic: 'and', filters: [] },
    sort: defaultSort,
  });

  const [selectedPromotion, setSelectedPromotion] = useState<Promotion>();

  /**
   * The data displayed in the grid, that is the data retrieved from the server after applying the filters.
   */
  const [allPromotions, setAllPromotions] = useState<IPagedResultViewModel<Promotion>>({
    data: [],
    total: 0,
  });

  const [promotionState, setPromotionState] = useState<PromotionState>(PromotionState.Active);

  /**
   * Applies date and time transformation and localization to the order status field.
   */
  const parseData = useCallback((data: Promotion[]): Promotion[] => {
    return data.map<Promotion>((promotion) => ({
      ...promotion,
      validityPeriodStart: convertDateFromUtcInLocale(
        new Date(promotion.validityPeriodStart),
        promotion.validityTimeStart,
      ),
      validityPeriodEnd: convertDateFromUtcInLocale(new Date(promotion.validityPeriodEnd), promotion.validityTimeEnd),
    }));
  }, []);

  const availablePromotionStates = useMemo<PromotionState[]>(
    () => [PromotionState.Active, PromotionState.Inactive, PromotionState.All],
    [],
  );

  /**
   * Applies the transformations to the data (the localization of Role and ReadOnly,
   * and the formatting of the data).
   */
  const gridData = useMemo(() => {
    return parseData(allPromotions.data);
  }, [parseData, allPromotions.data]);

  /**
   * Updates the data state.
   * @param e the new data state.
   */
  const dataStateChange = (e: any) => {
    e = filterDropDownUndefinedValues(e);
    setDataState(e.dataState);
  };

  /**
   * Unions the odata query computed by the Kendo grid with the global filters.
   * @param shop the shops selected in the global filter.
   * @param product the product selected in the global filter.
   * @param odataString the odata query computed by the Kendo grid
   * @returns an odata query string that includes both the filter computed by the Kendo grid and the global filters.
   */
  const createFilters = (
    promotionState: PromotionState,
    shop: Shop | null,
    product: Product | null,
    holding: Holding | undefined,
    dataState: IGridState,
  ): IPromotionFilterRequest => {
    const filter: IPromotionFilterRequest = { promotionState: promotionState };

    if (dataState && dataState.filter && dataState.filter.filters && dataState.filter.filters.length > 0) {
      filter.validityPeriodEnd = dataState.filter.filters.find((f) => f.field == 'validityPeriodEnd')?.value;
      filter.validityPeriodStart = dataState.filter.filters.find((f) => f.field == 'validityPeriodStart')?.value;
      filter.validityPeriod = dataState.filter.filters.find((f) => f.field == 'validityPeriod')?.value;
      filter.promotionType = +PromotionType[dataState.filter.filters.find((f) => f.field == 'promotionType')?.value];
      filter.internalName = dataState.filter.filters.find((f) => f.field == 'internalName')?.value;
      filter.productTypeId = dataState.filter.filters.find((f) => f.field == 'productTypeId')?.value;
      filter.discountCode = dataState.filter.filters.find((f) => f.field == 'discountCode')?.value;
      filter.amount = dataState.filter.filters.find((f) => f.field == 'CHFAmount')?.value;
    }

    if (dataState) {
      filter.skip = dataState.skip;
      filter.take = dataState.take;
    }

    filter.sortOrder = gridStateToSortOrder(dataState);

    if (holding) {
      filter.holdingId = holding.id;
    }

    if (product) {
      filter.productId = product.id;
      filter.productTypeId = product.productTypeId;
    }

    if (shop) {
      filter.zoneIds = shop.zones?.map((z) => z.id);
    }

    return filter;
  };

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      const filter = createFilters(promotionState, selectedShop, selectedProduct, holding, dataState);
      const promotions = await promotionsService.getAllPaged(filter);
      setAllPromotions(promotions);
    } catch (error) {
      console.error(error);
      setErrors((err) => [...err, error as ErrorViewModel]);
    } finally {
      setIsLoading(false);
      setInitialLoading(false);
    }
  }, [
    setIsLoading,
    promotionState,
    dataState,
    setErrors,
    holding,
    promotionsService,
    selectedProduct,
    selectedShop,
    setInitialLoading,
  ]);

  /**
   * The filter for the promotion type.
   */
  const PromotionTypeFilterCell = useCallback(
    (props: any) => (
      <DropdownFilterCell
        {...props}
        data={[
          { id: PromotionType[PromotionType.FixedAmount], text: t('PromotionType.FixedAmount') },
          { id: PromotionType[PromotionType.FixedAmountWithCode], text: t('PromotionType.FixedAmountWithCode') },
          { id: PromotionType[PromotionType.AmountByQuantity], text: t('PromotionType.AmountByQuantity') },
          {
            id: PromotionType[PromotionType.AmountByQuantityWithCode],
            text: t('PromotionType.AmountByQuantityWithCode'),
          },
          ,
          { id: PromotionType[PromotionType.Coupon], text: t('PromotionType.Coupon') },
          ,
          { id: PromotionType[PromotionType.AmountByQuantityWithCode], text: t('PromotionType.CouponWithCode') },
          { id: PromotionType[PromotionType.Gift], text: t('PromotionType.Gift') },
          ,
          { id: PromotionType[PromotionType.GiftWithCode], text: t('PromotionType.GiftWithCode') },
        ]}
        textField="text"
        dataItemKey="id"
        defaultItem={{ id: 0, text: t('PromotionType.Any') }}
      />
    ),
    [t],
  );

  /**
   * The filter for the product type.
   */
  const ProductTypeFilterCell = useCallback(
    (props: any) => (
      <DropdownFilterCell
        {...props}
        data={allProductTypes?.map(function (element: ProductType | undefined) {
          return { id: element?.id, text: t(`ProductsEnum.${element?.productName}`) };
        })}
        textField="text"
        dataItemKey="id"
        defaultItem={{ id: 0, text: t('CommonLabels.Any') }}
      />
    ),
    [allProductTypes, t],
  );

  /**
   * Deletes a promotion.
   */
  const deletePromotion = useCallback(
    async (promotionId: number) => {
      try {
        setIsLoading(true);
        await promotionsService.delete(promotionId);
        await loadData();
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errors, error as ErrorViewModel]);
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [promotionsService, loadData, setErrors, errors],
  );

  const internationalization = useMemo(() => new IntlService(currentLocale), [currentLocale]);

  /**
   * Retrieves the data from the server on the initial load.
   */
  useEffect(() => {
    loadData();
  }, [loadData]);

  /**
   * Reads the product types from the collection of products in the application state
   * and the sets the local state after filtering the duplicates.
   */
  useEffect(() => {
    const pts = uniqueBy(
      products.map((p) => p.productType),
      (x) => (x ? x.id : 0),
    );
    setAllProductTypes(pts);
  }, [setAllProductTypes, products]);

  /**
   * The spinner that is visibe only on the first loading of the data.
   */
  if (isLoading && initialLoading) {
    return <LoadingPanel />;
  }

  return (
    <ViewWrapper
      title={t('PromotionsView.Title')}
      addButton={
        isHoldingUser &&
        !isReadOnly && (
          <button
            type="button"
            className="ms-2 btn btn-primary p-1 pt-0 pb-0 rounded-circle fw-bold"
            onClick={() => setShowUpsertModal(true)}
          >
            <span style={{ fontSize: 36, lineHeight: 0.65 }}>+</span>
          </button>
        )
      }
    >
      <div style={{ margin: '0 20px' }}>
        <ButtonGroup className="align-self-start">
          {availablePromotionStates.map((state) => {
            return (
              <ToggleButton
                key={state.toString()}
                id={`radio-${state.toString().toLowerCase()}-promotion`}
                type="radio"
                variant={promotionState === state ? 'primary' : 'light'}
                name="radio"
                value={state}
                checked={promotionState === state}
                onChange={() => {
                  setPromotionState(state);
                  setDataState((ds) => ({ ...ds, skip: 0 }));
                }}
              >
                {t(`PromotionsView.${PromotionState[state]}`)}
              </ToggleButton>
            );
          })}
        </ButtonGroup>
      </div>

      <AddPromotionForm
        modalTitle={
          selectedPromotion ? (
            <Trans i18nKey="AddPromotionModal.EditTitle" t={t}>
              {{ promotion: selectedPromotion.internalName }}
            </Trans>
          ) : (
            t('AddPromotionModal.Title')
          )
        }
        show={showUpsertModal}
        size="xl"
        promotionToUpdate={selectedPromotion}
        handleClose={() => {
          setShowUpsertModal(false);
          selectedPromotion && setSelectedPromotion(undefined);
        }}
        handleSave={() => {
          setShowUpsertModal(false);
          selectedPromotion && setSelectedPromotion(undefined);
          loadData();
        }}
      />
      <LocalizationProvider language={language}>
        <IntlProvider locale={currentLocale}>
          <Grid
            style={{ margin: 20 }}
            data={gridData}
            total={allPromotions.total}
            sortable
            filterable
            resizable
            pageable={{ buttonCount: 4, pageSizes: true }}
            {...dataState}
            onDataStateChange={dataStateChange}
          >
            <Column
              field="productType.Id"
              title={t('PromotionsView.ProductType')}
              filterCell={ProductTypeFilterCell}
              cell={(props) => (
                <FormattableGridCell<Promotion>
                  formatValue={(value) =>
                    value.productType?.description?.toLowerCase() == FuelProductTypeName.toLowerCase()
                      ? t('ProductsEnum.Fuel')
                      : value.productType?.description?.toLowerCase() == DieselProductTypeName.toLowerCase()
                      ? t('ProductsEnum.Diesel')
                      : t('ProductsEnum.Wood')
                  }
                  {...props}
                />
              )}
            />
            <Column field="internalName" title={t('PromotionsView.Discount')} />
            <Column field="discountCode" title={t('PromotionsView.Code')} />
            <Column
              field="promotionType"
              title={t('PromotionsView.Type')}
              filterCell={PromotionTypeFilterCell}
              cell={(props) => (
                <FormattableGridCell<Promotion>
                  formatValue={(value) => formatters.promotion.getTypeName(t, value.promotionType)}
                  {...props}
                />
              )}
            />
            <Column
              field="CHFAmount"
              title={t('PromotionsView.Amount')}
              cell={(props) => (
                <FormattableGridCell<Promotion>
                  formatValue={(value) => formatters.promotion.asAmount(value, t)}
                  {...props}
                />
              )}
            />
            <Column
              field="validityPeriod"
              title={t('PromotionsView.Duration')}
              filterable={false}
              sortable={false}
              cell={(props) => (
                <FormattableGridCell<Promotion>
                  formatValue={(value) =>
                    `${internationalization.formatDate(value.validityPeriodStart)} - ${internationalization.formatDate(
                      value.validityPeriodEnd,
                    )}`
                  }
                  {...props}
                />
              )}
            />
            <Column field="validityPeriodStart" title={t('PromotionsView.From')} filter="date" format="{0:d}" />
            <Column field="validityPeriodEnd" title={t('PromotionsView.Until')} filter="date" format="{0:d}" />
            <Column
              title={t('Views.ColumnActions')}
              width={250}
              filterable={false}
              sortable={false}
              cell={(props) => {
                return (
                  <td
                    className="k-command-cell"
                    colSpan={props.colSpan}
                    role="gridcell"
                    aria-colindex={props.ariaColumnIndex}
                    data-grid-col-index={props.columnIndex}
                  >
                    <ActionButtons>
                      {isHoldingUser && !isReadOnly && (
                        <EditButton
                          onClick={() => {
                            setSelectedPromotion(props.dataItem);
                            setShowUpsertModal(true);
                          }}
                        />
                      )}
                      {isHoldingUser && !isReadOnly && (
                        <CopyButton
                          onClick={() => {
                            setSelectedPromotion({ ...props.dataItem, id: 0 });
                            setShowUpsertModal(true);
                          }}
                        />
                      )}
                      {isHoldingUser && !isReadOnly && (
                        <DeleteButton
                          onClick={() => {
                            if (confirm(`${t('PromotionsView.DeleteMessage')} ${props.dataItem.internalName}?`)) {
                              deletePromotion(props.dataItem.id);
                            }
                          }}
                        />
                      )}
                    </ActionButtons>
                  </td>
                );
              }}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewPromotions);
