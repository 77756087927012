export interface MapLink {
  route: string;
  key: string;
}

export const Routes = {
  Users: '/users',
  Products: '/products',
  MailTemplates: '/mailtemplates',
  Zones: '/zones',
  Shops: '/shops',
  SystemLogs: '/logs',
  Orders: '/orders',
  Disporegions: '/disporegions',
  PostalCodes: '/postalcodes',
  Dashboard: '/dashboard',
  Margins: '/margins',
  Alerts: '/alerts',
  PortalIntegration: '/portal',
  Promotions: '/promotions',
  Settings: '/settings',
  BasePrice: '/baseprice',
  PriceLogs: '/pricelogs',
  TextResources: '/textresources',
  Diesel: '/diesel',
};

export const LinkMap: Array<MapLink> = [
  { route: Routes.Users, key: 'Links.UserProfiles' },
  { route: Routes.Products, key: 'Links.Products' },
  { route: Routes.MailTemplates, key: 'Links.MailTemplates' },
  { route: Routes.Zones, key: 'Links.Zones' },
  { route: Routes.Shops, key: 'Links.Shops' },
  { route: Routes.SystemLogs, key: 'Links.Logs' },
  { route: Routes.Orders, key: 'Links.Orders' },
  { route: Routes.Disporegions, key: 'Links.Disporegions' },
  { route: Routes.PostalCodes, key: 'Links.PostalCodes' },
  { route: Routes.Margins, key: 'Links.Margins' },
  { route: Routes.Alerts, key: 'Links.Alerts' },
  { route: Routes.PortalIntegration, key: 'Links.PortalIntegration' },
  { route: Routes.Promotions, key: 'Links.Promotions' },
  { route: Routes.Settings, key: 'Links.Settings' },
  { route: Routes.BasePrice, key: 'Links.BasePrice' },
  { route: Routes.PriceLogs, key: 'Links.PriceLogs' },
  { route: Routes.TextResources, key: 'Links.TextResources' },
  { route: Routes.Diesel, key: 'Links.Diesel' },
];
