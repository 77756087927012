import withModal from '../../../hoc/with-modal/withModal';

/**
 *
 * The InspectPayloadDetails props.
 * payload indicates the json payload as from the Logs grid.
 *
 */
interface InspectPayloadDetailsProps {
  payload: string;
}

/**
 *
 * The InspectPayloadDetails Component.
 *
 * @returns the Component to be used in JSXs
 */
function InspectPayloadDetails({ payload }: InspectPayloadDetailsProps) {
  return (
    <div>
      <pre>{JSON.stringify(JSON.parse(payload), null, 2)}</pre>
    </div>
  );
}

export default withModal(InspectPayloadDetails);
