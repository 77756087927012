import { TextResourceService } from '../services/TextResourceService';
import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * TextResourceService to be used in components to make API calls.
 *
 * @returns a TextResourceService instance
 */
export default function useTextResourceService(): TextResourceService {
  const config = useContext(AgrConfigurationContext);

  const memoizedService = useMemo(() => {
    return new TextResourceService(config);
  }, [config]);

  return memoizedService;
}
