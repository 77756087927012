export const productTemplateFuelDE = `<div style="display: flex; justify-content: center; background-color: #f9f9f9; color: #4a4a4a; font: 22px Droid Sans, sans-serif;">
  <div style="padding: 0.5rem; padding-left: 0">
    <img
      src="{{image}}"
      width="112"
      alt="Product image"
      style="align-self: flex-start"
    />
  </div>

  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.5rem;
      padding-left: 1.5rem;
    "
  >
    <p style="margin: 0.5rem 0;">
      <span style="color: #f17000; font-size: larger; display: block;"
        ><strong
          >Das Günstige<br />AGROLA 100<br />Heizöl</strong
        ></span
      >

      <strong style="margin-top: 0.5rem; font-size: large; display: block;"
        >{{unitPrice}} CHF</strong
      >
      <strong style="margin-top: 0.5rem; font-size: small; display: block;"
        >je 100 Liter bei {{quantity}} Liter</strong
      >
    </p>

    <p style="margin: 0.5rem 0;">
      <span style="font-size: small; display: block;"
        >{{quantityBasedDiscount}}</span
      >
      <span style="font-size: small; margin-top: 0.5rem; display: block;"
        >inkl. MwSt., inkl. CO<sub>2</sub> Abgabe</span
      >
      <span style="font-size: small; margin-top: 0.5rem; display: block;"
        >Lieferzeit: ca. 1 Woche</span
      >
      <strong style="font-size: large; margin-top: 0.5rem; display: block;"
        >{{unitPriceDiscounted}}</strong
      >
      <p style="margin: 0.5rem 0;">{{discount}}</p>
    </p>

    <p style="font-size: x-small; margin: 0.5rem 0; font-style: italic;">
      Heizöl extra leicht Euro-Qualität darf in Anlagen oder betrieblichen
      Einheiten kleiner als 5 MW nur noch bis zum 31. Mai 2023 eingesetzt
      werden.
    </p>
  </div>
</div>`;

export const productTemplateFuelFR = `<div style="display: flex; justify-content: center; background-color: #f9f9f9; color: #4a4a4a; font: 22px Droid Sans, sans-serif;">
  <div style="padding: 0.5rem; padding-left: 0">
    <img
      src="{{image}}"
      width="112"
      alt="Product image"
      style="align-self: flex-start"
    />
  </div>

  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.5rem;
      padding-left: 1.5rem;
    "
  >
    <p style="margin: 0.5rem 0;">
      <span style="color: #f17000; font-size: larger; display: block;"
        ><strong
          >avantageux<br />AGROLA 100<br />mazout de chauffage</strong
        ></span
      >

      <strong style="margin-top: 0.5rem; font-size: large; display: block;"
        >{{unitPrice}} CHF</strong
      >
      <strong style="margin-top: 0.5rem; font-size: small; display: block;"
        >par 100 litres avec {{quantity}} litres</strong
      >
    </p>

    <p style="margin: 0.5rem 0;">
      <span style="font-size: small; display: block;"
        >{{quantityBasedDiscount}}</span
      >
      <span style="font-size: small; margin-top: 0.5rem; display: block;">
        TVA Comprise, taxe CO<sub>2</sub> incluse</span
      >
      <span style="font-size: small; margin-top: 0.5rem; display: block;"
        >délai de livraison: env. 1 semaine</span
      >
      <strong style="font-size: large; margin-top: 0.5rem; display: block;"
        >{{unitPriceDiscounted}}</strong
      >
      <p style="margin: 0.5rem 0;">{{discount}}</p>
    </p>

    <p style="font-size: x-small; margin: 0.5rem 0; font-style: italic;">
      Le mazout extra léger de qualité Euro ne peut être utilisé dans des
      installations ou des unités d’exploitation de moins de 5 MW que
      jusqu'au 31 mai 2023.
    </p>
  </div>
</div>`;

export const productTemplateWoodDE = `<div style="display: flex; justify-content: center; background-color: #f9f9f9; color: #4a4a4a; font: 22px Droid Sans, sans-serif;">
  <div style="padding: 0.5rem; padding-left: 0">
    <img
    src="{{image}}"
    width="112"
    alt="Product image"
    style="align-self: flex-start"
  />
  </div>

  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.5rem;
      padding-left: 1.5rem;
    "
  >
    <p style="margin: 0.5rem 0;">
      <span style="font-size: larger; display: block;"
        ><strong>AGROLA Holz-Pellets lose,<br />ENplus-A1</strong></span
      >

      <strong style="margin-top: 0.5rem; font-size: large; display: block;"
        >{{unitPrice}} CHF</strong
      >
      <strong style="margin-top: 0.5rem; font-size: small; display: block;"
        >je Tonne bei {{quantity}} kg</strong
      >
    </p>

    <p style="margin: 0.5rem 0;">
        <span style="font-size: small; display: block;">{{quantityBasedDiscount}}</span>
      <span style="font-size: small; margin-top: 0.5rem; display: block;">inkl. MwSt.</span>
      <span style="font-size: small; margin-top: 0.5rem; display: block;"
        >Lieferzeit: ca. 1 Woche</span
      >
    </p>
    
    <strong style="font-size: large; margin-top: 0.5rem; display: block;"
          >{{unitPriceDiscounted}}</strong
        >

    <p style="margin: 0.5rem 0;">{{discount}}</p>
  </div>
</div>`;

export const productTemplateWoodFR = `<div style="display: flex; justify-content: center; background-color: #f9f9f9; color: #4a4a4a; font: 22px Droid Sans, sans-serif;">
  <div style="padding: 0.5rem; padding-left: 0">
    <img
    src="{{image}}"
    width="112"
    alt="Product image"
    style="align-self: flex-start"
  />
  </div>

  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.5rem;
      padding-left: 1.5rem;
    "
  >
    <p style="margin: 0.5rem 0;">
      <span style="font-size: larger; display: block;"
        ><strong>AGROLA Pellets de bois,<br />ENplus-A1</strong></span
      >

      <strong style="margin-top: 0.5rem; font-size: large; display: block;"
        >{{unitPrice}} CHF</strong
      >
      <strong style="margin-top: 0.5rem; font-size: small; display: block;"
        >par tonne avec {{quantity}} kg</strong
      >
    </p>

    <p style="margin: 0.5rem 0;">
        <span style="font-size: small; display: block;">{{quantityBasedDiscount}}</span>
      <span style="font-size: small; margin-top: 0.5rem; display: block;">TVA Comprise</span>
      <span style="font-size: small; margin-top: 0.5rem; display: block;"
        >délai de livraison: env. 1 semaine</span
      >
    </p>
    
    <strong style="font-size: large; margin-top: 0.5rem; display: block;"
          >{{unitPriceDiscounted}}</strong
        >

    <p style="margin: 0.5rem 0;">{{discount}}</p>
  </div>
</div>`;

export const productTemplateDieselDE = `<div style="display: flex; justify-content: center; background-color: #f9f9f9; color: #4a4a4a; font: 22px Droid Sans, sans-serif;">
  <div style="padding: 0.5rem; padding-left: 0">
    <img
    src="{{image}}"
    width="112"
    alt="Product image"
    style="align-self: flex-start"
  />
  </div>

  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.5rem;
      padding-left: 1.5rem;
    "
  >
    <p style="margin: 0.5rem 0;">
      <span style="font-size: larger; display: block;"
        ><strong>AGROLA Diesel,<br />ENplus-A1</strong></span
      >

      <strong style="margin-top: 0.5rem; font-size: large; display: block;"
        >{{unitPrice}} CHF</strong
      >
      <strong style="margin-top: 0.5rem; font-size: small; display: block;"
        >je Liter bei {{quantity}} l</strong
      >
    </p>

    <p style="margin: 0.5rem 0;">
        <span style="font-size: small; display: block;">{{quantityBasedDiscount}}</span>
      <span style="font-size: small; margin-top: 0.5rem; display: block;">inkl. MwSt.</span>
      <span style="font-size: small; margin-top: 0.5rem; display: block;"
        >Lieferzeit: ca. 1 Woche</span
      >
    </p>
    
    <strong style="font-size: large; margin-top: 0.5rem; display: block;"
          >{{unitPriceDiscounted}}</strong
        >

    <p style="margin: 0.5rem 0;">{{discount}}</p>
  </div>
</div>`;

export const productTemplateDieselFR = `<div style="display: flex; justify-content: center; background-color: #f9f9f9; color: #4a4a4a; font: 22px Droid Sans, sans-serif;">
  <div style="padding: 0.5rem; padding-left: 0">
    <img
    src="{{image}}"
    width="112"
    alt="Product image"
    style="align-self: flex-start"
  />
  </div>

  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0.5rem;
      padding-left: 1.5rem;
    "
  >
    <p style="margin: 0.5rem 0;">
      <span style="font-size: larger; display: block;"
        ><strong>AGROLA Diesel,<br />ENplus-A1</strong></span
      >

      <strong style="margin-top: 0.5rem; font-size: large; display: block;"
        >{{unitPrice}} CHF</strong
      >
      <strong style="margin-top: 0.5rem; font-size: small; display: block;"
        >par litre avec {{quantity}} l</strong
      >
    </p>

    <p style="margin: 0.5rem 0;">
        <span style="font-size: small; display: block;">{{quantityBasedDiscount}}</span>
      <span style="font-size: small; margin-top: 0.5rem; display: block;">TVA Comprise</span>
      <span style="font-size: small; margin-top: 0.5rem; display: block;"
        >délai de livraison: env. 1 semaine</span
      >
    </p>
    
    <strong style="font-size: large; margin-top: 0.5rem; display: block;"
          >{{unitPriceDiscounted}}</strong
        >

    <p style="margin: 0.5rem 0;">{{discount}}</p>
  </div>
</div>`;
