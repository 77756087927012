import { useCallback, useMemo, useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import withModal from '../../../hoc/with-modal/withModal';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import {
  Disporegion,
  ErrorViewModel,
  PostalCode,
  Product,
  ProductType,
  Shop,
  ShopCalendar,
  Zone,
} from '../../../../models';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useModal from '../../../../hooks/useModal';
import useYupErrors, { YupError } from '../../../../hooks/useYupErrors';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import { getZoneFormValidationSchema, getShopCalendarValidationSchema } from './ValidationSchema';
import { ValidationError } from 'yup';
import useZonesService from '../../../../hooks/useZonesService';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Constants } from '../../../../constants/Constants';
import useApplicationState from '../../../../hooks/useApplicationState';
import { AllShopsName } from '../../../../assets/constants/DataConstants';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import ItemAssigner from '../../../common/item-assigner/ItemAssigner';
import Spinner from '../../../common/spinner/Spinner';
import CalendarSetting from '../../../common/calendar-setting/CalendarSetting';

enum NewsletterSubscription {
  No = 0,
  Yes,
}

const subscriptionPossibilities = [NewsletterSubscription.Yes, NewsletterSubscription.No];

interface InputLabelProps {
  validationError: boolean;
}

/**
 * Style for labels in this from.
 */
const InputLabel = styled.label<InputLabelProps>`
  margin-bottom: ${({ validationError }) => (validationError ? '0' : '0.5em')};
`;

/**
 * Style for the zone assignment section.
 */
const AssignZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

const ErrorMessage = styled.span.attrs(() => ({ className: 'text-danger' }))<{}>`
  margin-bottom: 0.25em;
`;

/**
 * The error shape for the calendar validation schema.
 */
interface CalendarError {
  showWeeks: string;
  deliveryWaitTime: string;
  textDe: string;
  textFr: string;
}

/**
 * The interface for the error messages.
 */
export interface ZoneError {
  zoneId: string;
  name: string;
  shopId: string;
  woodDisporegionId: string;
  fuelDisporegionId: string;
  dieselDisporegionId: string;
}

/**
 * Dummy object to initialize the yup error schema
 * in order to map the errors array to a typed object.
 */
const yupZoneErrorSchema: ZoneError = {
  zoneId: '',
  name: '',
  shopId: '',
  woodDisporegionId: '',
  fuelDisporegionId: '',
  dieselDisporegionId: '',
};

/**
 * Dummy object to initialize the yup error schema
 * in order to map the errors array to a typed object.
 */
const yupShopCalendarSchema: CalendarError = {
  deliveryWaitTime: '',
  showWeeks: '',
  textDe: '',
  textFr: '',
};

/**
 * This interface describes the necessary product shape in order
 * to be passed down to the ItemAssigner Component.
 */
interface AssignmentProduct {
  id: number;
  name: string;
}

/**
 * The form props.
 */
interface AddZoneFormProps {
  shops: Shop[];
  disporegions: Disporegion[];
  productTypes: ProductType[];

  update: boolean;

  id?: number;
  zoneId?: number;
  name?: string;
  newsletter?: boolean;
  shopId?: number;
  woodDisporegionId?: number;
  fuelDisporegionId?: number;
  dieselDisporegionId?: number;
  woodCalendar?: ShopCalendar;
  fuelCalendar?: ShopCalendar;
  dieselCalendar?: ShopCalendar;
  products?: Product[];
  postalCodes?: PostalCode[];
}

/**
 *
 * Helper function to retrieve the product type id
 * based on the product name.
 *
 * @param productTypes - all product types, as served from the APIs.
 * @param productName - the product name whose id has to be retrieved.
 *
 * @returns the product type id if the specified product name exists,
 *          otherwise undefined.
 */
function getProductTypeId(productTypes: ProductType[], productName: string) {
  return productTypes.find((productType) => productType.productName === productName)?.id;
}

/**
 *
 * Helper function to get disporegions filtered by a product type id.
 *
 * @param disporegions - all the disporegions, as served from the APIs.
 * @param productTypeIdFilter - the product id to filter for.
 *
 * @returns the filtered disporegions as an array.
 *
 */
function getFilteredDisporegions(disporegions: Disporegion[], productTypeIdFilter: number) {
  return disporegions.filter((disporegion) => disporegion.productTypeId === productTypeIdFilter);
}

/**
 *
 * The interectable content of the modal for adding a Zone.
 *
 * @returns the component to be displayed.
 */
const AddZoneForm = ({
  update,

  shops,
  disporegions,
  productTypes,

  id,
  zoneId: updateZoneId,
  name: updateZoneName,
  shopId: updateShopId,
  newsletter: updateNewsletter,
  fuelDisporegionId: updateFuelDisporegionId,
  woodDisporegionId: updateWoodDisporegionId,
  dieselDisporegionId: updateDieselDisporegionId,
  woodCalendar,
  fuelCalendar,
  dieselCalendar,
  products: existingAssignedProducts,
  postalCodes,
}: AddZoneFormProps) => {
  const { t } = useTranslation();
  const language = useCurrentLanguage();

  const { handleSave } = useModal();
  const { errors, setErrors } = useErrorHandling();

  const { products, showWeeksNumber } = useApplicationState();

  const mapZoneErrors = useYupErrors<ZoneError>(yupZoneErrorSchema);
  const mapCalendarErrors = useYupErrors<CalendarError>(yupShopCalendarSchema);

  const zonesService = useZonesService();

  const UpsertZoneValidationSchema = useMemo(() => getZoneFormValidationSchema(t), [t]);
  const ShopCalendarValidationSchema = useMemo(() => getShopCalendarValidationSchema(t, showWeeksNumber), [t]);

  const showWeeksData = useMemo(() => Array.from({ length: showWeeksNumber }, (_, i) => i + 1), []);

  const deliveryWaitTimeData = useMemo(() => [0, 1, 2, 3, 4, 5, 6], []);

  const shopCalendarChoice = useMemo<Partial<Disporegion>>(
    () => ({
      id: 0,
      name: t('AddZoneModal.UseShopCalendar'),
    }),
    [t],
  );

  /**
   * Memoized filtered wood disporegions based on all disporegions.
   */
  const woodDisporegions = useMemo<Partial<Disporegion>[] | undefined>(() => {
    const woodProductTypeId = getProductTypeId(productTypes, Constants.ProductTypes.Wood);

    if (!woodProductTypeId) {
      setErrors([
        ...errors,
        {
          title: 'No product type with given name',
          value: {
            description:
              'No product type with given name was found in the available product types. Returning an empty collection',
          },
          statusCode: '',
        },
      ]);

      return undefined;
    }

    return [shopCalendarChoice, ...getFilteredDisporegions(disporegions, woodProductTypeId)];
  }, [productTypes, setErrors, disporegions]);

  /**
   * Memoized filtered fuel disporegions based on all disporegions.
   */
  const fuelDisporegions = useMemo<Partial<Disporegion>[] | undefined>(() => {
    const fuelProductTypeId = getProductTypeId(productTypes, Constants.ProductTypes.Fuel);

    if (!fuelProductTypeId) {
      setErrors([
        ...errors,
        {
          title: 'No product type with given name',
          value: {
            description:
              'No product type with given name was found in the available product types. Returning an empty collection',
          },
          statusCode: '',
        },
      ]);

      return undefined;
    }

    return [shopCalendarChoice, ...getFilteredDisporegions(disporegions, fuelProductTypeId)];
  }, [productTypes, setErrors, disporegions]);

  /**
   * Memoized filtered diesel disporegions based on all disporegions.
   */
  const dieselDisporegions = useMemo<Partial<Disporegion>[] | undefined>(() => {
    const dieselProductTypeId = getProductTypeId(productTypes, Constants.ProductTypes.Diesel);

    if (!dieselProductTypeId) {
      setErrors([
        ...errors,
        {
          title: 'No product type with given name',
          value: {
            description:
              'No product type with given name was found in the available product types. Returning an empty collection',
          },
          statusCode: '',
        },
      ]);

      return undefined;
    }

    return [shopCalendarChoice, ...getFilteredDisporegions(disporegions, dieselProductTypeId)];
  }, [productTypes, setErrors, disporegions]);

  /**
   *
   * Function to initialize the disporegion dropdown.
   *
   * @param disporegionId - the eventual id of the previously chosen disporegion.
   * @param disporegions - the list of disporegions to search for the provided id.
   * @param shopCalendar - the eventual previously specified calendar.
   *
   * @returns the appropriate dropdown selection.
   */
  const initializeDisporegion = useCallback(
    (
      disporegionId: number | undefined | null,
      disporegions: Partial<Disporegion>[],
      shopCalendar?: ShopCalendar,
    ): Partial<Disporegion> | undefined => {
      if (!!disporegionId) {
        return disporegions.find((dispo) => dispo.id === disporegionId);
      } else if (!disporegionId && shopCalendar) {
        return shopCalendarChoice;
      } else {
        return undefined;
      }
    },
    [],
  );

  const [loading, setLoading] = useState<boolean>(false);

  const [zoneId, setZoneId] = useState<string>(updateZoneId?.toString() || '');
  const [zoneName, setZoneName] = useState<string>(
    updateZoneName ? (id === 0 ? updateZoneName + ' ' + `(${t('AddZoneModal.CopyName')})` : updateZoneName) : '',
  );
  const [selectedShop, setSelectedShop] = useState<Shop>(shops.find((shop) => shop.id === updateShopId) || shops[0]);
  const [selectedDisporegionWood, setSelectedDisporegionWood] = useState<Partial<Disporegion> | undefined>(
    initializeDisporegion(updateWoodDisporegionId, woodDisporegions ?? [], woodCalendar),
  );
  const [selectedDisporegionFuel, setSelectedDisporegionFuel] = useState<Partial<Disporegion> | undefined>(
    initializeDisporegion(updateFuelDisporegionId, fuelDisporegions ?? [], fuelCalendar),
  );
  const [selectedDisporegionDiesel, setSelectedDisporegionDiesel] = useState<Partial<Disporegion> | undefined>(
    initializeDisporegion(updateDieselDisporegionId, dieselDisporegions ?? [], dieselCalendar),
  );
  const [newsletterSubscription, setNewsletterSubscription] = useState<NewsletterSubscription>(
    updateNewsletter !== undefined
      ? updateNewsletter
        ? NewsletterSubscription.Yes
        : NewsletterSubscription.No
      : NewsletterSubscription.Yes,
  );

  const [selectedProduct, setSelectedProduct] = useState<AssignmentProduct>();
  const [assignedProducts, setAssignedProducts] = useState<AssignmentProduct[]>(
    existingAssignedProducts?.map((product) => ({
      id: product.id!,
      name: language === 'de' ? product.nameDe! : product.nameFr!,
    })) || [],
  );

  const [shopCalendars, setShopCalendars] = useState<{
    woodCalendar?: Partial<ShopCalendar>;
    fuelCalendar?: Partial<ShopCalendar>;
    dieselCalendar?: Partial<ShopCalendar>;
  }>({ woodCalendar: woodCalendar ?? {}, fuelCalendar: fuelCalendar ?? {}, dieselCalendar: dieselCalendar ?? {} });

  const [zoneValidationErrors, setZoneValidationErrors] = useState<YupError<ZoneError> | null>();
  const [woodCalendarValidationErrors, setWoodCalendarValidationErrors] = useState<YupError<CalendarError> | null>();
  const [fuelCalendarValidationErrors, setFuelCalendarValidationErrors] = useState<YupError<CalendarError> | null>();
  const [dieselCalendarValidationErrors, setDieselCalendarValidationErrors] =
    useState<YupError<CalendarError> | null>();

  // validate form and return true if no errors found, false vice versa.
  const validateForm = useCallback(
    (
      zone: Zone,
      woodCalendar: ShopCalendar | undefined,
      fuelCalendar: ShopCalendar | undefined,
      dieselCalendar: ShopCalendar | undefined,
    ) => {
      let foundErrors = false;

      try {
        UpsertZoneValidationSchema.validateSync(zone, { abortEarly: false });
        setZoneValidationErrors(null);
      } catch (error) {
        setZoneValidationErrors(mapZoneErrors(error as ValidationError));

        foundErrors = true;
      }

      if (selectedDisporegionWood?.id === 0) {
        try {
          ShopCalendarValidationSchema.validateSync(woodCalendar, { abortEarly: false });
          setWoodCalendarValidationErrors(null);
        } catch (error) {
          setWoodCalendarValidationErrors(mapCalendarErrors(error as ValidationError));

          foundErrors = true;
        }
      }

      if (selectedDisporegionFuel?.id === 0) {
        try {
          ShopCalendarValidationSchema.validateSync(fuelCalendar, { abortEarly: false });
          setFuelCalendarValidationErrors(null);
        } catch (error) {
          setFuelCalendarValidationErrors(mapCalendarErrors(error as ValidationError));

          foundErrors = true;
        }
      }

      if (selectedDisporegionDiesel?.id === 0) {
        try {
          ShopCalendarValidationSchema.validateSync(fuelCalendar, { abortEarly: false });
          setDieselCalendarValidationErrors(null);
        } catch (error) {
          setDieselCalendarValidationErrors(mapCalendarErrors(error as ValidationError));

          foundErrors = true;
        }
      }

      return !foundErrors;
    },
    [
      UpsertZoneValidationSchema,
      setZoneValidationErrors,
      mapZoneErrors,
      ShopCalendarValidationSchema,
      setWoodCalendarValidationErrors,
      mapCalendarErrors,
      setFuelCalendarValidationErrors,
      setDieselCalendarValidationErrors,
    ],
  );

  return (
    <div className="container px-2">
      <div className="d-flex flex-column align-items-start px-3">
        <InputLabel htmlFor="zoneIdInput" validationError={!!zoneValidationErrors?.zoneId}>
          {t('AddZoneModal.ZoneId')}
        </InputLabel>
        {zoneValidationErrors?.zoneId && <ErrorMessage>{zoneValidationErrors.zoneId}</ErrorMessage>}
        <input
          id="zoneIdInput"
          type="text"
          className="form-control"
          value={zoneId}
          onChange={async (e) => {
            setZoneId(e.currentTarget.value);

            if (zoneValidationErrors !== undefined) {
              const zone: Zone = {
                name: zoneName,
                zoneId: Number(e.currentTarget.value),
                shopId: selectedShop.id,
                newsletter: newsletterSubscription === NewsletterSubscription.Yes ? true : false,
                fuelDisporegionId: selectedDisporegionFuel?.id,
                woodDisporegionId: selectedDisporegionWood?.id,
                dieselDisporegionId: selectedDisporegionDiesel?.id,
                products: assignedProducts.map<Product>((assignedProduct) => ({ id: assignedProduct.id })),
                postalCodes: postalCodes ?? [],
                woodCalendar: shopCalendars.woodCalendar as Disporegion,
                fuelCalendar: shopCalendars.fuelCalendar as Disporegion,
                dieselCalendar: shopCalendars.dieselCalendar as Disporegion,
              };

              await validateForm(zone, zone.woodCalendar, zone.fuelCalendar, zone.dieselCalendar);
            }
          }}
          style={{ marginBottom: '1em' }}
        />

        <InputLabel htmlFor="zoneNameInput" validationError={!!zoneValidationErrors?.name}>
          {t('AddZoneModal.ZoneName')}
        </InputLabel>
        {zoneValidationErrors?.name && <ErrorMessage>{zoneValidationErrors.name}</ErrorMessage>}
        <input
          id="zoneNameInput"
          type="text"
          className="form-control"
          value={zoneName}
          onChange={async (e) => {
            setZoneName(e.currentTarget.value);

            if (zoneValidationErrors !== undefined) {
              const zone: Zone = {
                name: e.currentTarget.value,
                zoneId: Number(zoneId),
                shopId: selectedShop.id,
                newsletter: newsletterSubscription === NewsletterSubscription.Yes ? true : false,
                fuelDisporegionId: selectedDisporegionFuel?.id,
                woodDisporegionId: selectedDisporegionWood?.id,
                dieselDisporegionId: selectedDisporegionDiesel?.id,
                products: assignedProducts.map<Product>((assignedProduct) => ({ id: assignedProduct.id })),
                postalCodes: postalCodes ?? [],
                woodCalendar: shopCalendars.woodCalendar as Disporegion,
                fuelCalendar: shopCalendars.fuelCalendar as Disporegion,
                dieselCalendar: shopCalendars.dieselCalendar as Disporegion,
              };

              await validateForm(zone, zone.woodCalendar, zone.fuelCalendar, zone.dieselCalendar);
            }
          }}
          style={{ marginBottom: '1em' }}
        />

        <InputLabel htmlFor="assignToShopDropdown" validationError={!!zoneValidationErrors?.shopId}>
          {t('AddZoneModal.AssignToShop')}
        </InputLabel>
        {zoneValidationErrors?.shopId && <ErrorMessage>{zoneValidationErrors.shopId}</ErrorMessage>}
        <DropDownList
          style={{ marginBottom: '1em', width: '50%' }}
          id="assignToShopDropdown"
          data={shops?.filter((shop) => shop.name !== AllShopsName)}
          value={selectedShop}
          textField="name"
          dataItemKey="id"
          onChange={(e) => setSelectedShop(e.target.value)}
        />

        <InputLabel htmlFor="disporegionFuel" validationError={!!zoneValidationErrors?.fuelDisporegionId}>
          {t('AddZoneModal.DisporegionFuel')}
        </InputLabel>
        {zoneValidationErrors?.fuelDisporegionId && (
          <ErrorMessage>{zoneValidationErrors.fuelDisporegionId}</ErrorMessage>
        )}
        <DropDownList
          style={{ marginBottom: '1em', width: '50%' }}
          id="disporegionFuel"
          data={fuelDisporegions}
          textField="name"
          dataItemKey="id"
          defaultItem={{
            id: undefined,
            name: '',
          }}
          value={selectedDisporegionFuel}
          onChange={async (e) => {
            setSelectedDisporegionFuel(e.target.value);

            if (zoneValidationErrors !== undefined) {
              const zone: Zone = {
                name: zoneName,
                zoneId: Number(zoneId),
                shopId: selectedShop.id,
                newsletter: newsletterSubscription === NewsletterSubscription.Yes ? true : false,
                fuelDisporegionId: (e.target.value as Partial<Disporegion>)?.id,
                woodDisporegionId: selectedDisporegionWood?.id,
                dieselDisporegionId: selectedDisporegionDiesel?.id,
                products: assignedProducts.map<Product>((assignedProduct) => ({ id: assignedProduct.id })),
                postalCodes: postalCodes ?? [],
                woodCalendar: shopCalendars.woodCalendar as Disporegion,
                fuelCalendar: shopCalendars.fuelCalendar as Disporegion,
                dieselCalendar: shopCalendars.dieselCalendar as Disporegion,
              };

              await validateForm(zone, zone.woodCalendar, zone.fuelCalendar, zone.dieselCalendar);
            }
          }}
        />

        {selectedDisporegionFuel?.id === 0 && (
          <CalendarSetting
            showWeeksDropdownValues={showWeeksData}
            showWeeks={shopCalendars.fuelCalendar?.showWeeks}
            deliveryWaitTimeDropdownValues={deliveryWaitTimeData}
            deliveryWaitTime={shopCalendars.fuelCalendar?.deliveryWaitTime}
            textDe={shopCalendars.fuelCalendar?.textDe}
            textFr={shopCalendars.fuelCalendar?.textFr}
            validationErrors={
              fuelCalendarValidationErrors && {
                deliveryWaitTime: fuelCalendarValidationErrors.deliveryWaitTime,
                showWeeks: fuelCalendarValidationErrors.showWeeks,
                textDe: fuelCalendarValidationErrors.textDe,
                textFr: fuelCalendarValidationErrors.textFr,
              }
            }
            onShowWeeksChange={(e) =>
              setShopCalendars({
                ...shopCalendars,
                fuelCalendar: { ...shopCalendars.fuelCalendar, showWeeks: e.target.value },
              })
            }
            onDeliveryWaitTimeChange={(e) =>
              setShopCalendars({
                ...shopCalendars,
                fuelCalendar: { ...shopCalendars.fuelCalendar, deliveryWaitTime: e.target.value },
              })
            }
            onTextDeChange={(e) =>
              setShopCalendars({
                ...shopCalendars,
                fuelCalendar: { ...shopCalendars.fuelCalendar, textDe: e.value },
              })
            }
            onTextFrChange={(e) =>
              setShopCalendars({
                ...shopCalendars,
                fuelCalendar: { ...shopCalendars.fuelCalendar, textFr: e.value },
              })
            }
          />
        )}

        <InputLabel htmlFor="disporegionDiesel" validationError={!!zoneValidationErrors?.dieselDisporegionId}>
          {t('AddZoneModal.DisporegionDiesel')}
        </InputLabel>
        {zoneValidationErrors?.dieselDisporegionId && (
          <ErrorMessage>{zoneValidationErrors.dieselDisporegionId}</ErrorMessage>
        )}
        <DropDownList
          style={{ marginBottom: '1em', width: '50%' }}
          id="disporegionDiesel"
          data={dieselDisporegions}
          textField="name"
          dataItemKey="id"
          defaultItem={{
            id: undefined,
            name: '',
          }}
          value={selectedDisporegionDiesel}
          onChange={async (e) => {
            setSelectedDisporegionDiesel(e.target.value);

            if (zoneValidationErrors !== undefined) {
              const zone: Zone = {
                name: zoneName,
                zoneId: Number(zoneId),
                shopId: selectedShop.id,
                newsletter: newsletterSubscription === NewsletterSubscription.Yes ? true : false,
                fuelDisporegionId: selectedDisporegionFuel?.id,
                woodDisporegionId: selectedDisporegionWood?.id,
                dieselDisporegionId: (e.target.value as Partial<Disporegion>)?.id,
                products: assignedProducts.map<Product>((assignedProduct) => ({ id: assignedProduct.id })),
                postalCodes: postalCodes ?? [],
                woodCalendar: shopCalendars.woodCalendar as Disporegion,
                fuelCalendar: shopCalendars.fuelCalendar as Disporegion,
                dieselCalendar: shopCalendars.dieselCalendar as Disporegion,
              };

              await validateForm(zone, zone.woodCalendar, zone.fuelCalendar, zone.dieselCalendar);
            }
          }}
        />

        {selectedDisporegionDiesel?.id === 0 && (
          <CalendarSetting
            showWeeksDropdownValues={showWeeksData}
            showWeeks={shopCalendars.dieselCalendar?.showWeeks}
            deliveryWaitTimeDropdownValues={deliveryWaitTimeData}
            deliveryWaitTime={shopCalendars.dieselCalendar?.deliveryWaitTime}
            textDe={shopCalendars.dieselCalendar?.textDe}
            textFr={shopCalendars.dieselCalendar?.textFr}
            validationErrors={
              dieselCalendarValidationErrors && {
                deliveryWaitTime: dieselCalendarValidationErrors.deliveryWaitTime,
                showWeeks: dieselCalendarValidationErrors.showWeeks,
                textDe: dieselCalendarValidationErrors.textDe,
                textFr: dieselCalendarValidationErrors.textFr,
              }
            }
            onShowWeeksChange={(e) =>
              setShopCalendars({
                ...shopCalendars,
                dieselCalendar: { ...shopCalendars.dieselCalendar, showWeeks: e.target.value },
              })
            }
            onDeliveryWaitTimeChange={(e) =>
              setShopCalendars({
                ...shopCalendars,
                dieselCalendar: { ...shopCalendars.dieselCalendar, deliveryWaitTime: e.target.value },
              })
            }
            onTextDeChange={(e) =>
              setShopCalendars({
                ...shopCalendars,
                dieselCalendar: { ...shopCalendars.dieselCalendar, textDe: e.value },
              })
            }
            onTextFrChange={(e) =>
              setShopCalendars({
                ...shopCalendars,
                dieselCalendar: { ...shopCalendars.dieselCalendar, textFr: e.value },
              })
            }
          />
        )}

        <InputLabel htmlFor="disporegionWood" validationError={!!zoneValidationErrors?.woodDisporegionId}>
          {t('AddZoneModal.DisporegionWood')}
        </InputLabel>
        {zoneValidationErrors?.woodDisporegionId && (
          <ErrorMessage>{zoneValidationErrors.woodDisporegionId}</ErrorMessage>
        )}
        <DropDownList
          style={{ marginBottom: '1em', width: '50%' }}
          id="disporegionWood"
          data={woodDisporegions}
          textField="name"
          dataItemKey="id"
          defaultItem={{
            id: undefined,
            name: '',
          }}
          value={selectedDisporegionWood}
          onChange={async (e) => {
            setSelectedDisporegionWood(e.target.value);

            if (zoneValidationErrors !== undefined) {
              const zone: Zone = {
                name: zoneName,
                zoneId: Number(zoneId),
                shopId: selectedShop.id,
                newsletter: newsletterSubscription === NewsletterSubscription.Yes ? true : false,
                fuelDisporegionId: selectedDisporegionFuel?.id,
                woodDisporegionId: (e.target.value as Partial<Disporegion>)?.id,
                dieselDisporegionId: selectedDisporegionDiesel?.id,
                products: assignedProducts.map<Product>((assignedProduct) => ({ id: assignedProduct.id })),
                postalCodes: postalCodes ?? [],
                woodCalendar: shopCalendars.woodCalendar as Disporegion,
                fuelCalendar: shopCalendars.fuelCalendar as Disporegion,
                dieselCalendar: shopCalendars.dieselCalendar as Disporegion,
              };

              await validateForm(zone, zone.woodCalendar, zone.fuelCalendar, zone.dieselCalendar);
            }
          }}
        />

        {selectedDisporegionWood?.id === 0 && (
          <CalendarSetting
            showWeeksDropdownValues={showWeeksData}
            showWeeks={shopCalendars.woodCalendar?.showWeeks}
            deliveryWaitTimeDropdownValues={deliveryWaitTimeData}
            deliveryWaitTime={shopCalendars.woodCalendar?.deliveryWaitTime}
            textDe={shopCalendars.woodCalendar?.textDe}
            textFr={shopCalendars.woodCalendar?.textFr}
            validationErrors={
              woodCalendarValidationErrors && {
                deliveryWaitTime: woodCalendarValidationErrors.deliveryWaitTime,
                showWeeks: woodCalendarValidationErrors.showWeeks,
                textDe: woodCalendarValidationErrors.textDe,
                textFr: woodCalendarValidationErrors.textFr,
              }
            }
            onShowWeeksChange={(e) =>
              setShopCalendars({
                ...shopCalendars,
                woodCalendar: { ...shopCalendars.woodCalendar, showWeeks: e.target.value },
              })
            }
            onDeliveryWaitTimeChange={(e) =>
              setShopCalendars({
                ...shopCalendars,
                woodCalendar: { ...shopCalendars.woodCalendar, deliveryWaitTime: e.target.value },
              })
            }
            onTextDeChange={(e) =>
              setShopCalendars({
                ...shopCalendars,
                woodCalendar: { ...shopCalendars.woodCalendar, textDe: e.value },
              })
            }
            onTextFrChange={(e) =>
              setShopCalendars({
                ...shopCalendars,
                woodCalendar: { ...shopCalendars.woodCalendar, textFr: e.value },
              })
            }
          />
        )}

        <div className="d-flex align-self-start align-items-center" style={{ marginBottom: '1em' }}>
          {t('AddZoneModal.NewsletterSubscription')}
          <ButtonGroup className="ms-3">
            {subscriptionPossibilities.map((possibility, i) => (
              <ToggleButton
                key={i}
                id={`radio-${i}`}
                type="radio"
                variant={newsletterSubscription === possibility ? 'primary' : 'light'}
                name="radio"
                value={possibility}
                checked={newsletterSubscription === possibility}
                onChange={(e) => setNewsletterSubscription(Number(e.currentTarget.value))}
              >
                {t(`CommonLabels.${NewsletterSubscription[possibility]}`)}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>

        <AssignZoneContainer style={{ marginBottom: '1em' }}>
          <InputLabel htmlFor="productAssigned" validationError={false}>
            {t('AddZoneModal.ProductAssigned')}
          </InputLabel>

          <ItemAssigner
            dropdownItems={products.map((product) => ({
              id: product.id!,
              name: language === 'de' ? product.nameDe! : product.nameFr!,
            }))}
            assignedItems={assignedProducts}
            setAssignedItems={(assignedItems) => setAssignedProducts(assignedItems)}
            selectedItem={selectedProduct}
            setSelectedItem={(selectedProduct) => setSelectedProduct(selectedProduct)}
            removeButtonLabel={t('AddZoneModal.Remove')}
          />
        </AssignZoneContainer>
      </div>

      <div className="modal-footer pb-0 pe-0">
        <button
          className="btn btn-primary"
          type="button"
          disabled={loading}
          onClick={async () => {
            try {
              const zone: Zone = {
                id: id,
                name: zoneName,
                zoneId: Number(zoneId),
                shopId: selectedShop.id,
                newsletter: newsletterSubscription === NewsletterSubscription.Yes ? true : false,
                fuelDisporegionId: selectedDisporegionFuel?.id,
                woodDisporegionId: selectedDisporegionWood?.id,
                dieselDisporegionId: selectedDisporegionDiesel?.id,
                products: assignedProducts.map<Product>((assignedProduct) => ({ id: assignedProduct.id })),
                postalCodes: postalCodes ?? [],
                woodCalendar:
                  selectedDisporegionWood?.id === 0 ? (shopCalendars.woodCalendar as Disporegion) : undefined,
                fuelCalendar:
                  selectedDisporegionFuel?.id === 0 ? (shopCalendars.fuelCalendar as Disporegion) : undefined,
                dieselCalendar:
                  selectedDisporegionDiesel?.id === 0 ? (shopCalendars.dieselCalendar as Disporegion) : undefined,
              };

              if (await validateForm(zone, zone.woodCalendar, zone.fuelCalendar, zone.dieselCalendar)) {
                setLoading(true);
                zone.fuelDisporegionId = zone.fuelDisporegionId || undefined;
                zone.woodDisporegionId = zone.woodDisporegionId || undefined;
                zone.dieselDisporegionId = zone.dieselDisporegionId || undefined;

                if (!update || id === 0) {
                  await zonesService.saveZone(zone);
                } else {
                  await zonesService.updateZone(zone);
                }
              } else {
                return;
              }

              setLoading(false);
              handleSave!();
            } catch (error) {
              console.error(error);

              setErrors([...errors, error as ErrorViewModel]);

              setLoading(false);
            }
          }}
        >
          {loading ? <Spinner /> : t('AddZoneModal.SaveZone')}
        </button>
      </div>
    </div>
  );
};

export default withModal(withErrorHandling(AddZoneForm));
