import i18n from '../i18n';

/**
 * Interface describing application wide configuration.
 */
export interface IAgrConfiguration {
  api: IApiConfiguration;
  auth: IAuthConfiguration;
  appInsightsInstrumentationKey: string;
  showRestoreAdministratorButton: string;
  portalIntegrationUrls: string[];
  customerUiEndpoint: string;
}

export interface IApiConfiguration {
  baseUrl: string;
  authCode: string;
}

export interface IAuthConfiguration {
  clientId: string;
  loginAuthority: string;
  resetAuthority: string;
  changeAuthority: string;
  generalScopes: string;
  apiScopes: string;
  redirectUri: string;
}

/**
 * This class implements IAgrConfiguration and initializes the portal integration urls
 * with its dedicated parser function.
 */
export class AgrConfiguration implements IAgrConfiguration {
  api: IApiConfiguration;
  auth: IAuthConfiguration;
  appInsightsInstrumentationKey: string;
  showRestoreAdministratorButton: string;
  portalIntegrationUrls: string[];
  customerUiEndpoint: string;

  constructor(
    api: IApiConfiguration,
    auth: IAuthConfiguration,
    appInsightsInstrumentationKey: string,
    showRestoreAdministratorButton: string,
    portalIntegrationUrls: string,
    customerUiEndpoint: string,
  ) {
    this.api = api;
    this.auth = auth;
    this.appInsightsInstrumentationKey = appInsightsInstrumentationKey;
    this.showRestoreAdministratorButton = showRestoreAdministratorButton;
    this.customerUiEndpoint = customerUiEndpoint;

    this.portalIntegrationUrls = this.parsePortalIntegrationUrls(portalIntegrationUrls);
  }

  /**
   *
   * Since the variable group for the deployment pipeline does not support the array type,
   * the urls input in the variable field are semi colon separated, hence the need for this
   * tiny parser.
   *
   * @param semiColonSeparatedUrls - The string with semi colon separated urls.
   *
   * @returns an array containing the urls.
   */
  private parsePortalIntegrationUrls(semiColonSeparatedUrls: string): string[] {
    const urlProductSuffixes = semiColonSeparatedUrls.split(';');
    const possibleLanguages = Object.keys(i18n.services.resourceStore.data).filter(language => language !== 'en');

    const baseUrl = this.customerUiEndpoint;

    const allAvailableUrls: string[] = [];

    for (const languageIndex in possibleLanguages) {
      for (const productIndex in urlProductSuffixes) {
        allAvailableUrls.push(`${baseUrl}/${possibleLanguages[languageIndex]}/${urlProductSuffixes[productIndex]}`);
      }
    }

    return allAvailableUrls;
  }
}
