import { WoodProductTypeName, DieselProductTypeName } from '@/assets/constants';
import { GetPriceLabel, ProductType, Promotion, PromotionType } from '@/models';

export const formatPromotionAsAmount = (promotion: Promotion, t: (k: string) => string) => {
  const formattedAmount = readAmountToDiscount(promotion).toFixed(2);

  if (
    promotion.promotionType === PromotionType.FixedAmount ||
    promotion.promotionType === PromotionType.FixedAmountWithCode ||
    promotion.promotionType === PromotionType.Coupon ||
    promotion.promotionType === PromotionType.CouponWithCode ||
    promotion.promotionType === PromotionType.Gift ||
    promotion.promotionType === PromotionType.GiftWithCode
  ) {
    return `${formattedAmount} CHF`;
  } else if (
    promotion.promotionType === PromotionType.Percentual ||
    promotion.promotionType === PromotionType.PercentualWithCode
  ) {
    return `${formattedAmount} %`;
  } else {
    return `${formattedAmount} ${GetPriceLabel(t, promotion.unitCode)}`;
  }
};

/**
 * Computes a translated string representing the promotion type.
 * @param t the i8n translation object.
 * @param promotionType The value of the enumeration of the promotion type.
 * @returns A translated string representing the promotinos type.
 */
export const getPromotionTypeName = (t: (arg: string) => '', promotionType: PromotionType): string => {
  switch (promotionType) {
    case PromotionType.AmountByQuantity:
      return t('PromotionType.AmountByQuantity');
    case PromotionType.AmountByQuantityWithCode:
      return t('PromotionType.AmountByQuantityWithCode');
    case PromotionType.FixedAmount:
      return t('PromotionType.FixedAmount');
    case PromotionType.FixedAmountWithCode:
      return t('PromotionType.FixedAmountWithCode');
    case PromotionType.Coupon:
      return t('PromotionType.Coupon');
    case PromotionType.CouponWithCode:
      return t('PromotionType.CouponWithCode');
    case PromotionType.FinalFixedPrice:
      return t('PromotionType.FinalFixedPrice');
    case PromotionType.FinalFixedPriceWithCode:
      return t('PromotionType.FinalFixedPriceWithCode');
    case PromotionType.Percentual:
      return t('PromotionType.Percentual');
    case PromotionType.PercentualWithCode:
      return t('PromotionType.PercentualWithCode');
    case PromotionType.Gift:
      return t('PromotionType.Gift');
    case PromotionType.GiftWithCode:
      return t('PromotionType.GiftWithCode');
    default:
      throw 'Invalid promotionType: ' + promotionType;
  }
};

export const readAmountToDiscount = (promotion: Promotion): number => {
  if (
    promotion.promotionType == PromotionType.Percentual ||
    promotion.promotionType == PromotionType.PercentualWithCode
  ) {
    return promotion.percentual;
  }

  return promotion.chfAmount;
};

export const readMinimalQuantity = (promotion: Promotion): number => {
  if (!promotion.productType) {
    console.warn("Couldn't determine the product type for the conversion. Returning the base value...");

    return promotion.minimalQuantity;
  }

  return isWood(promotion.productType)
    ? promotion.minimalQuantity * 1000
    : isDiesel(promotion.productType)
    ? promotion.minimalQuantity * 100
    : promotion.minimalQuantity * 100;
};

export const readMaximumQuantity = (promotion: Promotion): number => {
  if (!promotion.productType) {
    console.warn("Couldn't determine the product type for the conversion. Returning the base value...");

    return promotion.maximumQuantity;
  }

  return isWood(promotion.productType)
    ? promotion.maximumQuantity * 1000
    : isDiesel(promotion.productType)
    ? promotion.maximumQuantity * 100
    : promotion.maximumQuantity * 100;
};

const isWood = (productType: ProductType) => productType.productName === WoodProductTypeName;
const isDiesel = (productType: ProductType) => productType.productName === DieselProductTypeName;
