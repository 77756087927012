import React from 'react';

export default function mapEnum(
  enumerable: { [key: number]: string },
  fn: (val: number, index: number) => React.ReactNode,
) {
  return Object.keys(enumerable)
    .filter((val) => !isNaN(Number(val)))
    .map((val, index) => fn(+val, index));
}
