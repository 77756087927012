import { createContext, Dispatch, SetStateAction } from 'react';
import { OrderStatus } from '../../../models';

export interface IViewOrdersContextValues {
  newStatus: OrderStatus | null | undefined;
  setNewStatus: Dispatch<SetStateAction<OrderStatus | null | undefined>>;
  previousStatus: OrderStatus | null | undefined;
  setPreviousStatus: Dispatch<SetStateAction<OrderStatus | null | undefined>>;
}

/**
 * The context that is shared between the ViewOrders, EditOrderForm and StatusChangeNotificationForm components.
 */
const EditOrderContext = createContext<IViewOrdersContextValues | undefined>(undefined);

export default EditOrderContext;
