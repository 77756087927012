import { Language } from '.';

/**
 * Represents a notification subscribed by an user, either a price alert or a price desired.
 */
export interface Alert {
  id: number;

  subscriptionType: SubscriptionType | string;

  /**
   * The translated representation of the subscriptionType.
   */
  subscriptionTypeName?: string | null;

  postalCode: string;

  deliveryDifficultyFee: string;

  productName: string;

  productId: number;

  quantity: number;

  unloadingPlaces: number;

  priceValidUntil: Date | null;

  interval?: number;

  portal: string;

  subscriberEmail: string;

  historicPrice: number;

  desiredPrice: string;

  subScriptionDate: Date | null;

  lastNotificationDate: Date | null;

  isExpired: boolean;

  subscriptionPeriodFormatted: string | null;

  shopContact: string;

  language: Language;

  currentPrice: number;
}

/**
 * @param subscriptionType Type guard for ErrorViewModel
 */
export function isSubscriptionType(subscriptionType: SubscriptionType | any): subscriptionType is SubscriptionType {
  return (
    (subscriptionType as SubscriptionType) === SubscriptionType.PriceDesired ||
    (subscriptionType as SubscriptionType) === SubscriptionType.PriceAlarm ||
    (subscriptionType as SubscriptionType) === SubscriptionType.PriceSubscription ||
    (subscriptionType as SubscriptionType) === SubscriptionType.Newsletter
  );
}

/**
 * Computes a translated string representing the subscription type.
 * @param t the i8n translation object.
 * @param subscriptionType The value of the enumeration of the subscription type.
 * @returns A translated string representing the subscription type.
 */
export function GetSubscriptionTypeName(
  t: (arg: string) => '',
  subscriptionType: SubscriptionType | string | null | undefined,
): string {
  if (isSubscriptionType(subscriptionType)) {
    switch (subscriptionType) {
      case SubscriptionType.PriceAlarm:
        return t('SubscriptionType.PriceAlarm');
      case SubscriptionType.PriceDesired:
        return t('SubscriptionType.PriceDesired');
      case SubscriptionType.PriceSubscription:
        return t('SubscriptionType.PriceSubscription');
      case SubscriptionType.Newsletter:
        return t('SubscriptionType.Newsletter');
      default:
        throw 'Invalid status: ' + subscriptionType;
    }
  }
  return '';
}

export enum SubscriptionType {
  PriceAlarm,
  PriceDesired,
  PriceSubscription,
  Newsletter,
}
