import { object, string } from 'yup';

export function getMailTemplateUpdateModelValidationSchema(t: any) {
  const schema = object().shape({
    sendGridId: string()
      .matches(/^d-[0-9a-f]{32}$/, `${t('EditMailTemplate.ValidationErrors.SendGridId')}`)
      .required(`${t('EditMailTemplate.ValidationErrors.SendGridId')}`),
    sendGridLink: string(),
    senderMail: string().email(`${t('EditMailTemplate.ValidationErrors.SenderMail')}`),
    senderName: string(),
  });
  return schema;
}

export function getMailTemplateSendMailModelValidationSchema(t: any) {
  const schema = object().shape({
    receiverMail: string()
      .email(`${t('EditMailTemplate.ValidationErrors.ReceiverMail')}`)
      .required(`${t('EditMailTemplate.ValidationErrors.ReceiverMail')}`),
  });
  return schema;
}
