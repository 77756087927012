import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';

import de from './assets/i18n/translations/de.json';
import fr from './assets/i18n/translations/fr.json';
import en from './assets/i18n/translations/en.json';

const translations = { ...de, ...fr, ...en };

i18n
  .use(languageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    resources: translations,
    fallbackLng: 'de',
    debug: true,
    keySeparator: '.', // Used to access nested translation keys for better readability in translation file.

    interpolation: {
      escapeValue: false, // not needed for react
    },
  });

export default i18n;
