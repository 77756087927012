import { useTranslation } from 'react-i18next';

interface PrintButtonProps {
  onClick: () => void;
}

export default function PrintButton({ onClick }: PrintButtonProps): JSX.Element | null {
  const { t } = useTranslation();

  return (
    <button className="btn btn-primary btn-sm" type="button" onClick={onClick}>
      <span>{t('Views.PrintButton')}</span>
    </button>
  );
}
