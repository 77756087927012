import { object, string, number } from 'yup';
import { Constants } from '../../../../constants/Constants';

/**
 * This file exports the function in order to get the validation schema
 * with the appropriate translation at runtime.
 *
 * @param t - The translation function as provided by the useTranslation
 *            i18next hook.
 *
 * @returns the validation schema with the appropriate translations.
 */
export default function getShopFormValidationSchema(t: any) {
  const requiredErrorSuffix = t('ValidationErrors.Required');
  const maxLengthTextBoxErrorSuffix = t('ValidationErrors.MaxLengthTextbox');
  const maxLengthTextAreaErrorSuffix = t('ValidationErrors.MaxLengthTextarea');

  const schema = object().shape({
    name: string()
      .max(
        Constants.Validation.TextMaxLength,
        `${t('AddShopModal.ValidationErrors.Name')} ${maxLengthTextBoxErrorSuffix}`,
      )
      .required(`${t('AddShopModal.ValidationErrors.Name')} ${requiredErrorSuffix}.`),
    holdingId: number()
      .nullable()
      .required(`${t('AddShopModal.ValidationErrors.Holding')} ${requiredErrorSuffix}.`),
    shopTypeId: number()
      .nullable()
      .required(`${t('AddShopModal.ValidationErrors.ShopType')} ${requiredErrorSuffix}.`),
    address: string()
      .max(
        Constants.Validation.TextareaMaxLength,
        `${t('AddShopModal.ValidationErrors.Address')} ${maxLengthTextAreaErrorSuffix}`,
      )
      .required(`${t('AddShopModal.ValidationErrors.Address')}`),
    emailAddress: string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('AddShopModal.ValidationErrors.EmailAddressNotValid'),
      )
      .required(`${t('AddShopModal.ValidationErrors.EmailAddress')}`),
    showWeeks: number().min(1).max(12),
    deliveryWaitTime: number().min(0).max(6),
  });

  return schema;
}
