import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { ZonesService } from '../services/ZonesService';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * ZonesService to be used in components to make API calls.
 *
 * @returns a ZonesService instance
 */
export default function useZonesService(): ZonesService {
  const config = useContext(AgrConfigurationContext);

  return useMemo(() => new ZonesService(config), [config]);
}
