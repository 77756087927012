import { useTranslation } from 'react-i18next';
import withModal from '@/components/hoc/with-modal/withModal';
import { Pricelog } from '@/models/Pricelog';
import usePricelogService from '../../../../../hooks/usePricelogService';
import { useCallback, useEffect, useState } from 'react';

export interface IPricelogDetailsProps {
  id: number | undefined;
}

const PricelogDetails = ({ id }: IPricelogDetailsProps) => {
  const { t } = useTranslation();
  const service = usePricelogService();
  const [pricelog, setPricelog] = useState<Pricelog | undefined>(undefined);

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const result = await service.getById(id ?? 0);
      setPricelog(result);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [id]);

  return (
    <>
      <div className="d-flex flex-row">
        <div className="col-md-4 p-2">
          <span>{t('PricelogDetailsView.Request')}:</span>
        </div>
        <div className="col-md-8 p-2">
          <pre>{JSON.stringify(JSON.parse(pricelog?.request ?? '{}'), null, 2)}</pre>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col-md-4 p-2">
          <span>{t('PricelogDetailsView.Response')}:</span>
        </div>
        <div className="col-md-8 p-2">
          <pre>{JSON.stringify(JSON.parse(pricelog?.response ?? '{}'), null, 2)}</pre>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col-md-4 p-2">
          <span>{t('PricelogDetailsView.Margin')}:</span>
        </div>
        <div className="col-md-8 p-2">
          <span>{pricelog?.order?.orderData?.priceOffer?.totalMargin}</span>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col-md-12 p-2">
          <span style={{ fontWeight: 'bold' }}>{t('PricelogDetailsView.OrderDetails')}:</span>
        </div>
      </div>
      <div style={{ borderTop: '1px solid #ddd', paddingTop: 0.5 }} />
      <div className="d-flex flex-row">
        <div className="col-md-4 p-2">
          <span>{t('PricelogDetailsView.OrderNumber')}:</span>
        </div>
        <div className="col-md-8 p-2">
          <span>{pricelog?.order?.id}</span>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col-md-4 p-2">
          <span>{t('PricelogDetailsView.Unloadingfee')}:</span>
        </div>
        <div className="col-md-8 p-2">
          <span>{pricelog?.order?.orderData?.priceOffer?.unloadingFee}</span>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col-md-4 p-2">
          <span>{t('PricelogDetailsView.UnloadingPlacesCount')}:</span>
        </div>
        <div className="col-md-8 p-2">
          <span>{pricelog?.order?.orderData?.priceOffer?.unloadingPlacesCount}</span>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col-md-4 p-2">
          <span>{t('PricelogDetailsView.Quantity')}:</span>
        </div>
        <div className="col-md-8 p-2">
          <span>{pricelog?.order?.orderData?.quantity}</span>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col-md-4 p-2">
          <span>{t('PricelogDetailsView.Discount')}:</span>
        </div>
        <div className="col-md-8 p-2">
          <span>{pricelog?.order?.orderData?.priceOffer?.totalDiscount}</span>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col-md-4 p-2">
          <span>{t('PricelogDetailsView.CalculatedPrice')}:</span>
        </div>
        <div className="col-md-8 p-2">
          <span>
            {pricelog?.order?.orderData?.priceOffer?.totalPrice} ({t('OrderDetail.WithVat')})
          </span>
        </div>
      </div>
    </>
  );
};

export default withModal(PricelogDetails);
