import { useContext, useMemo } from 'react';
import { MailTemplatesService } from '../services/MailTemplatesService';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';

/**
 * Wraps the MailTemplatesService in a useMemo call.
 * @param baseUrl the base url of the service
 * @returns the memoized UsersService
 */
export default function useMailTemplatesService(): MailTemplatesService {
  const config = useContext(AgrConfigurationContext);

  const mailService = useMemo(() => {
    return new MailTemplatesService(config);
  }, [config]);

  return mailService;
}
