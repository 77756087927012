import useApplicationState from './useApplicationState';

/**
 * determines if te current user is a readOnyy user.
 */
export default function useIsReadOnly(): boolean {
  const { isReadOnly } = useApplicationState();

  return isReadOnly;
}
