import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { TextArea } from '@progress/kendo-react-inputs';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import styled from 'styled-components';

import { Constants } from '../../../../../constants/Constants';
import { ErrorViewModel, Setting, isErrorViewModel } from '../../../../../models';
import useSettingService from '../../../../../hooks/useSettingService';
import useErrorHandling from '../../../../../hooks/useErrorHandling';
import Spinner from '../../../../common/spinner/Spinner';

/**
 *
 * This styled component is for adding the pointer
 * when I hover on the tab nav item.
 *
 */
const NavItemHoverPointer = styled(Nav.Item)`
  &:hover {
    cursor: pointer;
  }
`;

/**
 *
 * These are the styles for the tab content area.
 *
 */
const BorderedTabContent = styled(Tab.Content).attrs(() => ({
  className: 'container',
}))<{}>`
  @media (max-width: 576px) {
    border: 1px solid #dee2e6;
  }
`;

/**
 *
 * These are the styles for the tab pills.
 * I decided to make them custom in order to have a more
 * appropriate mobile version of them.
 *
 */
const NavPill = styled(Nav.Link)`
  @media (max-width: 576px) {
    border-radius: 0.5rem 0.5rem 0 0 !important;
    padding: 0.25rem 0.75rem;
  }

  @media (min-width: 576px) {
    border-radius: 0.5rem 0 0 0.5rem !important;
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;
  }
`;

interface ShopSettingData {
  StatusWood: boolean;
  StatusFuel: boolean;
  StatusDiesel: boolean;
  MessageWoodDE: string;
  MessageWoodFR: string;
  MessageFuelDE: string;
  MessageFuelFR: string;
  MessageDieselDE: string;
  MessageDieselFR: string;
}

/**
 *
 * The props for the ShopSettings Component.
 *
 */
interface ShopSettingsProps {
  shopSetting: Setting | undefined;
}

/**
 *
 * This Component handles the Settings of the Shops.
 *
 * @returns the Component to be used in JSXs.
 */
export default function ShopSettings({ shopSetting }: ShopSettingsProps): JSX.Element | null {
  const [shopSettingData, setShopSettingData] = useState<ShopSettingData>();

  const [woodOnOff, setWoodOnOff] = useState<boolean>(false);

  const [fuelOnOff, setFuelOnOff] = useState<boolean>(false);

  const [dieselOnOff, setDieselOnOff] = useState<boolean>(false);

  const [activeTabKeyWood, setActiveTabKeyWood] = useState<string>('first');

  const [activeTabKeyFuel, setActiveTabKeyFuel] = useState<string>('first');

  const [activeTabKeyDiesel, setActiveTabKeyDiesel] = useState<string>('first');

  const [loading, setLoading] = useState<boolean>(false);

  const [updatedWoodMessageDE, setUpdatedWoodMessageDE] = useState<string | undefined>('');

  const [updatedWoodMessageFR, setUpdatedWoodMessageFR] = useState<string | undefined>('');

  const [updatedFuelMessageDE, setUpdatedFuelMessageDE] = useState<string | undefined>('');

  const [updatedFuelMessageFR, setUpdatedFuelMessageFR] = useState<string | undefined>('');

  const [updatedDieselMessageDE, setUpdatedDieselMessageDE] = useState<string | undefined>('');

  const [updatedDieselMessageFR, setUpdatedDieselMessageFR] = useState<string | undefined>('');

  const { errors, setErrors } = useErrorHandling();

  const settingService = useSettingService();

  const { t } = useTranslation('translation', { keyPrefix: 'EditSettings' });

  useEffect(() => {
    async function initializeData() {
      if (shopSetting) {
        try {
          if (!shopSetting.settingData) {
            throw new Error('No Setting data for this Setting found.');
          }
          const settingData: ShopSettingData = JSON.parse(shopSetting?.settingData);
          setShopSettingData(settingData);
          setWoodOnOff(settingData.StatusWood);
          setFuelOnOff(settingData.StatusFuel);
          setDieselOnOff(settingData.StatusDiesel);
          setUpdatedWoodMessageDE(settingData.MessageWoodDE);
          setUpdatedWoodMessageFR(settingData.MessageWoodFR);
          setUpdatedFuelMessageDE(settingData.MessageFuelDE);
          setUpdatedFuelMessageFR(settingData.MessageFuelFR);
          setUpdatedDieselMessageDE(settingData.MessageDieselDE);
          setUpdatedDieselMessageFR(settingData.MessageDieselFR);
        } catch (error) {
          if (isErrorViewModel(error)) {
            setErrors([...errors, error as ErrorViewModel]);
          } else {
            console.error(error);
          }
        }
      }
    }
    initializeData();
  }, [
    setShopSettingData,
    setWoodOnOff,
    setFuelOnOff,
    setDieselOnOff,
    setUpdatedWoodMessageDE,
    setUpdatedWoodMessageFR,
    setUpdatedFuelMessageDE,
    setUpdatedFuelMessageFR,
    setUpdatedDieselMessageDE,
    setUpdatedDieselMessageFR,
    shopSetting,
    setErrors,
    errors,
  ]);

  return (
    <div className="d-flex flex-column bg-light mb-2">
      <div className="d-flex flex-row mb-12">
        <div className="p-2 text-success h5">{t('SubTitle')}</div>
      </div>
      <div className="d-flex flex-row bd-highlight mb-12">
        <div className="col-md-2 p-2">
          <span>{t('WoodOnOff')}</span>
        </div>
        <div className="col-md-5 p-2">
          <ButtonGroup className="align-self-start">
            <ToggleButton
              id="woodOnOff-false"
              name="woodOnOff"
              type="radio"
              value="false"
              variant={woodOnOff === false ? 'primary' : 'light'}
              checked={woodOnOff === false}
              onChange={(e) => setWoodOnOff(e.currentTarget.value === 'false' ? false : true)}
            >
              {t('Off')}
            </ToggleButton>
            <ToggleButton
              id="woodOnOff-true"
              name="woodOnOff"
              type="radio"
              value="true"
              variant={woodOnOff === true ? 'primary' : 'light'}
              checked={woodOnOff === true}
              onChange={(e) => setWoodOnOff(e.currentTarget.value === 'false' ? false : true)}
            >
              {t('On')}
            </ToggleButton>
          </ButtonGroup>
        </div>
      </div>
      <div className="d-flex flex-row bd-highlight mb-12">
        <div className="col-md-12 p-2">
          <Tab.Container activeKey={activeTabKeyWood} onSelect={(key) => setActiveTabKeyWood(key ?? 'first')}>
            <div className="d-flex flex-column flex-sm-row">
              <Nav variant="pills" className="mt-0 mt-sm-4 flex-row flex-sm-column">
                <NavItemHoverPointer>
                  <NavPill eventKey="first">{t('German')}</NavPill>
                </NavItemHoverPointer>
                <NavItemHoverPointer>
                  <NavPill eventKey="second">{t('French')}</NavPill>
                </NavItemHoverPointer>
              </Nav>
              <BorderedTabContent className="border border-bottom-1" style={{ minHeight: '20vh' }}>
                <Tab.Pane eventKey="first" className="h-100">
                  <TextArea
                    style={{ marginBottom: '0em', width: '100%' }}
                    id="woodGermanTextArea"
                    value={updatedWoodMessageDE}
                    onChange={async (e) => setUpdatedWoodMessageDE(e.value)}
                    rows={8}
                  ></TextArea>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className="h-100">
                  <TextArea
                    style={{ marginBottom: '0em', width: '100%' }}
                    id="woodFrenchTextArea"
                    value={updatedWoodMessageFR}
                    onChange={async (e) => setUpdatedWoodMessageFR(e.value)}
                    rows={8}
                  ></TextArea>
                </Tab.Pane>
              </BorderedTabContent>
            </div>
          </Tab.Container>
        </div>
      </div>
      <div className="d-flex flex-row bd-highlight mb-12">
        <div className="col-md-2 p-2">
          <span>{t('FuelOnOff')}</span>
        </div>
        <div className="col-md-5 p-2">
          <ButtonGroup className="align-self-start">
            <ToggleButton
              id="fuelOnOff-false"
              name="fuelOnOff"
              type="radio"
              value="false"
              variant={fuelOnOff === false ? 'primary' : 'light'}
              checked={fuelOnOff === false}
              onChange={(e) => setFuelOnOff(e.currentTarget.value === 'false' ? false : true)}
            >
              {t('Off')}
            </ToggleButton>
            <ToggleButton
              id="fuelOnOff-true"
              name="fuelOnOff"
              type="radio"
              value="true"
              variant={fuelOnOff === true ? 'primary' : 'light'}
              checked={fuelOnOff === true}
              onChange={(e) => setFuelOnOff(e.currentTarget.value === 'false' ? false : true)}
            >
              {t('On')}
            </ToggleButton>
          </ButtonGroup>
        </div>
      </div>
      <div className="d-flex flex-row bd-highlight mb-12">
        <div className="col-md-12 p-2">
          <Tab.Container activeKey={activeTabKeyFuel} onSelect={(key) => setActiveTabKeyFuel(key ?? 'first')}>
            <div className="d-flex flex-column flex-sm-row">
              <Nav variant="pills" className="mt-0 mt-sm-4 flex-row flex-sm-column">
                <NavItemHoverPointer>
                  <NavPill eventKey="first">{t('German')}</NavPill>
                </NavItemHoverPointer>
                <NavItemHoverPointer>
                  <NavPill eventKey="second">{t('French')}</NavPill>
                </NavItemHoverPointer>
              </Nav>
              <BorderedTabContent className="border border-bottom-1" style={{ minHeight: '20vh' }}>
                <Tab.Pane eventKey="first" className="h-100">
                  <TextArea
                    style={{ marginBottom: '0em', width: '100%' }}
                    id="fuelGermanTextArea"
                    value={updatedFuelMessageDE}
                    onChange={async (e) => setUpdatedFuelMessageDE(e.value)}
                    rows={8}
                  ></TextArea>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className="h-100">
                  <TextArea
                    style={{ marginBottom: '0em', width: '100%' }}
                    id="fuelFrenchTextArea"
                    value={updatedFuelMessageFR}
                    onChange={async (e) => setUpdatedFuelMessageFR(e.value)}
                    rows={8}
                  ></TextArea>
                </Tab.Pane>
              </BorderedTabContent>
            </div>
          </Tab.Container>
        </div>
      </div>
      <div className="d-flex flex-row bd-highlight mb-12">
        <div className="col-md-2 p-2">
          <span>{t('DieselOnOff')}</span>
        </div>
        <div className="col-md-5 p-2">
          <ButtonGroup className="align-self-start">
            <ToggleButton
              id="dieselOnOff-false"
              name="dieselOnOff"
              type="radio"
              value="false"
              variant={dieselOnOff === false ? 'primary' : 'light'}
              checked={dieselOnOff === false}
              onChange={(e) => setDieselOnOff(e.currentTarget.value === 'false' ? false : true)}
            >
              {t('Off')}
            </ToggleButton>
            <ToggleButton
              id="dieselOnOff-true"
              name="dieselOnOff"
              type="radio"
              value="true"
              variant={dieselOnOff === true ? 'primary' : 'light'}
              checked={dieselOnOff === true}
              onChange={(e) => setDieselOnOff(e.currentTarget.value === 'false' ? false : true)}
            >
              {t('On')}
            </ToggleButton>
          </ButtonGroup>
        </div>
      </div>
      <div className="d-flex flex-row bd-highlight mb-12">
        <div className="col-md-12 p-2">
          <Tab.Container activeKey={activeTabKeyDiesel} onSelect={(key) => setActiveTabKeyDiesel(key ?? 'first')}>
            <div className="d-flex flex-column flex-sm-row">
              <Nav variant="pills" className="mt-0 mt-sm-4 flex-row flex-sm-column">
                <NavItemHoverPointer>
                  <NavPill eventKey="first">{t('German')}</NavPill>
                </NavItemHoverPointer>
                <NavItemHoverPointer>
                  <NavPill eventKey="second">{t('French')}</NavPill>
                </NavItemHoverPointer>
              </Nav>
              <BorderedTabContent className="border border-bottom-1" style={{ minHeight: '20vh' }}>
                <Tab.Pane eventKey="first" className="h-100">
                  <TextArea
                    style={{ marginBottom: '0em', width: '100%' }}
                    id="dieselGermanTextArea"
                    value={updatedDieselMessageDE}
                    onChange={async (e) => setUpdatedDieselMessageDE(e.value)}
                    rows={8}
                  ></TextArea>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className="h-100">
                  <TextArea
                    style={{ marginBottom: '0em', width: '100%' }}
                    id="dieselFrenchTextArea"
                    value={updatedDieselMessageFR}
                    onChange={async (e) => setUpdatedDieselMessageFR(e.value)}
                    rows={8}
                  ></TextArea>
                </Tab.Pane>
              </BorderedTabContent>
            </div>
          </Tab.Container>
        </div>
      </div>
      <div className="modal-footer pb-0 pe-0">
        <button
          className="btn btn-primary"
          type="button"
          disabled={loading}
          onClick={async () => {
            try {
              setLoading(true);
              if (shopSetting == undefined) {
                return;
              }
              const data: ShopSettingData = {
                StatusWood: woodOnOff,
                StatusFuel: fuelOnOff,
                StatusDiesel: dieselOnOff,
                MessageWoodDE: updatedWoodMessageDE ? updatedWoodMessageDE : '',
                MessageWoodFR: updatedWoodMessageFR ? updatedWoodMessageFR : '',
                MessageFuelDE: updatedFuelMessageDE ? updatedFuelMessageDE : '',
                MessageFuelFR: updatedFuelMessageFR ? updatedFuelMessageFR : '',
                MessageDieselDE: updatedDieselMessageDE ? updatedDieselMessageDE : '',
                MessageDieselFR: updatedDieselMessageFR ? updatedDieselMessageFR : '',
              };

              const settingDataString = JSON.stringify(data);
              const id = shopSetting.id ? shopSetting?.id : 1;
              const name = shopSetting.settingName ? shopSetting.settingName : Constants.SettingTypes.ShopSettings;
              const settingForUpdate: Setting = {
                id,
                settingName: name,
                settingData: settingDataString,
                settingValueType: shopSetting?.settingValueType,
              };
              await settingService.update(settingForUpdate);
            } catch (error) {
              console.error(error);
              setErrors([...errors, error as ErrorViewModel]);
            } finally {
              setLoading(false);
            }
          }}
        >
          {loading ? <Spinner /> : t('SaveShopSetting')}
        </button>
      </div>
    </div>
  );
}
