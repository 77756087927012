import { useTranslation } from 'react-i18next';
import { boolean, string } from 'yup';

export interface ILoadMessageProps {
  keyMessage: string | null;
  showSpinner: boolean | null;
}

/**
 * The component that is displayed during the authentication flow.
 */
export const LoadMessage = ({ keyMessage, showSpinner }: ILoadMessageProps): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
        {showSpinner ?? <div className="spinner-border text-primary" role="status"></div>}
        <h1 className="text-primary">{keyMessage ? t(keyMessage) : t('Loading')}</h1>
      </div>
    </>
  );
};
