import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TextArea } from '@progress/kendo-react-inputs';
import styled from 'styled-components';

import useOrderService from '../../../../hooks/useOrderService';
import useModal from '../../../../hooks/useModal';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import withModal from '../../../hoc/with-modal/withModal';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import { YupError } from '../../../../hooks/useYupErrors';

import { ErrorViewModel, Order, OrderStatus, isErrorViewModel } from '../../../../models';

import { DropDownList } from '@progress/kendo-react-dropdowns';
import OrderDetails from './order-details/OrderDetails';
import EditOrderContext, { IViewOrdersContextValues } from '../OrderContext';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import Spinner from '../../../common/spinner/Spinner';

interface InputLabelProps {
  validationError: boolean;
}

/**
 * Base style for labels in this from.
 */
const InputLabel = styled.label<InputLabelProps>`
  margin-bottom: ${({ validationError }) => (validationError ? '0' : '0.5em')};
`;

const ErrorMessage = styled.span.attrs(() => ({ className: 'text-danger' }))<{}>`
  margin-bottom: 0.25em;
`;

/**
 * The interface for the error messages
 */
export interface IOrderError {
  ourComment: string;
  clientComment: string;
  orderStatus: OrderStatus;
  internalRemarks: string;
}

export interface IOrderDetailsProps {
  order: Order | null | undefined;
}

/**
 * Allows editing the status of the order, then the internal and external comments.
 * @param param0 the input of the order edit form.
 * @returns The form component.
 */
const EditOrderForm = ({ order }: IOrderDetailsProps) => {
  const { t } = useTranslation();

  const { handleSave } = useModal();

  const language = useCurrentLanguage();

  const { errors, setErrors } = useErrorHandling();

  const orderService = useOrderService();

  const [loading, setLoading] = useState<boolean>(false);

  const [orderId, setOrderId] = useState<number | null | undefined>(order?.id);

  const [updatedOrderStatus, setUpdatedOrderStatus] = useState<OrderStatus | null | undefined>(order?.status);

  const [ddlOrderStatus, setDdlOrderStatus] = useState<{ id: number; text: string }>();

  const [updatedOurComment, setUpdatedOurComment] = useState<string | undefined>(order?.ourComment);

  const [updatedInternalRemarks, setUpdatedInternalRemarks] = useState<string | undefined>(order?.internalRemarks);

  const [validationErrors, setValidationErrors] = useState<YupError<IOrderError> | null>();

  const { setPreviousStatus, setNewStatus } = useContext(EditOrderContext)!;

  /**
   * Stores the previous value of the order status in the context.
   */
  useEffect(() => {
    setPreviousStatus(order?.status);
  }, []);

  const orderStatusData = [
    { id: OrderStatus.Open, text: t('OrderStatus.Open') },
    { id: OrderStatus.Delivering, text: t('OrderStatus.Delivering') },
    { id: OrderStatus.Delivered, text: t('OrderStatus.Delivered') },
    { id: OrderStatus.Canceled, text: t('OrderStatus.Canceled') },
    { id: OrderStatus.Failed, text: t('OrderStatus.Failed') },
  ];

  useEffect(() => {
    if (updatedOrderStatus != undefined && updatedOrderStatus != null) {
      setDdlOrderStatus({ id: updatedOrderStatus, text: t('OrderStatus.' + OrderStatus[updatedOrderStatus]) });
    }
  }, [updatedOrderStatus, setUpdatedOrderStatus]);

  return (
    <div className="container px-2">
      <div className="d-flex flex-column align-items-start px-3">
        <OrderDetails order={order}></OrderDetails>
        <InputLabel htmlFor="orderStatusDdl" validationError={!!validationErrors?.orderStatus}>
          {t('OrdersView.ColumnStatus')}
        </InputLabel>

        <DropDownList
          id="orderStatusDdl"
          style={{ marginBottom: '1em', width: '100%' }}
          data={orderStatusData}
          value={ddlOrderStatus}
          textField="text"
          dataItemKey="id"
          onChange={(e) => {
            setUpdatedOrderStatus(e.value.id);
            setNewStatus(e.value.id);
          }}
        />

        <InputLabel htmlFor="internalCommentTxt" validationError={!!validationErrors?.internalRemarks}>
          {t('EditOrderModal.InternalRemarks')}
        </InputLabel>
        {validationErrors?.internalRemarks && <ErrorMessage>{validationErrors.internalRemarks}</ErrorMessage>}
        <TextArea
          style={{ marginBottom: '1em', width: '100%' }}
          id="internalCommentTxt"
          value={updatedInternalRemarks}
          onChange={async (e) => setUpdatedInternalRemarks(e.value)}
          rows={8}
        ></TextArea>

        <InputLabel htmlFor="ourCommentsTxt" validationError={!!validationErrors?.ourComment}>
          {t('EditOrderModal.OurComment')}
        </InputLabel>
        {validationErrors?.ourComment && <ErrorMessage>{validationErrors.ourComment}</ErrorMessage>}
        <TextArea
          style={{ marginBottom: '1em', width: '100%' }}
          id="ourCommentsTxt"
          value={updatedOurComment}
          onChange={async (e) => setUpdatedOurComment(e.value)}
          rows={8}
        ></TextArea>
      </div>

      <div className="modal-footer pb-0 pe-0">
        <button
          className="btn btn-primary"
          type="button"
          disabled={loading}
          onClick={async () => {
            try {
              setLoading(true);
              await orderService.updateOrderStatus(
                orderId,
                updatedOrderStatus,
                updatedOurComment,
                updatedInternalRemarks,
              );
              handleSave!();
            } catch (error) {
              console.error(error);
              setErrors([...errors, error as ErrorViewModel]);
            } finally {
              setLoading(false);
            }
          }}
        >
          {loading ? <Spinner /> : t('AddShopModal.SaveShop')}
        </button>
      </div>
    </div>
  );
};

export default withModal(withErrorHandling(EditOrderForm));
