import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';

import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';

import './ViewProducts.scss';

import { useCallback, useEffect, useMemo, useState } from 'react';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { ErrorViewModel, Product, ProductTemplate, ProductType } from '../../../../models';
import AddProductForm from '../new-form/AddProductForm';
import { useTranslation } from 'react-i18next';
import ActionButtons from '../../../common/action-buttons/ActionButtons';
import EditButton from '../../../common/edit-button/EditButton';
import DeleteButton from '../../../common/delete-button/DeleteButton';
import useProductsService from '../../../../hooks/useProductsService';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Constants } from '../../../../constants/Constants';
import useIsInRole from '../../../../hooks/useIsInRole';
import useIsReadOnly from '../../../../hooks/useIsReadOnly';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';

/**
 *
 * Initial grid sorting.
 *
 */
const initialSort: Array<SortDescriptor> = [{ field: 'agrolaProductNumber', dir: 'asc' }];

/**
 *
 * This interface defines the model for the grid
 * content.
 *
 */
interface IGridProduct {
  [index: string]: any;
  id?: number;
  nameDe?: string;
  nameFr?: string;
  agrolaProductNumber?: number;
  tktmProductNumber?: number;
  productTemplate?: ProductTemplate[];
  productType?: string;
  productTypeId?: number;
  productImageUrl?: string;
}

/**
 * A wrapper for the product table.
 */
function ViewProducts() {
  /**
   * Stores a value indicating if the user is administrator or not.
   */
  const isAdministrator = useIsInRole(Constants.Roles.Administrator);

  /**
   * Stores a value indicating if the user is read only or not.
   */
  const isReadOnly = useIsReadOnly();

  const appInsights = useAppInsightsContext();

  const { errors, setErrors } = useErrorHandling();

  const { t } = useTranslation();

  const language = useCurrentLanguage();

  const currentLocale = useCurrentLocale();

  const productsService = useProductsService();

  const [loadingProducts, setLoadingProducts] = useState<boolean>(true);
  const [allProducts, setAllProducts] = useState<Product[]>();
  const [allProductTypes, setAllProductTypes] = useState<ProductType[]>();

  /**
   *
   * This callback filters the list of products as returned from the APIs
   * based on the current language.
   *
   */
  const getGridProducts = useCallback(
    (products: Product[]) => {
      if (!allProductTypes) {
        return [];
      }

      return products.map<IGridProduct>((product) => {
        const productType = allProductTypes.find((productType) => productType.id === product.productTypeId);

        if (!productType) {
          console.error("Couldn't find product type id, returning empty collection.");
        }

        return { ...product, productType: productType![`name${capitalizeFirstLetter(language)}`] as string };
      });
    },
    [allProductTypes, language],
  );

  /**
   *
   * The memoized grid data to be displayed.
   *
   */
  const gridProducts = useMemo(() => {
    if (!allProducts) {
      return [];
    }

    return getGridProducts(allProducts);
  }, [allProducts, getGridProducts]);

  const [sort, setSort] = useState(initialSort);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();

  const [showModal, setShowModal] = useState<boolean>(false);

  const [editProduct, setEditProduct] = useState<IGridProduct>();

  const trackGetProducts = useTrackEvent(appInsights, 'Get Products', allProducts);
  const trackGetProductTypes = useTrackEvent(appInsights, 'Get Products', allProductTypes);

  /**
   *
   * This callback takes care of retrieving all the products
   * from the API through the appropriate service.
   *
   */
  const retrieveAllProducts = useCallback(async () => {
    setLoadingProducts(true);

    try {
      const allProducts = await productsService.getAllProducts();
      trackGetProducts(allProducts.products);
      trackGetProductTypes(allProducts.productTypes);

      setAllProducts(allProducts.products);
      setAllProductTypes(allProducts.productTypes);
    } catch (error) {
      console.error(error);

      setAllProducts([]);
      setAllProductTypes([]);

      setErrors([...errors, error as ErrorViewModel]);
    }

    setLoadingProducts(false);
  }, [
    productsService,
    setAllProducts,
    setAllProductTypes,
    setLoadingProducts,
    trackGetProducts,
    trackGetProductTypes,
    setErrors,
    errors,
  ]);

  const deleteProduct = useCallback(
    async (productId: number) => {
      setLoadingProducts(true);

      try {
        await productsService.delete(productId);
        await retrieveAllProducts();
      } catch (error) {
        console.error(error);

        setErrors([...errors, error as ErrorViewModel]);
      }

      setLoadingProducts(false);
    },
    [setLoadingProducts, productsService, retrieveAllProducts, setErrors, errors],
  );

  /**
   * This useEffect calls the callback to retrieve all the products
   * when it still hasn't been done.
   */
  useEffect(() => {
    if (!allProducts || !allProductTypes) {
      retrieveAllProducts();
    }
  }, [allProducts, allProductTypes]);

  if (!allProducts || !allProductTypes || loadingProducts) {
    return <LoadingPanel />;
  }

  return (
    <ViewWrapper
      title={t('ProductsView.ManageProducts')}
      addButton={
        isAdministrator && !isReadOnly ? (
          <button
            type="button"
            className="ms-2 btn btn-primary p-1 pt-0 pb-0 rounded-circle fw-bold"
            onClick={() => setShowModal(true)}
          >
            <span style={{ fontSize: 36, lineHeight: 0.65 }}>+</span>
          </button>
        ) : null
      }
    >
      <AddProductForm
        {...editProduct}
        productTypes={allProductTypes}
        update={!!editProduct}
        modalTitle={
          editProduct
            ? t('AddProductModal.UpdateModalTitle') +
              ' ' +
              (editProduct[`name${capitalizeFirstLetter(language)}`] as string)
            : t('AddProductModal.ModalTitle')
        }
        show={showModal}
        size="xl"
        handleClose={() => {
          setShowModal(false);
          editProduct && setEditProduct(undefined);
        }}
        handleSave={() => {
          setShowModal(false);
          editProduct && setEditProduct(undefined);
          retrieveAllProducts();
        }}
      />

      <LocalizationProvider language={language}>
        <IntlProvider locale={currentLocale}>
          <Grid
            data={filter ? filterBy(orderBy(gridProducts, sort), filter) : orderBy(gridProducts, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
            }}
            filterable={true}
            filter={filter}
            resizable={true}
            onFilterChange={(e) => setFilter(e.filter)}
            style={{ margin: '0 20px 0 20px' }}
          >
            <Column field={`name${capitalizeFirstLetter(language)}`} title={t('ProductsView.ColumnName')} />
            <Column field="productType" title={t('ProductsView.ColumnProductType')} />
            <Column
              title={t('Views.ColumnActions')}
              width={200}
              filterable={false}
              cell={(props) => {
                return (
                  <td colSpan={1} role="gridcell" aria-colindex={3} data-grid-col-index={2}>
                    {isAdministrator && !isReadOnly ? (
                      <ActionButtons>
                        <EditButton
                          onClick={() => {
                            setEditProduct(props.dataItem);
                            setShowModal(true);
                          }}
                        />
                        <DeleteButton
                          onClick={async () => {
                            const product = props.dataItem as IGridProduct;

                            if (
                              confirm(
                                `${t('ProductsView.DeleteMessage')} ${
                                  product['name' + capitalizeFirstLetter(language)]
                                }`,
                              )
                            ) {
                              await deleteProduct(product.id!);
                            }
                          }}
                        />
                      </ActionButtons>
                    ) : null}
                  </td>
                );
              }}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewProducts);
