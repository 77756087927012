import { GridCellProps, GridRowProps } from '@progress/kendo-react-grid';
import { cloneElement, ReactElement, ReactNode } from 'react';

interface CellRenderProps<T> {
  originalProps: GridCellProps;
  td: ReactElement<HTMLTableCellElement>;
  enterEdit: (dataItem: T, field: string | undefined) => void;
  exitEdit: () => void;
  editField: string | undefined;
  render: (dataItem: T, field: string, originalNode: ReactNode) => ReactNode;
  onInputBlur?: (dataItem: T, field: string | undefined) => void;
  onBlur?: (dataItem: T, field: string | undefined) => void;
}

interface RowRenderProps {
  originalProps: GridRowProps;
  tr: React.ReactElement<HTMLTableRowElement>;
  exitEdit: () => void;
  editField: string | undefined;
}

type HTMLInputWithPreviousListener = HTMLInputElement & { previousBlurListener?: () => void };

export const KendoCellRenderer = <T extends unknown>(props: CellRenderProps<T>) => {
  const dataItem = props.originalProps.dataItem as T;
  const cellField = props.originalProps.field;

  if (cellField) {
    function handleRefBlur() {
      props.exitEdit();
      if (!!props.onInputBlur) {
        props.onInputBlur(dataItem, cellField);
      }
    }

    const additionalProps = {
      onClick: () => {
        props.enterEdit(dataItem, cellField);
      },
      onBlur: () => {
        if (!!props.onBlur) {
          props.onBlur(dataItem, cellField);
        }
      },
      ref: (td: HTMLTableElement) => {
        const input = td && (td.querySelector('input') as HTMLInputWithPreviousListener);

        if (input) {
          input.focus();

          // we have no unmount function from kendo, so we need to store the previous listener in the dom
          if (!!input.previousBlurListener) {
            input.removeEventListener('blur', input.previousBlurListener);
          }
          input.previousBlurListener = handleRefBlur;
          input.addEventListener('blur', handleRefBlur);
        }
      },
    };

    const clonedProps: any = { ...props.td.props, ...additionalProps };

    return cloneElement(props.td, clonedProps, props.render(dataItem, cellField, props.td.props.children));
  }

  return props.td;
};

export const KendoRowRenderer = (props: RowRenderProps) => {
  const trProps = {
    ...props.tr.props,
    onBlur: () => {
      props.exitEdit();
    },
  };
  return cloneElement(props.tr, { ...trProps }, props.tr.props.children);
};
