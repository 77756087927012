import { object, number, boolean, string } from 'yup';

/**
 *
 * This file exports the function in order to get the validation schema
 * with the appropriate translation at runtime.
 *
 * @param t - The translation function as provided by the useTranslation
 *            i18next hook.
 *
 * @returns the validation schema with the appropriate translations.
 */
export default function getHoldingBasePriceValidationSchema(t: (key: string) => string) {
  const requiredErrorSuffix = t('ValidationErrors.Required');
  const numberErrorSuffix = t('ValidationErrors.Number');
  const positiveErrorSuffix = t('ValidationErrors.PositiveNumber');

  const greaterThanZero = t('BasePriceView.ValidationErrors.MustBeGreaterThanZero');

  const schema = object().shape({
    holdingId: number()
      .min(1, `${t('BasePriceView.ValidationErrors.TheHoldingId')} ${greaterThanZero}`)
      .required(`${t('BasePriceView.ValidationErrors.TheHoldingId')} ${requiredErrorSuffix}`),
    productId: number()
      .min(1, `${t('BasePriceView.ValidationErrors.TheProductId')} ${greaterThanZero}`)
      .required(`${t('BasePriceView.ValidationErrors.TheProductId')} ${requiredErrorSuffix}`),
    useTktm: boolean().required(`${t('BasePriceView.ValidationErrors.UseTktm')} ${requiredErrorSuffix}`),
    basePrice: number().when('useTktm', {
      is: false,
      then: number()
        .typeError(`${t('BasePriceView.ValidationErrors.TheBasePrice')} ${numberErrorSuffix}`)
        .positive(`${t('BasePriceView.ValidationErrors.TheBasePrice')} ${positiveErrorSuffix}`)
        .required(`${t('BasePriceView.ValidationErrors.TheBasePrice')} ${requiredErrorSuffix}`),
      otherwise: number()
        .typeError(`${t('BasePriceView.ValidationErrors.TheBasePrice')} ${numberErrorSuffix}`)
        .positive(`${t('BasePriceView.ValidationErrors.TheBasePrice')} ${positiveErrorSuffix}`),
    }),
    quantity: number()
      .min(1, `${t('BasePriceView.ValidationErrors.TheQuantity')} ${greaterThanZero}`)
      .required(`${t('BasePriceView.ValidationErrors.TheQuantity')} ${requiredErrorSuffix}`),
    unitCode: string().required(`${t('BasePriceView.ValidationErrors.TheUnitCode')} ${requiredErrorSuffix}`),
  });

  return schema;
}
