import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import './index.scss';
import { AgrConfiguration, IApiConfiguration, IAuthConfiguration } from './models/ConfigurationModel';
import './styles/custom-bootstrap.scss';

fetch('./config.json')
  .then((response) => response.json())
  .then(
    (data: {
      api: IApiConfiguration;
      auth: IAuthConfiguration;
      appInsightsInstrumentationKey: string;
      showRestoreAdministratorButton: string;
      portalIntegrationUrls: string;
      customerUiEndpoint: string;
    }) => {
      const appConfiguration: AgrConfiguration = new AgrConfiguration(
        data.api,
        data.auth,
        data.appInsightsInstrumentationKey,
        data.showRestoreAdministratorButton,
        data.portalIntegrationUrls,
        data.customerUiEndpoint,
      );

      ReactDOM.render(
        <React.StrictMode>
          <App {...appConfiguration} />
        </React.StrictMode>,
        document.getElementById('root'),
      );
    },
  )
  .catch((error) => console.error('Error while loading the configuration.', error));
