import './EditMailTemplate.scss';

import withModal from '../../../hoc/with-modal/withModal';
import useModal from '../../../../hooks/useModal';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import { useCallback, useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';
import useYupErrors, { YupError } from '../../../../hooks/useYupErrors';
import {
  MailTemplateEditModel,
  MailTemplateUpdateModel,
  MailTemplateSendGridModel,
  GetLanguageName,
} from '../../../../models';
import {
  getMailTemplateUpdateModelValidationSchema,
  getMailTemplateSendMailModelValidationSchema,
} from './ValidationSchema';
import useMailTemplatesService from '../../../../hooks/useMailTemplateService';
import Spinner from '../../../common/spinner/Spinner';

interface EditMailTemplateProps {
  id: number;
}

function EditMailTemplate(props: EditMailTemplateProps): JSX.Element | null {
  class MailTemplateUpdateModelError {
    sendGridId!: string;
    sendGridLink!: string;
    senderMail!: string;
    senderName!: string;
  }
  const mailTemplateUpdateModelError = new MailTemplateUpdateModelError();

  class MailTemplateSendMailModelError {
    receiverMail!: string;
  }
  const mailTemplateSendMailModelError = new MailTemplateSendMailModelError();

  /**
   * The error handling hook to send a message to the toast notification.
   */
  const { errors, setErrors } = useErrorHandling();
  const [message, setMessage] = useState<string>();
  const { t, i18n } = useTranslation();
  const language: string = i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1);
  const { closeModal } = useModal();
  const [saving, setSaving] = useState<boolean>(false);
  const [sendingMail, setSendingMail] = useState<boolean>(false);
  const service = useMailTemplatesService();
  const [editModel, setEditModel] = useState<MailTemplateEditModel>();
  const [updateModel, setUpdateModel] = useState<MailTemplateUpdateModel>(new MailTemplateUpdateModel());
  const [sendMailModel, setSendMailModel] = useState<MailTemplateSendGridModel>(new MailTemplateSendGridModel());
  const updateModelValidationSchema = useMemo(() => getMailTemplateUpdateModelValidationSchema(t), [t]);
  const sendMailModelValidationSchema = useMemo(() => getMailTemplateSendMailModelValidationSchema(t), [t]);
  const [updateModelValidationErrors, setUpdateModelValidationErrors] =
    useState<YupError<MailTemplateUpdateModelError>>();
  const [sendMailModelValidationErrors, setSendMailModelValidationErrors] =
    useState<YupError<MailTemplateSendMailModelError>>();

  const [changedSendGridId, setChangedSendGridId] = useState<boolean>(false);
  const [previousSendGridId, setPreviousSendGridId] = useState<string>('');

  const [changedMailLink, setChangedMailLink] = useState<boolean>(false);
  const [previousLink, setPreviousLink] = useState<string>('');

  const [changedSenderMailAddress, setChangedSenderMailAddress] = useState<boolean>(false);
  const [previousSenderMailAddress, setPreviousSenderMailAddress] = useState<string>('');

  const [changedSenderName, setChangedSenderName] = useState<boolean>(false);
  const [previousSenderName, setPreviousSenderName] = useState<string>('');

  const mapUpdateModelErrors = useYupErrors<MailTemplateUpdateModelError>(mailTemplateUpdateModelError);
  const mapSendMailModelErrors = useYupErrors<MailTemplateSendMailModelError>(mailTemplateSendMailModelError);

  useEffect(() => {
    service
      .load(props.id)
      .then((model) => {
        setPreviousSendGridId(model.sendGridId);
        setPreviousLink(model.sendGridLink || '');
        setPreviousSenderMailAddress(model.senderMail || '');
        setPreviousSenderName(model.senderName || '');

        setUpdateModel(model);
        sendMailModel.id = model.id;
        setSendMailModel(sendMailModel);
        setEditModel(model);
      })
      .catch((error) => {
        console.error(error);

        setErrors([...errors, error]);
      });
  }, []);

  const update = useCallback(async (model: MailTemplateUpdateModel) => {
    service
      .update(model)
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        console.error(error);

        setErrors([...errors, error]);
        setSaving(false);
      });
  }, []);

  const sendMail = useCallback(async (model: MailTemplateSendGridModel) => {
    service
      .sendMail(model)
      .then(() => {
        setSendingMail(false);
        setMessage(t('EditMailTemplate.SendMailSuccess'));
      })
      .catch((error) => {
        console.error(error);

        setErrors([...errors, error]);
        setSendingMail(false);
      });
  }, []);

  if (!editModel) {
    return (
      <div className="container px-2 d-flex justify-content-center">
        <div className="spinner-border spinner-border" role="status">
          <span className="visually-hidden">{t('EditUserProfile.Save')}</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <div className="col-md-3 p-2">
          <span>{t('EditMailTemplate.MailTemplateTypeName')}</span>
        </div>
        <div className="col-md-5 p-2">
          <span>{Reflect.get(editModel, 'mailTemplateTypeName' + language)}</span>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <div className="col-md-3 p-2">
          <span>{t('EditMailTemplate.ProductTypeName')}</span>
        </div>
        <div className="col-md-5 p-2">
          <span>{Reflect.get(editModel, 'productTypeName' + language)}</span>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <div className="col-md-3 p-2">
          <span>{t('EditMailTemplate.Language')}</span>
        </div>
        <div className="col-md-5 p-2">
          <span>{GetLanguageName(t, editModel.language)}</span>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <div className="col-md-3 p-2">
          <span>{t('EditMailTemplate.SendGridId')}</span>
        </div>
        <div className="col-md-5 p-2">
          <input
            type="text"
            className="form-control"
            value={updateModel.sendGridId}
            onChange={(e) => {
              const copy = Object.assign({}, updateModel);
              copy.sendGridId = e.currentTarget.value;
              setUpdateModel(copy);
              setChangedSendGridId(e.currentTarget.value !== previousSendGridId);
            }}
          />
          {updateModelValidationErrors?.sendGridId && (
            <small className="position-relative text-danger">{updateModelValidationErrors.sendGridId}</small>
          )}
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <div className="col-md-3 p-2">
          <span>{t('EditMailTemplate.SendGridLink')}</span>
        </div>
        <div className="col-md-5 p-2">
          <input
            type="text"
            className="form-control"
            value={updateModel.sendGridLink}
            onChange={(e) => {
              const copy = Object.assign({}, updateModel);
              copy.sendGridLink = e.currentTarget.value;
              setUpdateModel(copy);
              setChangedMailLink(e.currentTarget.value !== previousLink);
            }}
          />
          {updateModelValidationErrors?.sendGridLink && (
            <small className="position-relative text-danger">{updateModelValidationErrors.sendGridLink}</small>
          )}
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <div className="col-md-3 p-2">
          <span>{t('EditMailTemplate.SenderMail')}</span>
        </div>
        <div className="col-md-5 p-2">
          <input
            type="text"
            className="form-control"
            value={updateModel.senderMail}
            onChange={(e) => {
              const copy = Object.assign({}, updateModel);
              copy.senderMail = e.currentTarget.value;
              setUpdateModel(copy);
              setChangedSenderMailAddress(e.currentTarget.value !== previousSenderMailAddress);
            }}
          />
          {updateModelValidationErrors?.senderMail && (
            <small className="position-relative text-danger">{updateModelValidationErrors.senderMail}</small>
          )}
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <div className="col-md-3 p-2">
          <span>{t('EditMailTemplate.SenderName')}</span>
        </div>
        <div className="col-md-5 p-2">
          <input
            type="text"
            className="form-control"
            value={updateModel.senderName}
            onChange={(e) => {
              const copy = Object.assign({}, updateModel);
              copy.senderName = e.currentTarget.value;
              setUpdateModel(copy);
              setChangedSenderName(e.currentTarget.value !== previousSenderName);
            }}
          />
          {updateModelValidationErrors?.senderName && (
            <small className="position-relative text-danger">{updateModelValidationErrors.senderName}</small>
          )}
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <div className="col text-end">
          <button
            className="btn btn-primary"
            type="button"
            onClick={async () => {
              try {
                setUpdateModelValidationErrors(new MailTemplateUpdateModelError());
                await updateModelValidationSchema.validate(updateModel, { abortEarly: false });
                setSaving(true);
                await update(updateModel);
              } catch (error) {
                setUpdateModelValidationErrors(mapUpdateModelErrors(error as ValidationError));
              }
            }}
            disabled={saving}
          >
            {saving ? <Spinner /> : t('EditMailTemplate.Save')}
          </button>
        </div>
      </div>
      <>
        <hr />

        {(changedSendGridId || changedMailLink || changedSenderMailAddress || changedSenderName) && (
          <span className="text-danger mb-1 ps-2">{t('MailTemplateModal.ChangedData')}</span>
        )}

        <div className="d-flex flex-row align-items-center">
          <div className="col-md-3 p-2">
            <span>{t('EditMailTemplate.ReceiverMail')}</span>
          </div>
          <div className="col-md-5 p-2">
            <input
              type="text"
              className="form-control"
              value={sendMailModel.receiverMail ?? ''}
              onChange={(e) => {
                const copy = Object.assign({}, sendMailModel);
                copy.receiverMail = e.currentTarget.value;
                setSendMailModel(copy);
              }}
              disabled={changedSendGridId || changedMailLink || changedSenderMailAddress || changedSenderName}
            />
            {sendMailModelValidationErrors?.receiverMail && (
              <small className="position-relative text-danger">{sendMailModelValidationErrors.receiverMail}</small>
            )}
          </div>
        </div>
        <div className="d-flex flex-row align-items-center">
          <div className="col text-end">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() =>
                window.open(
                  editModel.sendGridLink ?? 'https://mc.sendgrid.com/dynamic-templates/' + editModel.sendGridId,
                )
              }
              disabled={changedSendGridId || changedMailLink || changedSenderMailAddress || changedSenderName}
            >
              {t('EditMailTemplate.OpenTemplate')}
            </button>
            &nbsp;
            <button
              className="btn btn-primary"
              type="button"
              onClick={async () => {
                try {
                  setSendMailModelValidationErrors(new MailTemplateSendMailModelError());
                  await sendMailModelValidationSchema.validate(sendMailModel, { abortEarly: false });
                  setSendingMail(true);
                  await sendMail(sendMailModel);
                } catch (error) {
                  setSendMailModelValidationErrors(mapSendMailModelErrors(error as ValidationError));
                }
              }}
              disabled={
                sendingMail || changedSendGridId || changedMailLink || changedSenderMailAddress || changedSenderName
              }
            >
              {sendingMail ? <Spinner /> : t('EditMailTemplate.SendMail')}
            </button>
          </div>
        </div>
      </>
      {/* <ToastContainer position="bottom-center">
        <Toast show={message !== undefined} onClose={() => setMessage(undefined)}>
          <Toast.Header>
            <strong>{message}</strong>
          </Toast.Header>
        </Toast>
      </ToastContainer> */}
    </>
  );
}

export default withModal(withErrorHandling(EditMailTemplate));
