import { SyntheticEvent, useEffect, useState } from 'react';
import i18n from 'i18next';
import './LanguageSwitch.scss';

/**
 * The switch that changes the language of the web site.
 */
function LanguageSwitch(): JSX.Element | null {
  const lanDe = 'de';
  const lanFr = 'fr';

  /**
   * The language which is stored in the state.
   */
  const [language, setLanguage] = useState(lanDe);

  /**
   * Uplon first mounting, it changes teh language to the default.
   */
  useEffect(() => {
    i18n.changeLanguage(lanDe);
  }, []);

  /**
   * Toggles the language in the state.
   */
  const toggleLanguage = (evt: SyntheticEvent) => {
    const selectedLanguage = (evt.target as HTMLInputElement).value;
    i18n.changeLanguage(selectedLanguage);
    setLanguage(selectedLanguage);
  };

  return (
    <div className="agr-lang-switch btn-group btn-group-toggle" data-toggle="buttons">
      <label className={language === lanDe ? 'btn btn-primary active' : 'btn btn-primary'}>
        <input
          type="radio"
          style={{ display: 'none' }}
          name="options"
          checked={language === lanDe}
          autoComplete="off"
          value={lanDe}
          onChange={toggleLanguage}
        />{' '}
        DE
      </label>
      <label className={language === lanFr ? 'btn btn-primary active' : 'btn btn-primary'}>
        <input
          type="radio"
          style={{ display: 'none' }}
          name="options"
          checked={language === lanFr}
          autoComplete="off"
          value={lanFr}
          onChange={toggleLanguage}
        />{' '}
        FR
      </label>
    </div>
  );
}

export default LanguageSwitch;
