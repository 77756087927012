/**
 *
 * As indicated in the {@https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin#basic-usage official Microsoft documentation},
 * this .js file creates all the necessary basic components in order to use the Application Insights SDK throughout our components.
 *
 */
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { IAgrConfiguration } from './models/ConfigurationModel';
import { envService } from './services/EnvService';

let currentConfig: {
  reactPlugin: ReactPlugin;
  appInsights: ApplicationInsights;
} | null = null;

function createAppInsightConfig(config: IAgrConfiguration): {
  reactPlugin: ReactPlugin;
  appInsights: ApplicationInsights;
} {
  // if you load multiple time appInsights it will create multiple hooks
  if (!currentConfig) {
    const browserHistory = createBrowserHistory({});
    const reactPlugin = new ReactPlugin();

    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: config.appInsightsInstrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
        enableAutoRouteTracking: true,
      },
    });

    if (envService.env !== 'LOCAL') {
      appInsights.loadAppInsights();
    }

    currentConfig = { reactPlugin, appInsights };
  }

  return currentConfig;
}

export default createAppInsightConfig;
