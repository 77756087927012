import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { ExportService } from '../services/ExportService';

/**
 * Wraps the PriceService in a useMemo call.
 *
 * @returns the memoized ExportService
 */
export default function useExportService(): ExportService {
  const config = useContext(AgrConfigurationContext);

  const priceService = useMemo(() => {
    return new ExportService(config);
  }, [config]);

  return priceService;
}
