import { UserRole } from '@/constants/Constants';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import useApplicationState from '../../../hooks/useApplicationState';
import useIsInRole from '../../../hooks/useIsInRole';

export type ProtectedRouteProps = {
  role: UserRole[];
} & RouteProps;

/**
 * Wraps a Route component to provide authorization.
 * @param {*} param0 The properties of the embedded route.
 * @returns A Route or a Redirect
 */
export default function ProtectedRoute({ role, ...routeProps }: ProtectedRouteProps) {
  const isInRole = useIsInRole(...role);

  const { isAuthorized } = useApplicationState();

  if (isAuthorized && isInRole) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to="/not-authorized" />;
  }
}
