/**
 *
 * Retrieve the value from query string using the given query parameter.
 *
 * @param queryParam - the query parameter.
 *
 * @returns the value for the given query parameter, otherwise null.
 */
 export default function retrieveQueryParamValue(queryParam: string): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(queryParam);
}
  