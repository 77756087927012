import logo from '../../../assets/agrola_logo.svg';
import './Header.scss';

import RestoreAdmin from '../../common/restore-admin-button/RestoreAdmin';
import UserInformation from '../user-information/UserInformation';
import LanguageSwitch from '../language-switch/LanguageSwitch';

/**
 * Displays the header information: logo, language switch and user information.
 */
function Header(): JSX.Element | null {
  return (
    <div className="agr-header d-flex flex-row justify-content-between align-items-center pt-15 mb-4">
      <div>
        <img style={{ height: 40 }} src={logo} alt="logo" />
      </div>
      <div>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div style={{ marginRight: 30 }}>
            <RestoreAdmin></RestoreAdmin>
          </div>
          <div style={{ marginRight: 30 }}>
            <LanguageSwitch></LanguageSwitch>
          </div>
          <div>
            <UserInformation></UserInformation>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
