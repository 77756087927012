import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { HoldingsService } from '../services/HoldingsService';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * HoldingsService to be used in components to make API calls.
 *
 * @returns a HoldingsService instance
 */
export default function useHoldingsService(): HoldingsService {
  const config = useContext(AgrConfigurationContext);

  const holdingService = useMemo(() => {
    return new HoldingsService(config);
  }, [config]);

  return holdingService;
}
