/**
 *
 * This function builds the hour component from number.
 * e.g.: from 15 to 15:00.
 *
 * @param hour - The hour number.
 *
 * @returns the hour time string.
 */
export function buildHour(hour: number | string): string {
  if (Number(hour) === 0) {
    return `${hour}0:00`;
  }

  return `${hour}:00`;
}

/**
 *
 * This function extracts the hour component from hour like input.
 * e.g.: from 15:00 to 15.
 *
 * @param hour - The hour like string.
 *
 * @returns the hour component of the time string.
 */
export function getHour(hour: string): string {
  const hourComponent = hour.split(':')[0];

  if (hourComponent) {
    if (Number(hourComponent) === 0) {
      return Number(hourComponent).toString();
    }

    return hourComponent;
  }

  return '';
}
