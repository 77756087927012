import { useTranslation } from 'react-i18next';

export const KendoCommandCell = (props: any) => {
  const { t } = useTranslation();
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem.id === undefined;

  return inEdit ? (
    <td className="k-command-cell">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command btn-primary"
        onClick={() => (isNewItem ? props.add(dataItem) : props.update(dataItem))}
      >
        {isNewItem ? t('Views.AddButton') : t('Views.EditButton')}
      </button>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-cancel-command"
        onClick={() => (isNewItem ? props.discard(dataItem) : props.cancel(dataItem))}
      >
        {t('Views.CancelButton')}
      </button>
    </td>
  ) : (
    <td className="k-command-cell">
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command btn-primary"
        onClick={() => props.edit(dataItem)}
      >
        {t('Views.EditButton')}
      </button>
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-remove-command btn-danger"
        onClick={() => props.remove(dataItem)}
      >
        {t('Views.DeleteButton')}
      </button>
    </td>
  );
};
