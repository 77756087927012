import { useCallback, useEffect, useMemo, useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Tab from 'react-bootstrap/Tab';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Nav from 'react-bootstrap/Nav';
import withModal from '../../../hoc/with-modal/withModal';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import { ErrorViewModel, Product, ProductTemplate, ProductType } from '../../../../models';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ProductDetails from './product-details/ProductDetails';

import useProductsService from '../../../../hooks/useProductsService';
import useModal from '../../../../hooks/useModal';
import useYupErrors, { YupError } from '../../../../hooks/useYupErrors';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import getProductFormValidationSchema from './ValidationSchema';
import { ValidationError } from 'yup';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import Spinner from '../../../common/spinner/Spinner';

/**
 *
 * Import the html default templates as strings to be passed
 * to the add/update product form.
 *
 */
import {
  productTemplateFuelDE as fuelDefaultTemplateDE,
  productTemplateFuelFR as fuelDefaultTemplateFR,
  productTemplateWoodDE as woodDefaultTemplateDE,
  productTemplateWoodFR as woodDefaultTemplateFR,
  productTemplateDieselDE as dieselDefaultTemplateDE,
  productTemplateDieselFR as dieselDefaultTemplateFR,
} from '../../../../assets/constants/html-templates';

/**
 *
 * This styled component is for adding the pointer
 * when I hover on the tab nav item.
 *
 */
const NavItemHoverPointer = styled(Nav.Item)`
  &:hover {
    cursor: pointer;
  }
`;

/**
 *
 * These are the styles for the tab content area.
 *
 */
const BorderedTabContent = styled(Tab.Content).attrs(() => ({
  className: 'container',
}))<{}>`
  @media (max-width: 576px) {
    border: 1px solid #dee2e6;
  }
`;

/**
 *
 * These are the styles for the tab pills.
 * I decided to make them custom in order to have a more
 * appropriate mobile version of them.
 *
 */
const NavPill = styled(Nav.Link)`
  @media (max-width: 576px) {
    border-radius: 0.5rem 0.5rem 0 0 !important;
    padding: 0.25rem 0.75rem;
  }

  @media (min-width: 576px) {
    border-radius: 0.5rem 0 0 0.5rem !important;
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;
  }
`;

/**
 *
 * These are the styles for form error messages.
 *
 */
const ErrorMessage = styled.small.attrs(() => ({ className: 'position-relative text-danger' }))<{}>`
  @media (min-width: 768px) {
    max-width: 25vw;
  }

  @media (min-width: 992px) {
    max-width: 15vw;
  }
`;

/**
 *
 * The interface that represents all the possible errors
 * that Yup can map on the product.
 *
 */
export interface ProductError {
  language: string;
  name: string;
  agrolaProductNumber: number;
  tktmProductNumber: number;
  template: string;
  productType: string;
  productImageUrl?: string;
}

/**
 *
 * Dummy object to create mapping function for Yup.
 *
 */
const yupProductErrorSchema: ProductError = {
  agrolaProductNumber: 0,
  language: '',
  name: '',
  productType: '',
  template: '',
  tktmProductNumber: 0,
};

/**
 *
 * The AddProductForm props.
 *
 */
interface AddProductFormProps {
  /**
   *
   * This property is to determine whether calling post or put.
   *
   */
  update?: boolean;
  productTypes: ProductType[];

  id?: number;
  nameDe?: string;
  nameFr?: string;
  agrolaProductNumber?: number;
  tktmProductNumber?: number;
  productTemplate?: ProductTemplate[];
  productTypeId?: number;
  productImageUrl?: string;
}

/**
 *
 * The interectable content of the modal for adding a product.
 *
 * @returns the component to be displayed.
 */
const AddProductForm = ({
  update,
  productTypes,

  id,
  productTypeId: type,
  agrolaProductNumber: productNumber,
  tktmProductNumber: tktmProductId,
  nameDe,
  nameFr,
  productTemplate: template,
  productImageUrl,
}: AddProductFormProps): JSX.Element | null => {
  const { t } = useTranslation();
  const language = useCurrentLanguage();

  const { handleSave } = useModal();
  const { errors, setErrors } = useErrorHandling();

  const mapErrors = useYupErrors<ProductError>(yupProductErrorSchema);

  const appInsights = useAppInsightsContext();
  const trackSaveProduct = useTrackEvent<Product | null>(appInsights, 'Save Product', null);

  const productsService = useProductsService();

  const UpsertProductValidationSchema = useMemo(() => getProductFormValidationSchema(t), [t]);

  const [loading, setLoading] = useState<boolean>(false);

  const [activeTabKey, setActiveTabKey] = useState<string>('first');

  const [chosenType, setChosenType] = useState<number>(type || productTypes[0].id);
  const [agrolaProductNumber, setAgrolaProductNumber] = useState<string | undefined>(productNumber?.toString());
  const [tktmProductNumber, setTktmProductNumber] = useState<string | undefined>(tktmProductId?.toString());

  // The setter for these 2 props is passed down the the ProductDetails Component
  const [productNameDE, setProductNameDE] = useState<string>(nameDe || '');
  const [productNameFR, setProductNameFR] = useState<string>(nameFr || '');

  // The setter and value of these 2 props is passed down the the ProductDetails Component
  const [htmlTemplateDE, setHtmlTemplateDE] = useState<ProductTemplate>();
  const [htmlTemplateFR, setHtmlTemplateFR] = useState<ProductTemplate>();

  const [productImage, setProductImage] = useState<string | undefined>(productImageUrl);

  const [validationErrors, setValidationErrors] = useState<YupError<ProductError | null>>();

  // validate form and return true if no errors found, false vice versa.
  const validateForm = useCallback(
    async (product: Product) => {
      try {
        await UpsertProductValidationSchema.validate(product, { abortEarly: false });

        setValidationErrors(null);

        return true;
      } catch (error) {
        const mappedErrors = mapErrors(error as ValidationError);
        setValidationErrors(mappedErrors);

        if (mappedErrors.name) {
          if (!productNameDE) {
            setActiveTabKey('first');
          } else if (!productNameFR) {
            setActiveTabKey('second');
          }

          return false;
        }

        if (mappedErrors.template) {
          if (!htmlTemplateDE) {
            setActiveTabKey('first');
          } else if (!htmlTemplateDE) {
            setActiveTabKey('second');
          }

          return false;
        }

        return false;
      }
    },
    [UpsertProductValidationSchema, setValidationErrors, mapErrors],
  );

  /**
   *
   * This useEffect takes care of loading the previous template if
   * existing, otherwise it provides one of the default ones.
   * In case of an error trying to find which product the template
   * belongs to, then it sets a Toast error.
   *
   */
  useEffect(() => {
    const existingTemplateDE = template?.find((t) => t.language === 'DE');
    const existingTemplateFR = template?.find((t) => t.language === 'FR');

    if (!existingTemplateDE || !existingTemplateFR) {
      const chosenTypeProductName = productTypes.find((productType) => chosenType === productType.id)?.productName;

      if (!chosenTypeProductName) {
        setErrors([
          ...errors,
          {
            title: 'Error in determining product type',
            statusCode: '',
            value: { description: 'Error in determining product type' },
          },
        ]);

        return;
      }

      if (chosenTypeProductName === 'Wood') {
        setHtmlTemplateDE({ id: 0, template: woodDefaultTemplateDE, language: 'DE', templateImageUrl: '' });
        setHtmlTemplateFR({ id: 0, template: woodDefaultTemplateFR, language: 'FR', templateImageUrl: '' });
      } else if (chosenTypeProductName === 'Fuel') {
        setHtmlTemplateDE({ id: 0, template: fuelDefaultTemplateDE, language: 'DE', templateImageUrl: '' });
        setHtmlTemplateFR({ id: 0, template: fuelDefaultTemplateFR, language: 'FR', templateImageUrl: '' });
      } else if (chosenTypeProductName === 'Diesel') {
        setHtmlTemplateDE({ id: 0, template: dieselDefaultTemplateDE, language: 'DE', templateImageUrl: '' });
        setHtmlTemplateFR({ id: 0, template: dieselDefaultTemplateFR, language: 'FR', templateImageUrl: '' });
      }
    } else {
      setHtmlTemplateDE(existingTemplateDE);
      setHtmlTemplateFR(existingTemplateFR);
    }
  }, [chosenType]);

  if (htmlTemplateDE === undefined || htmlTemplateFR === undefined) {
    return <></>;
  }

  return (
    <div className="container px-2 pt-2">
      <div className="d-flex flex-sm-row flex-column justify-content-start">
        <div className="me-sm-3 mb-2 d-flex flex-column">
          <span className="mx-1 mb-2">{t('AddProductModal.ProductType')}</span>
          <ButtonGroup className="align-self-start">
            {productTypes.map((productType, i) => {
              return (
                <ToggleButton
                  key={i}
                  id={`radio-${i}`}
                  type="radio"
                  variant={chosenType === productType.id ? 'primary' : 'light'}
                  name="radio"
                  value={productType.id}
                  checked={chosenType === productType.id}
                  onChange={(e) => setChosenType(Number(e.currentTarget.value))}
                >
                  {productType[`name${capitalizeFirstLetter(language)}`]}
                </ToggleButton>
              );
            })}
          </ButtonGroup>
        </div>

        <div className="mx-sm-3 mb-2 d-flex flex-column">
          <span className="mx-1 mb-2">{t('AddProductModal.AgrolaProductNr')}</span>
          <input
            type="text"
            className="form-control"
            value={agrolaProductNumber}
            onChange={async (e) => {
              setAgrolaProductNumber(e.currentTarget.value);

              // only do live checks after first round trip of validations.
              if (validationErrors !== undefined) {
                const product: Product = {
                  agrolaProductNumber: Number(e.currentTarget.value),
                  nameDe: productNameDE,
                  nameFr: productNameFR,
                  productTypeId: chosenType,
                  productTemplate: [htmlTemplateDE, htmlTemplateFR],
                  tktmProductNumber: Number(tktmProductNumber),
                  productImageUrl: productImage,
                };

                await validateForm(product);
              }
            }}
          />

          {validationErrors?.agrolaProductNumber && <ErrorMessage>{validationErrors.agrolaProductNumber}</ErrorMessage>}
        </div>

        <div className="ms-sm-3 mb-5 d-flex flex-column">
          <span className="mx-1 mb-2">{t('AddProductModal.TktmProductId')}</span>
          <input
            type="text"
            className="form-control"
            value={tktmProductNumber}
            onChange={async (e) => {
              setTktmProductNumber(e.currentTarget.value);

              // only do live checks after first round trip of validations.
              if (validationErrors !== undefined) {
                const product: Product = {
                  agrolaProductNumber: Number(agrolaProductNumber),
                  nameDe: productNameDE,
                  nameFr: productNameFR,
                  productTypeId: chosenType,
                  productTemplate: [htmlTemplateDE, htmlTemplateFR],
                  tktmProductNumber: Number(e.currentTarget.value),
                  productImageUrl: productImage,
                };

                await validateForm(product);
              }
            }}
          />

          {validationErrors?.tktmProductNumber && <ErrorMessage>{validationErrors.tktmProductNumber}</ErrorMessage>}
        </div>
      </div>

      <Tab.Container activeKey={activeTabKey} onSelect={(key) => setActiveTabKey(key ?? 'first')}>
        <div className="d-flex flex-column flex-sm-row">
          <Nav variant="pills" className="mt-0 mt-sm-4 flex-row flex-sm-column">
            <NavItemHoverPointer>
              <NavPill eventKey="first">{t('CommonLabels.German')}</NavPill>
            </NavItemHoverPointer>
            <NavItemHoverPointer>
              <NavPill eventKey="second">{t('CommonLabels.French')}</NavPill>
            </NavItemHoverPointer>
          </Nav>
          <BorderedTabContent className="border border-bottom-0" style={{ minHeight: '60vh' }}>
            <Tab.Pane eventKey="first" className="h-100">
              <ProductDetails
                productTemplate={htmlTemplateDE}
                setTemplate={setHtmlTemplateDE}
                name={productNameDE}
                setProductName={setProductNameDE}
                productImage={productImage}
                setProductImage={setProductImage}
                validationErrors={validationErrors}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="second" className="h-100">
              <ProductDetails
                productTemplate={htmlTemplateFR}
                setTemplate={setHtmlTemplateFR}
                name={productNameFR}
                setProductName={setProductNameFR}
                productImage={productImage}
                setProductImage={setProductImage}
                validationErrors={validationErrors}
              />
            </Tab.Pane>
          </BorderedTabContent>
        </div>
      </Tab.Container>

      <div className="modal-footer pb-0 pe-0">
        <button
          className="btn btn-primary"
          type="button"
          disabled={loading}
          onClick={async () => {
            const product: Product = {
              id: id,
              agrolaProductNumber: Number(agrolaProductNumber),
              nameDe: productNameDE,
              nameFr: productNameFR,
              productTypeId: chosenType,
              productTemplate: [htmlTemplateDE, htmlTemplateFR],
              tktmProductNumber: Number(tktmProductNumber),
              productImageUrl: productImage,
            };

            const validation = await validateForm(product);

            if (!validation) {
              return;
            }

            setLoading(true);

            try {
              trackSaveProduct(product);

              if (update) {
                await productsService.updateProduct(product);
              } else {
                await productsService.saveProduct(product);
              }

              setLoading(false);
              handleSave!();
            } catch (error) {
              console.error(error);

              setErrors([...errors, error as ErrorViewModel]);

              setLoading(false);
            }
          }}
        >
          {loading ? <Spinner /> : t('AddProductModal.SaveProduct')}
        </button>
      </div>
    </div>
  );
};

export default withModal(withErrorHandling(AddProductForm));
