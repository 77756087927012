export default function injectCustomOData(filterToAdd: string, existingODataFilter: string): string {
  
  if(filterToAdd === "" || filterToAdd === undefined){
    return existingODataFilter;
  }
  
  const filter = '$filter=';
  if (existingODataFilter.includes(filter)) {
    let insertAtIndex = filter.length;
    if (existingODataFilter.includes(filter + '(')) {
      insertAtIndex++;
      existingODataFilter =
        existingODataFilter.slice(0, insertAtIndex) + filterToAdd + ' and ' + existingODataFilter.slice(insertAtIndex);
    } else {
      existingODataFilter =
        existingODataFilter.slice(0, insertAtIndex) +
        '(' +
        filterToAdd +
        ' and ' +
        existingODataFilter.slice(insertAtIndex, existingODataFilter.indexOf('&')) +
        existingODataFilter.slice(insertAtIndex, 1) +
        ')' +
        existingODataFilter.slice(existingODataFilter.indexOf('&'));
    }
  } else {
    existingODataFilter = filter + '(' + filterToAdd + ')' + '&' + existingODataFilter;
  }

  return existingODataFilter;
}
