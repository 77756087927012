import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';

import useCurrentLanguage from '../../../hooks/useCurrentLanguage';
import { useCurrentLocale } from '../../../hooks/useCurrentLocale';

import './AgrDropDown.scss';

/**
 * The data props to be passed to the AgrDropDown component.
 */
export interface IAgrDropDownItem {
  id?: number;
  title?: string | undefined;
  active?: boolean;
}

/**
 * The data props to be passed to the AgrDropDown component.
 */
export interface IAgrDropDownProps {
  id?: string;
  selectedItem?: IAgrDropDownItem | null;
  defaultItem?: IAgrDropDownItem | null;
  data: Array<IAgrDropDownItem> | undefined;
  onChange: (selectedItem: IAgrDropDownItem) => any;
  disabled?: boolean;
  style?: any;
}

/**
 * Wraps a drop down list control.
 * @param props SHall contain the data, and the onCHange events.
 */
function AgrDropDown({
  id,
  selectedItem,
  defaultItem,
  data,
  onChange: onSelect,
  disabled,
  style,
}: IAgrDropDownProps): JSX.Element | null {
  /**
   * The hook to always have the updated language.
   */
  const language = useCurrentLanguage();

  const currentLocale = useCurrentLocale();

  /**
   * Raised when the selection has changed
   * @param event the Kendo DropDownListEvent
   */
  const onChange = (event: DropDownListChangeEvent) => {
    onSelect(event.target.value);
  };

  return (
    <>
      <LocalizationProvider language={language}>
        <IntlProvider locale={currentLocale}>
          <DropDownList
            id={id}
            data={data}
            textField="title"
            dataItemKey="id"
            value={selectedItem}
            defaultItem={defaultItem}
            onChange={onChange}
            style={style}
            disabled={disabled}
          ></DropDownList>
        </IntlProvider>
      </LocalizationProvider>
    </>
  );
}

export default AgrDropDown;
