import { Product, ProductType } from '../models';
import { BaseService } from './BaseService';
import { IAgrConfiguration } from '../models/ConfigurationModel';

export interface GetAllProductsModel {
  products: Product[];
  productTypes: ProductType[];
}

interface UploadImage {
  name: string;
  image: string;
}

export interface ImageUrl {
  url: string;
}

/**
 * The service class to interact with the Products.
 */
export class ProductsService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/product');
  }

  getAllProducts(): Promise<GetAllProductsModel> {
    return this.get('');
  }

  getAllProductTypes(): Promise<ProductType[]> {
    return this.get('/types');
  }

  saveProduct(productToSave: Product): Promise<boolean> {
    return this.post('', productToSave);
  }

  updateProduct(productToUpdate: Product): Promise<boolean> {
    return this.put('', productToUpdate);
  }

  delete(productId: number): Promise<void> {
    return super.delete(`/${productId}`);
  }

  /**
   *
   * This API uploads an image chosen from the product modal.
   *
   * @param image - the image to be uploaded.
   *
   * @returns the URL as a string to the resource.
   */
  uploadImage(imageName: string, bytes: string): Promise<ImageUrl> {
    return this.post('/image', {
      name: imageName,
      image: bytes,
    } as UploadImage);
  }
}
