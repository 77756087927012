import useCurrentLanguage from './useCurrentLanguage';

/**
 *
 * This hook returns the always updated current language.
 *
 * @returns the current language in string.
 */
export function useCurrentLocale(): string {
  const language = useCurrentLanguage();

  if (language.includes('-CH')) {
    return language;
  }

  return `${language}-CH`;
}
