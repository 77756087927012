import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

import getMsalConfig from '@/authConfig';

const configPromise = fetch('./config.json').then((response) => response.json());

/**
 * Acquires an access token to call the meeting API
 * @returns A Promise object, that has the function that returns the access token.
 */
export const acquireAccessToken = async (): Promise<string> => {
  const data = await configPromise;

  return acquireToken(data);
};

/**
 * Acquires an access token to call the MS Graph OneDrive API
 * @returns A Promise object, that has the function that returns the access token.
 */
export const acquireFileToken = async (): Promise<string> => {
  const data = await configPromise;

  return acquireToken(data);
};

/**
 * Acquires an access token
 * @returns A Promise object, that has the function that returns the access token.
 */
export const acquireToken = async (data: any): Promise<string> => {
  const pcaConfig = getMsalConfig(data, LogLevel.Error);
  const msalInstance = new PublicClientApplication(pcaConfig);
  const accounts = msalInstance.getAllAccounts();

  const request = {
    scopes: data.auth.apiScopes,
    account: accounts[0],
  };
  return msalInstance.acquireTokenSilent(request).then((authResult) => {
    return `${authResult.accessToken}`;
  });
};

/**
 * Signs-out an user and redirects her to a default page.
 * @param data the application configuration.
 */
export const logout = async () => {
  const data = await configPromise;

  const pcaConfig = getMsalConfig(data, LogLevel.Error);
  const logoutConfig = { ...pcaConfig };
  logoutConfig.auth.postLogoutRedirectUri = pcaConfig.auth.redirectUri + '/sign-out.html';

  const msalInstance = new PublicClientApplication(logoutConfig);
  const accounts = msalInstance.getAllAccounts();

  const logoutRequest = {
    account: accounts[0],
  };

  msalInstance.logoutRedirect(logoutRequest);
};
