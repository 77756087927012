import SaveButton from '../../../../common/save-button/SaveButton';
import CancelButton from '../../../../common/cancel-button/CancelButton';
import DeleteButton from '../../../../common/delete-button/DeleteButton';
import { useState } from 'react';
import useErrorHandling from '../../../../../hooks/useErrorHandling';
import useIsReadOnly from '../../../../../hooks/useIsReadOnly';
import EditButton from '@/components/common/edit-button/EditButton';

/**
 * The InlineActionButton props.
 */
interface InlineActionButtonProps {
  /**
   * The unique identifier for the in edit row.
   */
  rowId: string;

  /**
   * The id of the margin as server by the API from the DB.
   * Optional because the margin might not be saved yet.
   */
  marginId?: number;

  /**
   * If the row is currently in edit mode.
   */
  inEdit: boolean;

  /**
   * Callback to save the row in edit.
   */
  saveMargin: (rowId: string) => Promise<void>;

  /**
   * Callback to save the row in edit.
   */
  editMargin: (rowId: string) => void;

  /**
   * Cancel the changes that are being made in edit mode.
   */
  cancelEdit: (rowId: string) => void;

  /**
   * Callback for deleting the margin from the records.
   */
  deleteMargin: (marginId: number) => Promise<void>;
}

export const InlineActionButtons = ({
  rowId,
  marginId,
  inEdit,
  saveMargin,
  editMargin,
  cancelEdit,
  deleteMargin,
}: InlineActionButtonProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isReadOnly = useIsReadOnly();

  const { errors, setErrors } = useErrorHandling();

  return inEdit ? (
    <td className="k-command-cell d-flex">
      <SaveButton
        loading={isLoading}
        onClick={async () => {
          setIsLoading(true);

          await saveMargin(rowId);

          setIsLoading(false);
        }}
      />

      <CancelButton onClick={() => cancelEdit(rowId)} />
    </td>
  ) : (
    <td className="k-command-cell d-flex justify-content-around">
      {!isReadOnly && (
        <DeleteButton
          loading={isLoading}
          onClick={async () => {
            if (marginId) {
              setIsLoading(true);

              await deleteMargin(marginId);

              setIsLoading(false);
            } else {
              setErrors([
                ...errors,
                { title: 'Margin id is undefined.', statusCode: '', value: { description: 'Margin id is undefined.' } },
              ]);
            }
          }}
        />
      )}
      {!isReadOnly && (    
        <EditButton
          onClick={async () => {
            setIsLoading(true);
            
            await editMargin(rowId);

            setIsLoading(false);
          }}
        />
      )}
    </td>
  );
};
