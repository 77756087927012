import { BaseService } from './BaseService';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { Dashboard } from '../models';

/**
 * The interface for the getDashboardData request
 */
export interface GetDashboardDataRequest {
  /**
   * The shops to filter for.
   * Only the id of the shop is needed.
   */
  shopsId: number[];

  /**
   * The products to filter for.
   * Only the id of the product is needed.
   */
  productsId: number[];
}

/**
 * The service class to interact with the Disporegions.
 */
export class DashboardService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/dashboard');
  }

  /**
   * Retrieves the dashboard data.
   * @returns the dashboard data.
   */
  getDashboardData(request: GetDashboardDataRequest): Promise<Dashboard> {
    return this.post('', request);
  }
}
