import { useTranslation } from 'react-i18next';

interface CopyButtonProps {
  onClick: () => void;
}

export default function CopyButton({ onClick }: CopyButtonProps): JSX.Element | null {
  const { t } = useTranslation();

  return (
    <button className="btn btn-primary btn-sm mx-1" type="button" onClick={onClick}>
      <span>{t('Views.CopyButton')}</span>
    </button>
  );
}
