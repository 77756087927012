import './EditUserProfile.scss';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import withModal from '../../../hoc/with-modal/withModal';
import useModal from '../../../../hooks/useModal';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import useErrorHandling from '../../../../hooks/useErrorHandling';

import { useCallback, useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';
import useYupErrors, { YupError } from '../../../../hooks/useYupErrors';
import { Language, UserProfileEditModel, UserShop, Shop, ErrorViewModel, Holding } from '../../../../models';
import { getUserProfileEditModelValidationSchema } from './ValidationSchema';
import useUserProfilesService from '../../../../hooks/useUserProfileService';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import useShopsService from '../../../../hooks/useShopsService';
import styled from 'styled-components';
import { AllShopsName } from '../../../../assets/constants/DataConstants';
import useHoldingsService from '../../../../hooks/useHoldingsService';
import { Constants } from '../../../../constants/Constants';
import Spinner from '../../../common/spinner/Spinner';

/**
 *
 * This interface describes the shape of the
 * holdings dropdown values.
 *
 */
interface DropdownHolding {
  id: number;
  name: string;
}

/**
 *
 * This interface describes the Yup error schema.
 *
 */
interface UserProfileEditModelError {
  email: string;
}

/**
 *
 * Dummy object to initialize the error schema.
 *
 */
const dummyYupErrorSchemaInitializer = {
  email: '',
};

/**
 *
 * This interface describes the shape for a shop in the
 * textarea after it's been added to a user.
 *
 */
interface ListShop {
  id: number;
  name: string;
}

const ListShopContainer = styled.div`
  margin: 0.5em;
`;

/**
 *
 * This interface defines the EditUserProfile props.
 *
 */
interface EditUserProfileProps {
  userId: number;
}

/**
 *
 * This Component describes the UI for adding/updating a user
 * from the user grid.
 *
 * @param id - the id of the user to edit.
 *
 * @returns the Component to be used in JSXs.
 */
function EditUserProfile({ userId }: EditUserProfileProps) {
  const { handleSave } = useModal();
  const { errors, setErrors } = useErrorHandling();

  const { t } = useTranslation();

  const usersProfileService = useUserProfilesService();
  const shopsService = useShopsService();
  const holdingsService = useHoldingsService();

  const editModelValidationSchema = useMemo(() => getUserProfileEditModelValidationSchema(t), [t]);
  const mapUpdateModelErrors = useYupErrors<UserProfileEditModelError>(dummyYupErrorSchemaInitializer);
  const [editModelValidationErrors, setEditModelValidationErrors] =
    useState<YupError<UserProfileEditModelError> | null>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [saving, setSaving] = useState<boolean>(false);

  const [id, setId] = useState<number>(0);
  const [email, setEmail] = useState<string>('');
  const [language, setLanguage] = useState<Language>(Language.DE);
  const [readOnly, setReadonly] = useState<boolean>(false);
  const [dieselAdministrator, setDieselAdministrator] = useState<boolean>(false);
  const [roleName, setRoleName] = useState<string>(Constants.Roles.Administrator);

  const [holdings, setHoldings] = useState<Holding[]>();
  const [selectedHolding, setSelectedHolding] = useState<DropdownHolding | null>();

  const noneDropdownValue = useMemo<DropdownHolding>(
    () => ({
      id: 0,
      name: t('EditUserProfile.NoneHolding'),
    }),
    [t],
  );

  const [shops, setShops] = useState<Shop[]>();
  const [selectedShop, setSelectedShop] = useState<Shop>();
  const [assignedShops, setAssignedShops] = useState<Shop[]>([]);

  /**
   *
   * This useEffect loads the appropriate user profile edit model
   * whether it's an update or a create.
   *
   */
  useEffect(() => {
    async function retrieveAllShops() {
      try {
        const shops = await shopsService.getAll();
        setShops(shops);

        return shops;
      } catch (error) {
        console.error(error);

        setErrors([...errors, error as ErrorViewModel]);
      }
    }

    async function retrieveAllHoldings() {
      try {
        const allHoldings = await holdingsService.getAll();
        setHoldings(allHoldings);

        return allHoldings;
      } catch (error) {
        console.error(error);

        setErrors([...errors, error as ErrorViewModel]);
      }
    }

    async function retrieveUserProfile(userId: number) {
      try {
        return await usersProfileService.load(userId);
      } catch (error) {
        console.error(error);

        setErrors([...errors, error as ErrorViewModel]);
      }
    }

    function setUserData(
      id: number,
      email: string,
      language: Language,
      readOnly: boolean,
      roleName: string,
      dieselAdministrator: boolean,
    ) {
      setId(id);
      setEmail(email);
      setLanguage(language);
      setReadonly(readOnly);
      setRoleName(roleName);
      setDieselAdministrator(dieselAdministrator);
    }

    async function setupEnvironment() {
      const shopsList = await retrieveAllShops();
      const allHoldings = await retrieveAllHoldings();

      if (!shopsList) {
        return;
      }

      if (!allHoldings) {
        return;
      }

      if (userId !== 0) {
        const userProfile = await retrieveUserProfile(userId);

        if (!userProfile) {
          return;
        }

        userProfile.readOnly = userProfile.readOnly ?? false;

        if (userProfile.roleName !== Constants.Roles.Administrator) {
          const assignedShopIds = userProfile.userShops.map((userShop) => userShop.shopId);
          setAssignedShops(shopsList.filter((shop) => assignedShopIds.some((shopId) => shop.id === shopId)));
        }

        setUserData(
          userProfile.id,
          userProfile.email,
          userProfile.language,
          userProfile.readOnly,
          userProfile.roleName,
          userProfile.dieselAdministrator,
        );

        if (userProfile.holdingId) {
          const holding = allHoldings.find((holding) => holding.id === userProfile.holdingId);

          if (!holding) {
            setErrors([
              ...errors,
              {
                title: "Couldn't get the holding associated to the user.",
                statusCode: '',
                value: { description: "Couldn't get the holding associated to the user." },
              },
            ]);

            return;
          }

          setSelectedHolding(holding as DropdownHolding);
        }
      }

      setIsLoading(false);
    }

    setupEnvironment();
  }, []);

  const dropdownHoldings = useMemo<DropdownHolding[]>(() => {
    return (
      holdings?.map((holding) => ({
        id: holding.id,
        name: holding.name,
      })) || []
    );
  }, [holdings]);

  /**
   *
   * This callback takes care of calling the create user API
   * using the appropriate service.
   *
   */
  const create = useCallback(
    async (model: UserProfileEditModel) => {
      usersProfileService
        .create(model)
        .then(() => {
          handleSave!();
        })
        .catch((error) => {
          console.error(error);

          setErrors([...errors, error]);
          setSaving(false);
        });
    },
    [usersProfileService, handleSave, setErrors, errors, setSaving],
  );

  /**
   *
   * This callback takes care of calling the update user API
   * using the appropriate service.
   *
   */
  const update = useCallback(
    async (model: UserProfileEditModel) => {
      usersProfileService
        .update(model)
        .then(() => {
          handleSave!();
        })
        .catch((error) => {
          console.error(error);

          setErrors([...errors, error]);
          setSaving(false);
        });
    },
    [usersProfileService, handleSave, setErrors, errors, setSaving],
  );

  /**
   *
   * This callback calls the API for retrieving the shops
   * linked to a holding and assigns them to the user.
   *
   */
  const assignHoldingShops = useCallback(
    async (holdingId: number) => {
      try {
        const holdingShops = await holdingsService.getHoldingShops(holdingId);
        setAssignedShops(holdingShops);
      } catch (error) {
        console.error(error);

        setErrors([...errors, error as ErrorViewModel]);
      }
    },
    [holdingsService, setAssignedShops],
  );

  useEffect(() => {
    if (selectedHolding) {
      assignHoldingShops(selectedHolding.id);
    } else if (selectedHolding === null) {
      setAssignedShops([]);
    }
  }, [selectedHolding]);

  if (isLoading) {
    return (
      <div className="container px-2 d-flex justify-content-center">
        <div className="spinner-border spinner-border" role="status">
          <span className="visually-hidden">{t('EditUserProfile.Save')}</span>
        </div>
      </div>
    );
  }

  return (
    <>
      {!userId && (
        <div className="d-flex align-items-center">
          <div className="col-md-3 p-2">
            <span>{t('EditUserProfile.Email')}</span>
          </div>
          <div className="col-md-9 p-2">
            <input
              type="text"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            {editModelValidationErrors?.email && (
              <small className="position-relative text-danger">{editModelValidationErrors.email}</small>
            )}
          </div>
        </div>
      )}
      <div className="d-flex align-items-center">
        <div className="col-md-3 p-2">
          <span>{t('EditUserProfile.Role')}</span>
        </div>
        <div className="col-md-5 p-2">
          <ButtonGroup className="align-self-start">
            <ToggleButton
              id="role-administrator"
              name="role"
              type="radio"
              value={Constants.Roles.Administrator}
              variant={roleName === Constants.Roles.Administrator ? 'primary' : 'light'}
              checked={roleName === Constants.Roles.Administrator}
              onChange={(e) => {
                if (confirm(t('EditUserProfile.SwitchToAdministratorWarning'))) {
                  setRoleName(e.currentTarget.value);
                }
              }}
            >
              {t('Roles.Administrator')}
            </ToggleButton>
            <ToggleButton
              id="role-user"
              name="role"
              type="radio"
              value={Constants.Roles.ShopUser}
              variant={
                roleName === Constants.Roles.ShopUser || roleName === Constants.Roles.HoldingUser ? 'primary' : 'light'
              }
              checked={roleName === Constants.Roles.ShopUser || roleName === Constants.Roles.HoldingUser}
              onChange={(e) => {
                if (confirm(t('EditUserProfile.SwitchToShopUserWarning'))) {
                  setRoleName(e.currentTarget.value);
                }
              }}
            >
              {t('Roles.ShopUser')}
            </ToggleButton>
          </ButtonGroup>
        </div>

        <div className="d-flex col-md-4 align-items-center justify-content-end px-2">
          <span className="px-2">{t('EditUserProfile.Holding')}</span>
          <DropDownList
            data={dropdownHoldings}
            defaultItem={noneDropdownValue}
            dataItemKey="id"
            textField="name"
            value={selectedHolding}
            onChange={(e) => setSelectedHolding(e.target.value === noneDropdownValue ? null : e.target.value)}
            disabled={roleName === Constants.Roles.Administrator}
          />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="col-md-3 p-2">
          <span>{t('EditUserProfile.ReadOnly')}</span>
        </div>
        <div className="col-md-5 p-2">
          <ButtonGroup className="align-self-start">
            <ToggleButton
              id="readOnly-false"
              name="readOnly"
              type="radio"
              value="false"
              variant={readOnly === false ? 'primary' : 'light'}
              checked={readOnly === false}
              onChange={(e) => setReadonly(e.currentTarget.value === 'false' ? false : true)}
            >
              {t('EditUserProfile.False')}
            </ToggleButton>
            <ToggleButton
              id="readOnly-true"
              name="readOnly"
              type="radio"
              value="true"
              variant={readOnly === true ? 'primary' : 'light'}
              checked={readOnly === true}
              onChange={(e) => setReadonly(e.currentTarget.value === 'false' ? false : true)}
            >
              {t('EditUserProfile.True')}
            </ToggleButton>
          </ButtonGroup>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="col-md-3 p-2">
          <span>{t('EditUserProfile.DieselAdministrator')}</span>
        </div>
        <div className="col-md-5 p-2">
          <ButtonGroup className="align-self-start">
            <ToggleButton
              id="dieselAdministrator-false"
              name="dieselAdministrator"
              type="radio"
              value="false"
              variant={dieselAdministrator === false ? 'primary' : 'light'}
              checked={dieselAdministrator === false}
              onChange={(e) => setDieselAdministrator(e.currentTarget.value === 'false' ? false : true)}
            >
              {t('EditUserProfile.False')}
            </ToggleButton>
            <ToggleButton
              id="dieselAdministrator-true"
              name="dieselAdministrator"
              type="radio"
              value="true"
              variant={dieselAdministrator === true ? 'primary' : 'light'}
              checked={dieselAdministrator === true}
              onChange={(e) => setDieselAdministrator(e.currentTarget.value === 'false' ? false : true)}
            >
              {t('EditUserProfile.True')}
            </ToggleButton>
          </ButtonGroup>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="col-md-3 p-2">
          <span>{t('EditUserProfile.Language')}</span>
        </div>
        <div className="col-md-5 p-2">
          <ButtonGroup className="align-self-start">
            <ToggleButton
              id={'language-' + Language.DE}
              name="language"
              type="radio"
              value={Language.DE}
              variant={language === Language.DE ? 'primary' : 'light'}
              checked={language === Language.DE}
              onChange={(e) =>
                setLanguage(e.currentTarget.value === Language.DE.toString() ? Language.DE : Language.FR)
              }
            >
              {t('Language.German')}
            </ToggleButton>
            <ToggleButton
              id={'language-' + Language.FR}
              name="language"
              type="radio"
              value={Language.FR}
              variant={language === Language.FR ? 'primary' : 'light'}
              checked={language === Language.FR}
              onChange={(e) =>
                setLanguage(e.currentTarget.value === Language.DE.toString() ? Language.DE : Language.FR)
              }
            >
              {t('Language.French')}
            </ToggleButton>
          </ButtonGroup>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-start p-2">
        <span className="mb-1">{t('EditUserProfile.AddShops')}</span>
        <div className="d-flex mb-2">
          <div className="d-flex flex-column align-items-stretch" style={{ flex: 1 }}>
            <DropDownList
              className="mb-3 w-100"
              data={shops?.filter((shop) => shop.name !== AllShopsName)}
              value={selectedShop}
              textField="name"
              dataItemKey="id"
              onChange={(e) => setSelectedShop(e.target.value)}
              disabled={!!selectedHolding || roleName === Constants.Roles.Administrator}
            />

            <div style={{ minHeight: 100, backgroundColor: '#e4e7eb' }}>
              {roleName === Constants.Roles.Administrator ? (
                <ListShopContainer>
                  <span>{t('EditUserProfile.AllShops')}</span>
                </ListShopContainer>
              ) : (
                assignedShops.map((shop, index) => (
                  <ListShopContainer key={index}>
                    <span>{shop.name}</span>
                    {!selectedHolding && (
                      <button
                        type="button"
                        className="ms-2 btn btn-danger btn-sm"
                        onClick={() => {
                          const shopToRemove = assignedShops.findIndex((assignedShop) => assignedShop.id === shop.id);
                          const newShops = [...assignedShops];
                          newShops.splice(shopToRemove, 1);

                          setAssignedShops(newShops);
                        }}
                        disabled={assignedShops.length === 1}
                      >
                        {t('EditUserProfile.Remove')}
                      </button>
                    )}
                  </ListShopContainer>
                ))
              )}
            </div>
          </div>

          <button
            type="button"
            className="ms-2 btn btn-primary p-1 pt-0 pb-0 rounded-circle fw-bold align-self-start"
            onClick={() => {
              if (selectedShop && !assignedShops.some((shop) => shop.id === selectedShop.id)) {
                setAssignedShops([...assignedShops, selectedShop]);
              }
            }}
            disabled={!!selectedHolding || roleName === Constants.Roles.Administrator}
          >
            <span style={{ fontSize: 36, lineHeight: 0.65, alignSelf: 'center' }}>+</span>
          </button>
        </div>
      </div>

      <div className="d-flex align-items-center modal-footer pb-0 pe-0">
        <div className="col text-end">
          <button
            className="btn btn-primary"
            type="button"
            onClick={async () => {
              try {
                const editModel: UserProfileEditModel = {
                  id,
                  email,
                  language,
                  readOnly,
                  roleName,
                  userShops:
                    roleName === Constants.Roles.Administrator
                      ? []
                      : assignedShops.map<UserShop>((shop) => ({
                          shopId: shop.id,
                        })),
                  holdingId: selectedHolding?.id,
                  dieselAdministrator,
                };

                await editModelValidationSchema.validate(editModel, { abortEarly: false });

                setEditModelValidationErrors(null);

                setSaving(true);

                if (editModel.id === 0) {
                  await create(editModel);
                } else {
                  await update(editModel);
                }
              } catch (error) {
                setEditModelValidationErrors(mapUpdateModelErrors(error as ValidationError));

                setSaving(false);
              }
            }}
            disabled={saving}
          >
            {saving ? <Spinner /> : t('EditUserProfile.Save')}
          </button>
        </div>
      </div>
    </>
  );
}

export default withModal(withErrorHandling(EditUserProfile));
