import { CSSProperties, useState } from 'react';
import Spinner from '../spinner/Spinner';

/**
 * The GridButton props.
 */
interface GridButtonProps {
  /**
   * The text for the button, if any.
   */
  text: string;

  /**
   * Indicates whether this button should use the integrated loading while
   * performing the onClick action.
   */
  useLoading: boolean;

  /**
   * Additional class names for styles that can be passed.
   */
  className?: string;

  /**
   * Additional styles to pass to the button node.
   */
  style?: CSSProperties;

  /**
   * The onClick callback.
   */
  onClick: () => void | Promise<void>;
}

export default function GridButton({
  text,
  useLoading,
  style,
  onClick,
  className = 'btn-primary',
}: GridButtonProps): JSX.Element | null {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <button
      className={`btn btn-sm ${className}`}
      type="button"
      style={style}
      disabled={useLoading ? loading : false}
      onClick={() => {
        if (useLoading) {
          setLoading(true);

          Promise.resolve(onClick()).finally(() => setLoading(false));
        } else {
          onClick();
        }
      }}
    >
      {useLoading ? loading ? <Spinner /> : <span>{text}</span> : <span>{text}</span>}
    </button>
  );
}
