import { useTranslation } from 'react-i18next';

/**
 * The loading panel that contains the spinning circle that appears when any page is loading data from the remote API.
 */
export default function LoadingPanel() {
  const { t } = useTranslation();

  return (
    <div className="k-loading-mask">
      <span className="k-loading-text">{t('CommonLabels.Loading')}</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
}
