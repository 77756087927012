import { PostalCode, Product, Shop } from '../../../../models/index';

/**
 * The model that describes the zones grid data.
 */
export interface IGridZone {
  id?: number;
  zoneId?: number;
  name?: string;
  newsletter?: boolean;
  shopName?: string;
  shop?: Shop;
  shopId: number;
  woodDisporegionId?: number;
  fuelDisporegionId?: number;
  dieselDisporegionId?: number;
  products?: Product[];
  postalCodes: PostalCode[];
  unloadingFee: number;
  useTktm: boolean;
  transportCostPrice: number;
  inEdit?: string | undefined;
}

const toCheckKey: { [key in keyof IGridZone]: 0 } = {
  id: 0,
  zoneId: 0,
  name: 0,
  newsletter: 0,
  shopName: 0,
  shop: 0,
  shopId: 0,
  woodDisporegionId: 0,
  fuelDisporegionId: 0,
  dieselDisporegionId: 0,
  products: 0,
  postalCodes: 0,
  unloadingFee: 0,
  useTktm: 0,
  transportCostPrice: 0,
  inEdit: 0,
};

export function IsIGridZoneKey(key: string | null | undefined): key is keyof IGridZone {
  if (!key) {
    return false;
  }

  return Object.keys(toCheckKey).includes(key);
}
