import { useTranslation } from 'react-i18next';

/**
 * @returns The spinner that is used inside of the modal forms buttons.
 */
const Spinner = () => {
  const { t } = useTranslation();

  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="visually-hidden">{t('CommonLabels.Loading')}...</span>
    </div>
  );
};

export default Spinner;
