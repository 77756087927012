import { useContext } from 'react';
import AgrApplicationStateContext, { IAgrApplicationContextValues } from '../contexts/AgrApplicationStateContext';

/**
 * creates the application state context.
 * @returns the application state context.
 */
export default function useApplicationState(): IAgrApplicationContextValues {
  const appContext = useContext(AgrApplicationStateContext);

  return appContext!;
}
