import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { ShopTypesService } from '../services/ShopTypesService';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * ShopsService to be used in components to make API calls.
 *
 * @returns a ShopsService instance
 */
export default function useShopTypesService(): ShopTypesService {
  const config = useContext(AgrConfigurationContext);

  const shopService = useMemo(() => {
    return new ShopTypesService(config);
  }, [config]);

  return shopService;
}
