export type ENV = 'LOCAL' | 'DEV' | 'TEST' | 'PROD';

const getEnv = (): ENV => {
  const domain = document.location.host;
  switch (domain) {
    case 'localhost:3000':
      return 'LOCAL';
    case 'fenacodevapsmodadmnst.z1.web.core.windows.net':
      return 'DEV';
    case 'fenacotestapsmodadmnst.z1.web.core.windows.net':
      return 'TEST';
    default:
    case 'fenacoprodapsmodadmnst.z1.web.core.windows.net':
      return 'PROD';
  }
};

export const envService = {
  env: getEnv(),
};
