import React from 'react';

interface ViewWrapperProps {
  title: string | React.ReactNode;
  addButton?: React.ReactNode;
  children: React.ReactNode;
}

export default function ViewWrapper({ title, addButton, children }: ViewWrapperProps): JSX.Element | null {
  return (
    <div className="full">
      <div className="mt-1 mb-2 d-flex align-items-center" style={{ marginLeft: 20 }}>
        <strong className="h2" style={{ fontWeight: 600 }}>
          {title}
        </strong>
        {addButton && addButton}
      </div>

      {children}
    </div>
  );
}
