import { BaseService } from './BaseService';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { Disporegion } from '../models';

/**
 * The service class to interact with the Disporegions.
 */
export class DisporegionsService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/disporegion');
  }

  /**
   * Retrieves all the disporegions.
   * @returns A list of all the disporegions.
   */
  getAll(): Promise<Disporegion[]> {
    return this.get('/list');
  }

  delete(dispoRegionId: number) {
    return super.delete(`/${dispoRegionId}`);
  }

  update(disporegion: Disporegion) {
    return this.put('', disporegion);
  }

  add(disporegion: Disporegion) {
    return this.post('', disporegion);
  }
}
