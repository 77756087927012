import { IPagedResultViewModel, Language, Order, OrderStatus } from '../models';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { BaseService } from './BaseService';

/**
 * The service class to interact with the Orders.
 */
export class OrdersService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/orders');
  }

  saveOrder(order: Order): Promise<number> {
    return this.post('', order);
  }

  updateOrder(order: Order): Promise<number> {
    return this.put('', order);
  }

  getAllPaged(filter: string): Promise<IPagedResultViewModel<Order>> {
    return this.get('?' + filter);
  }

  getOrderPdfById(order: Order, lang: string): Promise<string> {
    return this.get(`/download/${order.id}/${lang}`);
  }

  updateOrderStatus(
    orderId: number | null | undefined,
    orderStatus: OrderStatus | null | undefined,
    ourComment: string | null | undefined,
    internalRemarks: string | null | undefined,
  ): Promise<string> {
    return this.put(`/status`, {
      orderId: orderId,
      orderStatus: orderStatus,
      ourComment: ourComment,
      internalRemarks: internalRemarks,
    });
  }

  sendStatusChangeNotification(
    orderId: number | null | undefined,
    previousStatus: OrderStatus | undefined | null,
    sender: string | null | undefined,
    recipient: string | null | undefined,
    language: Language | null | undefined,
  ): Promise<string> {
    return this.put('/notify', {
      orderId: orderId,
      previousStatus: previousStatus,
      sender: sender,
      recipient: recipient,
      language: language,
    });
  }

  getCSV(filter: string): Promise<string> {
    return this.get(`/csv?` + filter);
  }
}
