import { BaseService } from './BaseService';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { Holding } from '../models/Holding';
import { HoldingBasePrice, Shop } from '../models';

/**
 * The service class to interact with the Holdings.
 */
export class HoldingsService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/holdings');
  }

  /**
   * Retrieves all the holdings.
   * @returns A list of all the holdings
   */
  getAll(): Promise<Holding[]> {
    return this.get('');
  }

  getHoldingShops(holdingId: number): Promise<Shop[]> {
    return this.get(`/shop/${holdingId}`);
  }

  setHoldingBasePrice(holdingBasePrice: Omit<HoldingBasePrice, 'holding' | 'product'>): Promise<number> {
    return this.post('/baseprice', holdingBasePrice);
  }

  getHoldingBasePrices(holdingId: number, productTypeId: number): Promise<HoldingBasePrice[]> {
    return this.get(`/${holdingId}/${productTypeId}`);
  }
}
