import { useTranslation } from 'react-i18next';
import Spinner from '../spinner/Spinner';

interface SaveButtonProps {
  loading?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export default function SaveButton({ loading, disabled, onClick }: SaveButtonProps): JSX.Element | null {
  const { t } = useTranslation();

  return (
    <button className="btn btn-primary btn-sm" type="button" disabled={disabled} onClick={onClick}>
      {loading ? <Spinner /> : <span>{t('Views.SaveButton')}</span>}
    </button>
  );
}
