import { useContext } from 'react';
import ModalContext, { ModalContextValues } from '../contexts/ModalContext';

/**
 *
 * This hook gets the order form context in order to read/set
 * its values in the Components that need it.
 *
 * @returns the order form context values.
 */
export default function useModal(): ModalContextValues {
  const orderFormContext = useContext(ModalContext);

  return orderFormContext!;
}
