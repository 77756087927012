import withModal from '../../../hoc/with-modal/withModal';
import { PostalCode } from '../../../../models';

/**
 * The InspectPayloadDetails props.
 * payload indicates the json payload as from the Logs grid.
 */
interface InspectPostalCodesProps {
  postalCodes: PostalCode[];
}

/**
 *
 * The InspectPayloadDetails Component.
 *
 * @returns the Component to be used in JSXs
 */
function InspectPostalCodes({ postalCodes }: InspectPostalCodesProps) {
  return (
    <div className="p-2">
      {postalCodes.map((postalCode) => (
        <>
          <span>
            {postalCode.npa} {postalCode.name}
          </span>
          <br />
        </>
      ))}
    </div>
  );
}

export default withModal(InspectPostalCodes);
