import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AgrConfigurationContext from '../../../../contexts/AgrConfigurationContext';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';

const initialSort: Array<SortDescriptor> = [{ field: 'name', dir: 'asc' }];

/**
 * This interface describes the shape of the items in the grid.
 */
interface GridPortal {
  /**
   * Url to the online landing form page of the order process.
   */
  url: string;
}

/**
 *
 * This View shows all the available entry points for the order process.
 *
 * @returns the Component to be used in JSXs.
 */
export default function ViewPortalIntegration() {
  const { t } = useTranslation('translation', { keyPrefix: 'ViewPortalIntegration' });
  const language = useCurrentLanguage();
  const currentLocale = useCurrentLocale();

  const { portalIntegrationUrls } = useContext(AgrConfigurationContext)!;

  const [sort, setSort] = useState(initialSort);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();

  const gridPortals = useMemo<GridPortal[]>(
    () =>
      portalIntegrationUrls
        .filter((url) => url)
        .map<GridPortal>((url) => ({
          url,
        })),
    [],
  );

  return (
    <ViewWrapper title={t('Title')}>
      <LocalizationProvider language={language}>
        <IntlProvider locale={currentLocale}>
          <Grid
            data={filter ? filterBy(orderBy(gridPortals, sort), filter) : orderBy(gridPortals, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
            filterable={!!gridPortals.length}
            filter={filter}
            resizable={true}
            onFilterChange={(e) => setFilter(e.filter)}
            style={{ margin: '0 20px 0 20px' }}
          >
            <Column field="url" title={t('Grid.ColumnLink')} />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </ViewWrapper>
  );
}
