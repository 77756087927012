import styled from 'styled-components';
import { useDrag } from 'react-dnd';
import { dndPostalCodeType, DnDPostalCode, DropResult } from '../dnd-types';
import { PostalCode, Zone } from '../../../../models';
import useIsReadOnly from '../../../../hooks/useIsReadOnly';

/**
 * The postal code DnD item styles.
 */
const Container = styled.div`
  border: 1px solid gray;
  background-color: white;
  color: black;
  padding: 0.5em;
  margin: 0.2em 0 0.2em 0;
`;

/**
 * The postal code item props.
 */
interface PostalCodeItemProps {
  /**
   * The postal code
   */
  postalCode: PostalCode;

  /**
   * Callback for updating the displayed zones.
   */
  performDragAndDrop: (postalCodeToUpdate: DropResult) => Promise<void>;

  /**
   * The zone to which the postal code is associated.
   * Used to disable drag & drop onto the same column (which means dropping in the same zone).
   * This parameter is optional for this reason: it is useful only in the DnD scenario.
   */
  zone?: Zone;
}

export default function PostalCodeItem({ postalCode, performDragAndDrop, zone }: PostalCodeItemProps) {
  const readOnly = useIsReadOnly();

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: dndPostalCodeType,
      canDrag: () => !readOnly,
      end: (item, monitor) => {
        if (monitor.didDrop()) {
          const targetPostalCode = monitor.getDropResult() as DropResult;

          performDragAndDrop(targetPostalCode);
        }
      },
      item: () => ({ postalCode, zone } as DnDPostalCode),
      collect: (monitor) => ({ opacity: monitor.isDragging() ? 0.5 : 1 }),
    }),
    [dndPostalCodeType, readOnly, performDragAndDrop],
  );

  return (
    <Container style={{ opacity }} ref={drag}>
      <span>{postalCode.npa}</span>&nbsp;
      <span>{postalCode.name}</span>
    </Container>
  );
}
