import { PriceCalculationResult } from '../../models';

/**
 * This constant is necessary in order to map the AllShops
 * entity in the database to its id.
 * We know that the name for indicating all the shops is always
 * going to be AllShops, however the id might not be the same everytime.
 */
export const AllShopsName = 'AllShops';

/**
 * The constant indicating the pellets product type name as in the
 * database.
 */
export const WoodProductTypeName = 'Wood';

/**
 * The constant indicating the fuel product type name as in the
 * database.
 */
export const FuelProductTypeName = 'Fuel';

/**
 * The constant indicating the diesel product type name as in the
 * database.
 */
export const DieselProductTypeId = 100;
export const DieselProductTypeName = 'Diesel';

/**
 * template substitution key for the unit price.
 */
export type ProductTemplateKeys =
  | `{{${keyof (Omit<PriceCalculationResult, 'discountedPhraseDE' | 'discountedPhraseFR' | 'discountedPrice'> & {
      quantity: string;
      discount: string;
      image: string;
      quantityBasedDiscount: string;
    })}}}`;

/**
 * Supported placeholders for discount phrase.
 */
export type DiscountPhraseKeys =
  | 'MinimalQuantity'
  | 'CHFAmount'
  | 'CHFAmountTotal'
  | 'EmailContribution'
  | 'PromotionName';
