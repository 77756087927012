import { object, boolean, string, number, array, ref, date, TestContext } from 'yup';
import { PromotionType } from '../../../../models';
import { ValidityZone, ProductTemplateItem } from './AddPromotionForm';

/**
 *
 * This file exports the function in order to get the validation schema
 * with the appropriate translation at runtime.
 *
 * @param t - The translation function as provided by the useTranslation
 *            i18next hook.
 *
 * @returns the validation schema with the appropriate translations.
 */
export default function getPromotionFormValidationSchema(t: any) {
  const requiredErrorSuffix = t('ValidationErrors.Required');
  const numberErrorSuffix = t('ValidationErrors.Number');
  const floatingPointErrorSuffix = t('ValidationErrors.FloatingPointNumber');

  const mustBeGreaterThan = t('AddPromotionModal.ValidationErrors.MustBeGreaterThan');
  const mustBeEqualOrLowerThan = t('AddPromotionModal.ValidationErrors.MustBeEqualOrLowerThan');

  const schema = object().shape({
    productType: object().required(`${t('AddPromotionModal.ValidationErrors.TheProductType')} ${requiredErrorSuffix}`),
    promotionForSingleProduct: boolean().required(
      `${t('AddPromotionModal.ValidationErrors.PromotionFor')} ${requiredErrorSuffix}`,
    ),
    product: object().when('promotionForSingleProduct', {
      is: true,
      then: object()
        .nullable()
        .required(`${t('AddPromotionModal.ValidationErrors.TheProduct')} ${requiredErrorSuffix}`),
      otherwise: object().nullable(),
    }),
    internalName: string().required(
      `${t('AddPromotionModal.ValidationErrors.TheInternalName')} ${requiredErrorSuffix}`,
    ),
    promotionType: number().required(
      `${t('AddPromotionModal.ValidationErrors.ThePromotionType')} ${requiredErrorSuffix}`,
    ),
    minimumQuantity: number()
      .typeError(`${t('AddPromotionModal.ValidationErrors.TheMinimumQuantity')} ${numberErrorSuffix}`)
      .min(
        0,
        `${t('AddPromotionModal.ValidationErrors.TheMinimumQuantity')} ${t(
          'AddPromotionModal.ValidationErrors.GreaterThanZero',
        )}`,
      )
      .required(`${t('AddPromotionModal.ValidationErrors.TheMinimumQuantity')} ${requiredErrorSuffix}`),
    maximumQuantity: number()
      .typeError(`${t('AddPromotionModal.ValidationErrors.TheMaximumQuantity')} ${numberErrorSuffix}`)
      .min(
        0,
        `${t('AddPromotionModal.ValidationErrors.TheMaximumQuantity')} ${t(
          'AddPromotionModal.ValidationErrors.GreaterThanZero',
        )}`,
      )
      .test(
        'maximum-greater-than-minimum',
        t('AddPromotionModal.ValidationErrors.MaximumQuantityGreaterThanMinimum'),
        (value, ctx) => {
          if (
            (value !== null || value !== undefined) &&
            (ctx.parent.minimumQuantity !== null || ctx.parent.minimumQuantity !== undefined)
          ) {
            return Number(value) > Number(ctx.parent.minimumQuantity);
          }

          return false;
        },
      )
      .required(`${t('AddPromotionModal.ValidationErrors.TheMaximumQuantity')} ${requiredErrorSuffix}`),
    amountToDiscount: number().when('promotionType', {
      is: (promotionType: PromotionType) =>
        promotionType === PromotionType.Coupon ||
        promotionType === PromotionType.CouponWithCode ||
        promotionType === PromotionType.Gift ||
        promotionType === PromotionType.GiftWithCode,
      then: number().typeError(`${t('AddPromotionModal.ValidationErrors.TheAmountToDiscount')} ${numberErrorSuffix}`),
      otherwise: number()
        .typeError(`${t('AddPromotionModal.ValidationErrors.TheAmountToDiscount')} ${numberErrorSuffix}`)
        .min(
          0,
          `${t('AddPromotionModal.ValidationErrors.TheAmountToDiscount')} ${t(
            'AddPromotionModal.ValidationErrors.GreaterThanZero',
          )}`,
        )
        .required(`${t('AddPromotionModal.ValidationErrors.TheAmountToDiscount')} ${requiredErrorSuffix}`),
    }),
    validityZone: number().required(
      `${t('AddPromotionModal.ValidationErrors.TheValidityZone')} ${requiredErrorSuffix}`,
    ),
    validFrom: date().required(`${t('AddPromotionModal.ValidationErrors.TheValidFrom')} ${requiredErrorSuffix}`),
    validTo: date()
      .min(ref('validFrom'), t('AddPromotionModal.ValidationErrors.BeginningDateLowerThanEnding'))
      .required(`${t('AddPromotionModal.ValidationErrors.TheValidTo')} ${requiredErrorSuffix}`),
    hourValidFrom: number()
      .typeError(`${t('AddPromotionModal.ValidationErrors.TheHourValidFrom')} ${numberErrorSuffix}`)
      .integer(`${t('AddPromotionModal.ValidationErrors.TheHourValidFrom')} ${floatingPointErrorSuffix}`)
      .min(0, `${t('AddPromotionModal.ValidationErrors.TheHourValidFrom')} ${mustBeGreaterThan} 0`)
      .max(23, `${t('AddPromotionModal.ValidationErrors.TheHourValidFrom')} ${mustBeEqualOrLowerThan} 23`)
      .nullable()
      .required(`${t('AddPromotionModal.ValidationErrors.TheHourValidFrom')} ${requiredErrorSuffix}`),
    hourValidTo: number().when(['validFrom', 'validTo'], {
      is: (validFrom: Date, validTo: Date) => validFrom?.getTime() === validTo?.getTime(),
      then: number()
        .typeError(`${t('AddPromotionModal.ValidationErrors.TheHourValidTo')} ${numberErrorSuffix}`)
        .integer(`${t('AddPromotionModal.ValidationErrors.TheHourValidTo')} ${floatingPointErrorSuffix}`)
        .min(0, `${t('AddPromotionModal.ValidationErrors.TheHourValidTo')} ${mustBeGreaterThan} 0`)
        .max(23, `${t('AddPromotionModal.ValidationErrors.TheHourValidTo')} ${mustBeEqualOrLowerThan} 23`)
        .nullable()
        .test(
          'hourValidTo-greater-than-hourValidFrom',
          t('AddPromotionModal.ValidationErrors.BeginningHourLowerThanEndingHour'),
          (value, ctx) => {
            if (
              (value !== null || value !== undefined) &&
              (ctx.parent.hourValidFrom !== null || ctx.parent.hourValidFrom !== undefined)
            ) {
              return Number(value) > Number(ctx.parent.hourValidFrom);
            }

            return false;
          },
        )
        .required(`${t('AddPromotionModal.ValidationErrors.TheHourValidTo')} ${requiredErrorSuffix}`),
      otherwise: number()
        .typeError(`${t('AddPromotionModal.ValidationErrors.TheHourValidTo')} ${numberErrorSuffix}`)
        .integer(`${t('AddPromotionModal.ValidationErrors.TheHourValidTo')} ${floatingPointErrorSuffix}`)
        .min(0, `${t('AddPromotionModal.ValidationErrors.TheHourValidTo')} ${mustBeGreaterThan} 0`)
        .max(23, `${t('AddPromotionModal.ValidationErrors.TheHourValidTo')} ${mustBeEqualOrLowerThan} 23`)
        .nullable()
        .required(`${t('AddPromotionModal.ValidationErrors.TheHourValidTo')} ${requiredErrorSuffix}`),
    }),
    validityDelimitation: number().required(
      `${t('AddPromotionModal.ValidationErrors.TheValidityZone')} ${requiredErrorSuffix}`,
    ),
    descriptionDE: string().required(
      `${t('AddPromotionModal.ValidationErrors.TheGermanDesription')} ${requiredErrorSuffix}`,
    ),
    descriptionFR: string().required(
      `${t('AddPromotionModal.ValidationErrors.TheFrenchDesription')} ${requiredErrorSuffix}`,
    ),
    emailDescriptionDE: string(),
    emailDescriptionFR: string(),
    promotionCode: string().when('promotionType', {
      is: (promotionType: PromotionType) =>
        promotionType === PromotionType.FixedAmountWithCode ||
        promotionType === PromotionType.AmountByQuantityWithCode ||
        promotionType === PromotionType.CouponWithCode ||
        promotionType === PromotionType.FinalFixedPriceWithCode ||
        promotionType === PromotionType.PercentualWithCode,
      then: string()
        .nullable()
        .required(`${t('AddPromotionModal.ValidationErrors.ThePromotionCode')} ${requiredErrorSuffix}`),
      otherwise: string().nullable(),
    }),
    hasTemplate: boolean().required(),
    templates: array().when('hasTemplate', {
      is: true,
      then: array().of(
        object()
          .shape({
            baseProductTemplate: object(),
            product: object().test(
              'productRequired',
              `${t('AddPromotionModal.ValidationErrors.TemplateProduct')} ${requiredErrorSuffix}`,
              (value, context) => {
                return value && (value.id === undefined || value?.id > 0);
              },
            ),
            productTemplates: array().of(
              object().shape({
                id: number(),
                language: string().oneOf(['DE', 'FR']).required(),
                template: string()
                  .required(`${t('AddProductModal.ValidationErrors.RequiredTemplate')} ${requiredErrorSuffix}.`)
                  .label('template'),
                productId: number(),
              }),
            ),
          })
          .test(
            'SingleAllProductSelectionMismatch',
            `${t('AddPromotionModal.ValidationErrors.SingleAllProductTemplateMismatch')}`,
            (value, context) => {
              const hasError = value && value.product?.id === undefined && context.parent?.length > 1;
              return hasError
                ? context.createError({
                    path: `${context.path}.product`,
                    message: `${t('AddPromotionModal.ValidationErrors.SingleAllProductTemplateMismatch')}`,
                  })
                : true;
            },
          )
          .test(
            'DoNotAllowMultipleTemplatesForSameProduct',
            `${t('AddPromotionModal.ValidationErrors.AlreadyExistingTemplateForTheProduct')}`,
            (value, context) => {
              const templatesItems = context.parent as ProductTemplateItem[];
              const hasError =
                templatesItems?.length > 1
                  ? templatesItems.filter((o) => o.product?.id === value.product?.id).length > 1
                  : false;
              return hasError
                ? context.createError({
                    path: `${context.path}.product`,
                    message: `${t('AddPromotionModal.ValidationErrors.AlreadyExistingTemplateForTheProduct')}`,
                  })
                : true;
            },
          ),
      ),
      otherwise: array(),
    }),
    assignedZones: array().when('validityZone', {
      is: ValidityZone.SelectedZones,
      then: array()
        .of(
          object().shape({
            id: number().required(),
            name: string(),
          }),
        )
        .min(1, t('AddPromotionModal.ValidationErrors.AssignedZonesAreRequired'))
        .required(t('AddPromotionModal.ValidationErrors.AssignedZonesAreRequired')),
      otherwise: array(),
    }),
    assignedPostalCodes: array().when('validityZone', {
      is: ValidityZone.SelectedPostalCodes,
      then: array()
        .of(
          object().shape({
            id: number().required(),
            name: string(),
            description: string(),
          }),
        )
        .min(1, t('AddPromotionModal.ValidationErrors.AssignedPostalCodesAreRequired'))
        .required(t('AddPromotionModal.ValidationErrors.AssignedPostalCodesAreRequired')),
      otherwise: array(),
    }),
    gameficationLinkUrl: string().url(t('AddPromotionModal.ValidationErrors.InvalidUrl')),
    gameficationLinkTitle: string().when('gameficationLinkUrl', {
      is: (gameficationLinkUrl: string | null) => gameficationLinkUrl && gameficationLinkUrl !== '',
      then: string().required(t('AddPromotionModal.ValidationErrors.GameficationLinkTitleRequired')),
      otherwise: string(),
    }),
    gameficationIFrameLinkUrl: string().url(t('AddPromotionModal.ValidationErrors.InvalidUrl')),
    gameficationLinkUrlFR: string().url(t('AddPromotionModal.ValidationErrors.InvalidUrl')),
    gameficationLinkTitleFR: string().when('gameficationLinkUrlFR', {
      is: (gameficationLinkUrlFR: string | null) => gameficationLinkUrlFR && gameficationLinkUrlFR !== '',
      then: string().required(t('AddPromotionModal.ValidationErrors.GameficationLinkTitleRequired')),
      otherwise: string(),
    }),
    gameficationIFrameLinkUrlFR: string().url(t('AddPromotionModal.ValidationErrors.InvalidUrl')),
  });

  return schema;
}
