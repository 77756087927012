/**
 *
 * This enum describes all the possible unit measures for
 * the product quantities.
 *
 */
export enum UnitCode {
  lt,
  hl,
  kg,
  t,
}

/**
 * Computes a translated string for the unit of measurement.
 * @param t the translation service.
 * @param unitCode the unit of measurement.
 * @returns a translated string for the unit of measurement.
 */
export function GetUnitCodeName(t: (arg: string) => string, unitCode: UnitCode | null | undefined): string {
  switch (unitCode) {
    case UnitCode.lt:
      return t('UnitCode.lt');
    case UnitCode.hl:
      return t('UnitCode.hl');
    case UnitCode.kg:
      return t('UnitCode.kg');
    case UnitCode.t:
      return t('UnitCode.t');
    default:
      throw 'Invalid status: ' + unitCode;
  }
}

/**
 * Computes a string containg the base quantity for the given unit of measurement.
 * @param unitCode the unit of measurement.
 * @returns a string representing the base quantity for the given unit of measurement.
 */
export function GetPriceLabel(t: (arg: string) => string, unitCode: UnitCode | undefined): string {
  if (unitCode == undefined) {
    return '';
  }

  const unitCodeName = GetUnitCodeName(t, unitCode);

  if (unitCode == UnitCode.lt) {
    return 'CHF/100 ' + unitCodeName;
  } else if (unitCode == UnitCode.kg) {
    return 'CHF/1000' + unitCodeName;
  } else {
    return 'CHF/' + unitCodeName;
  }
}
