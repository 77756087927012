import { Constants } from '@/constants/Constants';
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useIsInRole from '../../../../hooks/useIsInRole';
import useIsReadOnly from '../../../../hooks/useIsReadOnly';
import useMailTemplatesService from '../../../../hooks/useMailTemplateService';
import { GetLanguageName, MailTemplate, MailTemplateItem } from '../../../../models';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import EditMailTemplate from '../edit-form/EditMailTemplate';

function ViewMailTemplates(): JSX.Element | null {
  /**
   * Stores a value indicating if the user is administrator or not.
   */
  const isAdministrator = useIsInRole(Constants.Roles.Administrator);

  /**
   * Stores a value indicating if the user is read only or not.
   */

  const isReadOnly = useIsReadOnly();
  const { errors, setErrors } = useErrorHandling();
  const { t } = useTranslation();
  const language = useCurrentLanguage();
  const currentLocale = useCurrentLocale();
  const [editId, setEditId] = useState<number | null>(null);
  const service = useMailTemplatesService();
  const [data, setData] = useState<MailTemplate[]>([]);
  const initialSort: Array<SortDescriptor> = [{ field: 'mailTemplateTypeName' + language, dir: 'asc' }];
  const [sort, setSort] = useState(initialSort);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();

  useEffect(() => {
    service
      .getAll()
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setErrors([...errors, error]);
        console.log(error);
      });
  }, []);

  return data ? (
    <ViewWrapper title={t('ViewMailTemplates.Title')}>
      <EditMailTemplate
        id={editId ?? -1}
        modalTitle={t('EditMailTemplate.Title')}
        show={editId !== null}
        size="xl"
        handleClose={() => setEditId(null)}
      />

      <LocalizationProvider language={language}>
        <IntlProvider locale={currentLocale}>
          <Grid
            data={filter ? filterBy(orderBy(data, sort), filter) : orderBy(data, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
            }}
            filterable={true}
            filter={filter}
            resizable={true}
            onFilterChange={(e) => {
              setFilter(e.filter);
            }}
            style={{ margin: '0 20px 0 20px' }}
          >
            <Column
              field={'mailTemplateTypeName' + capitalizeFirstLetter(language)}
              title={t('ViewMailTemplates.GridColumnMailTemplateTypeName')}
            />
            <Column
              field={'productTypeName' + capitalizeFirstLetter(language)}
              title={t('ViewMailTemplates.GridColumnProductTypeName')}
            />
            <Column
              field="items"
              title={t('ViewMailTemplates.GridColumnItems')}
              sortable={false}
              filterable={false}
              width={200}
              cell={(props) => (
                <td>
                  {isAdministrator && !isReadOnly ? (
                    <div className="d-flex flex-row justify-content-around" style={{ padding: '0.5rem 0.5rem' }}>
                      {props.dataItem.items.map((item: MailTemplateItem) => (
                        <button
                          key={item.id}
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => setEditId(item.id)}
                        >
                          <span>{GetLanguageName(t, item.language)}</span>
                        </button>
                      ))}
                    </div>
                  ) : null}
                </td>
              )}
            />
          </Grid>
        </IntlProvider>
      </LocalizationProvider>
    </ViewWrapper>
  ) : null;
}

export default withErrorHandling(ViewMailTemplates);
