import { Constants } from '@/constants/Constants';
import { useContext, useEffect, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useTranslation } from 'react-i18next';
import AgrConfigurationContext from '../../../contexts/AgrConfigurationContext';
import useIsInRole from '../../../hooks/useIsInRole';
import useUserProfilesService from '../../../hooks/useUserProfileService';

function RestoreAdmin() {
  /**
   * The translation service.
   */
  const { t } = useTranslation();

  /**
   * The configuration context.
   */
  const config = useContext(AgrConfigurationContext);

  /**
   * The user profile service, will be used to restore the admin rights when the button is visible.
   */
  const userService = useUserProfilesService();

  /**
   * Indicates if the user is administrator or not.
   */
  const isAdministrator = useIsInRole(Constants.Roles.Administrator);

  /**
   * The toggle that toggles the message displayed to the user.
   */
  const [displaySuccessNotification, setDisplaySuccessNotification] = useState(false);

  /**
   * The message that is displayed upon success. It varies according the the preceding role of the user.
   */
  const [message, setMessage] = useState('');

  /**
   * When the status of the displaySuccessNotification is true, it will start a timer
   * and when it's over the  user will be redirected to the homepage.
   */
  useEffect(() => {
    if (displaySuccessNotification) {
      setTimeout(() => {
        window.location.replace('/');
      }, 3000);
    }
  }, [displaySuccessNotification]);

  /**
   * Calls the server and triggers the notification timer by changing the value of displaySuccessNotification
   */
  const onRestoreAdminClick = () => {
    userService.restore().then(() => {
      if (isAdministrator) {
        setMessage(t('RestoreAdministratorRole.AdministratorSuccess'));
      } else {
        setMessage(t('RestoreAdministratorRole.ShopUserSuccess'));
      }
      setDisplaySuccessNotification(true);
    });
  };

  return (
    <>
      {config?.showRestoreAdministratorButton.toLowerCase() === 'true' ? (
        <button className="btn btn-primary" onClick={onRestoreAdminClick}>
          Restore administrator
        </button>
      ) : null}
      {displaySuccessNotification ? (
        <ToastContainer className="p-3" position="top-center">
          <Toast className="d-inline-block m-1" bg="success">
            <Toast.Body className="text-white">{message}</Toast.Body>
          </Toast>
        </ToastContainer>
      ) : null}
    </>
  );
}

export default RestoreAdmin;
