import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { MarginsService } from '../services/MarginsService';

/**
 * Wraps the MarginsService in a useMemo call.
 *
 * @returns the memoized MarginsService
 */
export default function useMarginsService(): MarginsService {
  const config = useContext(AgrConfigurationContext);

  const marginService = useMemo(() => {
    return new MarginsService(config);
  }, [config]);

  return marginService;
}
