import { useTranslation } from 'react-i18next';
import { TextArea, TextAreaChangeEvent } from '@progress/kendo-react-inputs';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import styled from 'styled-components';

interface InputLabelProps {
  validationError: boolean;
}

/**
 * Styles for labels in this component.
 */
const InputLabel = styled.label<InputLabelProps>`
  margin-bottom: ${({ validationError }) => (validationError ? '0' : '0.5em')};
`;

/**
 * Styles for the error messages.
 */
const ErrorMessage = styled.span.attrs(() => ({ className: 'text-danger' }))<{}>`
  margin-bottom: 0.25em;
`;

/**
 * The interface for the error messages
 */
interface CalendarSettingErrors {
  showWeeks: string;
  deliveryWaitTime: string;
  textDe: string;
  textFr: string;
}

/**
 * Props for the CalendarSetting Component.
 */
interface CalendarSettingProps {
  showWeeksDropdownValues: number[];
  deliveryWaitTimeDropdownValues: number[];

  showWeeks: number | undefined;
  deliveryWaitTime: number | undefined;

  textDe: string | undefined;
  textFr: string | undefined;
  textAreaDeRows?: number;
  textAreaFrRows?: number;

  validationErrors?: CalendarSettingErrors | null;

  onShowWeeksChange: (e: DropDownListChangeEvent) => void;
  onDeliveryWaitTimeChange: (e: DropDownListChangeEvent) => void;
  onTextDeChange: (e: TextAreaChangeEvent) => void;
  onTextFrChange: (e: TextAreaChangeEvent) => void;
}

/**
 *
 * This Component represents the configurable setting for the calendar that appears
 * in the Guest UI.
 *
 * @returns the Component to be used in JSXs.
 */
export default function CalendarSetting({
  showWeeksDropdownValues,
  showWeeks,
  deliveryWaitTimeDropdownValues,
  deliveryWaitTime,

  textDe,
  textFr,
  textAreaDeRows,
  textAreaFrRows,

  validationErrors,

  onShowWeeksChange,
  onDeliveryWaitTimeChange,
  onTextDeChange,
  onTextFrChange,
}: CalendarSettingProps) {
  const { t } = useTranslation();

  return (
    <div style={{ padding: '1em', border: '1px solid #ddd', borderRadius: '0.3em', marginBottom: '0.5em' }}>
      {validationErrors?.showWeeks && <ErrorMessage>{validationErrors.showWeeks}</ErrorMessage>}
      <div className="d-flex justify-content-start w-100" style={{ marginTop: '0.5em' }}>
        <div>{t('CalendarSetting.ShowWeeks')}</div>
        <div style={{ margin: '0 0.3em' }}>
          <DropDownList
            id="showWeeksDropDown"
            style={{ marginBottom: '1em', width: 'min-content' }}
            data={showWeeksDropdownValues}
            value={showWeeks}
            onChange={onShowWeeksChange}
          />
        </div>
        <div>{t('CalendarSetting.Weeks')}</div>
      </div>

      {validationErrors?.deliveryWaitTime && <ErrorMessage>{validationErrors.deliveryWaitTime}</ErrorMessage>}
      <div className="d-flex justify-content-start w-100" style={{ marginTop: '0.5em' }}>
        <div>{t('CalendarSetting.DeliveryWaitTime')}</div>
        <div style={{ margin: '0 0.3em' }}>
          <DropDownList
            id="deliveryWaitTimeDropDown"
            style={{ marginBottom: '1em', width: 'min-content' }}
            data={deliveryWaitTimeDropdownValues}
            value={deliveryWaitTime}
            onChange={onDeliveryWaitTimeChange}
          />
        </div>
        <div>{t('CalendarSetting.Weeks')}</div>
      </div>

      <InputLabel htmlFor="textDeTextArea" validationError={!!validationErrors?.textDe}>
        {t('CalendarSetting.TextDe')}
      </InputLabel>
      {validationErrors?.textDe && <ErrorMessage>{validationErrors.textDe}</ErrorMessage>}
      <TextArea
        className="w-100"
        style={{ marginBottom: '1em' }}
        id="textDeTextArea"
        value={textDe ?? ''}
        onChange={onTextDeChange}
        rows={textAreaDeRows ?? 8}
      ></TextArea>

      <InputLabel htmlFor="textFrTextArea" validationError={!!validationErrors?.textFr}>
        {t('CalendarSetting.TextFr')}
      </InputLabel>
      {validationErrors?.textFr && <ErrorMessage>{validationErrors.textFr}</ErrorMessage>}
      <TextArea
        className="w-100"
        style={{ marginBottom: '1em' }}
        id="textFrTextArea"
        value={textFr ?? ''}
        onChange={onTextFrChange}
        rows={textAreaFrRows ?? 8}
      ></TextArea>
    </div>
  );
}
