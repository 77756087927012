import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { TextArea } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import PubSub from 'pubsub-js';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Constants } from '../../../../constants/Constants';
import useApplicationState from '../../../../hooks/useApplicationState';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useIsInRole from '../../../../hooks/useIsInRole';
import useIsReadOnly from '../../../../hooks/useIsReadOnly';
import useShopsService from '../../../../hooks/useShopsService';
import { ErrorViewModel, isErrorViewModel, Shop } from '../../../../models';
import ActionButtons from '../../../common/action-buttons/ActionButtons';
import DeleteButton from '../../../common/delete-button/DeleteButton';
import EditButton from '../../../common/edit-button/EditButton';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import AddShopForm from '../new-form/AddShopForm';
import './ViewShops.scss';

const initialSort: Array<SortDescriptor> = [{ field: 'name', dir: 'asc' }];

/**
 * A wrapper for a data table.
 */
function ViewShops(): JSX.Element | null {
  /**
   * Stores a value indicating if the user is read only or not.
   */
  const isReadOnly = useIsReadOnly();
  /**
   * The sorting descriptor of the Kendo React grid.
   */
  const [sort, setSort] = useState(initialSort);

  /**
   * The filtering description of the Kendo react grid.
   */
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();

  /**
   * The translation service.
   */
  const { t } = useTranslation();

  /**
   * The error handling helpers.
   */
  const { errors, setErrors } = useErrorHandling();

  /**
   * The application state. Specific for the shops.
   */
  const { shops, selectedShop } = useApplicationState();

  /**
   * The hook to always have the updated language.
   */
  const language = useCurrentLanguage();

  /**
   * Hook to retrieve current locale.
   */
  const currentLocale = useCurrentLocale();

  /**
   * The shops service for making API calls.
   */
  const shopsService = useShopsService();

  /**
   * The data displayed in the grid, a list of Shops. This list contains
   * a record only for each user, with the roles collpsed.
   */
  const [gridData, setGridData] = useState<Shop[]>([]);

  /**
   * The total count of items in the grid after applying the filtering but before applying the pagination.
   */
  const [gridDataTotal, setGridDataTotal] = useState<number>();

  /**
   * Toggles the display of the shop add/update modal window.
   */
  const [showModal, setShowModal] = useState<boolean>(false);

  /**
   * Sets the product that has to be edited in the modal window.
   */
  const [editingShop, setEditingShop] = useState<Shop>();

  /**
   * Boolean indicating whether an APi call is ongoing.
   */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * Boolean indicating if email adresses of the shops are desplayed.
   */
  const [isEmailDisplayed, setIsEmailDisplayed] = useState<boolean>(false);

  /**
   * Boolean indicating if email adresses of the shops are desplayed.
   */
  const [allEmailAddresses, setAllEmailAddresses] = useState<string>('');

  /**
   * Stores a value indicating if the user is administrator or not.
   */
  const isAdministrator = useIsInRole(Constants.Roles.Administrator);

  /**
   * The configuration of the initial "window" (or page) of visible data.
   */
  const initialDataState = {
    skip: 0,
    take: 10,
  };

  /**
   * The initial "window" (or page) of visible data.
   */
  const [page, setPage] = useState(initialDataState);

  /**
   * Applies the global filter selection to the visible shops.
   */
  useEffect(() => {
    if (selectedShop == undefined) {
      if (filter) {
        const filteredData = filterBy(orderBy(shops, sort), filter);
        setGridDataTotal(filteredData.length);
        setGridData([...filteredData.slice(page.skip, page.take + page.skip)]);
      } else {
        const sortedData = orderBy(shops, sort);
        setGridDataTotal(sortedData.length);
        setGridData([...sortedData.slice(page.skip, page.take + page.skip)]);
      }
    } else {
      setGridData([selectedShop]);
    }
  }, [filter, page, selectedShop, shops, setGridData]);

  /**
   * The event handler for the page change event of the grid.
   * @param event The page change grid event.
   */
  const pageChange = (event: any) => {
    setPage(event.page);
  };

  /**
   * Applies the filter to the data and resets the paging status.
   */
  const applyFilter = (filter: any) => {
    setFilter(filter);
    initialDataState.skip = 0;
    setPage(initialDataState);
  };

  /**
   * Applies the sorting to the data and resets the paging status.
   */
  const applySort = (sort: any) => {
    setSort(sort);
    initialDataState.skip = 0;
    setPage(initialDataState);
  };

  const deleteShop = useCallback(
    async (shopId: number) => {
      try {
        setIsLoading(true);
        await shopsService.delete(shopId);
        PubSub.publish(Constants.Topics.UserInformation);
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errors, error as ErrorViewModel]);
        } else {
          console.error(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, shopsService, PubSub, Constants, setErrors, errors],
  );

  const showShopEmailAddresses = useCallback(async () => {
    try {
      setIsLoading(true);
      if (isEmailDisplayed) {
        setIsEmailDisplayed(false);
      } else {
        let addresses = '';
        gridData.map((shop) => {
          if (shop.emailAddress) {
            addresses += shop.emailAddress + ', ';
          }
        });
        if (addresses.length > 2) {
          addresses = addresses.substr(0, addresses.length - 2);
        }
        setAllEmailAddresses(addresses);

        setIsEmailDisplayed(true);
      }
    } catch (error) {
      console.error(error);

      setErrors([...errors, error as ErrorViewModel]);
    } finally {
      setIsLoading(false);
    }
  }, [PubSub, setIsEmailDisplayed, isEmailDisplayed, gridData, setErrors, errors]);

  if (isLoading) {
    return <LoadingPanel />;
  }

  return (
    <ViewWrapper
      title={t('ShopsView.ManageShops')}
      addButton={
        isAdministrator && !isReadOnly ? (
          <button
            type="button"
            className="ms-2 btn btn-primary p-1 pt-0 pb-0 rounded-circle fw-bold"
            onClick={() => setShowModal(true)}
          >
            <span style={{ fontSize: 36, lineHeight: 0.65 }}>+</span>
          </button>
        ) : null
      }
    >
      <>
        <LocalizationProvider language={language}>
          <IntlProvider locale={currentLocale}>
            <AddShopForm
              show={showModal}
              update={!!editingShop}
              {...editingShop}
              modalTitle={
                !!editingShop ? (
                  <Trans i18nKey="AddShopModal.EditShopTitle" t={t}>
                    {{ shop: editingShop.name }}
                  </Trans>
                ) : (
                  t('AddShopModal.ModalTitle')
                )
              }
              handleClose={() => {
                setShowModal(false);
                editingShop && setEditingShop(undefined);
              }}
              handleSave={() => {
                setShowModal(false);
                PubSub.publish(Constants.Topics.UserInformation);
                editingShop && setEditingShop(undefined);
              }}
            />
            <Grid
              style={{ margin: 20 }}
              data={gridData}
              sortable={true}
              sort={sort}
              filterable={true}
              filter={filter}
              pageable={{ buttonCount: 4, pageSizes: true }}
              resizable={true}
              skip={page.skip}
              take={page.take}
              total={gridDataTotal}
              onPageChange={pageChange}
              onFilterChange={(e) => applyFilter(e.filter)}
              onSortChange={(e) => applySort(e.sort)}
            >
              <Column field="name" title={t('ShopsView.ColumnName')} />
              <Column field="holding.name" title={t('ShopsView.ColumnHolding')} />
              <Column
                title={t('Views.ColumnActions')}
                width={200}
                filterable={false}
                cell={(props) => {
                  return (
                    <td>
                      {isAdministrator && !isReadOnly ? (
                        <ActionButtons>
                          <EditButton
                            onClick={() => {
                              setEditingShop(props.dataItem);
                              setShowModal(true);
                            }}
                          />
                          <DeleteButton
                            onClick={() => {
                              if (confirm(t('ShopsView.DeleteMessage') + ' ' + props.dataItem.name)) {
                                deleteShop(props.dataItem.id);
                              }
                            }}
                          />
                        </ActionButtons>
                      ) : null}
                    </td>
                  );
                }}
              />
            </Grid>
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={() => {
                showShopEmailAddresses();
              }}
            >
              <span>{t('ShopsView.ShowEmailList')}</span>
            </button>
            {isEmailDisplayed && (
              <div>
                <TextArea
                  style={{ marginBottom: '1em', width: '100%' }}
                  id="emailAddressesTextArea"
                  value={allEmailAddresses}
                  rows={8}
                ></TextArea>
              </div>
            )}
          </IntlProvider>
        </LocalizationProvider>
      </>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewShops);
