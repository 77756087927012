import { IPagedResultViewModel } from '../models';
import { Pricelog } from '../models/Pricelog';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { BaseService } from './BaseService';

/**
 * The service class to interact with the Alert.
 */
export class PricelogService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/pricelog');
  }

  getAllPaged(filter: string): Promise<IPagedResultViewModel<Pricelog>> {
    return this.get('?' + filter);
  }

  getCount(filter: any): Promise<number> {
    return this.get('/count?' + filter);
  }

  getById(filter: number): Promise<Pricelog> {
    return this.get('/' + filter);
  }
}
