import { TransportCostViewModel } from '@/components/pages/zones/view-form/TransportCostViewModel';
import { Disporegion, ProductType, Shop, Zone } from '../models';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { ZoneUnloadingfeeUpdateViewModel } from '../models/ZoneUnloadingfeeUpdateViewModel';
import { BaseService } from './BaseService';

export interface GetAllZonesViewModel {
  zones: Zone[];
  disporegions: Disporegion[];
  shops: Shop[];
  productTypes: ProductType[];
}

/**
 * The service class to interact with the Zones.
 */
export class ZonesService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/zone');
  }

  saveZone(zone: Zone): Promise<number> {
    return this.post('', zone);
  }

  updateZone(zone: Zone): Promise<number> {
    return this.put('', zone);
  }

  getAllZones(): Promise<GetAllZonesViewModel> {
    return this.get('/view');
  }

  getByShopIds(shopIds?: number[]): Promise<GetAllZonesViewModel> {
    return this.post('/shops', {
      shopIds,
    });
  }

  getByIdList(idList: (number | undefined)[]): Promise<Zone[]> {
    return this.post('/list/byid', idList);
  }

  updateUnloadingFee(data: ZoneUnloadingfeeUpdateViewModel): Promise<boolean> {
    return this.put('/unloadingfees', data);
  }

  deleteZone(zoneId: number) {
    return this.delete(`/${zoneId}`);
  }

  setTransportCost(model: TransportCostViewModel) {
    return this.post('/transportcost', model);
  }

  disableTransportCost(...zoneProductIds: number[]) {
    return this.post('/disable/transportcost', zoneProductIds);
  }
}
