import { useCurrentLocale } from '@/hooks/useCurrentLocale';
import { useMsal } from '@azure/msal-react';
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import PubSub from 'pubsub-js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Constants } from '../../../../constants/Constants';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useIsInRole from '../../../../hooks/useIsInRole';
import useIsReadOnly from '../../../../hooks/useIsReadOnly';
import useToggle from '../../../../hooks/useToggle';
import useUserProfilesService from '../../../../hooks/useUserProfileService';
import { UserProfile } from '../../../../models';
import ActionButtons from '../../../common/action-buttons/ActionButtons';
import DeleteButton from '../../../common/delete-button/DeleteButton';
import EditButton from '../../../common/edit-button/EditButton';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import EditUserProfile from '../edit-form/EditUserProfile';
import './ViewUserProfiles.scss';

const initialSort: Array<SortDescriptor> = [{ field: 'FullName', dir: 'asc' }];

/**
 * A wrapper for a data table.
 */
function ViewUserProfile(): JSX.Element | null {
  /**
   * The sorting descriptor of the Kendo React grid.
   */
  const [sort, setSort] = useState(initialSort);

  /**
   * The filtering description of the Kendo react grid.
   */
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();

  /**
   * The translation service.
   */
  const { t } = useTranslation();

  /**
   * The hook to always have the updated language.
   */
  const language = useCurrentLanguage();

  /**
   * Hook to retrieve current locale.
   */
  const currentLocale = useCurrentLocale();

  /**
   * Stores a value indicating if the user is read only or not.
   */
  const isReadOnly = useIsReadOnly();

  /**
   * Stores a value indicating if the user is administrator or not.
   */
  const isAdministrator = useIsInRole(Constants.Roles.Administrator);

  /**
   * The hook that returns the an UsersService instance.
   */
  const userService = useUserProfilesService();

  /**
   * The error handling hook to send a message to the toast notification.
   */
  const { errors, setErrors } = useErrorHandling();

  /**
   * The data returned from the remote API, a list of UserProfile. This list contains
   * a record only for each user, with the roles collpsed.
   */
  const [userData, setUserData] = useState<UserProfile[]>([]);

  /**
   * The data displayed in the grid, a list of UserProfile. This list contains
   * a record for each user/role/shop combination.
   */
  const [expandedUserData, setExpandedUserData] = useState<UserProfile[]>([]);

  /**
   * The data that is displayed in the grid. It corresponds to userData or expanded userData
   * after applying filtering and sorting.
   */
  const [gridData, setGridData] = useState<UserProfile[]>([]);

  /**
   * The total count of items in the grid after applying the filtering but before applying the pagination.
   */
  const [gridDataTotal, setGridDataTotal] = useState<number>();

  /**
   * Toggles the underlying dataset of the grid between userData and normalizedUserData.
   */
  const [isExpandedDataToggled, setToggleExpanded] = useToggle(false);

  /**
   * Switch that is used to hide the kendo loadining spinner when there is an error.
   */
  const [errorSwitch, setErrorSwitch] = useState(false);

  /**
   * Switch that is used to hid ethe kendo loading spinner when there is an error.
   */
  const [loadingUsers, setLoadingUsers] = useState(true);

  /**
   * The configuration of the initial "window" (or page) of visible data.
   */
  const initialDataState = {
    skip: 0,
    take: 10,
  };

  /**
   * The initial "window" (or page) of visible data.
   */
  const [page, setPage] = useState(initialDataState);

  /**
   * Transforms the data within the user list.
   * @param data The user profile data.
   */
  const parseData = useCallback(
    (data: UserProfile[], normalizedData: UserProfile[]) => {
      data.forEach((u) => {
        u.readOnlyString = u.readOnly ? t('CommonLabels.Yes') : t('CommonLabels.No');
        u.dieselAdministratorString = u.dieselAdministrator ? t('CommonLabels.Yes') : t('CommonLabels.No');
        u.isActiveString = u.isActive ? t('CommonLabels.Yes') : t('CommonLabels.No');
        u.lastLogin = u.lastLogin !== null ? new Date(new Date(u.lastLogin).toDateString()) : null;
        u.translatedRole = t('Roles.' + u.roleName);

        if (u.roleName === Constants.Roles.Administrator) {
          u.shop = t('Roles.AllShops');
        } else {
          if (u.userShops.length > 1) {
            u.shop = '...';
          } else {
            u.shop = u.userShops[0]?.shop?.name;
          }
        }
      });
      normalizedData.forEach((u) => {
        u.readOnlyString = u.readOnly ? t('CommonLabels.Yes') : t('CommonLabels.No');
        u.isActiveString = u.isActive ? t('CommonLabels.Yes') : t('CommonLabels.No');
        u.lastLogin = u.lastLogin !== null ? new Date(u.lastLogin) : null;
        u.translatedRole = t('Roles.' + u.roleName);

        if (u.roleName === Constants.Roles.Administrator) {
          u.shop = t('Roles.AllShops');
        }
      });
    },
    [t],
  );

  /**
   * Applies the transformations to the data (the localization of Role and ReadOnly,
   * and the formatting of the data). And the filtering and sorting.
   */
  useMemo(() => {
    parseData(userData, expandedUserData);
  }, [parseData, userData, expandedUserData]);

  /**
   * When the userData changes, like when it is first loaded, computes the "expanded" data set
   */
  useMemo(() => {
    setExpandedUserData(userService.expand(userData));
  }, [userData, userService]);

  /**
   * Sets the grid data. Applies the filtering, sorting and pagination to the data that comes
   * from the remote API. Also computes the correct total for the page bar at the bottom of the grid.
   */
  useMemo(() => {
    if (filter) {
      const filteredData = filterBy(orderBy(isExpandedDataToggled ? expandedUserData : userData, sort), filter);
      setGridDataTotal(filteredData.length);
      setGridData(filteredData.slice(page.skip, page.take + page.skip));
    } else {
      const orderedData = orderBy(isExpandedDataToggled ? expandedUserData : userData, sort);
      setGridDataTotal(orderedData.length);
      setGridData(orderedData.slice(page.skip, page.take + page.skip));
    }
  }, [filter, isExpandedDataToggled, expandedUserData, userData, sort, page.skip, page.take]);

  /**
   * Calls the function that Loads the user data from the remote API.
   */
  useEffect(() => {
    loadUsers();
  }, []);

  /**
   * The msal provider, to retrieve the name to be displayed.
   */
  const { accounts } = useMsal();

  /**
   * Loads the user data from the remote API. Applies the necessary
   * transformations for the translations of the content of the fields.
   */
  const loadUsers = () => {
    setErrorSwitch(false);
    setLoadingUsers(true);
    userService
      .getAll()
      .then((data) => {
        setUserData(data);
      })
      .catch((error) => {
        setErrors([...errors, error]);
        setErrorSwitch(true);
      })
      .finally(() => {
        setLoadingUsers(false);
      });
  };

  /**
   * The event handler for the page change event of the grid.
   * @param event The page change grid event.
   */
  const pageChange = (event: any) => {
    setPage(event.page);
  };

  /**
   * Toggles the visualization of the expanded list of user and resets the paging status.
   */
  const displayExpandedData = () => {
    setToggleExpanded();
    initialDataState.skip = 0;
    setPage(initialDataState);
  };

  /**
   * Applies the filter to the data and resets the paging status.
   */
  const applyFilter = (filter: any) => {
    setFilter(filter);
    initialDataState.skip = 0;
    setPage(initialDataState);
  };

  /**
   * Applies the sorting to the data and resets the paging status.
   */
  const applySort = (sort: any) => {
    setSort(sort);
    initialDataState.skip = 0;
    setPage(initialDataState);
  };

  const [editId, setEditId] = useState<number | null>(null);

  /**
   * Asks for confirmation and deletes an user.
   */
  const confirmDelete = (user: UserProfile) => {
    let message = t('UsersView.ConfirmDeletion');
    let sameAccount = false;
    // in case we are chaning
    if (
      accounts &&
      accounts.length > 0 &&
      user &&
      user.email &&
      accounts[0].username.toLowerCase() == user.email.toLowerCase()
    ) {
      sameAccount = true;
      message = t('UsersView.ConfirmDeletionSameAccount');
    }

    if (confirm(message)) {
      setLoadingUsers(true);
      userService
        .delete({ id: user.id })
        .then(() => {
          if (sameAccount) {
            PubSub.publish(Constants.Topics.Logout);
          } else {
            loadUsers();
          }
        })
        .catch((error) => {
          setErrors([...errors, error]);
          setErrorSwitch(true);
        })
        .finally(() => {
          setLoadingUsers(false);
        });
    }
  };

  if (loadingUsers && !errorSwitch) {
    return <LoadingPanel />;
  }

  return (
    <ViewWrapper
      title={t('UsersView.ManageUsers')}
      addButton={
        isAdministrator && !isReadOnly ? (
          <button
            type="button"
            className="ms-2 btn btn-primary p-1 pt-0 pb-0 rounded-circle fw-bold"
            onClick={() => setEditId(0)}
          >
            <span style={{ fontSize: 36, lineHeight: 0.65 }}>+</span>
          </button>
        ) : null
      }
    >
      <>
        <EditUserProfile
          userId={editId || 0}
          modalTitle={
            editId !== 0 ? (
              <Trans i18nKey="EditUserProfile.EditTitle" t={t}>
                {{ user: userData.find((user) => user.id === editId)?.email }}
              </Trans>
            ) : (
              t('EditUserProfile.Title')
            )
          }
          show={editId !== null}
          size="xl"
          handleClose={() => {
            setEditId(null);
            setErrorSwitch(false);
          }}
          handleSave={() => {
            setEditId(null);
            setErrorSwitch(false);
            setLoadingUsers(true);
            userService
              .getAll()
              .then((data) => {
                setUserData(data);
              })
              .catch((error) => {
                setErrors([...errors, error]);
                setErrorSwitch(true);
              })
              .finally(() => {
                setLoadingUsers(false);
              });
          }}
        />
        <LocalizationProvider language={language}>
          <IntlProvider locale={currentLocale}>
            <Grid
              style={{ margin: 20 }}
              data={gridData}
              sortable={true}
              sort={sort}
              filterable={true}
              filter={filter}
              resizable={true}
              pageable={{ buttonCount: 4, pageSizes: true }}
              skip={page.skip}
              take={page.take}
              total={gridDataTotal}
              onPageChange={pageChange}
              onFilterChange={(e) => applyFilter(e.filter)}
              onSortChange={(e) => applySort(e.sort)}
            >
              <GridToolbar>
                <button className="btn btn-primary btn-sm" type="button" onClick={displayExpandedData}>
                  <span>{isExpandedDataToggled ? t('UsersView.ContractRoles') : t('UsersView.ExpandRoles')}</span>
                </button>
              </GridToolbar>
              <Column field="fullName" title={t('UsersView.ColumnName')} />
              <Column field="translatedRole" title={t('UsersView.ColumnRole')} />
              <Column field="readOnlyString" title={t('UsersView.ColumnReadOnly')} />
              <Column field="dieselAdministratorString" title={t('UsersView.ColumnDieselAdministrator')} />
              <Column field="shop" title={t('UsersView.ColumnShop')} />
              <Column field="holding.name" title={t('UsersView.ColumnHolding')} />
              <Column field="lastLogin" title={t('UsersView.ColumnLastLogin')} filter="date" format="{0:d}" />
              <Column field="isActiveString" title={t('UsersView.ColumnIsActive')} />
              <Column
                title={t('Views.ColumnActions')}
                width={270}
                filterable={false}
                cell={(props) => {
                  return (
                    <td>
                      {isAdministrator && !isReadOnly ? (
                        <ActionButtons>
                          <EditButton onClick={() => setEditId(props.dataItem.id)} />
                          {props.dataItem.lastLogin !== null && (
                            <DeleteButton onClick={() => confirmDelete(props.dataItem)} />
                          )}
                          {props.dataItem.lastLogin === null && (
                            <button
                              className="btn btn-danger btn-sm"
                              type="button"
                              onClick={() => confirmDelete(props.dataItem)}
                            >
                              <span>{t('UsersView.CancelInvitation')}</span>
                            </button>
                          )}
                        </ActionButtons>
                      ) : null}
                    </td>
                  );
                }}
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewUserProfile);
