import { KendoCellRenderer } from '@/components/common/kendo-grid-renderers/KendoGridRenderer';
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn as Column, GridItemChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import ToggleButton from 'react-bootstrap/esm/ToggleButton';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ValidationError } from 'yup';
import useApplicationState from '../../../../hooks/useApplicationState';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useHoldingsService from '../../../../hooks/useHoldingsService';
import useProductsService from '../../../../hooks/useProductsService';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';
import useYupErrors, { YupError } from '../../../../hooks/useYupErrors';
import { HoldingBasePrice, isErrorViewModel, ProductType } from '../../../../models';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import SaveButton from '../../../common/save-button/SaveButton';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import getHoldingBasePriceValidationSchema from './ValidationSchema';
import { formatters } from '@/utils/formatters';

function getKeys<T>(obj: T) {
  return Object.keys(obj) as Array<keyof T>;
}

/**
 * Initial grid sorting.
 */
const initialSort: Array<SortDescriptor> = [{ field: 'product.name', dir: 'asc' }];

/**
 * The styles for the content inside the ViewWrapper.
 */
const ContentWrapper = styled.div`
  margin: 0 20px 0 20px;
`;

/**
 * The interface that represents all the possible errors
 * that Yup can map on the holding base price.
 */
type HoldingBasePriceError = {
  [index in keyof Omit<HoldingBasePrice, 'id' | 'holding' | 'product'>]: string;
};

const yupHoldingBasePriceErrorSchema: HoldingBasePriceError = {
  basePrice: '',
  holdingId: '',
  productId: '',
  quantity: '',
  unitCode: '',
  useTktm: '',
};

export interface GridHoldingBasePrice extends HoldingBasePrice {
  [index: string]: any;
  inEdit?: boolean | string;
}

function ViewBasePrice() {
  const { t } = useTranslation();
  const language = useCurrentLanguage();
  const currentLocale = useCurrentLocale();

  const { errors, setErrors } = useErrorHandling();

  const { holding } = useApplicationState();

  const productService = useProductsService();
  const holdingService = useHoldingsService();

  const mapErrors = useYupErrors<HoldingBasePriceError>(yupHoldingBasePriceErrorSchema);

  const [sort, setSort] = useState(initialSort);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSavingBasePrice, setIsSavingBasePrice] = useState<{ [gridHoldingBasePriceId: number]: boolean }>({});

  const [allProductTypes, setAllProductTypes] = useState<ProductType[]>();
  const [selectedProductType, setSelectedProductType] = useState<ProductType>();

  const [initialHoldingBasePrices, setInitialHoldingBasePrices] = useState<GridHoldingBasePrice[]>([]);
  const [holdingBasePrices, setHoldingBasePrices] = useState<GridHoldingBasePrice[]>([]);

  const [editField, setEditField] = useState<string>();

  const hbpValidationSchema = useMemo(() => getHoldingBasePriceValidationSchema(t), [t]);

  const [validationErrors, setValidationErrors] = useState<YupError<HoldingBasePriceError | null>>();

  const validateHoldingBasePrice = useCallback(
    async (holdingBasePrice: GridHoldingBasePrice) => {
      try {
        await hbpValidationSchema.validate(holdingBasePrice, { abortEarly: false });

        setValidationErrors(null);

        return true;
      } catch (error) {
        const mappedErrors = mapErrors(error as ValidationError);
        setValidationErrors(mappedErrors);

        return false;
      }
    },
    [hbpValidationSchema, setValidationErrors, mapErrors],
  );

  const saveChanges = useCallback(
    async (dataItem: GridHoldingBasePrice) => {
      try {
        
        const newHoldingBasePrices = holdingBasePrices.map((item) => ({ ...item, inEdit: undefined }));

        setHoldingBasePrices(newHoldingBasePrices);
        setEditField(undefined);
        
        const isItemValid = await validateHoldingBasePrice(dataItem);

        if (!isItemValid) {
          return;
        }

        const { product, holding, ...rest } = dataItem;

        setIsSavingBasePrice({ ...isSavingBasePrice, [rest.productId]: true });

        const savedBasePriceId = await holdingService.setHoldingBasePrice(rest);

        const savedHoldingBasePrice = { ...dataItem, id: savedBasePriceId };

        const newInitialHoldingBasePrices = [
          ...initialHoldingBasePrices.map((hbp) =>
            hbp.productId === dataItem.productId ? savedHoldingBasePrice : hbp,
          ),
        ];

        setInitialHoldingBasePrices(newInitialHoldingBasePrices);

        if (dataItem.id === 0) {
          setHoldingBasePrices([
            ...holdingBasePrices.map((hbp) => (hbp.productId === dataItem.productId ? savedHoldingBasePrice : hbp)),
          ]);
        }
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errors, error]);
        } else if (error instanceof Error) {
          setErrors([
            ...errors,
            {
              statusCode: '',
              title: 'Unexpected error',
              value: {
                description: error.message,
              },
            },
          ]);
        }
      } finally {
        const newIsSavingBasePrice = { ...isSavingBasePrice };
        delete newIsSavingBasePrice[dataItem.productId];

        setIsSavingBasePrice(newIsSavingBasePrice);
      }
    },
    [
      setIsSavingBasePrice,
      isSavingBasePrice,
      holdingService,
      initialHoldingBasePrices,
      setInitialHoldingBasePrices,
      setHoldingBasePrices,
      holdingBasePrices,
      isErrorViewModel,
      setErrors,
      errors,
    ],
  );

  const onUseTktmChange = useCallback(
    (newValue: boolean, previousHoldingBasePrice: GridHoldingBasePrice) => {
      try {
        const newHoldingBasePrices = holdingBasePrices.map((hbp) => ({
          ...hbp,
          useTktm: hbp.productId === previousHoldingBasePrice.productId ? newValue : hbp.useTktm,
          inEdit: hbp.productId === previousHoldingBasePrice.productId && newValue ? false : hbp.inEdit,
        }));

        setHoldingBasePrices(newHoldingBasePrices);
      } catch (error) {
        if (error instanceof Error) {
          setErrors([
            ...errors,
            {
              statusCode: '',
              title: 'Unexpected error',
              value: {
                description: error.message,
              },
            },
          ]);
        }
      }
    },
    [holdingBasePrices, setHoldingBasePrices, setErrors, errors],
  );

  const enterEdit = (dataItem: GridHoldingBasePrice, field: string | undefined) => {
    const newHoldingBasePrices = holdingBasePrices.map((item) => ({
      ...item,
      inEdit: item.productId === dataItem.productId && !dataItem.useTktm ? field : undefined,
    }));

    setHoldingBasePrices(newHoldingBasePrices);
    setEditField(field);
  };
  const doNothing = () => {
    return true;
    // do nothing
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || '';

    const newHoldingBasePrices = holdingBasePrices.map((item) => {
      if (item.productId === (event.dataItem as GridHoldingBasePrice).productId) {
        item[field] = event.value;
      }

      return item;
    });

    setHoldingBasePrices(newHoldingBasePrices);
  };

  const checkIfItemChanged = (item: GridHoldingBasePrice | null) => {
    if (!item) {
      return false;
    }

    const initialBasePrice = initialHoldingBasePrices.find((hbp) => hbp.productId === item.productId);

    if (!initialBasePrice) {
      return false;
    }

    // inEdit is extracted from the object destructuring because it's not needed
    // for the "deep comparison" performed through the JSON.stringify.

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { inEdit: _, ...initialItemState } = initialBasePrice;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { inEdit: __, ...currentItemState } = item;

    return JSON.stringify(initialItemState) === JSON.stringify(currentItemState);
  };

  const checkIfItemIsBeingSaved = (gridHoldingBasePrice: GridHoldingBasePrice | null) => {
    if (!gridHoldingBasePrice) {
      return false;
    }

    return isSavingBasePrice[gridHoldingBasePrice.productId] ?? false;
  };

  const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, props: GridCellProps) => (
    <KendoCellRenderer
      originalProps={props}
      td={td}
      enterEdit={enterEdit}
      render={(item, field, node) => {
        if (item.useTktm && field === 'basePrice') {
          return '-';
        }
        return node;
      }}
      exitEdit={doNothing}
      editField={editField}
    />
  );

  const retrieveHoldingBasePrices = useCallback(
    async (productType: ProductType) => {
      try {
        setIsLoading(true);

        if (!holding) {
          throw new Error("Couldn't read the holding information.");
        }

        const initialHoldingBasePrices = await holdingService.getHoldingBasePrices(holding.id, productType.id);

        setInitialHoldingBasePrices(
          initialHoldingBasePrices.map<GridHoldingBasePrice>((hbp) => ({
            ...hbp,
            inEdit: false,
          })),
        );

        setHoldingBasePrices(
          initialHoldingBasePrices.map<GridHoldingBasePrice>((hbp) => ({
            ...hbp,
            inEdit: false,
          })),
        );
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errors, error]);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      setIsLoading,
      holding,
      holdingService,
      setInitialHoldingBasePrices,
      initialHoldingBasePrices,
      isErrorViewModel,
      setErrors,
      errors,
    ],
  );

  useEffect(() => {
    if (validationErrors) {
      const errorsArray = getKeys(validationErrors).map((key) => validationErrors[key]);

      setErrors([
        ...errors,
        {
          statusCode: '',
          title: 'Validation errors',
          value: {
            description: errorsArray.join(';\n'),
          },
        },
      ]);
    }
  }, [validationErrors]);

  useEffect(() => {
    if (selectedProductType) {
      retrieveHoldingBasePrices(selectedProductType);
    }
  }, [selectedProductType]);

  /**
   * This useEffect takes care of retrieving the necesary data for showing the view.
   */
  useEffect(() => {
    async function setupEnvironment() {
      try {
        setIsLoading(true);

        const productTypesAvailable = await productService.getAllProductTypes();
        setAllProductTypes(productTypesAvailable);

        const initialProductType = productTypesAvailable[0];
        setSelectedProductType(initialProductType);
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errors, error]);
        } else if (error instanceof Error) {
          setErrors([
            ...errors,
            {
              title: 'Error while retrieving the information.',
              statusCode: '',
              value: { description: (error as Error).message },
            },
          ]);
        }
      } finally {
        setIsLoading(false);
      }
    }

    setupEnvironment();
  }, []);

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (!allProductTypes || allProductTypes.length === 0) {
    return <></>;
  }

  return (
    <ViewWrapper
      title={
        <Trans i18nKey="BasePriceView.Title" t={t}>
          Base prices for {{ holding: holding?.name }} and product type{' '}
          {{ productType: selectedProductType && selectedProductType[`name${capitalizeFirstLetter(language)}`] }}
        </Trans>
      }
    >
      <ContentWrapper>
        <span className="d-block">{t('BasePriceView.SelectProductType')}</span>

        <DropDownList
          className="mt-2"
          data={allProductTypes}
          value={selectedProductType}
          dataItemKey="id"
          textField={`name${capitalizeFirstLetter(language)}`}
          onChange={(e) => setSelectedProductType(e.target.value)}
        />

        <div className="d-flex mt-4 mb-1 align-items-center">
          <span className="h3" style={{ fontWeight: 600 }}>
            {t('Links.BasePrice')}
          </span>
        </div>

        <LocalizationProvider language={language}>
          <IntlProvider locale={currentLocale}>
            <Grid
              data={filter ? filterBy(orderBy(holdingBasePrices, sort), filter) : orderBy(holdingBasePrices, sort)}
              sortable
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              filterable
              filter={filter}
              editField="inEdit"
              onFilterChange={(e) => setFilter(e.filter)}
              onItemChange={itemChange}
              cellRender={customCellRender}
            >
              <Column
                title={t('BasePriceView.ProductName')}
                field={`product.name${capitalizeFirstLetter(language)}`}
                editable={false}
              />
              <Column
                title={t('BasePriceView.UseTktm')}
                editable={false}
                width={language === 'de' ? 320 : 250}
                cell={(props) => {
                  const item = props.dataItem as GridHoldingBasePrice;

                  return (
                    <td
                      colSpan={props.colSpan}
                      role="gridcell"
                      aria-colindex={props.ariaColumnIndex}
                      data-grid-col-index={props.columnIndex}
                      style={{ textAlign: 'center' }}
                    >
                      <ButtonGroup className="align-self-start">
                        <ToggleButton
                          id={`radio-use-tktm-${props.id}`}
                          type="radio"
                          variant={item.useTktm ? 'primary' : 'light'}
                          value="true"
                          checked={item.useTktm}
                          onChange={(e) => {
                            onUseTktmChange(e.currentTarget.value === 'true', item);
                          }}
                        >
                          {t('BasePriceView.UseTktm')}
                        </ToggleButton>

                        <ToggleButton
                          id={`radio-use-custom-${props.id}`}
                          type="radio"
                          variant={!item.useTktm ? 'primary' : 'light'}
                          value="false"
                          checked={!item.useTktm}
                          onChange={(e) => {
                            onUseTktmChange(e.currentTarget.value === 'true', item);
                          }}
                        >
                          {t('BasePriceView.UseCustom')}
                        </ToggleButton>
                      </ButtonGroup>
                    </td>
                  );
                }}
              />
              <Column
                title={t('BasePriceView.BasePrice')}
                field="basePrice"
                editor="numeric"
                format={formatters.currency.asKendoFormat({ decimals: 2 })}
              />
              <Column
                title={t('Views.ColumnActions')}
                editable={false}
                cell={(props) => (
                  <td className="k-command-cell" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    <SaveButton
                      disabled={
                        checkIfItemIsBeingSaved(props.dataItem as GridHoldingBasePrice) ||
                        checkIfItemChanged(props.dataItem as GridHoldingBasePrice)
                      }
                      loading={checkIfItemIsBeingSaved(props.dataItem as GridHoldingBasePrice)}
                      onClick={async () => {
                        try {
                          const item = props.dataItem as GridHoldingBasePrice;

                          if (!item) {
                            throw new Error("Couldn't cast data item to appropriate type.");
                          }

                          await saveChanges(item);
                        } catch (error) {
                          if (error instanceof Error) {
                            setErrors([
                              ...errors,
                              {
                                statusCode: '',
                                title: 'Unexpected error',
                                value: {
                                  description: error.message,
                                },
                              },
                            ]);
                          }
                        }
                      }}
                    />
                  </td>
                )}
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </ContentWrapper>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewBasePrice);
