import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useContext, useEffect, useState } from 'react';
import PubSub from 'pubsub-js';

import useErrorHandling from '../../../hooks/useErrorHandling';
import withErrorHandling from '../../hoc/with-error-handling/withErrorHandling';
import useUserProfilesService from '../../../hooks/useUserProfileService';
import useShopsService from '../../../hooks/useShopsService';
import useApplicationState from '../../../hooks/useApplicationState';
import { Constants } from '../../../constants/Constants';

import useProductsService from '../../../hooks/useProductsService';

import './UserInformation.scss';
import { logout } from '../../../services/AuthService';
import { UserRole } from '../../../constants/Constants';
import useZonesService from '../../../hooks/useZonesService';
import useHoldingsService from '../../../hooks/useHoldingsService';
import uniqeBy from '../../../utils/uniqueBy';
import { ErrorViewModel, isErrorViewModel } from '../../../models';

/**
 * Displays the top-right user information and menu.
 */
const UserInformation = (): JSX.Element | null => {
  /**
   * The translation service.
   */
  const { t } = useTranslation();

  /**
   * The msal provider, to retrieve the name to be displayed.
   */
  const { accounts } = useMsal();

  /**
   * Indicates if the user is authenticated or not, should be always true.
   */
  const isAuthenticated = useIsAuthenticated();

  /**
   * The display name of the user that is shown in this component.
   */
  const [userDisplayName, setUserDisplayName] = useState<string | undefined>();

  /**
   * The application state that is used in this component.
   */
  const {
    shops,
    setShops,
    products,
    setProducts,
    selectedShop,
    setSelectedShop,
    selectedProduct,
    setSelectedProduct,
    setRole,
    setHolding,
    loadingRoles,
    setLoadingRoles,
    setIsReadOnly,
    setIsAuthorized,
    setIsDieselAdministrator,
  } = useApplicationState();

  /**
   * The role of the user that is shown in this component.
   */
  const [visibleRole, setVisibleRole] = useState('');

  /**
   * The error handling hook to send a message to the toast notification.
   */
  const { errors, setErrors } = useErrorHandling();

  /**
   * The hook that returns an UsersService instance.
   */
  const usersService = useUserProfilesService();

  /**
   * The hook that returns a ShopsService instance.
   */
  const shopService = useShopsService();

  /**
   * The hook that returns a ProductsService instance.
   */
  const productsService = useProductsService();

  /**
   * The hook that returns a ZonesService instance.
   */
  const zoneService = useZonesService();

  /**
   * The hook that returns a ZonesService instance.
   */
  const holdingService = useHoldingsService();

  /**
   * Sets the user display name on the top right corner of the page.
   */
  useEffect(() => {
    setUserDisplayName(accounts[0].name);
  }, []);

  /**
   * Anytime the shops are refreshed, then the application shoud check if there is a selected shop and refresh that too.
   */
  useEffect(() => {
    // if there a selection in the shops global filter, then it has to be updated too.
    if (selectedShop != null) {
      setSelectedShop(shops.find((s) => s.id == selectedShop.id)!);
    }
  }, [shops, selectedShop, setSelectedProduct]);

  /**
   * Anytime the products are refreshed, then the application shoud check if there is a selected product and refresh that too.
   */
  useEffect(() => {
    // if there a selection in the shops global filter, then it has to be updated too.
    if (selectedProduct != null) {
      setSelectedProduct(products.find((s) => s.id == selectedProduct.id)!);
    }
  }, [products, selectedProduct, setSelectedProduct]);

  /**
   * Retrieves the user information and stores it in the application state.
   */
  const storeUserInformation = useCallback(() => {
    usersService
      .getAuthenticatedUser()
      ?.then((user) => {
        if (user) {
          setIsAuthorized(true);

          if (user.roleName != null) {
            setVisibleRole('Roles.' + user.roleName);
          }

          setRole(user.roleName as UserRole);
          setIsReadOnly(user.readOnly);
          setIsDieselAdministrator(user.dieselAdministrator);
          // Here it is setting the visible shops in the application state.
          // If the user has "AllShops" in its role, then the application must retrieve all the shops.
          if (user.roleName === Constants.Roles.Administrator) {
            shopService
              .getAll()
              .then((dataShops) => {
                // sets the visible shops in the application state.
                setShops(dataShops);

                productsService
                  .getAllProducts()
                  .then((allProducts) => {
                    setProducts(allProducts.products);
                  })
                  .catch((error) => {
                    setErrors([...errors, error]);
                  });
              })
              .catch((error) => {
                setErrors([...errors, error]);
              });
          } else {
            // If the user has a list of shops linked to its role, then the application stores in the state only the assigned shops.
            setShops(user.userShops.map((r) => r.shop!));

            if (user.userShops.length > 0) {
              if ((user.roleName as UserRole) === 'HoldingUser' || (user.roleName as UserRole) === 'ShopUser') {
                setHolding(user.userShops[0].shop?.holding || undefined);
              }

              const zoneIds = user.userShops.flatMap((shop) => shop.shop?.zones?.map((zone) => zone.id));

              zoneService
                .getByIdList(zoneIds)
                .then((result) => {
                  const fm = result.flatMap((zone) => zone.products);
                  const fi = uniqeBy(fm, (p) => (p.id ? p.id : 0)); // excludes the duplicates
                  setProducts(fi);
                })
                .catch((error) => {
                  setErrors([...errors, error]);
                });
            }
          }
        }
      })
      .catch((error) => {
        if (isErrorViewModel(error)) {
          if ((error as ErrorViewModel).value?.statusCode === 'NoAuth') {
            setIsAuthorized(false);
          }
          setErrors([...errors, error]);
        } else {
          console.error(error);
        }
      })
      .finally(() => {
        setLoadingRoles(false);
      });
  }, [usersService, setLoadingRoles]);

  /**
   * Logsout the currently logged in user.
   */
  const logoutUser = useCallback(async () => {
    await logout();
  }, []);

  /**
   * On mounting, registers the 'storeUserInformation' function as the subscriber for this topic.
   */
  useEffect(() => {
    const subscriptionLoadUsers = PubSub.subscribe(Constants.Topics.UserInformation, storeUserInformation);
    const subscriptionLogoutUser = PubSub.subscribe(Constants.Topics.Logout, logoutUser);
    // cleans up the subscription on unmounting.
    return function cleanup() {
      PubSub.unsubscribe(subscriptionLoadUsers);
      PubSub.unsubscribe(subscriptionLogoutUser);
    };
  }, [storeUserInformation]);

  /**
   * When authenticated it will publish the event to retrieve the user information.
   */
  useEffect(() => {
    if (isAuthenticated) {
      PubSub.publish(Constants.Topics.UserInformation);
    }
  }, [isAuthenticated]);

  return (
    <>
      <AuthenticatedTemplate>
        <div className="row agr-user-info">
          <div className="col-sm-auto">
            <div>{userDisplayName}</div>
            <div>{loadingRoles ? <FontAwesomeIcon icon={faSpinner}></FontAwesomeIcon> : t(visibleRole)}</div>
          </div>
          <div className="col d-flex align-items-center">
            <FontAwesomeIcon icon={faUser} size="2x"></FontAwesomeIcon>
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>You are not logged in.</div>
      </UnauthenticatedTemplate>
    </>
  );
};

export default withErrorHandling(UserInformation);
