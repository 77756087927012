import { object, string } from 'yup';

export function getUserProfileEditModelValidationSchema(t: any) {
  const schema = object().shape({
    email: string()
      .email(`${t('EditUserProfile.ValidationErrors.Email')}`)
      .required(`${t('EditUserProfile.ValidationErrors.Email')}`),
  });
  return schema;
}
