import { PriceExportResult, ExportType } from '@/models/price-calculation/PriceExportResult';
import {} from '../models';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { BaseService } from './BaseService';

/**
 * The service class to interact with the Zones.
 */
export class ExportService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/export');
  }

  getExports(type: ExportType): Promise<PriceExportResult[]> {
    return this.get(`/type/${type}`);
  }

  triggerPriceExport(productId: number, quantity: number): Promise<PriceExportResult> {
    return this.post('/price', { ProductId: productId, Quantity: quantity });
  }

  deleteExport(id: number): Promise<PriceExportResult> {
    return this.delete(`/${id}`);
  }
}
