import { formatCurrencyAsKendoFormat } from './currency/formatters.number';
import {
  formatPromotionAsAmount,
  getPromotionTypeName,
  readAmountToDiscount,
  readMaximumQuantity,
  readMinimalQuantity,
} from './promotion/formatters.promotion';
import { buildHour, getHour } from './datetime/formatters.time';

export const formatters = {
  currency: {
    asKendoFormat: formatCurrencyAsKendoFormat,
  },
  promotion: {
    asAmount: formatPromotionAsAmount,
    getTypeName: getPromotionTypeName,
    amountToDiscount: readAmountToDiscount,
    minimalQuantity: readMinimalQuantity,
    maximumQuantity: readMaximumQuantity,
  },
  datetime: {
    toHour: buildHour,
    getHour: getHour,
  },
};
