import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useZonesService from '../../../../hooks/useZonesService';
import usePostalCodeService from '../../../../hooks/usePostalCodeService';
import { ErrorViewModel, PostalCode, Zone } from '../../../../models';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import ColumnsWrapper from '../columns-wrapper/ColumnsWrapper';
import Column from '../column/Column';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DropResult } from '../dnd-types';

/**
 *
 * The view for the postal code assignment columns.
 *
 * @returns the Component to be used in JSXs.
 */
function ViewPostalCodesAssignment() {
  const appInsights = useAppInsightsContext();
  const { t } = useTranslation();

  const { errors, setErrors } = useErrorHandling();

  const zonesService = useZonesService();
  const postalCodeService = usePostalCodeService();

  const [loadingZones, setLoadingZones] = useState<boolean>(true);
  const [isUpdatingPostalCodes, setIsUpdatingPostalCodes] = useState<boolean>(false);

  const [allZones, setAllZones] = useState<Zone[]>();
  const [dropdownZones, setDropdownZones] = useState<Zone[]>();

  const [displayedZones, setDisplayedZones] = useState<Zone[]>();

  const [postalCodeSearchQuery, setPostalCodeSearchQuery] = useState<string>('');
  const [searchResult, setSearchResult] = useState<PostalCode[]>();

  const trackGetZones = useTrackEvent(appInsights, 'Get Zones', allZones);

  /**
   *
   * The UI loading screen.
   * Memoized because it never needs to be re-evaluated.
   *
   */
  const loadingPanel = useMemo(
    () => (
      <div className="k-loading-mask">
        <span className="k-loading-text">Loading...</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
      </div>
    ),
    [],
  );

  /**
   *
   * This callback takes care of retrieving the zones
   * by calling the appropriate endpoint through the zones
   * service.
   *
   */
  const retrieveAllZones = useCallback(async () => {
    setLoadingZones(true);

    try {
      // API call for getting all the zones.
      const { zones: allZones } = await zonesService.getAllZones();
      trackGetZones(allZones);
      setAllZones(allZones);
    } catch (error) {
      console.error(error);

      setErrors([...errors, error as ErrorViewModel]);
    }

    setLoadingZones(false);
  }, [setLoadingZones, zonesService, trackGetZones, setAllZones, setErrors, errors]);

  useEffect(() => {
    if (!allZones) {
      retrieveAllZones();
    }
  }, [allZones]);

  useEffect(() => {
    if (allZones) {
      setDropdownZones(
        allZones.filter((zone) => !displayedZones?.some((displayedZone) => displayedZone.id === zone.id)),
      );
    }
  }, [allZones, displayedZones]);

  /**
   * Callback for removing a zone from the displayed zones.
   * Displayed zones are passed as an input parameter.
   */
  const removeDisplayedZone = useCallback((displayedZones: Zone[], zoneToRemove: Zone) => {
    const newDisplayedZones = [...displayedZones];
    const indexZoneToRemove = newDisplayedZones.findIndex((displayedZone) => displayedZone.id === zoneToRemove.id);

    if (indexZoneToRemove === -1) {
      throw new Error('Cannot find zone to remove.');
    }

    newDisplayedZones.splice(indexZoneToRemove, 1);

    return newDisplayedZones;
  }, []);

  /**
   * Callback that substitutes in place two zones in an array.
   */
  const replaceZoneInDisplayedZones = useCallback((zones: Zone[], zoneToInsert: Zone, zoneToReplace: Zone) => {
    const newZones = [...zones];
    const indexToReplace = newZones.findIndex((zone) => zone.id === zoneToReplace.id);

    if (indexToReplace < 0) {
      throw new Error('Cannot find zone to replace.');
    }

    newZones[indexToReplace] = zoneToInsert;

    return newZones;
  }, []);

  /**
   * Callback that takes care of keeping track of the zones currently displayed in order
   * to remove them from other instances of the dropdowns in columns for the selection.
   */
  const updateDisplayedZones = useCallback(
    (displayedZones: Zone[] | undefined, zoneToAdd: Zone, zoneToReplace?: Zone) => {
      let newDisplayedZones: Zone[] = [];

      if (zoneToReplace) {
        try {
          newDisplayedZones = replaceZoneInDisplayedZones(displayedZones ?? [], zoneToAdd, zoneToReplace);
        } catch (error) {
          setErrors([
            ...errors,
            {
              title: 'Error while trying to remove displayed zone.',
              statusCode: '',
              value: { description: (error as Error).message },
            },
          ]);

          return;
        }
      } else {
        newDisplayedZones = displayedZones ? [...displayedZones, zoneToAdd] : [zoneToAdd];
      }

      setDisplayedZones(newDisplayedZones);
    },
    [removeDisplayedZone, setErrors, errors, setDisplayedZones],
  );

  const removePostalCodeFromArray = useCallback((postalCodes: PostalCode[], postalCodeToRemove: PostalCode) => {
    const newPostalCodes = [...postalCodes];
    const indexToRemove = newPostalCodes.findIndex((postalCode) => postalCode.id === postalCodeToRemove.id);

    if (indexToRemove < 0) {
      throw new Error('Cannot find postal code to remove.');
    }

    newPostalCodes.splice(indexToRemove, 1);

    return newPostalCodes;
  }, []);

  const performDragAndDrop = useCallback(
    async (postalCodeToUpdate: DropResult) => {
      if (!displayedZones) {
        return;
      }

      const dndPostalCode = postalCodeToUpdate.dndPostalCode;
      const postalCodeToMove = postalCodeToUpdate.dndPostalCode.postalCode;

      const previousZoneId = dndPostalCode.zone?.id ?? null;
      const targetZoneId = postalCodeToUpdate.zoneId;

      const newDisplayedZones = [...displayedZones].map((displayedZone) => {
        if (displayedZone.id === previousZoneId) {
          const newPostalCodes = removePostalCodeFromArray(displayedZone.postalCodes, postalCodeToMove);
          const newZoneWithoutPostalCode: Zone = { ...displayedZone, postalCodes: newPostalCodes };

          return newZoneWithoutPostalCode;
        } else if (displayedZone.id === targetZoneId) {
          const newZoneWithPostalCode: Zone = {
            ...displayedZone,
            postalCodes: [...displayedZone.postalCodes, postalCodeToMove],
          };

          return newZoneWithPostalCode;
        }

        return displayedZone;
      });

      // API call to update postal codes.
      try {
        setIsUpdatingPostalCodes(true);

        await postalCodeService.updatePostalCode({ ...postalCodeToMove, zoneId: targetZoneId });
      } catch (error) {
        console.error(error);

        setErrors([...errors, error as ErrorViewModel]);

        return;
      } finally {
        setIsUpdatingPostalCodes(false);
      }

      setDisplayedZones(newDisplayedZones);

      if (previousZoneId === null) {
        const newSearchResult = searchResult
          ? removePostalCodeFromArray(searchResult, postalCodeToMove)
          : [postalCodeToMove];

        setSearchResult(newSearchResult);
      } else if (targetZoneId === null) {
        setSearchResult(searchResult ? [...searchResult, postalCodeToMove] : [postalCodeToMove]);
      }
    },
    [displayedZones, postalCodeService, setErrors, errors, setDisplayedZones, searchResult, removePostalCodeFromArray],
  );

  if (loadingZones) {
    return loadingPanel;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <ViewWrapper title={t('PostalCodesView.Title')}>
        <ColumnsWrapper>
          <Column
            variant="search"
            postalCodes={searchResult || []}
            search={postalCodeSearchQuery}
            setSearch={(e) => setPostalCodeSearchQuery(e.currentTarget.value)}
            startSearch={async () => {
              try {
                if (postalCodeSearchQuery) {
                  const searchResult = await postalCodeService.getPostalCodesByCriteria(postalCodeSearchQuery);

                  setSearchResult(searchResult.searchResult);
                  setDisplayedZones(searchResult.zones);
                } else {
                  setSearchResult(undefined);
                }
              } catch (error) {
                console.error(error);

                setErrors([...errors, error as ErrorViewModel]);
              }
            }}
            hasSearchResults={searchResult && searchResult.length > 0}
            performDragAndDrop={performDragAndDrop}
          />
          {displayedZones?.map((zone, index) => (
            <Column
              key={index}
              dropdownZones={dropdownZones}
              dropdownValue={zone}
              setDropdownZone={async (e) => {
                const selectedZone = e.target.value as Zone;

                if (!selectedZone.id) {
                  setErrors([
                    ...errors,
                    {
                      title: 'Cannot get id of selected zone.',
                      statusCode: '',
                      value: { description: 'Cannot get id of selected zone.' },
                    },
                  ]);

                  return;
                }

                const postalCodes = await postalCodeService.getPostalCodesByZoneId(selectedZone.id);
                selectedZone.postalCodes = postalCodes;

                updateDisplayedZones(displayedZones, selectedZone, zone);
              }}
              onClose={() => {
                const newDisplayedZones = removeDisplayedZone(displayedZones, zone);
                setDisplayedZones(newDisplayedZones);
              }}
              postalCodes={zone.postalCodes}
              performDragAndDrop={performDragAndDrop}
            />
          ))}
          <Column
            variant="dummy"
            dropdownZones={dropdownZones}
            setDropdownZone={async (e) => {
              const selectedZone = e.target.value as Zone;

              if (!selectedZone.id) {
                setErrors([
                  ...errors,
                  {
                    title: 'Cannot get id of selected zone.',
                    statusCode: '',
                    value: { description: 'Cannot get id of selected zone.' },
                  },
                ]);

                return;
              }

              const postalCodes = await postalCodeService.getPostalCodesByZoneId(selectedZone.id);
              selectedZone.postalCodes = postalCodes;
              updateDisplayedZones(displayedZones, selectedZone);
            }}
            postalCodes={[]}
            performDragAndDrop={performDragAndDrop}
          />
        </ColumnsWrapper>

        {isUpdatingPostalCodes && loadingPanel}
      </ViewWrapper>
    </DndProvider>
  );
}

export default withErrorHandling(ViewPostalCodesAssignment);
