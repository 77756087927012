import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import styled from 'styled-components';
import { PostalCode, Zone } from '../../../../models';
import PostalCodeItem from '../postal-code-item/PostalCodeItem';

import magnifyingGlass from '../../../../assets/magnifying-glass.png';
import { useCallback, useMemo, useState } from 'react';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { useDrop } from 'react-dnd';
import { DnDPostalCode, dndPostalCodeType, DropResult } from '../dnd-types';
import useZonesService from '../../../../hooks/useZonesService';

/**
 * Styles for the Column main container.
 */
const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 325px;
  min-width: 300px;
  margin: 0 5px 0 5px;
`;

interface ColumnContentProps {
  backgroundColor?: string;
}

/**
 * Styles for the content of a column.
 */
const ColumnContent = styled.div<ColumnContentProps>`
  display: inherit;
  flex-direction: inherit;

  border: 1px solid black;
  flex: 1;
  padding: 1em;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#f0f0f0')};
  overflow-y: auto;
  max-height: 60vh;
`;

/**
 * The Column Component props.
 */
interface ColumnProps {
  /**
   * The Column type, if the first column, the search column
   * or a column for assigning the postal codes.
   */
  variant?: 'search' | 'assignment' | 'dummy';

  /**
   * The zones to be shown in the column header dropdown.
   * Optional because if variant is "search", the column doesn't
   * have a dropdown as a header to choose from, just a title.
   */
  dropdownZones?: Zone[];

  /**
   * The selected dropdown value.
   * Optional because if variant is "search", the column doesn't
   * have a dropdown as a header to choose from, just a title.
   */
  dropdownValue?: Zone;

  /**
   * A callback that sets the selected zone for a column.
   * Optional because if variant is "search", the column doesn't
   * have a dropdown as a header to choose from, just a title.
   */
  setDropdownZone?: (e: DropDownListChangeEvent) => void;

  /**
   * The postal code search query.
   * Optional because assignment variant columns don't have a search.
   */
  search?: string;

  /**
   * Callback for updating the search input form.
   * Optional because assignment variant columns don't have a search.
   */
  setSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * Callback when the magnifying glass is clicked to begin searching
   * for the postal codes in the input field.
   * Optional because assignment variant columns don't have a search.
   */
  startSearch?: () => Promise<void>;

  /**
   * Boolean indicating whether the search has found unassociated
   * postal codes that can be drag and dropped and can show the hint.
   * Optional because the assignment column doesn't have to show the hint
   * related to drag and dropping: required only in the search column.
   */
  hasSearchResults?: boolean;

  /**
   * Callback that gets fired once the close column button is pressed.
   * Optional because "search" column can't be closed and palceholder
   * column for searching postal codes by zone cannot be closed.
   */
  onClose?: () => void;

  /**
   * Callback for updating the displayed zones.
   */
  performDragAndDrop: (postalCodeToUpdate: DropResult) => Promise<void>;

  /**
   * The postal codes to be displayed in the Column.
   */
  postalCodes: PostalCode[];
}

/**
 *
 * The Column Component that takes care of showing the postal codes of a zone.
 *
 * @returns the Component to be used in JSXs.
 */
export default function Column({
  variant = 'assignment',
  dropdownZones,
  dropdownValue,
  setDropdownZone,
  search,
  setSearch,
  startSearch,
  hasSearchResults,
  onClose,
  performDragAndDrop,
  postalCodes,
}: ColumnProps) {
  const { t } = useTranslation();

  const zonesService = useZonesService();

  const [isSearching, setIsSearching] = useState<boolean>(false);

  /**
   * This loading represents whether a drag & drop triggered
   * the API that associates the postal code to the zone.
   */
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filter, setFilter] = useState<FilterDescriptor | CompositeFilterDescriptor>();

  const defaultItem = useMemo(() => ({ name: t('ViewPostalCodesAssignment.DropdownPlaceholder') }), [t]);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: dndPostalCodeType,
      drop: (item: DnDPostalCode): DropResult => {
        return {
          zoneId: dropdownValue ? dropdownValue.id! : null,
          dndPostalCode: item,
        };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      canDrop: (item) => variant !== 'dummy' && item.zone !== dropdownValue,
    }),
    [dndPostalCodeType, variant, dropdownValue],
  );

  const searchPostalCodes = useCallback(async () => {
    setIsSearching(true);

    await startSearch!();

    setIsSearching(false);
  }, [setIsSearching, startSearch]);

  return (
    <ColumnWrapper ref={drop}>
      {variant === 'search' && (
        <div className="d-flex align-items-end" style={{ marginBottom: '0.5em', height: 37.6 }}>
          <span>{t('ViewPostalCodesAssignment.SearchPostalCodes')}</span>
        </div>
      )}
      {(variant === 'assignment' || variant === 'dummy') && (
        <DropDownList
          style={{ marginBottom: '0.5em', width: '100%' }}
          value={dropdownValue || defaultItem}
          data={filter ? filterBy(dropdownZones || [], filter) : dropdownZones}
          dataItemKey="id"
          textField="name"
          onChange={setDropdownZone}
          filterable
          onFilterChange={(e) => setFilter(e.filter)}
        />
      )}

      {variant === 'search' && (
        <ColumnContent backgroundColor={canDrop ? '#5ff587' : ''}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <input
              data-tip={t('ViewPostalCodesAssignment.SearchTooltip')}
              className="form-control me-3"
              type="text"
              placeholder={t('ViewPostalCodesAssignment.SearchPlaceholder')}
              value={search}
              onChange={setSearch}
              onKeyPress={(e) => e.key === 'Enter' && searchPostalCodes()}
            />
            <div
              onClick={() => {
                searchPostalCodes();
              }}
            >
              {!isSearching && <img src={magnifyingGlass} width="40" style={{ cursor: 'pointer' }} />}
              {isSearching && (
                <div className="spinner-border spinner-border-sm text-black" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
          </div>

          {hasSearchResults && (
            <span style={{ display: 'block', marginBottom: 10 }}>
              {t('ViewPostalCodesAssignment.FreePostalCodesFound')}
              <br />
              {t('ViewPostalCodesAssignment.DnDHint')}
            </span>
          )}
          {postalCodes.map((postalCode, index) => (
            <PostalCodeItem
              key={index}
              postalCode={postalCode}
              performDragAndDrop={performDragAndDrop}
              zone={dropdownValue}
            />
          ))}
        </ColumnContent>
      )}

      {(variant === 'assignment' || variant === 'dummy') && (
        <ColumnContent backgroundColor={canDrop ? '#5ff587' : ''}>
          {postalCodes.map((postalCode, index) => (
            <PostalCodeItem
              key={index}
              postalCode={postalCode}
              performDragAndDrop={performDragAndDrop}
              zone={dropdownValue}
            />
          ))}
          {dropdownValue && (
            <button
              type="button"
              className="btn btn-danger"
              style={{ alignSelf: 'flex-end', marginTop: 'auto' }}
              onClick={onClose}
            >
              {t('ViewPostalCodesAssignment.CloseColumn')}
            </button>
          )}
        </ColumnContent>
      )}

      <ReactTooltip multiline place="top" effect="solid" textColor="white" backgroundColor="#007d32" />
    </ColumnWrapper>
  );
}
