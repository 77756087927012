import { useTranslation } from 'react-i18next';
import useCurrentLanguage from '../../../../../hooks/useCurrentLanguage';
import { GetOrderName, getDeliveryWeeks, Language, GetPriceLabel, GetUnitCodeName } from '../../../../../models';
import { IOrderDetailsProps } from '../EditOrderForm';
import numberWithApostrophe from '../../../../../utils/numberWithApostrophe';

import './OrderDetails.scss';

const OrderDetails = ({ order }: IOrderDetailsProps) => {
  const { t } = useTranslation();

  const language = useCurrentLanguage();

  const quantityUnit = GetUnitCodeName(t, order?.orderData?.quantityUnit);

  const quantityUnitPrice = GetPriceLabel(t, order?.orderData?.quantityUnit);

  let optionsValue = 0;

  if (order?.orderData?.storageRoomCleaningWithoutVAT !== undefined) {
    optionsValue += order?.orderData?.storageRoomCleaningWithoutVAT;
  }

  if (order?.orderData?.hoseLengthWithoutVAT !== undefined) {
    optionsValue += order?.orderData?.hoseLengthWithoutVAT;
  }

  if (order?.orderData?.smallVehicleWithoutVAT !== undefined) {
    optionsValue += order?.orderData?.smallVehicleWithoutVAT;
  }

  let discountValue = 0;

  if (order?.orderData?.priceOffer !== undefined) {
    discountValue = order?.orderData?.priceOffer.totalDiscount;
  }

  return (
    <>
      <div style={{ margin: 10, width: '100%' }}>
        <div className="order-title h5">
          {t('OrderDetail.OrderTitle')} {order?.orderDate?.toLocaleDateString(`${language}-${language}`)}
        </div>
        <div className="d-flex justify-content-between mb-4">
          <div>
            <span className="title">{t('OrderDetail.InvoiceAddress')}</span>
            <ul className="no-bullets">
              {!!order?.orderData.invoiceCompany && <li>{order?.orderData.invoiceCompany}</li>}
              <li>{order?.orderData.invoiceSalutation}</li>
              <li>
                {order?.orderData.invoiceName} {order?.orderData.invoiceSurname}
              </li>
              <li>
                {order?.orderData.invoiceStreet} {order?.orderData.invoiceHouseNumber}
              </li>
              <li>
                {order?.orderData.invoicePostalCode} {order?.orderData.invoiceLocation}
              </li>
            </ul>
          </div>
          <div className="margin-right">
            <span className="title">{t('OrderDetail.DeliveryAddress')}</span>
            <ul className="no-bullets">
              {order?.orderData?.deliveryAddresses?.map((delivery, index) => {
                return (
                  <div key={index}>
                    <li>
                      {delivery?.street} {delivery?.houseNumber}
                    </li>
                    <li>
                      {delivery?.location} {delivery?.postalCode}
                    </li>
                    <li>
                      {delivery?.quantity} {quantityUnit}
                    </li>
                    {delivery.fillTank !== undefined && (
                      <li className="mb-3">{`${t('OrderDetail.FillComplete')}: ${
                        delivery.fillTank ? t('CommonLabels.Yes') : t('CommonLabels.No')
                      }`}</li>
                    )}
                  </div>
                );
              })}
            </ul>
          </div>
        </div>
        <table className="order-detail">
          <tbody>
            <tr>
              <td className="firstCell" style={{ fontWeight: 'bold' }}>
                {t('OrderDetail.Quantity')}
              </td>
              <td className="firstCell" style={{ fontWeight: 'bold' }}>
                {t('OrderDetail.Description')}:
              </td>
              <td className="firstCell" style={{ fontWeight: 'bold' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '50%', textAlign: 'right' }}>{t('OrderDetail.UnitPrice')}:</div>
                  <div></div>
                </div>
              </td>
              <td className="firstCell" style={{ fontWeight: 'bold', textAlign: 'right' }}>
                {t('OrderDetail.TotalPrice')}:
              </td>
            </tr>
            <tr style={{ verticalAlign: 'top' }}>
              <td>
                {order?.orderData?.quantity} {quantityUnit}
              </td>
              <td className="firstCell">
                {language.toLowerCase() == 'de' ? order?.product?.nameDe : order?.product?.nameFr}
              </td>
              <td>
                <div style={{ display: 'flex' }}>
                  <div className="cellWithSpan">
                    {numberWithApostrophe(order?.orderData?.priceWithoutVAT?.toFixed(2))}
                  </div>
                  <div>
                    &nbsp;{quantityUnitPrice} <span style={{ fontSize: 10 }}>({t('OrderDetail.WithoutVat')})</span>
                  </div>
                </div>
                <div style={{ display: 'flex', fontWeight: 'bold' }}>
                  <div className="cellWithSpan">{numberWithApostrophe(order?.orderData?.priceWithVAT?.toFixed(2))}</div>
                  <div>
                    &nbsp;{quantityUnitPrice} <span style={{ fontSize: 10 }}>({t('OrderDetail.WithVat')})</span>
                  </div>
                </div>
              </td>
              <td align="right">
                {numberWithApostrophe(
                  (order?.orderData?.totalPriceWithoutVAT !== undefined
                    ? order?.orderData?.totalPriceWithoutVAT - optionsValue
                    : undefined
                  )?.toFixed(2),
                )}
                &nbsp;CHF
              </td>
            </tr>
            {optionsValue > 0 ? (
              <tr>
                <td className="firstCell"></td>
                <td>{t('OrderDetail.Options')}</td>
                <td>
                  <div style={{ display: 'flex' }}>
                    <div className="cellWithSpan"></div>
                  </div>
                </td>
                <td align="right">{numberWithApostrophe(optionsValue.toFixed(2))}&nbsp;CHF</td>
              </tr>
            ) : null}
            {discountValue > 0 ? (
              <tr>
                <td className="firstCell"></td>
                <td>{t('PromotionsView.Discount')}</td>
                <td>
                  <div style={{ display: 'flex' }}>
                    <div className="cellWithSpan"></div>
                  </div>
                </td>
                <td align="right">-{numberWithApostrophe(discountValue.toFixed(2))}&nbsp;CHF</td>
              </tr>
            ) : null}
            <tr>
              <td className="firstCell"></td>
              <td>
                {((order?.orderData?.priceOffer?.vatRate ?? 0) * 100).toFixed(2)} % {t('OrderDetail.Vat')}
              </td>
              <td>
                <div style={{ display: 'flex' }}>
                  <div className="cellWithSpan"></div>
                </div>
              </td>
              <td align="right">{numberWithApostrophe(order?.orderData?.totalVAT?.toFixed(2))}&nbsp;CHF</td>
            </tr>
            <tr>
              <td className="border-top-bottom firstCell">{t('OrderDetail.Total')}:</td>
              <td className="border-top-bottom"></td>
              <td className="border-top-bottom">
                <div style={{ display: 'flex', color: '#888' }}>
                  <div className="cellWithSpan">
                    {numberWithApostrophe(order?.orderData?.priceDiscountedWithVAT?.toFixed(2))}
                  </div>
                  <div>
                    &nbsp;{quantityUnitPrice}{' '}
                    <span style={{ fontSize: 10, fontWeight: 'normal' }}>({t('OrderDetail.WithVat')})</span>
                  </div>
                </div>
              </td>
              <td className="border-top-bottom" align="right">
                {numberWithApostrophe(
                  order?.orderData?.totalPriceIncludingVAT !== undefined
                    ? (order?.orderData?.totalPriceIncludingVAT - discountValue).toFixed(2)
                    : undefined,
                )}
                &nbsp;CHF
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="detail-set">
        <div>
          <div>{t('OrderDetail.EMail')}:</div>
          <div>{order?.orderData?.email}</div>
        </div>
        <div>
          <div>{t('OrderDetail.PhoneNumber')}:</div>
          <div>{order?.orderData?.phone}</div>
        </div>
        <div>
          <div>{t('OrderDetail.DeliveryWeek')}:</div>
          <div>{getDeliveryWeeks(t, order?.orderData?.deliveryWeeks)}</div>
        </div>
        <div>
          <div>{t('OrderDetail.PaymentMethod')}:</div>
          <div>{order?.orderData?.paymentType}</div>
        </div>
        {order?.orderData?.fillTankCompletely !== undefined && (
          <div>
            <div>{t('OrderDetail.FillComplete')}:</div>
            <div>{order?.orderData?.fillTankCompletely ? t('CommonLabels.Yes') : t('CommonLabels.No')}</div>
          </div>
        )}

        {order?.orderData.storageRoomCleaning || order?.orderData.smallVehicle || order?.orderData.hoseLength ? (
          <div>
            <div>{t('OrderDetail.Options')}:</div>
            <div className="detail-value-list">
              {order.orderData.storageRoomCleaning ? (
                <div className="">{order?.orderData?.storageRoomCleaningText}</div>
              ) : null}
              {order.orderData.smallVehicle ? <div className="">{order?.orderData?.smallVehicleText}</div> : null}
              {order.orderData.hoseLength ? <div className="">{order?.orderData?.hoseLengthText}</div> : null}
            </div>
          </div>
        ) : null}
        {!!order?.orderData?.priceOffer?.promotion && (
          <div>
            <div>{t('OrderDetail.Promotion')}:</div>
            <div>
              {(order?.orderData?.language == Language.DE
                ? order?.orderData?.priceOffer?.promotion?.descriptionDE
                : order?.orderData?.priceOffer?.promotion?.descriptionFR) +
                (order?.orderData?.promotionGift
                  ? order?.orderData?.language == Language.DE
                    ? ' (' + order.orderData.promotionGift.descriptionDE + ')'
                    : ' (' + order.orderData.promotionGift.descriptionFR + ')'
                  : '')}
            </div>
          </div>
        )}
        <div>
          <div>{t('OrderDetail.Portal')}:</div>
          <div>{order?.orderData?.portal}</div>
        </div>
        <div>
          <div>{t('OrderDetail.Language')}:</div>
          <div>{order?.orderData?.language == Language.DE ? t('CommonLabels.German') : t('CommonLabels.French')}</div>
        </div>
        <div>
          <div>{t('OrderDetail.Other')}:</div>
          <div>{order?.orderData?.clientComment}</div>
        </div>
        <div style={{ borderTop: '1px dotted #000', marginTop: 20 }}>&nbsp;</div>
        <div>
          <div>Status:</div>
          <div>{GetOrderName(t, order?.status)}</div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
