import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { YupError } from '../../../../../hooks/useYupErrors';
import { ProductError } from '../AddProductForm';
import { ProductTemplate } from '../../../../../models';
import ProductTemplatePreview from '../../../../common/product-template-preview/ProductTemplatePreview';

/**
 * The props for the ProductDetails Component.
 */
interface ProductDetailsProps {
  productTemplate: ProductTemplate;
  setTemplate: (template: ProductTemplate | undefined) => void;

  showProductName?: boolean;
  name: string;
  setProductName: (name: string) => void;

  productImage: string | undefined;
  setProductImage: (imageUrl: string | undefined) => void;

  validationErrors?: YupError<Pick<ProductError, 'name' | 'template'>> | null;
}

/**
 *
 * This is the bottom part of the add product modal menu.
 * It contains the tab menus with the product details and
 * the coding environment for writing the template.
 *
 * @returns the product details Component.
 */
export default function ProductDetails({
  productTemplate,
  setTemplate,

  showProductName = true,
  name,
  setProductName,

  productImage,
  setProductImage,

  validationErrors,
}: ProductDetailsProps): JSX.Element | null {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column h-100">
      {showProductName && (
        <>
          <span className="my-2 mt-sm-0">{t('AddProductModal.ProductName')}</span>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setProductName(e.currentTarget.value)}
          />

          <div className="position-relative">
            {validationErrors?.name && !name && <small className="text-danger">{validationErrors.name}</small>}
          </div>
        </>
      )}

      <ProductTemplatePreview
        productImage={productImage}
        setProductImage={setProductImage}
        productTemplate={productTemplate}
        setTemplate={setTemplate}
        templateError={validationErrors?.template}
      />
    </div>
  );
}
