import { useCallback, useEffect, useMemo, useState } from 'react';
import withModal from '../../../hoc/with-modal/withModal';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import {
  ErrorViewModel,
  isErrorViewModel,
  PostalCode,
  PriceCalculationRequest,
  PriceCalculationResult,
  ProductType,
  UnitCode,
} from '../../../../models';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import getCheckPriceValidationSchema from './ValidationSchema';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import usePriceService from '../../../../hooks/usePriceService';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import useApplicationState from '../../../../hooks/useApplicationState';
import { WoodProductTypeName, DieselProductTypeName } from '../../../../assets/constants/DataConstants';
import usePostalCodeService from '../../../../hooks/usePostalCodeService';
import roundPrice from '../../../../utils/roundPrice';
import Spinner from '../../../common/spinner/Spinner';
import { Formik, FormikErrors } from 'formik';
import { Form } from 'react-bootstrap';

/**
 * Style for the virtual column title.
 */
const ColumnTitle = styled.span`
  margin-bottom: 1rem;
`;

/**
 * Responsive style for alignment blank row in form's
 * second column.
 */
const BlankLine = styled.span.attrs((props) => ({
  ...props,
}))<{}>`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

/** Interface for unloading places */
export interface UnloadingPlace {
  quantity: number;
  postalCodeNpa: string;
  postalCodeLocation: string;
}

interface CheckPriceFormValue {
  quantity: number;
  postalCodeNpa: string;
  postalCodeLocation: string;
  unloadingPlaceItems: UnloadingPlace[];
}

/**
 * The form props.
 */
interface CheckPriceFormProps {
  /**
   * The zone id for whose price has to be calculated.
   * Undefined is for errors.
   */
  zoneId: number | undefined;

  /**
   * The product type for which we want to calculate the price.
   * Used to differentiate the unit of measure and is also a parameter
   * for the CheckPrice API call.
   */
  productType: ProductType;
}

/**
 *
 * The interectable content of the modal for testing the price of a product.
 *
 * @returns the Component to be displayed.
 */
const CheckPriceForm = ({ zoneId, productType }: CheckPriceFormProps) => {
  const { t } = useTranslation();

  const priceService = usePriceService();
  const postalCodeService = usePostalCodeService();

  const { errors, setErrors } = useErrorHandling();

  const CheckPriceValidationSchema = useMemo(() => getCheckPriceValidationSchema(t), [t]);

  const isWood = useMemo(() => productType.productName === WoodProductTypeName, [productType]);
  const isDiesel = useMemo(() => productType.productName === DieselProductTypeName, [productType]);

  const [isLoadingPrice, setIsLoadingPrice] = useState<boolean>(false);

  const [isRetrievingPostalCodes, setIsRetrievingPostalCodes] = useState<boolean>(true);
  const [zonePostalCodes, setZonePostalCodes] = useState<PostalCode[]>();

  const { selectedShop, selectedProduct } = useApplicationState();

  const [quantity, setQuantity] = useState<string>();
  const [selectedNpa, setSelectedNpa] = useState<number>();
  const [selectedLocation, setSelectedLocation] = useState<string>();
  const [unloadingPlacesCount, setUnloadingPlacesCount] = useState<number>(1);
  const [unloadingPlaceItems, setUnloadingPlaceItems] = useState<UnloadingPlace[]>([]);

  const [priceCalculationResult, setPriceCalculationResult] = useState<PriceCalculationResult>();
  const unloadingPlacesDataSet = useMemo(() => [1, 2, 3, 4, 5], []);

  const selectedPostalCodes = useMemo(() => {
    let postalCodesByNpa = zonePostalCodes?.filter((zpc) => zpc.npa === selectedNpa) ?? [];

    if (selectedLocation) {
      postalCodesByNpa = postalCodesByNpa.filter((pc) => pc.name === selectedLocation);
    }

    return postalCodesByNpa;
  }, [zonePostalCodes, selectedNpa, selectedLocation]);

  const zonesPostalCodeNpas = useMemo(
    () => [...new Set([...(zonePostalCodes?.map((zpc) => zpc.npa) ?? [])])],
    [zonePostalCodes],
  );

  const isNpaUnique = useMemo(() => {
    return (zonePostalCodes?.filter((zpc) => zpc.npa === selectedNpa) ?? []).length <= 1;
  }, [zonePostalCodes, selectedNpa]);

  const initialValues = useMemo<CheckPriceFormValue>(() => {
    const initialValues: CheckPriceFormValue = {
      quantity: 0,
      postalCodeLocation: '',
      postalCodeNpa: '',
      unloadingPlaceItems: [],
    };
    return initialValues;
  }, []);

  /**
   * Validate form and return true if no errors found, false vice versa.
   */
  const validateForm = useCallback(
    async (formValue: CheckPriceFormValue) => {
      setIsLoadingPrice(true);

      try {
        const deliveryDate = new Date();
        deliveryDate.setDate(new Date().getDate() + 14);

        const deliveryDifficultyFeeCode = selectedLocation
          ? selectedPostalCodes.find((spc) => spc.name === selectedLocation)?.deliveryDifficultyFeeCode
          : selectedPostalCodes[0].deliveryDifficultyFeeCode;

        if (deliveryDifficultyFeeCode === undefined) {
          throw new Error('Unable to retrieve the correct delivery difficulty fee code.');
        }

        const priceRequest: PriceCalculationRequest = {
          postalCode: selectedNpa!.toString(),
          deliveryDifficultyFeeCode: deliveryDifficultyFeeCode,
          productNumber: selectedProduct!.agrolaProductNumber!.toString(),
          shopId: selectedShop!.id,
          zoneId: zoneId!,
          requestedProductQuantity: Number(quantity),
          unloadingPlaces: unloadingPlacesCount,
          inputUnitCode: isWood ? UnitCode.kg : isDiesel ? UnitCode.lt : UnitCode.lt,
          outputUnitCode: productType.unitCode,
          unloadingPlaceItems: unloadingPlaceItems,
        };
        const priceResult = await priceService.checkPrice(priceRequest);
        setPriceCalculationResult(priceResult);
      } catch (error) {
        console.error(error);

        if (isErrorViewModel(error)) {
          setErrors([...errors, error]);
        } else if (error instanceof Error) {
          setErrors([
            ...errors,
            {
              statusCode: '',
              title: error.name,
              value: {
                description: error.message,
              },
            },
          ]);
        }
      } finally {
        setIsLoadingPrice(false);
      }
    },
    [
      selectedLocation,
      selectedPostalCodes,
      selectedNpa,
      selectedProduct,
      selectedShop,
      zoneId,
      quantity,
      unloadingPlacesCount,
      isWood,
      isDiesel,
      productType.unitCode,
      unloadingPlaceItems,
      priceService,
      setErrors,
      errors,
    ],
  );

  const retrievePostalCodes = useCallback(
    async (zoneId: number) => {
      setIsRetrievingPostalCodes(true);

      try {
        const zonePostalCodes = await postalCodeService.getPostalCodesByZoneId(zoneId);
        setZonePostalCodes(zonePostalCodes);
      } catch (error) {
        console.error(error);

        setErrors([...errors, error as ErrorViewModel]);
      } finally {
        setIsRetrievingPostalCodes(false);
      }
    },
    [setIsRetrievingPostalCodes, postalCodeService, setZonePostalCodes, setErrors, errors],
  );

  useEffect(() => {
    if (!zoneId) {
      setErrors([
        ...errors,
        {
          title: 'The zone ID is undefined.',
          statusCode: '',
          value: {
            description: 'Cannot perform a price check without the zone ID, used to retrieve the postal codes.',
          },
        },
      ]);
    } else {
      retrievePostalCodes(zoneId);
    }
  }, []);

  if (!zoneId) {
    return <span>{t('CheckPriceModal.ZoneIdUndefined')}</span>;
  }

  if (isRetrievingPostalCodes) {
    return (
      <div className="container px-2 d-flex justify-content-center">
        <div className="spinner-border spinner-border" role="status">
          <span className="visually-hidden">{t('Loading')}</span>
        </div>
      </div>
    );
  }

  if (!zonePostalCodes || zonePostalCodes?.length === 0) {
    return <span>{t('CheckPriceModal.NoPostalCodeAssigned')}</span>;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={validateForm} validationSchema={CheckPriceValidationSchema}>
      {({ handleSubmit, handleChange, setFieldValue, setFieldTouched, handleBlur, errors, touched, values }) => (
        <Form
          noValidate
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="container px-2">
            <div className="d-flex flex-column flex-md-row justify-content-evenly align-items-start">
              <div className="d-flex flex-column align-items-start justify-content-start">
                <ColumnTitle>{t('CheckPriceModal.Input')}</ColumnTitle>
                <label htmlFor="postalCodeNpasId" className="mb-1">
                  {t('CheckPriceModal.PostalCodeNpa')}
                </label>
                {touched.postalCodeNpa && errors.postalCodeNpa && (
                  <span className="d-block text-danger">{errors.postalCodeNpa}</span>
                )}
                <DropDownList
                  style={{ width: '100%' }}
                  id="postalCodeNpasId"
                  data={zonesPostalCodeNpas}
                  name="postalCodeNpa"
                  value={values.postalCodeNpa}
                  onChange={(e) => {
                    const postalCodes = zonePostalCodes?.filter((zpc) => zpc.npa === Number(e.target.value));
                    const location = postalCodes.length === 1 ? postalCodes[0].name : undefined;
                    setFieldValue('postalCodeLocation', location, false);
                    setFieldTouched('postalCodeLocation', true, false);
                    setSelectedNpa(e.target.value);
                    setSelectedLocation(location);
                    handleChange(e);
                  }}
                />
                {!isNpaUnique && (
                  <>
                    <label htmlFor="postalCodeLocationsId" className="mb-1 mt-3">
                      {t('CheckPriceModal.PostalCodeLocation')}
                    </label>
                    {touched.postalCodeLocation && errors.postalCodeLocation && (
                      <span className="d-block text-danger">{errors.postalCodeLocation}</span>
                    )}
                    <DropDownList
                      style={{ width: '100%' }}
                      id="postalCodeLocationsId"
                      data={zonePostalCodes.filter((zpc) => zpc.npa === selectedNpa).map((pc) => pc.name)}
                      name="postalCodeLocation"
                      value={values.postalCodeLocation}
                      onChange={(e) => {
                        setSelectedLocation(e.target.value);
                        handleChange(e);
                      }}
                    />
                  </>
                )}
                <label htmlFor="quantity" className="mb-1 mt-3">
                  {t('CheckPriceModal.Quantity')} ({isWood ? 'kg' : isDiesel ? 'L' : 'L'})
                </label>
                {touched.quantity && errors.quantity && <span className="d-block text-danger">{errors.quantity}</span>}
                <input
                  id="quantity"
                  className="form-control"
                  type="text"
                  name="quantity"
                  value={values.quantity}
                  onChange={async (e) => {
                    setQuantity(e.target.value);
                    handleChange(e);
                  }}
                />
                <label htmlFor="unloadingPlacesCount" className="mb-1 mt-3">
                  {t('CheckPriceModal.HowManyUnloading')}
                </label>
                <DropDownList
                  style={{ width: '100%' }}
                  id="assignToShopDropdown"
                  data={unloadingPlacesDataSet}
                  value={unloadingPlacesCount}
                  onChange={(e) => {
                    let items: UnloadingPlace[] = [];

                    if (Number(e.target.value) > 1) {
                      items = Array.from({ length: Number(e.target.value) }).map<UnloadingPlace>((_item, index) => ({
                        postalCodeNpa: '',
                        quantity: 0,
                        postalCodeLocation: '',
                      }));
                    }
                    setUnloadingPlaceItems(items);
                    setUnloadingPlacesCount(e.target.value);
                    setFieldValue('unloadingPlaceItems', items, false);
                    handleChange(e);
                  }}
                />
                {/* Unloading places items */}
                {unloadingPlaceItems.length > 0 &&
                  unloadingPlaceItems.map((item, index) => (
                    <div key={index} className="d-flex flex-column align-items-start justify-content-start">
                      <label style={{ fontWeight: 'bold' }} className="mb-1">
                        <span>{t('CheckPriceModal.UnloadingPlace') + ' ' + (index + 1)}</span>
                      </label>
                      <label htmlFor={`unloadingPlaceItems[${index}].postalCodeNpa`} className="mb-1">
                        {t('CheckPriceModal.PostalCodeNpa')}
                      </label>
                      {touched.unloadingPlaceItems?.[index]?.postalCodeNpa &&
                        (errors.unloadingPlaceItems?.[index] as FormikErrors<UnloadingPlace>)?.postalCodeNpa && (
                          <span className="d-block text-danger">
                            {(errors.unloadingPlaceItems?.[index] as FormikErrors<UnloadingPlace>)?.postalCodeNpa}
                          </span>
                        )}
                      <DropDownList
                        style={{ width: '100%' }}
                        id={`unloadingPlaceItems[${index}].postalCodeNpa`}
                        name={`unloadingPlaceItems[${index}].postalCodeNpa`}
                        data={zonesPostalCodeNpas}
                        value={values.unloadingPlaceItems[index].postalCodeNpa}
                        onChange={(e) => {
                          const items = [...unloadingPlaceItems];
                          items[index].postalCodeNpa = e.value;
                          const postalCodes = zonePostalCodes?.filter(
                            (zpc) => zpc.npa === Number(unloadingPlaceItems[index].postalCodeNpa),
                          );
                          items[index].postalCodeLocation = postalCodes.length === 1 ? postalCodes[0].name : '';
                          setUnloadingPlaceItems(items);
                          handleChange(e);
                        }}
                      />

                      {zonePostalCodes &&
                        zonePostalCodes.filter((zpc) => zpc.npa === Number(unloadingPlaceItems[index].postalCodeNpa))
                          .length > 1 && (
                          <>
                            <label htmlFor={`unloadingPlaceItems[${index}].postalCodeLocation`} className="mb-1 mt-3">
                              {t('CheckPriceModal.PostalCodeLocation')}
                            </label>
                            {touched.unloadingPlaceItems?.[index]?.postalCodeLocation &&
                              (errors.unloadingPlaceItems?.[index] as FormikErrors<UnloadingPlace>)
                                ?.postalCodeLocation && (
                                <span className="d-block text-danger">
                                  {
                                    (errors.unloadingPlaceItems?.[index] as FormikErrors<UnloadingPlace>)
                                      ?.postalCodeLocation
                                  }
                                </span>
                              )}
                            <DropDownList
                              style={{ width: '100%' }}
                              id={`unloadingPlaceItems[${index}].postalCodeLocation`}
                              name={`unloadingPlaceItems[${index}].postalCodeLocation`}
                              data={zonePostalCodes
                                .filter((zpc) => zpc.npa === Number(unloadingPlaceItems[index].postalCodeNpa))
                                .map((pc) => pc.name)}
                              value={values.unloadingPlaceItems[index].postalCodeLocation}
                              onChange={(e) => {
                                const items = [...unloadingPlaceItems];
                                items[index].postalCodeLocation = e.target.value;
                                setUnloadingPlaceItems(items);
                                handleChange(e);
                              }}
                            />
                          </>
                        )}

                      <label htmlFor="quantity" className="mb-1 mt-3">
                        {t('CheckPriceModal.Quantity')} ({isWood ? 'kg' : isDiesel ? 'L' : 'L'})
                      </label>
                      {touched.unloadingPlaceItems?.[index]?.quantity &&
                        (errors.unloadingPlaceItems?.[index] as FormikErrors<UnloadingPlace>)?.quantity && (
                          <span className="d-block text-danger">
                            {(errors.unloadingPlaceItems?.[index] as FormikErrors<UnloadingPlace>)?.quantity}
                          </span>
                        )}
                      <input
                        id={`unloadingPlaceItems[${index}].quantity`}
                        className="form-control"
                        type="text"
                        name={`unloadingPlaceItems[${index}].quantity`}
                        value={values.unloadingPlaceItems[index].quantity}
                        onChange={async (e) => {
                          const items = [...unloadingPlaceItems];
                          items[index].quantity = Number(e.currentTarget.value);
                          setUnloadingPlaceItems(items);
                          handleChange(e);
                        }}
                      />
                    </div>
                  ))}
              </div>

              <div className="d-flex flex-column align-items-start justify-content-end mt-3 mt-md-0">
                <ColumnTitle>{t('CheckPriceModal.Output')}</ColumnTitle>

                <BlankLine className="mb-1">&zwnj;</BlankLine>
                <button className="btn btn-primary align-self-start" type="submit" disabled={isLoadingPrice}>
                  {isLoadingPrice ? <Spinner /> : <span>{t('CheckPriceModal.TestPrice')}</span>}
                </button>

                <table className="mt-3">
                  <tbody>
                    <tr>
                      <td className="pe-1">{t('CheckPriceModal.BasePrice')}:</td>
                      <td className="ps-1">
                        {roundPrice(priceCalculationResult?.basePriceWithoutVat ?? 0)}{' '}
                        {isWood ? 'CHF/1t' : isDiesel ? 'CHF/L' : 'CHF/100L'}
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-1">{t('CheckPriceModal.Margin')}:</td>
                      <td className="ps-1">
                        {roundPrice(priceCalculationResult?.unitMarginWithoutVat ?? 0)}{' '}
                        {isWood ? 'CHF/1t' : isDiesel ? 'CHF/L' : 'CHF/100L'}
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-1">{t('CheckPriceModal.UnloadingFee')}: </td>
                      <td className="ps-1">
                        {roundPrice(priceCalculationResult?.totalUnloadingFeeWithoutVat ?? 0)} CHF
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-1">{t('CheckPriceModal.Discount')} :</td>
                      <td className="ps-1">-{roundPrice(priceCalculationResult?.totalDiscountWithoutVat ?? 0)} CHF</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="d-flex flex-column align-items-start align-items-md-center justify-content-center align-self-stretch mt-3 mt-md-0">
                <h1>{roundPrice(priceCalculationResult?.totalPriceDiscounted ?? 0)} CHF</h1>
                <span className="align-self-md-end">
                  {roundPrice(priceCalculationResult?.unitPrice ?? 0)}{' '}
                  {isWood ? 'CHF/1t' : isDiesel ? 'CHF/L' : 'CHF/100L'} {t('CheckPriceModal.Including')}
                </span>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withModal(withErrorHandling(CheckPriceForm));
