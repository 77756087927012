import { BaseService } from './BaseService';
import { IAgrConfiguration } from '../models/ConfigurationModel';
import { Shop } from '../models/Shop';

/**
 * The service class to interact with the Shops.
 */
export class ShopsService extends BaseService {
  constructor(config: IAgrConfiguration | undefined) {
    super(config, '/shop');
  }

  /**
   * Retrieves all the shops.
   * @returns A list of all the shops
   */
  getAll(): Promise<Shop[]> {
    return this.get('/list');
  }

  /**
   * Adds a shop.
   * @param shop the shop to be added.
   */
  add(shop: Shop): Promise<Shop> {
    return this.post('', shop);
  }

  /**
   * Updates a shop
   * @param shop the shop to be updated.
   * @returns the updated shop.
   */
  update(shop: Shop): Promise<Shop> {
    return this.put('', shop);
  }

  delete(shopId: number) {
    return super.delete(`/${shopId}`);
  }
}
