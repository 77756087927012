import { object, string, number, array } from 'yup';

/**
 *
 * This file exports the function in order to get the validation schema
 * with the appropriate translation at runtime.
 *
 * @param t - The translation function as provided by the useTranslation
 *            i18next hook.
 *
 * @returns the validation schema with the appropriate translations.
 */
export default function getCheckPriceFormValidationSchema(t: any) {
  const requiredErrorSuffix = t('ValidationErrors.Required');
  const numberErrorSuffix = t('ValidationErrors.Number');
  const positiveErrorSuffix = t('ValidationErrors.PositiveNumber');

  const postalCodeNpaSchema = {
    postalCodeNpa: string().required(
      `${t('CheckPriceModal.ValidationErrors.ThePostalCodeNpa')} ${requiredErrorSuffix}.`,
    ),
  };

  const postalCodeLocationSchema = {
    postalCodeLocation: string().required(
      `${t('CheckPriceModal.ValidationErrors.ThePostalCodeLocation')} ${requiredErrorSuffix}.`,
    ),
  };

  const quantitySchema = {
    quantity: number()
      .typeError(`${t('CheckPriceModal.ValidationErrors.TheQuantity')} ${numberErrorSuffix}.`)
      .required(`${t('CheckPriceModal.ValidationErrors.TheQuantity')} ${requiredErrorSuffix}.`)
      .positive(`${t('CheckPriceModal.ValidationErrors.TheQuantity')} ${positiveErrorSuffix}.`),
  };

  const schema = object().shape({
    ...postalCodeNpaSchema,
    ...postalCodeLocationSchema,
    ...quantitySchema,
    unloadingPlaceItems: array().of(
      object().shape({
        ...postalCodeNpaSchema,
        ...postalCodeLocationSchema,
        ...quantitySchema,
      }),
    ),
  });

  return schema;
}
