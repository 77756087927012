import ClockIcon from '../../../../../assets/clock-icon.svg';

/**
 * The TimePicker props.
 */
interface TimePickerProps {
  /**
   * The input value.
   */
  value: string;

  /**
   * Whether the input is valid or not.
   * Used to fill the input background color with red.
   */
  isInputValid: boolean;

  /**
   * The onChange callback.
   */
  onChange: (value: string) => void;
}

export default function TimePicker({ value, isInputValid, onChange }: TimePickerProps) {
  return (
    <div className="d-inline-block">
      <div className="d-flex align-items-center">
        <input
          type="text"
          className="form-control"
          style={{ flex: '0 0 60px', backgroundColor: isInputValid ? '#f9f9f9' : 'rgba(255, 150, 150, 0.9)' }}
          maxLength={2}
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
        />

        <img className="mx-2" src={ClockIcon} alt="clock icon" />
      </div>
    </div>
  );
}
