import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkMap, Routes } from '../../../assets/constants/Links';

import './Navigation.scss';
import useIsInRole from '../../../hooks/useIsInRole';
import { Constants } from '@/constants/Constants';
import useIsDieselAdministrator from '@/hooks/useIsDieselAdministrator';

/**
 * The responsive side menu. It highlights the current page based on the route.
 */
function Navigation(): JSX.Element | null {
  /**
   * The translation service.
   */
  const { t } = useTranslation();

  /**
   * The location service.
   */
  const location = useLocation();

  /**
   * It is displayed when a key is missing in the localization files.
   */
  const missingKey = 'missing';

  /**
   * Maps the /users route with its key in the localization files.
   */
  const usersKey = LinkMap.find((l) => l.route == Routes.Users)?.key;

  /**
   * Maps the /products route with its key in the localization files.
   */
  const productsKey = LinkMap.find((l) => l.route == Routes.Products)?.key;
  const mailTemplatesKey = LinkMap.find((l) => l.route == Routes.MailTemplates)?.key;
  const zonesKey = LinkMap.find((l) => l.route == Routes.Zones)?.key;
  const shopsKey = LinkMap.find((l) => l.route == Routes.Shops)?.key;
  const systemLogsKey = LinkMap.find((l) => l.route == Routes.SystemLogs)?.key;
  const ordersKey = LinkMap.find((l) => l.route == Routes.Orders)?.key;
  const disporegionsKey = LinkMap.find((l) => l.route == Routes.Disporegions)?.key;
  const postalCodesKey = LinkMap.find((l) => l.route == Routes.PostalCodes)?.key;
  const marginsKey = LinkMap.find((l) => l.route == Routes.Margins)?.key;
  const alertsKey = LinkMap.find((l) => l.route == Routes.Alerts)?.key;
  const portalIntegrationKey = LinkMap.find((l) => l.route == Routes.PortalIntegration)?.key;
  const promotionsKey = LinkMap.find((l) => l.route == Routes.Promotions)?.key;
  const settingsKey = LinkMap.find((l) => l.route == Routes.Settings)?.key;
  const basePriceKey = LinkMap.find((l) => l.route == Routes.BasePrice)?.key;
  const priceLogsKey = LinkMap.find((l) => l.route == Routes.PriceLogs)?.key;
  const textResourcesKey = LinkMap.find((l) => l.route == Routes.TextResources)?.key;
  const dieselKey = LinkMap.find((l) => l.route == Routes.Diesel)?.key;

  const isAdministrator = useIsInRole(Constants.Roles.Administrator);
  const isShopUser = useIsInRole(Constants.Roles.ShopUser);
  const isHoldinguser = useIsInRole(Constants.Roles.HoldingUser);
  const isDieselAdministrator = useIsDieselAdministrator();

  return (
    <ul className="agr-navigation nav nav-pills flex-column">
      {isAdministrator && (
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === Routes.Users ? 'active' : ''}`} to={Routes.Users}>
            {t(usersKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isAdministrator && (
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === Routes.Products ? 'active' : ''}`} to={Routes.Products}>
            {t(productsKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isAdministrator && (
        <li className="nav-item">
          <Link
            className={`nav-link ${location.pathname === Routes.MailTemplates ? 'active' : ''}`}
            to={Routes.MailTemplates}
          >
            {t(mailTemplatesKey ?? missingKey)}
          </Link>
        </li>
      )}
      {(isAdministrator || isShopUser || isHoldinguser) && (
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === Routes.Zones ? 'active' : ''}`} to={Routes.Zones}>
            {t(zonesKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isDieselAdministrator && (isAdministrator || isShopUser || isHoldinguser) && (
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === Routes.Diesel ? 'active' : ''}`} to={Routes.Diesel}>
            {t(dieselKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isAdministrator && (
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === Routes.Shops ? 'active' : ''}`} to={Routes.Shops}>
            {t(shopsKey ?? missingKey)}
          </Link>
        </li>
      )}
      {(isAdministrator || isShopUser || isHoldinguser) && (
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === Routes.Orders ? 'active' : ''}`} to={Routes.Orders}>
            {t(ordersKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isAdministrator && (
        <li className="nav-item">
          <Link
            className={`nav-link ${location.pathname === Routes.Disporegions ? 'active' : ''}`}
            to={Routes.Disporegions}
          >
            {t(disporegionsKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isAdministrator && (
        <li className="nav-item">
          <Link
            className={`nav-link ${location.pathname === Routes.PostalCodes ? 'active' : ''}`}
            to={Routes.PostalCodes}
          >
            {t(postalCodesKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isHoldinguser && (
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === Routes.Margins ? 'active' : ''}`} to={Routes.Margins}>
            {t(marginsKey ?? missingKey)}
          </Link>
        </li>
      )}
      {(isAdministrator || isShopUser || isHoldinguser) && (
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === Routes.Alerts ? 'active' : ''}`} to={Routes.Alerts}>
            {t(alertsKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isAdministrator && (
        <li className="nav-item">
          <Link
            className={`nav-link ${location.pathname === Routes.PortalIntegration ? 'active' : ''}`}
            to={Routes.PortalIntegration}
          >
            {t(portalIntegrationKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isHoldinguser && (
        <li className="nav-item">
          <Link
            className={`nav-link ${location.pathname === Routes.Promotions ? 'active' : ''}`}
            to={Routes.Promotions}
          >
            {t(promotionsKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isAdministrator && (
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === Routes.Settings ? 'active' : ''}`} to={Routes.Settings}>
            {t(settingsKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isAdministrator && (
        <li className="nav-item">
          <Link
            className={`nav-link ${location.pathname === Routes.SystemLogs ? 'active' : ''}`}
            to={Routes.SystemLogs}
          >
            {t(systemLogsKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isHoldinguser && (
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === Routes.BasePrice ? 'active' : ''}`} to={Routes.BasePrice}>
            {t(basePriceKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isAdministrator && (
        <li className="nav-item">
          <Link className={`nav-link ${location.pathname === Routes.PriceLogs ? 'active' : ''}`} to={Routes.PriceLogs}>
            {t(priceLogsKey ?? missingKey)}
          </Link>
        </li>
      )}
      {isAdministrator && (
        <li className="nav-item">
          <Link
            className={`nav-link ${location.pathname === Routes.TextResources ? 'active' : ''}`}
            to={Routes.TextResources}
          >
            {t(textResourcesKey ?? missingKey)}
          </Link>
        </li>
      )}
    </ul>
  );
}

export default Navigation;
