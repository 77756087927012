export const operatorContains = 'contains';
export const operatorEq = 'eq';
export const operatorNotEq = 'neq';
export const operatorStartsWith = 'startswith';
export const operatorEndsWith = 'endswith';

/**
 * Holds the types of filter for the orders grid For the orders grid it is necessary
 * to remove some types of filter, because of the order number column which has a specific
 * behavior. However the grid must take the operators for all the kinds of columns (text,
 * numeric and boolean), hence they are stored them all here.
 */
export const filterOperators: {} = {
  text: [
    { text: 'grid.filterContainsOperator', operator: operatorContains },
    { text: 'grid.filterEqOperator', operator: operatorEq },
    { text: 'grid.filterNotEqOperator', operator: operatorNotEq },
    { text: 'grid.filterStartsWithOperator', operator: operatorStartsWith },
    { text: 'grid.filterEndsWithOperator', operator: operatorEndsWith },
  ],
  numeric: [
    { text: 'grid.filterEqOperator', operator: operatorEq },
    { text: 'grid.filterNotEqOperator', operator: operatorNotEq },
    { text: 'grid.filterGteOperator', operator: 'gte' },
    { text: 'grid.filterGtOperator', operator: 'gt' },
    { text: 'grid.filterLteOperator', operator: 'lte' },
    { text: 'grid.filterLtOperator', operator: 'lt' },
    { text: 'grid.filterIsNullOperator', operator: 'isnull' },
    { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' },
  ],
  date: [
    { text: 'grid.filterEqOperator', operator: operatorEq },
    { text: 'grid.filterNotEqOperator', operator: operatorNotEq },
    { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
    { text: 'grid.filterAfterOperator', operator: 'gt' },
    { text: 'grid.filterBeforeOperator', operator: 'lt' },
    { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
    { text: 'grid.filterIsNullOperator', operator: 'isnull' },
    { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' },
  ],
  boolean: [{ text: 'grid.filterEqOperator', operator: operatorEq }],
};
