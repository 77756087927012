/**
 * Removes the skip and top filters, necessary to retrieve the correct item count.
 */
export default function removePaginationFilters(odataString: string): string {
  const topIndex = odataString.indexOf('$top');
  const skipIndex = odataString.indexOf('$skip');
  if (topIndex > -1 && skipIndex > -1) {
    if (topIndex > skipIndex) {
      return odataString.slice(0, skipIndex);
    } else {
      return odataString.slice(0, topIndex);
    }
  } else if (topIndex > -1) {
    return odataString.slice(0, topIndex);
  } else if (skipIndex > -1) {
    return odataString.slice(0, skipIndex);
  } else {
    return odataString;
  }
}
