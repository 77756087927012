import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../../common/spinner/Spinner';

/**
 * The InspectPostalCodesButton props.
 */
interface InspectPostalCodesButtonProps {
  /**
   * Callback for button click.
   */
  onClick: () => void | Promise<void>;
}

/**
 *
 * The InspectPostalCodesButton Component.
 *
 * @returns the Component to be used in JSXs.
 */
export default function InspectPostalCodesButton({ onClick }: InspectPostalCodesButtonProps) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <button
      className="btn btn-secondary btn-sm mx-1"
      type="button"
      disabled={loading}
      onClick={() => {
        setLoading(true);

        Promise.resolve(onClick()).finally(() => setLoading(false));
      }}
    >
      {loading ? <Spinner /> : <span>{t('ZonesView.InspectPostalCodesButton')}</span>}
    </button>
  );
}
