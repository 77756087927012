import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { SettingService } from '../services/SettingService';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * SettingService to be used in components to make API calls.
 *
 * @returns a SettingService instance
 */
export default function useSettingService(): SettingService {
  const config = useContext(AgrConfigurationContext);

  const settingService = useMemo(() => {
    return new SettingService(config);
  }, [config]);

  return settingService;
}
