import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { PromotionsService } from '../services/PromotionsService';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * OrderPromotionsServicesService to be used in components to make API calls.
 *
 * @returns a PromotionsService instance
 */
export default function usePromotionsService(): PromotionsService {
  const config = useContext(AgrConfigurationContext);

  const memoizedPromotionsServices = useMemo(() => {
    return new PromotionsService(config);
  }, [config]);

  return memoizedPromotionsServices;
}
