import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorViewModel, Language, Setting, isErrorViewModel } from '../../../../../models';
import useSettingService from '../../../../../hooks/useSettingService';
import useErrorHandling from '../../../../../hooks/useErrorHandling';
import Spinner from '../../../../common/spinner/Spinner';
import useCurrentLanguage from '@/hooks/useCurrentLanguage';

/**
 *
 * The props for the ShopSettings Component.
 *
 */
interface ShopSettingsKeyValueProps {
  shopSetting: Setting | undefined;
}

/**
 *
 * This Component handles the Settings of the Shops.
 *
 * @returns the Component to be used in JSXs.
 */
export default function ShopSettingsKeyValue({ shopSetting }: ShopSettingsKeyValueProps): JSX.Element | null {
  const [loading, setLoading] = useState<boolean>(false);

  const { errors, setErrors } = useErrorHandling();

  const [setting, setSetting] = useState<Setting | undefined>(shopSetting);

  const settingService = useSettingService();

  const language = useCurrentLanguage();

  const { t } = useTranslation('translation', { keyPrefix: 'EditSettings' });

  useEffect(() => {
    async function initializeData() {
      if (shopSetting) {
        try {
          if (!shopSetting.settingValue || !shopSetting.settingName) {
            throw new Error('No Setting Value for this Setting found.');
          }
          setSetting(shopSetting);
        } catch (error) {
          if (isErrorViewModel(error)) {
            setErrors([...errors, error as ErrorViewModel]);
          } else {
            console.error(error);
          }
        }
      }
    }
    initializeData();
  }, [language]);

  return (
    <div className="d-flex flex-column bg-light mb-2">
      <div className="d-flex flex-row mb-12">
        <div className="p-2 text-success h5">
          {language === 'fr' ? setting?.settingDescriptionFR : setting?.settingDescriptionDE}
        </div>
      </div>
      <div className="d-flex flex-row bd-highlight mb-12">
        <div className="col-md-2 p-2">
          <span>{setting?.settingName}</span>
        </div>
        <div className="col-md-5 p-2">
          <input
            type="text"
            className="form-control"
            style={{ backgroundColor: 'white' }}
            value={setting?.settingValue}
            onChange={(e) => {
              const copy = Object.assign({}, setting);
              copy.settingValue = e.currentTarget.value;
              setSetting(copy);
            }}
          />
        </div>
      </div>
      <div className="modal-footer pb-0 pe-0">
        <button
          className="btn btn-primary"
          type="button"
          disabled={loading}
          onClick={async () => {
            try {
              setLoading(true);
              if (shopSetting == undefined) {
                return;
              }

              const settingForUpdate: Setting = {
                id: setting?.id ?? 0,
                settingName: setting?.settingName ?? '',
                settingData: setting?.settingData,
                settingValue: setting?.settingValue,
                settingValueType: setting?.settingValueType,
              };

              await settingService.update(settingForUpdate);
            } catch (error) {
              console.error(error);
              setErrors([...errors, error as ErrorViewModel]);
            } finally {
              setLoading(false);
            }
          }}
        >
          {loading ? <Spinner /> : t('SaveShopSetting')}
        </button>
      </div>
    </div>
  );
}
