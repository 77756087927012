import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { DisporegionsService } from '../services/DisporegionsService';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * DisporegionsService to be used in components to make API calls.
 *
 * @returns a DisporegionsService instance
 */
export default function useDisporegionsService(): DisporegionsService {
  const config = useContext(AgrConfigurationContext);

  const disporegionsService = useMemo(() => {
    return new DisporegionsService(config);
  }, [config]);

  return disporegionsService;
}
