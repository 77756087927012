/**
 * Application-wide contants.
 */
export const Constants = {
  Roles: {
    Administrator: 'Administrator' as UserRole,
    ShopUser: 'ShopUser' as UserRole,
    HoldingUser: 'HoldingUser' as UserRole,
  },
  Shops: {
    AllShops: 'AllShops',
  },
  ProductTypes: {
    Fuel: 'Fuel',
    Wood: 'Wood',
    Diesel: 'Diesel',
  },
  Topics: {
    UserInformation: 'UserInformation',
    Logout: 'Logout',
  },
  Validation: {
    TextMaxLength: 128,
    TextareaMaxLength: 65536,
  },
  SettingTypes: {
    ShopSettings: 'CustomerShopSetting',
  },
};

export type UserRole = 'Administrator' | 'ShopUser' | 'HoldingUser';
