import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  WoodProductTypeName,
  DieselProductTypeName,
  DieselProductTypeId,
} from '../../../../assets/constants/DataConstants';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useIsReadOnly from '../../../../hooks/useIsReadOnly';
import useIsDieselAdministrator from '../../../../hooks/useIsDieselAdministrator';
import useMarginsService from '../../../../hooks/useMarginsService';
import { ErrorViewModel, Margin, ProductType, Zone } from '../../../../models';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import ViewWrapper from '../../../layout/view-wrapper/ViewWrapper';
import { InlineActionButtons } from './inline-edit-actions/InlineActionButtons';
import { v4 as uuidv4 } from 'uuid';
import useApplicationState from '../../../../hooks/useApplicationState';
import useYupErrors from '../../../../hooks/useYupErrors';
import getEditMarginValidationSchema from './ValidationSchema';
import { ValidationError } from 'yup';
import LoadingPanel from '../../../common/loading-panel/LoadingPanel';
import roundPrice from '../../../../utils/roundPrice';
import { useCurrentLocale } from '../../../../hooks/useCurrentLocale';
import { useLocation } from 'react-router-dom';
import useZonesService from '@/hooks/useZonesService';
import { Constants } from '@/constants/Constants';

const editField = 'inEdit';

/**
 * Initial grid sorting.
 */
const initialSort: Array<SortDescriptor> = [{ field: 'deliveryQuantity', dir: 'asc' }];

/**
 * The styles for the content inside the ViewWrapper.
 */
const ContentWrapper = styled.div`
  margin: 0 20px 0 20px;
`;

/**
 * The type for the margin being edited.
 */
type InlineMargin = Margin & {
  [key: string]: any;
  inEdit: boolean;
  rowId?: string;
};

/**
 * The Yup interface for errors to be mapped to its schema.
 */
interface EditError {
  deliveryQuantity: number;
  marginValue: number;
}

/**
 * Dummy object to initialize the yup error schema
 * in order to map the errors array to a typed object.
 */
const yupEditErrorSchema: EditError = {
  deliveryQuantity: 0,
  marginValue: 0,
};

/**
 *
 * This Component represents the margin table view.
 *
 * @returns the JSX to be used in other Components.
 */
function ViewMargins() {
  const isReadOnly = useIsReadOnly();
  const isDieselAdministrator = useIsDieselAdministrator();

  const { t } = useTranslation();
  const language = useCurrentLanguage();
  const currentLocale = useCurrentLocale();

  const { errors, setErrors } = useErrorHandling();

  const mapErrors = useYupErrors<EditError>(yupEditErrorSchema);
  const AddMarginValidationSchema = useMemo(() => getEditMarginValidationSchema(t), [t]);

  const marginService = useMarginsService();
  const zonesService = useZonesService();

  const { holding, shops, role, setIsAuthorized } = useApplicationState();

  const [sort, setSort] = useState(initialSort);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [allMargins, setAllMargins] = useState<Margin[]>();
  const [inEditMargins, setInEditMargins] = useState<{ [rowId: string]: InlineMargin }>({});

  const [allProductTypes, setAllProductTypes] = useState<ProductType[]>();
  const [selectedProductType, setSelectedProductType] = useState<ProductType>();

  const location = useLocation();
  const [zone, setZone] = useState<Zone | null>(null);

  /**
   * This callback filters the margins based on the selected product type.
   */
  const filteredMarginsByProductType = useMemo(() => {
    return (
      allMargins?.filter((margin) => margin.productTypeId === selectedProductType?.id || (margin as any).inEdit) || []
    );
  }, [allMargins, selectedProductType]);

  /**
   * This callback is used in the main bootstrap useEffect.
   * It calls the API to get all the margins as well as all the
   * product types.
   * It also sets a default product type to start working with in the view
   * by choosing the first product type in the array.
   */
  const retrieveMargins = useCallback(async () => {
    if (!holding) {
      throw new Error("Couldn't get the holding information associated to the user.");
    }

    let zoneId: number | null = null;
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('zoneId')) {
      const queryParam = searchParams.get('zoneId');

      if (queryParam) {
        zoneId = parseInt(queryParam);

        if (zoneId && zoneId > 0) {
          const zoneViewModel = await zonesService.getByIdList([zoneId]);
          const zone = zoneViewModel.find((z) => z.id == zoneId);

          if (zone) {
            setZone(zone);
          } else {
            zoneId = null;
          }
        } else {
          zoneId = null;
        }
      }
    }

    if (role === Constants.Roles.ShopUser && !zoneId) {
      // admin users only allowed to change margins for a holding
      setIsAuthorized(false);
      return [];
    }

    if (role === Constants.Roles.Administrator && zoneId) {
      // admin users only allowed to change margins for a holding
      setIsAuthorized(false);
      return [];
    }

    if (zoneId) {
      const shop = shops.find((s) => s.zones?.find((z) => z.id === zoneId));
      if (!shop) {
        // user is not allowed to change mappings for this zone
        setIsAuthorized(false);
        return [];
      }
    }

    const allMargins = await marginService.getAll(holding.id, zoneId ?? null);

    if (!isDieselAdministrator || zoneId) {
      allMargins.productTypes = allMargins.productTypes.filter((pt) => pt.id !== DieselProductTypeId);
      allMargins.margins = allMargins.margins.filter((m) => m.productType?.id !== DieselProductTypeId);
    }

    if (allMargins.productTypes.length === 0) {
      throw new Error('No product types are available.');
    }

    setAllProductTypes(allMargins.productTypes);
    !selectedProductType && setSelectedProductType(allMargins.productTypes[0]); // Default product type if first render/not selected.

    return allMargins.margins.map<Margin>((margin) => ({
      ...margin,
      deliveryQuantity:
        margin.productType?.productName === WoodProductTypeName
          ? roundPrice(margin.deliveryQuantity * 1000)
          : margin.productType?.productName === DieselProductTypeName
          ? roundPrice(margin.deliveryQuantity * 100)
          : roundPrice(margin.deliveryQuantity * 100),
    }));
  }, [marginService, holding, setAllProductTypes, selectedProductType, setSelectedProductType]);

  // validate inline edit and return true if no errors found, false vice versa.
  const validateForm = useCallback(
    (margin: InlineMargin) => {
      try {
        AddMarginValidationSchema.validateSync(margin, { abortEarly: false });

        // setValidationErrors(null);

        return true;
      } catch (error) {
        const validationErrors = mapErrors(error as ValidationError);

        setErrors([
          ...errors,
          {
            title: 'Validation errors.',
            statusCode: '',
            value: {
              description: `${t('MarginsView.ValidationErrors.CannotSave')}: ${
                validationErrors.deliveryQuantity ? validationErrors.deliveryQuantity + '\n' : ''
              }${validationErrors.marginValue ?? ''}`,
            },
          },
        ]);

        return false;
      }
    },
    [AddMarginValidationSchema, mapErrors, setErrors, errors],
  );

  /**
   * This useEffect takes care of retrieving the necesary data for showing the view,
   * like getting the holding information related to the logged in user and
   * getting all the margins for the holding.
   */
  useEffect(() => {
    async function setupEnvironment() {
      try {
        setIsLoading(true);

        const margins = await retrieveMargins();
        setAllMargins(margins);
      } catch (error) {
        console.error(error);

        setErrors([
          ...errors,
          {
            title: 'Error while retrieving the information.',
            statusCode: '',
            value: { description: (error as Error).message },
          },
        ]);
      } finally {
        setIsLoading(false);
      }
    }

    setupEnvironment();
  }, []);

  /**
   * This callback takes care of getting a Margin array as an input
   * and given the rowId, it delets that margin from the array, returning
   * a new copy of the margins array.
   */
  const removeMarginFromArray = useCallback(
    (margins: Margin[], rowId: string) => {
      const newAllMargins = [...margins];
      const editedMargin = newAllMargins.find((margin) => (margin as InlineMargin).rowId === rowId);
      if (editedMargin && editedMargin.id) {
        const o = editedMargin as InlineMargin;
        o.inEdit = false;
        delete o.rowId;

        // edit existing item, do not remove
        return newAllMargins;
      }

      const indexMarginInEditToRemove = newAllMargins.findIndex((margin) => (margin as InlineMargin).rowId === rowId);

      if (indexMarginInEditToRemove < 0) {
        setErrors([
          ...errors,
          {
            title: 'Unexpected error',
            statusCode: '',
            value: { description: "Couldn't find margin in edit to remove from allMargins display list." },
          },
        ]);
      } else {
        newAllMargins.splice(indexMarginInEditToRemove, 1);
      }

      return newAllMargins;
    },
    [setErrors, errors],
  );

  /**
   * This callback removes the key of the saved margin that was previously in edit mode
   * from the specified margin dictionary.
   */
  const cleanupInEditMargins = useCallback((inEditMargins: { [rowId: string]: InlineMargin }, rowId: string) => {
    const newInEditMargins = { ...inEditMargins };
    delete newInEditMargins[rowId];

    return newInEditMargins;
  }, []);

  /**
   * This callback is triggered when the save button while in edit mode
   * for a margin is clicked, and calls the API to save the new margin
   * and takes care of cleaning up the states for getting the updated view.
   */
  const saveMargin = useCallback(
    async (rowId: string) => {
      const marginToSave = inEditMargins[rowId];
      marginToSave.deliveryQuantity /=
        selectedProductType?.productName === WoodProductTypeName
          ? 1000
          : selectedProductType?.productName === DieselProductTypeName
          ? 100
          : 100;

      const validEdit = validateForm(marginToSave);

      if (validEdit) {
        try {
          let successfullyAdded = false;
          if (marginToSave.id) {
            delete marginToSave.holding;
            delete marginToSave.productType;
            successfullyAdded = await marginService.updateMargin({ ...marginToSave } as Margin);
          } else {
            successfullyAdded = await marginService.addMargin({ ...marginToSave } as Margin);
          }

          if (successfullyAdded) {
            const addedMargin = marginToSave;
            addedMargin.inEdit = false;

            const newInEditMargins = cleanupInEditMargins(inEditMargins, rowId);
            const margins = await retrieveMargins();

            setInEditMargins(newInEditMargins);
            setAllMargins([...Object.keys(newInEditMargins).map<Margin>((key) => newInEditMargins[key]), ...margins]);
          }
        } catch (error) {
          console.error(error);

          if (error instanceof Error) {
            setErrors([
              ...errors,
              {
                title: 'Error while retrieving margins.',
                statusCode: '',
                value: { description: (error as Error).message },
              },
            ]);
          } else {
            setErrors([...errors, error as ErrorViewModel]);
          }
        }
      }
    },
    [
      marginService,
      inEditMargins,
      cleanupInEditMargins,
      retrieveMargins,
      setInEditMargins,
      setAllMargins,
      setErrors,
      errors,
    ],
  );

  /**
   * This callback is triggered when clicking on cancel while editing a margin.
   * It deletes the entry from the table and updates the states and view accordingly.
   */
  const cancelEdit = useCallback(
    (rowId: string) => {
      const newInEditMargins = cleanupInEditMargins(inEditMargins, rowId);
      const newAllMargins = removeMarginFromArray(allMargins || [], rowId);

      setInEditMargins(newInEditMargins);
      setAllMargins(newAllMargins);
    },
    [cleanupInEditMargins, inEditMargins, removeMarginFromArray, allMargins, setInEditMargins, setAllMargins],
  );

  /**
   * This callback is triggered by the delete button in the margin row of the table.
   * It deletes the entry by calling the appropriate API and updates the view.
   */
  const deleteMargin = useCallback(
    async (marginId: number) => {
      try {
        await marginService.delete(marginId);

        const margins = await retrieveMargins();

        setAllMargins([...Object.keys(inEditMargins).map<Margin>((key) => inEditMargins[key]), ...margins]);
      } catch (error) {
        console.error(error);

        if (error instanceof Error) {
          setErrors([
            ...errors,
            {
              title: 'Error while retrieving margins.',
              statusCode: '',
              value: { description: (error as Error).message },
            },
          ]);
        } else {
          setErrors([...errors, error as ErrorViewModel]);
        }
      }
    },
    [marginService, retrieveMargins, setAllMargins, inEditMargins, setErrors, errors],
  );

  if (isLoading) {
    return <LoadingPanel />;
  }

  if (!allProductTypes || allProductTypes.length === 0) {
    return <></>;
  }

  return (
    <ViewWrapper
      title={
        (zone?.name && (
          <Trans i18nKey="MarginsView.ZoneTitle" t={t}>
            {{ zone: zone.name }}
          </Trans>
        )) || (
          <Trans i18nKey="MarginsView.Title" t={t}>
            {{ holding: holding?.name }}
          </Trans>
        )
      }
    >
      <ContentWrapper>
        <span className="d-block">{t('MarginsView.SelectProductType')} </span>

        <DropDownList
          className="mt-2"
          data={allProductTypes}
          value={selectedProductType}
          dataItemKey="id"
          textField={'name' + capitalizeFirstLetter(language)}
          onChange={(e) => setSelectedProductType(e.target.value)}
        />

        <div className="d-flex mt-4 mb-1 align-items-center">
          <span className="h3" style={{ fontWeight: 600 }}>
            {t('Links.Margins')}
          </span>
          {!isReadOnly && (
            <button
              type="button"
              className="ms-2 btn btn-primary p-1 pt-0 pb-0 rounded-circle fw-bold"
              onClick={() => {
                const rowId = uuidv4();
                const inEditMarginToAdd = {
                  inEdit: true,
                  rowId: rowId,
                  productTypeId: selectedProductType?.id,
                  unitCode: selectedProductType?.unitCode,
                  holdingId: holding?.id,
                  zoneId: zone?.id,
                  quantity: 1, // Quantity refers to the unit quantity, so it's always one (or changing depending on business rules)
                } as InlineMargin;

                setInEditMargins({ [rowId]: inEditMarginToAdd, ...inEditMargins });
                setAllMargins(allMargins ? [inEditMarginToAdd, ...allMargins] : [inEditMarginToAdd]);
              }}
            >
              <span style={{ fontSize: 36, lineHeight: 0.65 }}>+</span>
            </button>
          )}
        </div>

        <LocalizationProvider language={language}>
          <IntlProvider locale={currentLocale}>
            <Grid
              data={[
                ...filteredMarginsByProductType.filter((margin) => !margin.id || margin.id <= 0),
                ...orderBy(
                  filteredMarginsByProductType.filter((margin) => margin.id > 0),
                  sort,
                ),
              ]}
              sortable={true}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
              }}
              editField={editField}
              resizable={true}
              onItemChange={(e) => {
                if (!e.field) {
                  setErrors([
                    ...errors,
                    {
                      title: 'Field to update is undefined',
                      statusCode: '',
                      value: { description: 'Field to update is undefined' },
                    },
                  ]);

                  return;
                }

                const rowId = (e.dataItem as InlineMargin)?.rowId;

                if (rowId) {
                  const newInEditMargin = inEditMargins[rowId];
                  newInEditMargin[e.field] = e.value;

                  const newInEditMargins = { ...inEditMargins };
                  newInEditMargins[rowId] = newInEditMargin;

                  setInEditMargins(newInEditMargins);
                }
              }}
            >
              <Column
                field="deliveryQuantity"
                title={
                  t('MarginsView.DeliveryQuantityColumn') +
                  `${
                    selectedProductType?.productName === WoodProductTypeName
                      ? ' (Kg)'
                      : selectedProductType?.productName === DieselProductTypeName
                      ? ' (L)'
                      : ' (L)'
                  }`
                }
              />
              <Column
                field="marginValue"
                title={
                  t('MarginsView.MarginColumn') +
                  `${
                    selectedProductType?.productName === WoodProductTypeName
                      ? ' (CHF/1t)'
                      : selectedProductType?.productName === DieselProductTypeName
                      ? ' (CHF/100L)'
                      : ' (CHF/100L)'
                  }`
                }
              />
              <Column
                title={t('Views.ColumnActions')}
                width={200}
                cell={(props) => (
                  <InlineActionButtons
                    rowId={props.dataItem['rowId']}
                    marginId={props.dataItem['id']}
                    inEdit={props.dataItem[editField]}
                    saveMargin={saveMargin}
                    editMargin={() => {
                      const rowId = uuidv4();
                      props.dataItem.inEdit = true;
                      props.dataItem.rowId = rowId;

                      setInEditMargins({ [rowId]: props.dataItem, ...inEditMargins });
                    }}
                    cancelEdit={cancelEdit}
                    deleteMargin={deleteMargin}
                  />
                )}
              />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </ContentWrapper>
    </ViewWrapper>
  );
}

export default withErrorHandling(ViewMargins);
