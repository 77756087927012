export function GetOrderName(t: (arg: string) => '', orderStatus: OrderStatus | null | undefined): string {
  switch (orderStatus) {
    case OrderStatus.Open:
      return t('OrderStatus.Open');
    case OrderStatus.Delivering:
      return t('OrderStatus.Delivering');
    case OrderStatus.Delivered:
      return t('OrderStatus.Delivered');
    case OrderStatus.Canceled:
      return t('OrderStatus.Canceled');
    case OrderStatus.Failed:
      return t('OrderStatus.Failed');
    default:
      throw 'Invalid status: ' + orderStatus;
  }
}

export enum OrderStatus {
  Open,
  Delivering,
  Delivered,
  Canceled,
  Failed,
}
