import { useContext, useMemo } from 'react';
import AgrConfigurationContext from '../contexts/AgrConfigurationContext';
import { PostalCodeService } from '../services/PostalCodeService';

/**
 *
 * This hook is to get an optimized (memoized) instance of a
 * PostalCodeService to be used in components to make API calls.
 *
 * @returns a PostalCodeService instance
 */
export default function usePostalCodeService(): PostalCodeService {
  const config = useContext(AgrConfigurationContext);

  const postalCodeService = useMemo(() => {
    return new PostalCodeService(config);
  }, [config]);

  return postalCodeService;
}
