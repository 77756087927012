import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ValidationError } from 'yup';
import { TextArea } from '@progress/kendo-react-inputs';
import { ErrorViewModel, Holding, Shop } from '../../../../models';
import withModal from '../../../hoc/with-modal/withModal';
import withErrorHandling from '../../../hoc/with-error-handling/withErrorHandling';
import useModal from '../../../../hooks/useModal';
import useYupErrors, { YupError } from '../../../../hooks/useYupErrors';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import useShopsService from '../../../../hooks/useShopsService';
import useHoldingsService from '../../../../hooks/useHoldingsService';
import useToggle from '../../../../hooks/useToggle';
import getShopFormValidationSchema from './ValidationSchema';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Spinner from '../../../common/spinner/Spinner';
import { ShopType } from '@/models/ShopType';
import useShopTypesService from '@/hooks/useShopTypesService';

interface InputLabelProps {
  validationError: boolean;
}

/**
 * Base style for labels in this from.
 */
const InputLabel = styled.label<InputLabelProps>`
  margin-bottom: ${({ validationError }) => (validationError ? '0' : '0.5em')};
`;

const ErrorMessage = styled.span.attrs(() => ({ className: 'text-danger' }))<{}>`
  margin-bottom: 0.25em;
`;

/**
 * The interface for the error messages
 */
export interface IShopError {
  name: string;
  holdingId: number;
  address: string;
  emailAddress: string;
  showWeeks: string;
  deliveryWaitTime: string;
  shopTypeId: number;
}

/**
 * Dummy object to initialize the yup error schema
 * in order to map the errors array to a typed object.
 */
const yupShopErrorSchema: IShopError = {
  name: '',
  holdingId: 0,
  address: '',
  emailAddress: '',
  showWeeks: '',
  deliveryWaitTime: '',
  shopTypeId: 2,
};

/**
 * Interface defining the shape for objects in the calendar dropdowns.
 */
interface CalendarDropdownValue {
  value: number | null;
  text: string;
}

/**
 * The form props.
 */
interface AddShopFormProps {
  update?: boolean;
  id?: number;
  name?: string;
  emailAddress?: string;
  address?: string;
  holding?: Holding | null;
  holdingId?: number | null;
  shopType?: ShopType | null;
  shopTypeId?: number | null;
}

/**
 * The interectable content of the modal for adding a Shop.
 * @returns the component to be displayed.
 */
const AddShopForm = ({
  update,
  id: shopId,
  name,
  address,
  emailAddress,
  holding,
  holdingId,
  shopType,
  shopTypeId,
}: AddShopFormProps) => {
  const { t } = useTranslation();

  const { handleSave } = useModal();

  const { errors, setErrors } = useErrorHandling();

  const mapErrors = useYupErrors<IShopError>(yupShopErrorSchema);

  const shopsService = useShopsService();

  const upsertShopValidationSchema = useMemo(() => getShopFormValidationSchema(t), [t]);

  const [loading, setLoading] = useState<boolean>(false);

  const [validationErrors, setValidationErrors] = useState<YupError<IShopError> | null>();

  const [updatedName, setName] = useState<string | undefined>(name ? name : '');

  const [updatedAddress, setUpdatedAddress] = useState<string | undefined>(address ? address : '');

  const [updatedHolding, setUpdatedHolding] = useState<Holding | null | undefined>(holding);

  const [updatedHoldingId, setUpdatedHoldingId] = useState<number | null | undefined>(holdingId);

  const [updatedShopType, setUpdatedShopType] = useState<ShopType | null | undefined>(shopType);

  const [updateShopTypeId, setUpdatedShopTypeId] = useState<number | null | undefined>(shopTypeId);

  const [updatedEmailAddress, setUpdatedEmailAddress] = useState<string | undefined>(emailAddress ? emailAddress : '');

  const [holdings, setHoldings] = useState<Holding[] | null>(null);

  const [shopTypes, setShopTypes] = useState<ShopType[] | null>(null);

  const [holdingsLoadToggle, setholdingsLoadToggle] = useToggle(true);

  const holdingsService = useHoldingsService();

  const shopTypesService = useShopTypesService();

  /**
   * Upon mounting, loads the holdinds
   */
  useEffect(() => {
    holdingsService.getAll().then((data) => {
      setHoldings(data);
      setholdingsLoadToggle(false);
    });
    shopTypesService.getAll().then((data) => {
      setShopTypes(data);
    });
  }, []);

  // validate form and return true if no errors found, false vice versa.
  const validateForm = useCallback(
    (shop: Shop) => {
      try {
        setValidationErrors(null);
        upsertShopValidationSchema.validateSync(shop, { abortEarly: false });
        return true;
      } catch (error) {
        setValidationErrors(mapErrors(error as ValidationError));

        return false;
      }
    },
    [upsertShopValidationSchema, setValidationErrors, mapErrors],
  );

  return (
    <div className="container px-2">
      <div className="d-flex flex-column align-items-start px-3">
        <InputLabel htmlFor="shopNameInput" validationError={!!validationErrors?.name}>
          {t('AddShopModal.ShopName')}
        </InputLabel>
        {validationErrors?.name && <ErrorMessage>{validationErrors.name}</ErrorMessage>}
        <input
          id="shopNameInput"
          type="text"
          className="form-control"
          value={updatedName}
          style={{ marginBottom: '1em' }}
          onChange={async (e) => {
            setName(e.target.value);
          }}
        />

        <InputLabel htmlFor="shopEmailInput" validationError={!!validationErrors?.emailAddress}>
          {t('AddShopModal.EmailAddress')}
        </InputLabel>
        {validationErrors?.emailAddress && <ErrorMessage>{validationErrors.emailAddress}</ErrorMessage>}
        <input
          id="shopEmailInput"
          type="text"
          className="form-control"
          value={updatedEmailAddress}
          style={{ marginBottom: '1em' }}
          onChange={async (e) => {
            setUpdatedEmailAddress(e.target.value);
          }}
        />

        <InputLabel htmlFor="holdingDropDown" validationError={!!validationErrors?.holdingId}>
          {t('AddShopModal.AssignHolding')}
        </InputLabel>
        {validationErrors?.holdingId && <ErrorMessage>{validationErrors.holdingId}</ErrorMessage>}
        <DropDownList
          id="holdingDropDown"
          style={{ marginBottom: '1em', width: '100%' }}
          data={holdings ? holdings : undefined}
          value={updatedHolding}
          textField="name"
          dataItemKey="id"
          disabled={holdingsLoadToggle}
          onChange={(e) => {
            setUpdatedHolding(e.value);
            setUpdatedHoldingId(e.value?.id);
          }}
        />

        <InputLabel htmlFor="shopTypesDropDown" validationError={!!validationErrors?.shopTypeId}>
          {t('AddShopModal.AssignShopType')}
        </InputLabel>
        {validationErrors?.shopTypeId && <ErrorMessage>{validationErrors.shopTypeId}</ErrorMessage>}
        <DropDownList
          id="shopTypesDropDown"
          style={{ marginBottom: '1em', width: '100%' }}
          data={shopTypes ? shopTypes : undefined}
          value={updatedShopType}
          textField="shopTypeName"
          dataItemKey="id"
          onChange={(e) => {
            setUpdatedShopType(e.value);
            setUpdatedShopTypeId(e.value?.id);
          }}
        />

        <InputLabel htmlFor="addressTextArea" validationError={!!validationErrors?.address}>
          {t('AddShopModal.Address')}
        </InputLabel>
        {validationErrors?.address && <ErrorMessage>{validationErrors.address}</ErrorMessage>}
        <TextArea
          style={{ marginBottom: '1em', width: '100%' }}
          id="addressTextArea"
          value={updatedAddress}
          onChange={async (e) => setUpdatedAddress(e.value)}
          rows={8}
        ></TextArea>
      </div>

      <div className="modal-footer pb-0 pe-0">
        <button
          className="btn btn-primary"
          type="button"
          disabled={loading}
          onClick={async () => {
            try {
              const shop: Shop = {
                id: shopId ? shopId : 0,
                name: updatedName,
                holdingId: updatedHoldingId,
                address: updatedAddress,
                emailAddress: updatedEmailAddress,
                shopTypeId: updateShopTypeId,
              };

              if (await validateForm(shop)) {
                setLoading(true);

                if (update) {
                  await shopsService.update(shop);
                } else {
                  await shopsService.add(shop);
                }

                handleSave!();
              }
            } catch (error) {
              console.error(error);

              setErrors([...errors, error as ErrorViewModel]);
            } finally {
              setLoading(false);
            }
          }}
        >
          {loading ? <Spinner /> : t('AddShopModal.SaveShop')}
        </button>
      </div>
    </div>
  );
};

export default withModal(withErrorHandling(AddShopForm));
