import { GridCell, GridCellProps } from '@progress/kendo-react-grid';

interface FormattableGridCellProps<T> extends GridCellProps {
  formatValue: (value: T) => string;
}

export const FormattableGridCell = <T extends unknown>(props: FormattableGridCellProps<T>) => {
  const { formatValue, ...otherProps } = props;
  return (
    <GridCell
      {...otherProps}
      render={(_, gridCellProps) => {
        const {
          dataItem,
          ariaColumnIndex,
          columnIndex,

          onChange,
          selectionChange,
          columnsCount,
          dataIndex,
          isSelected,
          render,
          expanded,
          rowType,

          ...tdProps
        } = gridCellProps;
        const fixedDashTdProps = { 'aria-colindex': ariaColumnIndex, 'data-grid-col-index': columnIndex };
        const value = formatValue(dataItem as T);
        return (
          <td {...tdProps} {...fixedDashTdProps}>
            {value}
          </td>
        );
      }}
    />
  );
};
